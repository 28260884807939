import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class VerifyFingerPrintVm {
	userId?: number;
	biometricXml?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'userId': new UntypedFormControl(null, [...(validators['userId'] ?? []), ...[]]),
			'biometricXml': new UntypedFormControl(null, [...(validators['biometricXml'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): VerifyFingerPrintVmFromFg {
		return new VerifyFingerPrintVmFromFg(fg);
	}
}

export class VerifyFingerPrintVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get userId(): number {
		return this.fg.get('userId').value;
	}
	set userId(val) {
		this.fg.get('userId').setValue(val);
	}

	get biometricXml(): string {
		return this.fg.get('biometricXml').value;
	}
	set biometricXml(val) {
		this.fg.get('biometricXml').setValue(val);
	}
}
