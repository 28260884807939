import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavRoute } from '../nav.route.interface';
import { NavDividerCharacter, NavigationService } from '../navigation.service';

@Component({
	selector: 'pcg-top-secondary-nav',
	templateUrl: './top-secondary-nav.component.html',
	styleUrls: ['./top-secondary-nav.component.scss']
})
export class TopSecondaryNavComponent implements OnInit, OnChanges {
	@Input() navRoutes: NavRoute[] = [];
	@Input() rootNavId: string;
	@Input() routeNavId: string;
	@Input() forceSelect: string;

	loopNavRoutes: NavRoute[] = [];

	constructor(public navService: NavigationService) { }

	ngOnInit() { this.loopNavRoutes = this.getNavRoutes(); }

	ngOnChanges(changes: SimpleChanges) {
		const chng = changes['navRoutes'];
		const cur = chng.currentValue;
		const prev = chng.previousValue;
		if (cur !== prev) { this.loopNavRoutes = this.getNavRoutes(); }
	}

	// Show or hide submenu based on dropdown click
	clickDropdown(childMenuId: string, back = false) {
		if (back) {
			const regexp = new RegExp(`${NavDividerCharacter}[^${NavDividerCharacter}]+$`);
			childMenuId = childMenuId.replace(regexp, '');
		}
		this.navService.setCurrOpenNavMenu(childMenuId, true);
		return false;
	}

	getNavRoutes() {
		const routeIndex = this.navService.getCurrRouteIndex(this.navRoutes);
		return (
			this.navRoutes
				?.map((o, i) => ({ ...o, index: i }))
				.filter(
					route =>
						!route?.shouldDisplay ||
						route.ellipseAfter ||
						route.ellipseBefore ||
						route?.shouldDisplay(routeIndex)
				) ?? []
		);
	}
}
