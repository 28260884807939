<div class="modal-header">
	<h5 class="modal-title" id="modal-basic-title">View Exception Message</h5>
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body text-center">
	<div id="messageDiv">{{ exceptionMsg }}</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-cancel btn-lg" (click)="modal.dismiss()">Close</button>
</div>
