import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { getMainNavRoutes } from "app/core/main-nav-routes";
import { SecurityService } from "app/core/security/security.service";
import { UserJsVm } from "app/shared/generated/Administration/Models/Users/UserJsVm";
import { NavigationService } from "app/shared/navigation/navigation.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    user$ : BehaviorSubject<UserJsVm> = new BehaviorSubject(UserService.getUser())

    constructor(public http : HttpClient, private nav : NavigationService, private sec : SecurityService) {}

    /** Returns the User object as it's stored in local storage */
    static getUser() : UserJsVm {
        const userJson: string = localStorage.getItem('user');
        if (userJson == null) return null;
        return JSON.parse(userJson);
    }

    /** Updates the User object within local storage */
    private setUser(json) {
        if (json == null) return;
        localStorage.setItem('user',JSON.stringify(json));
    }

    /**
     *  Subscribes to event changes on the User Behavior Subject.
     *  Updates the navigation upon event changes.
     * */
    listen() {
        this.user$.subscribe(_ => 
            // Updates navRoutes BehaviorSubject with newest nav route state
            this.nav.navRoutes.next(this.sec.getSecureNavItems(getMainNavRoutes())));
    }

    /**
     * Performs an HTTP GET request to retrieve the latest user object. 
     * Stores new object in local storage and emits event change to User behavior subject.
    */
    update() : void {
        this.http.get('api/Account/GetUserInfo').subscribe(resp => {
            const user = (resp as any)?.user
            this.setUser(user)
            this.user$.next(UserService.getUser())
        })
    }
}