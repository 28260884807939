import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class UserFacilityAgencyListVm {
	userFacilityId?: number;
	userHEROSAgencyId?: number;
	userId?: number;
	facilityId?: number;
	facilityIdentifier?: string;
	herosAgencyId?: number;
	userName?: string;
	facilityName?: string;
	facilityType?: string;
	canDelete: boolean;
	canAdd: boolean;
	countyId?: number;
	countyName?: string;
	pfsFacilityId?: number;
	facilityBusinessAreas?: any;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'userFacilityId': new UntypedFormControl(null, [...(validators['userFacilityId'] ?? []), ...[]]),
			'userHEROSAgencyId': new UntypedFormControl(null, [...(validators['userHEROSAgencyId'] ?? []), ...[]]),
			'userId': new UntypedFormControl(null, [...(validators['userId'] ?? []), ...[]]),
			'facilityId': new UntypedFormControl(null, [...(validators['facilityId'] ?? []), ...[]]),
			'facilityIdentifier': new UntypedFormControl(null, [...(validators['facilityIdentifier'] ?? []), ...[]]),
			'herosAgencyId': new UntypedFormControl(null, [...(validators['herosAgencyId'] ?? []), ...[]]),
			'userName': new UntypedFormControl(null, [...(validators['userName'] ?? []), ...[]]),
			'facilityName': new UntypedFormControl(null, [...(validators['facilityName'] ?? []), ...[]]),
			'facilityType': new UntypedFormControl(null, [...(validators['facilityType'] ?? []), ...[]]),
			'canDelete': new UntypedFormControl(false, [...(validators['canDelete'] ?? []), ...[]]),
			'canAdd': new UntypedFormControl(false, [...(validators['canAdd'] ?? []), ...[]]),
			'countyId': new UntypedFormControl(null, [...(validators['countyId'] ?? []), ...[]]),
			'countyName': new UntypedFormControl(null, [...(validators['countyName'] ?? []), ...[]]),
			'pfsFacilityId': new UntypedFormControl(null, [...(validators['pfsFacilityId'] ?? []), ...[]]),
			'facilityBusinessAreas': new UntypedFormControl([], [...(validators['facilityBusinessAreas'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['facilityName', {"orderId":0,"sortColumn":"facilityName","searchColumn":"facilityName","includeInExcel":true,"exportColumns":null,"displayName":"Facility","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['facilityIdentifier', {"orderId":1,"sortColumn":"facilityIdentifier","searchColumn":"facilityIdentifier","includeInExcel":true,"exportColumns":null,"displayName":"Identifier","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['countyName', {"orderId":2,"sortColumn":"countyName","searchColumn":"countyName","includeInExcel":true,"exportColumns":null,"displayName":"County","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['facilityType', {"orderId":3,"sortColumn":"facilityType","searchColumn":"facilityType","includeInExcel":true,"exportColumns":null,"displayName":"Type","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['canAdd', {"orderId":4,"sortColumn":"canAdd","searchColumn":"canAdd","includeInExcel":true,"exportColumns":null,"displayName":"Add","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['canDelete', {"orderId":5,"sortColumn":"canDelete","searchColumn":"canDelete","includeInExcel":true,"exportColumns":null,"displayName":"Remove","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): UserFacilityAgencyListVmFromFg {
		return new UserFacilityAgencyListVmFromFg(fg);
	}
}

export class UserFacilityAgencyListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get userFacilityId(): number {
		return this.fg.get('userFacilityId').value;
	}
	set userFacilityId(val) {
		this.fg.get('userFacilityId').setValue(val);
	}

	get userHEROSAgencyId(): number {
		return this.fg.get('userHEROSAgencyId').value;
	}
	set userHEROSAgencyId(val) {
		this.fg.get('userHEROSAgencyId').setValue(val);
	}

	get userId(): number {
		return this.fg.get('userId').value;
	}
	set userId(val) {
		this.fg.get('userId').setValue(val);
	}

	get facilityId(): number {
		return this.fg.get('facilityId').value;
	}
	set facilityId(val) {
		this.fg.get('facilityId').setValue(val);
	}

	get facilityIdentifier(): string {
		return this.fg.get('facilityIdentifier').value;
	}
	set facilityIdentifier(val) {
		this.fg.get('facilityIdentifier').setValue(val);
	}

	get herosAgencyId(): number {
		return this.fg.get('herosAgencyId').value;
	}
	set herosAgencyId(val) {
		this.fg.get('herosAgencyId').setValue(val);
	}

	get userName(): string {
		return this.fg.get('userName').value;
	}
	set userName(val) {
		this.fg.get('userName').setValue(val);
	}

	get facilityName(): string {
		return this.fg.get('facilityName').value;
	}
	set facilityName(val) {
		this.fg.get('facilityName').setValue(val);
	}

	get facilityType(): string {
		return this.fg.get('facilityType').value;
	}
	set facilityType(val) {
		this.fg.get('facilityType').setValue(val);
	}

	get canDelete(): boolean {
		return this.fg.get('canDelete').value;
	}
	set canDelete(val) {
		this.fg.get('canDelete').setValue(val);
	}

	get canAdd(): boolean {
		return this.fg.get('canAdd').value;
	}
	set canAdd(val) {
		this.fg.get('canAdd').setValue(val);
	}

	get countyId(): number {
		return this.fg.get('countyId').value;
	}
	set countyId(val) {
		this.fg.get('countyId').setValue(val);
	}

	get countyName(): string {
		return this.fg.get('countyName').value;
	}
	set countyName(val) {
		this.fg.get('countyName').setValue(val);
	}

	get pfsFacilityId(): number {
		return this.fg.get('pfsFacilityId').value;
	}
	set pfsFacilityId(val) {
		this.fg.get('pfsFacilityId').setValue(val);
	}
}
