<div class="modal-header bg-white rounded">
	<h5 
		class="modal-title" 
		id="modal-basic-title"
	>Notifications</h5>
	<button 
		type="button" 
		class="close" 
		aria-label="Close" 
		(click)="modal.dismiss()"
	>
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body rounded bg-light p-0">	

	<ng-container *ngFor='let r of reconNotifications; let i = index; let last = last'>
		<div class='p-3 notification'>
			<a 
				[href]="getHref(r.inventorySiteId, r.reconciliationEventId)" 
				class='notification-anchor'
			>
				<div class="row">
					<div class="col-md-12">
						<div class='row'>
							<div class='col-md-2'>
								<div class='text-center'>
									<fa-stack class='mt-2'>
										<fa-icon 
											stackItemSize='2x' 
											[icon]="faCircle" 
											class='notificationCircle'
										></fa-icon>
										<fa-icon 
											stackItemSize='1x' 
											[icon]="faBell" 
											[inverse]='true' 
											class='notificationBell'
										></fa-icon>
										<fa-icon 
											stackItemSize='1x' 
											[icon]="faLink" 
											[inverse]='true' 
											class='notificationLink'
										></fa-icon>
									</fa-stack>
								</div>
							</div>

							<div class='col-md-8'>
								<div class='row'>
									<div class='col-md-12'>
										<small class='font-weight-bold'>Scheduled Reconciliations - {{ r.inventoryName }}</small>
									</div>
									<div class='col-md-12'>
										<small class='text-muted'>Click to view Reconciliations.</small>
									</div>
								</div>
							</div>

							<div class='col-md-2'>
								<div class='mt-2 text-muted text-center'>
									<small class='text-muted'>{{ r.reconciliationCount }}</small>
								</div>
							</div>
						</div>
					</div>	
				</div>
			</a>
		</div>
		<hr *ngIf='!last' class='my-0' />
	</ng-container>

	<ng-container *ngIf="ciInvoiceCount > 0">
		<div class='p-3 notification'>
			<a 
				[href]="getInvoiceHref()" 
				class='notification-anchor'
			>
				<div class="row">
					<div class="col-md-12">
						<div class='row'>
							<div class='col-md-2'>
								<div class='text-center'>
									<fa-stack class='mt-2'>
										<fa-icon 
											stackItemSize='2x' 
											[icon]="faCircle" 
											class='notificationCircle'
										></fa-icon>
										<fa-icon 
											stackItemSize='1x' 
											[icon]="faBell" 
											[inverse]='true' 
											class='notificationBell'
										></fa-icon>
										<fa-icon 
											stackItemSize='1x' 
											[icon]="faLink" 
											[inverse]='true' 
											class='notificationLink'
										></fa-icon>
									</fa-stack>
								</div>
							</div>

							<div class='col-md-8'>
								<div class='row'>
									<div class='col-md-12'>
										<small class='font-weight-bold'>Cardinal Invoices Available</small>
									</div>
									<div class='col-md-12'>
										<small class='text-muted'>Click to view Invoices.</small>
									</div>
								</div>
							</div>

							<div class='col-md-2'>
								<div class='mt-2 text-muted text-center'>
									<small class='text-muted'>{{ ciInvoiceCount }}</small>
								</div>
							</div>
						</div>
					</div>	
				</div>
			</a>
		</div>
		<hr *ngIf='!last' class='my-0' />
	</ng-container>

	<ng-container *ngIf="icqCount > 0">
		<div class='p-3 notification'>
			<a 
			[href]="getIcqHref()" 
			class='notification-anchor'
		>
			<div class="row">
				<div class="col-md-12">
					<div class='row'>
						<div class='col-md-2'>
							<div class='text-center'>
								<fa-stack class='mt-2'>
									<fa-icon 
										stackItemSize='2x' 
										[icon]="faCircle" 
										class='notificationCircle'
									></fa-icon>
									<fa-icon 
										stackItemSize='1x' 
										[icon]="faBell" 
										[inverse]='true' 
										class='notificationBell'
									></fa-icon>
									<fa-icon 
										stackItemSize='1x' 
										[icon]="faLink" 
										[inverse]='true' 
										class='notificationLink'
									></fa-icon>
								</fa-stack>
							</div>
						</div>

						<div class='col-md-8'>
							<div class='row'>
								<div class='col-md-12'>
									<small class='font-weight-bold'>ICQ Reviews Available</small>
								</div>
								<div class='col-md-12'>
									<small class='text-muted'>Click to view ICQ List.</small>
								</div>
							</div>
						</div>

						<div class='col-md-2'>
							<div class='mt-2 text-muted text-center'>
								<small class='text-muted'>{{ icqCount }}</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a>
		</div>
		
		<hr *ngIf='!last' class='my-0' />
	</ng-container>

	<div 
		*ngIf='notificationsCount === 0' 
		class='row pt-3'
	>
		<div class='col-md-12'>
			<div class='text-center'>
				<p>There are no notifications.</p>
			</div>
		</div>
	</div>
	
</div>
