<form 
	(ngSubmit)="changePassword()" 
	[formGroup]="changePasswordForm"
>
	<div class="modal-header">
		<h5 
			class="modal-title" 
			id="modal-basic-title"
		>Change Password</h5>
		<button 
			type="button" 
			class="close" 
			aria-label="Close" 
			(click)="activeModal.dismiss()"
		><span aria-hidden="true">&times;</span></button>
	</div>
	<div class="modal-body">
		<div class="text-center">
			<u>Password Requirements</u><br />
			Must be at least 10 characters in Length.<br />
			Cannot contain more than 2 sequential characters.<br />
			Cannot match any of the passwords in our list of commonly used passwords.<br />
		</div>
		<div class='row'>
			<pcg-control-group class="col-sm-12">
				<pcg-show-hide-password
					fcName="currentPassword"
					[parentForm]="changePasswordForm" 
					label="Current Password"
					inputCssClass="form-control"
				></pcg-show-hide-password>
			</pcg-control-group>
		</div>
		<div class='row'>
			<pcg-control-group class="col-sm-12">		
				<pcg-show-hide-password
					fcName="password" 
					[parentForm]="changePasswordForm" 
					label="New Password"
					inputCssClass="form-control"
					[validations]='{ "commonPassword": "Pick a less common password.", "sequentialChars" : "Password cannot contain more than 2 sequential characters."  }'
				></pcg-show-hide-password>
			</pcg-control-group>
		</div>
		<div class='row'>
			<pcg-control-group class="col-sm-12">
				<pcg-show-hide-password
					fcName="confirmPassword"
					[parentForm]="changePasswordForm" 
					label="Confirm New Password"
					inputCssClass="form-control"
					[validations]='{ "passwordsNotEqual": "Passwords do not match." }'
				></pcg-show-hide-password>
			</pcg-control-group>
		</div>
	</div>
	<div class="modal-footer">
		<button 
			type="button" 
			class="btn btn-cancel btn-lg" 
			(click)="activeModal.dismiss()"
		>Cancel</button>
		<button 
			type="submit" 
			class="btn btn-save btn-lg"
		>Change Password</button>
	</div>
</form>
