import { Directive, Input, OnChanges, OnDestroy, HostListener, } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

export function getSortDuplicateSortableIdError(id: string): Error {
	return Error(`Cannot have two MatSortables with the same id (${id}).`);
}

export function getSortHeaderMissingIdError(): Error {
	return Error(`MatSortHeader must be provided with a unique id.`);
}

/** Interface for a directive that holds sorting state consumed by `PcgSortHeader`. */
export interface PcgSortable {
	/** The id of the column being sorted. */
	id: string;
}

/** The current sort state. */
export interface Sort {
	/** The ids of the columns being sorted. */
	sortData: string[][];
}

/** Container for PcgSortables to manage the sort state and provide default sort parameters. */
@Directive({
	selector: '[pcgSort]',
	exportAs: 'pcgSort'
})
export class PcgSortDirective implements OnChanges, OnDestroy {

	/** Whether or not shift is being held down, set in host listener below */
	isShiftDown = false;

	/** Collection of all registered sortables that this directive manages. */
	sortables = new Map<string, PcgSortable>();

	/** Used to notify any child components listening to state changes. */
	readonly _stateChanges = new Subject<void>();

	/** The ids and directions to sort on */
	@Input('pcgSort') sortData = [];

	/** Event emitted when the user changes either the active sort or sort direction. */
	readonly sortChange: BehaviorSubject<Sort> = new BehaviorSubject<Sort>({ sortData: [] });

	/**
	 * Register function to be used by the contained PcgSortables. Adds the PcgSortable to the
	 * collection of PcgSortables.
	 */
	register(sortable: PcgSortable): void {
		if (!sortable.id) { throw getSortHeaderMissingIdError(); }
		if (this.sortables.has(sortable.id)) { throw getSortDuplicateSortableIdError(sortable.id); }
		this.sortables.set(sortable.id, sortable);
	}

	/**
	 * Unregister function to be used by the contained PcgSortables. Removes the PcgSortable from the
	 * collection of contained PcgSortables.
	 */
	deregister(sortable: PcgSortable): void { this.sortables.delete(sortable.id); }

	/** Handle shift up and down */
	@HostListener('document:keydown', ['$event'])
	shiftDown(event: KeyboardEvent) { if (event.key === 'Shift') { this.isShiftDown = true; } }
	@HostListener('document:keyup', ['$event'])
	shiftUp(event: KeyboardEvent) { if (event.key === 'Shift') { this.isShiftDown = false; } }
	// Adding this because I noticed that using ctrl-shift-tab to go to previous tab
	// will cause the page to continue thinking those keys are being held down
	@HostListener('document:blur', ['$event'])
	onBlur() { this.isShiftDown = false; }

	/** Update the sort data based on the item clicked and whether or not shift is being held */
	sort(sortable: PcgSortable): void {
		if (!this.isShiftDown) {
			if (this.sortData[0][0] !== sortable.id) {
				this.sortData = [ [ sortable.id, 'asc' ] ];
			} else {
				this.sortData = [ 
					[ 
						sortable.id
						, this.sortData[0][1] === 'asc' 
							? 'desc' 
							: 'asc' 
					] 
				];
			}
		} else {
			const theSort = this.sortData.find(o => o[0] === sortable.id);
			if (theSort) { theSort[1] = theSort[1] === 'asc' ? 'desc' : 'asc'; }
			else { this.sortData.push([ sortable.id, 'asc' ]); }
		}
		this.sortChange.next({ sortData: this.sortData });
	}

	ngOnChanges() { this._stateChanges.next(); }
	ngOnDestroy() { this._stateChanges.complete(); }
}
