import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class RxToFillVm {
	rxNumber?: string;
	refillsRemaining?: string;
	errorMessage?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'rxNumber': new UntypedFormControl(null, [...(validators['rxNumber'] ?? []), ...[]]),
			'refillsRemaining': new UntypedFormControl(null, [...(validators['refillsRemaining'] ?? []), ...[]]),
			'errorMessage': new UntypedFormControl(null, [...(validators['errorMessage'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['rxNumber', {"orderId":0,"sortColumn":"rxNumber","searchColumn":"rxNumber","includeInExcel":true,"exportColumns":null,"displayName":"Rx Number","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['refillsRemaining', {"orderId":1,"sortColumn":"refillsRemaining","searchColumn":"refillsRemaining","includeInExcel":true,"exportColumns":null,"displayName":"Refills Remaining","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['errorMessage', {"orderId":2,"sortColumn":"errorMessage","searchColumn":"errorMessage","includeInExcel":true,"exportColumns":null,"displayName":"","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): RxToFillVmFromFg {
		return new RxToFillVmFromFg(fg);
	}
}

export class RxToFillVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get rxNumber(): string {
		return this.fg.get('rxNumber').value;
	}
	set rxNumber(val) {
		this.fg.get('rxNumber').setValue(val);
	}

	get refillsRemaining(): string {
		return this.fg.get('refillsRemaining').value;
	}
	set refillsRemaining(val) {
		this.fg.get('refillsRemaining').setValue(val);
	}

	get errorMessage(): string {
		return this.fg.get('errorMessage').value;
	}
	set errorMessage(val) {
		this.fg.get('errorMessage').setValue(val);
	}
}
