import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from '../../shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { AuthGuard } from '../security/auth.guard';
import { SecurityService } from '../security/security.service';

export const getMetabolicFormulaNav: () => NavRoute = () => {

    const user = getUser();
	var navItems: NavRoute[] = [
        {
            name: 'Order List'
            , exact: true
            , path: 'metabolic-formula/list'
            , moduleAccess: [
                {
                    modules: [ BusinessAreaEnum.MetabolicFormula ]
                    , permissionRoles: SecurityService.setMinRole(PermissionRole.User)
                }
            ]
        }
        , {
            name: 'Facility List'
            , exact: true
            , path: 'metabolic-formula/metabolic-facilities'
            , moduleAccess: [
                {
                    modules: [ BusinessAreaEnum.MetabolicFormula ]
                    , permissionRoles: [
                        PermissionRole.UserAdmin
                        , ...SecurityService.setMinRole(PermissionRole.Manager)
                    ]
                }
            ]
        }
    ];

	if (user?.errorReportBusinessAreas?.includes(BusinessAreaEnum.MetabolicFormula)) {
		navItems.push({
			name: 'Error Reporting'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.ErrorReport ]
                    , permissionRoles: SecurityService.setMinRole(PermissionRole.User)
				}
			]
			, exact: true
			, path: `error-report/error-report-list/${BusinessAreaEnum.MetabolicFormula}`
		});
	}

    navItems.push({
        name: 'Admin Settings'
        , exact: true
        , href: 'metabolic-formula/admin'
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.MetabolicFormula ]
                , permissionRoles: SecurityService.setMinRole(PermissionRole.User)
            }
        ]
        , children: [
            {
                name: 'Admin Settings'
                , exact: true
                , path: 'metabolic-formula/admin'
                , moduleAccess: [
                    {
                        modules: [ BusinessAreaEnum.MetabolicFormula ]
                        , permissionRoles: SecurityService.setMinRole(PermissionRole.Manager)
                    }
                ]
            }
        ]
    });

    return {
        name: 'Metabolic Formula'
        , canActivate: [ AuthGuard ]
        , href: 'metabolic-formula/list'
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.MetabolicFormula ]
                , permissionRoles: SecurityService.setMinRole(PermissionRole.User)
            }
        ]
        , children: navItems
    }
}

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}