import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class ForgotPasswordVm {
	email?: string;
	isWelcomeEmail: boolean;
	isHeros: boolean;
	isSendToAll: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[Validators.required, Validators.email]]),
			'isWelcomeEmail': new UntypedFormControl(false, [...(validators['isWelcomeEmail'] ?? []), ...[]]),
			'isHeros': new UntypedFormControl(false, [...(validators['isHeros'] ?? []), ...[]]),
			'isSendToAll': new UntypedFormControl(false, [...(validators['isSendToAll'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): ForgotPasswordVmFromFg {
		return new ForgotPasswordVmFromFg(fg);
	}
}

export class ForgotPasswordVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get email(): string {
		return this.fg.get('email').value;
	}
	set email(val) {
		this.fg.get('email').setValue(val);
	}

	get isWelcomeEmail(): boolean {
		return this.fg.get('isWelcomeEmail').value;
	}
	set isWelcomeEmail(val) {
		this.fg.get('isWelcomeEmail').setValue(val);
	}

	get isHeros(): boolean {
		return this.fg.get('isHeros').value;
	}
	set isHeros(val) {
		this.fg.get('isHeros').setValue(val);
	}

	get isSendToAll(): boolean {
		return this.fg.get('isSendToAll').value;
	}
	set isSendToAll(val) {
		this.fg.get('isSendToAll').setValue(val);
	}
}
