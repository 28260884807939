import { Component, OnInit } from '@angular/core';

import { ButtonFilterService } from '../button-filter/button-filter.service';

@Component({
	selector: 'pcg-button-reset-filter',
	templateUrl: './button-reset-filter.component.html',
	styleUrls: ['./button-reset-filter.component.scss']
})
export class ButtonResetFilterComponent implements OnInit {

    resetFiltersBool = false;

	constructor(private btnFilterService: ButtonFilterService) { }

	ngOnInit() { }

    resetFilterData(){
        this.resetFiltersBool = true;
        this.btnFilterService.sendResetFilterData(this.resetFiltersBool);
        this.resetFiltersBool = false;
    }
}
