import { AfterViewInit, Component } from '@angular/core';

import { PushNotificationService } from '../push-notification.service';

@Component({
    selector: 'pcg-install-timer',
    templateUrl: './install-timer.component.html',
    styleUrls: ['./install-timer.component.scss']
})
export class InstallTimerComponent implements AfterViewInit {

    targetDate: Date;
    date: any;
    warning: boolean;
    minimized: boolean;
    minutes: number;
    seconds: number;
    navSpacerVisible: boolean;

    ngAfterViewInit() {
        let updateTimer = localStorage.getItem('update-timer');

        if (PushNotificationService.checkForSystemUpdate()) {
            this.targetDate = new Date(JSON.parse(updateTimer));
            setInterval(() => this.tickTock(), 1000);
        }
    }

    tickTock() {
        this.targetDate = PushNotificationService.checkForSystemUpdate();

        if (this.targetDate) {
            this.date = new Date();
            let diffMs = (this.targetDate?.valueOf() - this.date.valueOf());          // Milliseconds between now & update
            let min = Math.ceil(((diffMs % 86400000) % 3600000) / 60000) - 1;         // Whole minutes between now & update
            let sec = Math.ceil(((diffMs % 86400000) % 3600000) % 60000 / 1000) - 1;  // Remaining seconds
            this.minutes = min >= 0 ? min : 60 + min;
            this.seconds = sec >= 0 ? sec : 60 + sec;
        }
        // Prevent count down going negative
        else { this.minutes = this.seconds = 0; }

        this.warning = !this.targetDate || (this.minutes <= 0 && this.seconds < 30);
    }

    toggleVisibility() {
        this.navSpacerVisible = PushNotificationService.checkForNavSpace();
        this.targetDate = PushNotificationService.checkForSystemUpdate();

        if (!this.targetDate) { this.date = null; }
        else { this.minimized = !this.minimized; }
    }
}
