import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class DonorRegistrationVm {
	honeyPot?: string;
	donorId: number;
	name?: string;
	phoneNumber?: string;
	address?: string;
	addressId?: number;
	smartyAddress?: any;
	smartyAddressString?: string;
	county?: any;
	countyId: number;
	emailAddress?: string;
	contactName?: string;
	contactPhone?: string;
	signature?: string;
	dateSigned: Date;
	isAttested?: boolean;
	facilityType?: string;
	facilityTypeId: number;
	comments?: string;
	statusId: number;
	deniedReasonId?: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'honeyPot': new UntypedFormControl(null, [...(validators['honeyPot'] ?? []), ...[]]),
			'donorId': new UntypedFormControl(0, [...(validators['donorId'] ?? []), ...[Validators.required]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[Validators.required]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[Validators.pattern("(\\+\\d{1,3}\\s?)?((\\(\\d{3}\\)\\s?)|(\\d{3})(\\s|-?))(\\d{3}(\\s|-?))(\\d{4})(\\s?(([E|e]xt[:|.|]?)|x|X)(\\s?\\d+))?")]]),
			'address': new UntypedFormControl(null, [...(validators['address'] ?? []), ...[]]),
			'addressId': new UntypedFormControl(null, [...(validators['addressId'] ?? []), ...[]]),
			'smartyAddress': new UntypedFormControl(null, [...(validators['smartyAddress'] ?? []), ...[Validators.required]]),
			'smartyAddressString': new UntypedFormControl(null, [...(validators['smartyAddressString'] ?? []), ...[]]),
			'county': new UntypedFormControl(null, [...(validators['county'] ?? []), ...[]]),
			'countyId': new UntypedFormControl(0, [...(validators['countyId'] ?? []), ...[Validators.required]]),
			'emailAddress': new UntypedFormControl(null, [...(validators['emailAddress'] ?? []), ...[Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]]),
			'contactName': new UntypedFormControl(null, [...(validators['contactName'] ?? []), ...[Validators.required]]),
			'contactPhone': new UntypedFormControl(null, [...(validators['contactPhone'] ?? []), ...[Validators.required, Validators.pattern("(\\+\\d{1,3}\\s?)?((\\(\\d{3}\\)\\s?)|(\\d{3})(\\s|-?))(\\d{3}(\\s|-?))(\\d{4})(\\s?(([E|e]xt[:|.|]?)|x|X)(\\s?\\d+))?")]]),
			'signature': new UntypedFormControl(null, [...(validators['signature'] ?? []), ...[Validators.required]]),
			'dateSigned': new UntypedFormControl('', [...(validators['dateSigned'] ?? []), ...[Validators.required]]),
			'isAttested': new UntypedFormControl(null, [...(validators['isAttested'] ?? []), ...[Validators.required]]),
			'facilityType': new UntypedFormControl(null, [...(validators['facilityType'] ?? []), ...[]]),
			'facilityTypeId': new UntypedFormControl(0, [...(validators['facilityTypeId'] ?? []), ...[Validators.required]]),
			'comments': new UntypedFormControl(null, [...(validators['comments'] ?? []), ...[Validators.maxLength(250)]]),
			'statusId': new UntypedFormControl(0, [...(validators['statusId'] ?? []), ...[Validators.required]]),
			'deniedReasonId': new UntypedFormControl(null, [...(validators['deniedReasonId'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): DonorRegistrationVmFromFg {
		return new DonorRegistrationVmFromFg(fg);
	}
}

export class DonorRegistrationVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get honeyPot(): string {
		return this.fg.get('honeyPot').value;
	}
	set honeyPot(val) {
		this.fg.get('honeyPot').setValue(val);
	}

	get donorId(): number {
		return this.fg.get('donorId').value;
	}
	set donorId(val) {
		this.fg.get('donorId').setValue(val);
	}

	get name(): string {
		return this.fg.get('name').value;
	}
	set name(val) {
		this.fg.get('name').setValue(val);
	}

	get phoneNumber(): string {
		return this.fg.get('phoneNumber').value;
	}
	set phoneNumber(val) {
		this.fg.get('phoneNumber').setValue(val);
	}

	get address(): string {
		return this.fg.get('address').value;
	}
	set address(val) {
		this.fg.get('address').setValue(val);
	}

	get addressId(): number {
		return this.fg.get('addressId').value;
	}
	set addressId(val) {
		this.fg.get('addressId').setValue(val);
	}

	get smartyAddress(): any {
		return this.fg.get('smartyAddress').value;
	}
	set smartyAddress(val) {
		this.fg.get('smartyAddress').setValue(val);
	}

	get smartyAddressString(): string {
		return this.fg.get('smartyAddressString').value;
	}
	set smartyAddressString(val) {
		this.fg.get('smartyAddressString').setValue(val);
	}

	get county(): any {
		return this.fg.get('county').value;
	}
	set county(val) {
		this.fg.get('county').setValue(val);
	}

	get countyId(): number {
		return this.fg.get('countyId').value;
	}
	set countyId(val) {
		this.fg.get('countyId').setValue(val);
	}

	get emailAddress(): string {
		return this.fg.get('emailAddress').value;
	}
	set emailAddress(val) {
		this.fg.get('emailAddress').setValue(val);
	}

	get contactName(): string {
		return this.fg.get('contactName').value;
	}
	set contactName(val) {
		this.fg.get('contactName').setValue(val);
	}

	get contactPhone(): string {
		return this.fg.get('contactPhone').value;
	}
	set contactPhone(val) {
		this.fg.get('contactPhone').setValue(val);
	}

	get signature(): string {
		return this.fg.get('signature').value;
	}
	set signature(val) {
		this.fg.get('signature').setValue(val);
	}

	get dateSigned(): Date {
		return this.fg.get('dateSigned').value;
	}
	set dateSigned(val) {
		this.fg.get('dateSigned').setValue(val);
	}

	get isAttested(): boolean {
		return this.fg.get('isAttested').value;
	}
	set isAttested(val) {
		this.fg.get('isAttested').setValue(val);
	}

	get facilityType(): string {
		return this.fg.get('facilityType').value;
	}
	set facilityType(val) {
		this.fg.get('facilityType').setValue(val);
	}

	get facilityTypeId(): number {
		return this.fg.get('facilityTypeId').value;
	}
	set facilityTypeId(val) {
		this.fg.get('facilityTypeId').setValue(val);
	}

	get comments(): string {
		return this.fg.get('comments').value;
	}
	set comments(val) {
		this.fg.get('comments').setValue(val);
	}

	get statusId(): number {
		return this.fg.get('statusId').value;
	}
	set statusId(val) {
		this.fg.get('statusId').setValue(val);
	}

	get deniedReasonId(): number {
		return this.fg.get('deniedReasonId').value;
	}
	set deniedReasonId(val) {
		this.fg.get('deniedReasonId').setValue(val);
	}
}
