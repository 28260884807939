/* Documentation for the trix editor is in the ReadMe of the NPM package https://www.npmjs.com/package/trix */

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PcgFile } from '../file-upload/file-upload.component';
import { SystemMessageService } from 'app/core/system-message/system-message-service';

@Component({
    selector: 'pcg-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss']
})
export class TextEditorComponent {

    @Input() editorValue: any;
    @Input() imageFolder: string;
    @Input() minHeight: string = "10em";
    @Input() excludeImages = false;

    @Output() pcgFileChange = new EventEmitter<PcgFile[]>();

    textEditor: any;
    allowedFiles: string = "image";
    imgFileTypes = "bmp,gif,png,jpg,jpeg,tiff";
    pcgFiles: PcgFile[] = [];

    constructor(public ms: SystemMessageService) {}

    ngOnInit() { }

    ngAfterViewInit() {
        this.textEditor = document.querySelector("trix-editor");
        this.textEditor?.editor?.setSelectedRange([0, 0]);
        if (this.editorValue != null) { this.textEditor?.editor?.insertHTML(this.editorValue); }
        this.textEditor?.setAttribute("style", `min-height: ${this.minHeight}`);

        if (this.excludeImages == true) {
            document.addEventListener("trix-file-accept", (event: any) => {
                this.ms.setSystemMessage("File upload is not allowed.", "warning");
                event.preventDefault(); // stops files from being uploaded to trix editor 
            });
        }

        document.addEventListener("trix-attachment-add", (attachment: any) => {
            if (attachment.attachment.file) {
                this.uploadFile(attachment);
                var attributes = {
                    url: `uploads/${this.imageFolder}/${attachment.attachment.file.name}`
                };
                attachment.attachment.setAttributes(attributes);
            }
        });
    }

    uploadFile(file: any) {
        let el = file.attachment.file;
        const fileType = el?.name.split('.').pop();

        if (this.allowedFiles.includes("image")) {
            if (!this.imgFileTypes.includes(fileType)) {
                if (!this.allowedFiles.includes(fileType)) {
                    this.ms.setSystemMessage("File type " + fileType + " is not allowed. Acceptable file types are " + this.allowedFiles, "error");
                    return;
                }
            }
        } else { return this.ms.setSystemMessage("Only image files are allowed to be uploaded", "error"); }

        if (el && el instanceof File) {
            let fileSize = 0;
            const p: PcgFile = new PcgFile();
            const fileReader = new FileReader();            
            fileReader.onload = () => {
                p.fileName = el.name;
                p.contentType = el.type || 'application/octet-stream';
                p.contentLength = el.size;
                p.fileContent = fileReader.result as string;
                fileSize = el.size/1024/1024;
            };
            fileReader.readAsDataURL(el);

            setTimeout(() => { 
                if (fileSize > 10) { this.ms.setSystemMessage("File size is too large. 10 mb is the maximum.", "error"); } 
                else {
                    this.pcgFiles.push(p);
                    this.pcgFileChange.emit(this.pcgFiles);       
                }
            }, 300);
        }
    }
}
