import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'pcg-multi-factor-auth-modal',
	templateUrl: './multi-factor-auth-modal.component.html',
	styleUrls: ['./multi-factor-auth-modal.component.scss']
})
export class MultiFactorAuthModalComponent implements OnInit {

	constructor(public modal: NgbActiveModal) { }

	ngOnInit() { }

}
