import { Injectable } from '@angular/core';
import { CollapsibleTableRowStateEnum } from 'app/core/enums/CollapsibleTableRowStateEnum';

@Injectable({
    providedIn: 'root'
})
export class CollapsibleTableService {
    //Current set of expanded rows
	expandedRows = new Set<number>();

	constructor() {}	

	/**
	 * Toggle a row expanded or collapsed; the opposite of what it is currently;
	 *
	 * @param row the row to toggle
	 */
	toggleRow(row: number) {
		if (this.expandedOrCollapsed(row) === CollapsibleTableRowStateEnum.Expanded) {
			this.setCollapsed(row);
			return;
		}
		this.setExpanded(row);
	}

	/**
	 * Expand a row
	 *
	 * @param row the row that we would like to expand
	 */
	setExpanded(row: number) {
		this.expandedRows.add(row);
	}

	/**
	 * Collapse a row
	 *
	 * @param row the row to collapse
	 */
	setCollapsed(row: number) {
		this.expandedRows.delete(row);
	}

	/**
	 * Toggles expanding of all rows at once
	 *
	 * @param length The length of the current number of rows
	 */
	toggleAllRows(length: number) {
		if (this.expandedRows.size > 0) {
			this.expandedRows.clear();
		} else {
			this.expandedRows = new Set<number>([...Array(length).keys()]);
		}
	}

	/**
	 * Collapses all rows at once
	 */
	collapseAllRows() {
		if (this.expandedRows.size > 0) {
			this.expandedRows.clear();
		} 
	}

	/**
	 * Function to use in the template to determine of if a row should be expanded or collapsed
	 *
	 * @param row the row that we would like to see if its expanded or collapsed
	 */
	expandedOrCollapsed(row: number) {
		if (this.expandedRows.has(row)) {
			return CollapsibleTableRowStateEnum.Expanded;
		}
		return CollapsibleTableRowStateEnum.Collapsed;
	}
}
