import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class AuditHistoryVm {
	auditHistoryID: number;
	dateChanged: Date;
	dateChangedFormatted?: string;
	field?: string;
	changedFrom?: string;
	changedTo?: string;
	changedBy?: string;
	pfsLink?: string;
	module?: string;
	recordAffected?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'auditHistoryID': new UntypedFormControl(0, [...(validators['auditHistoryID'] ?? []), ...[Validators.required]]),
			'dateChanged': new UntypedFormControl('', [...(validators['dateChanged'] ?? []), ...[Validators.required]]),
			'dateChangedFormatted': new UntypedFormControl(null, [...(validators['dateChangedFormatted'] ?? []), ...[]]),
			'field': new UntypedFormControl(null, [...(validators['field'] ?? []), ...[]]),
			'changedFrom': new UntypedFormControl(null, [...(validators['changedFrom'] ?? []), ...[]]),
			'changedTo': new UntypedFormControl(null, [...(validators['changedTo'] ?? []), ...[]]),
			'changedBy': new UntypedFormControl(null, [...(validators['changedBy'] ?? []), ...[]]),
			'pfsLink': new UntypedFormControl(null, [...(validators['pfsLink'] ?? []), ...[]]),
			'module': new UntypedFormControl(null, [...(validators['module'] ?? []), ...[]]),
			'recordAffected': new UntypedFormControl(null, [...(validators['recordAffected'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['dateChanged', {"orderId":0,"sortColumn":"dateChanged","searchColumn":"dateChangedFormatted","includeInExcel":true,"exportColumns":null,"displayName":"Date Changed","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['module', {"orderId":1,"sortColumn":"module","searchColumn":"module","includeInExcel":true,"exportColumns":null,"displayName":"Module","isVisible":false,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['recordAffected', {"orderId":2,"sortColumn":"recordAffected","searchColumn":"recordAffected","includeInExcel":true,"exportColumns":null,"displayName":"Record Affected","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['field', {"orderId":3,"sortColumn":"field","searchColumn":"field","includeInExcel":true,"exportColumns":null,"displayName":"Field","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['changedFrom', {"orderId":4,"sortColumn":"changedFrom","searchColumn":"changedFrom","includeInExcel":true,"exportColumns":null,"displayName":"Changed From","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['changedTo', {"orderId":5,"sortColumn":"changedTo","searchColumn":"changedTo","includeInExcel":true,"exportColumns":null,"displayName":"Changed To","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['changedBy', {"orderId":6,"sortColumn":"changedBy","searchColumn":"changedBy","includeInExcel":true,"exportColumns":null,"displayName":"Changed By","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): AuditHistoryVmFromFg {
		return new AuditHistoryVmFromFg(fg);
	}
}

export class AuditHistoryVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get auditHistoryID(): number {
		return this.fg.get('auditHistoryID').value;
	}
	set auditHistoryID(val) {
		this.fg.get('auditHistoryID').setValue(val);
	}

	get dateChanged(): Date {
		return this.fg.get('dateChanged').value;
	}
	set dateChanged(val) {
		this.fg.get('dateChanged').setValue(val);
	}

	get dateChangedFormatted(): string {
		return this.fg.get('dateChangedFormatted').value;
	}
	set dateChangedFormatted(val) {
		this.fg.get('dateChangedFormatted').setValue(val);
	}

	get field(): string {
		return this.fg.get('field').value;
	}
	set field(val) {
		this.fg.get('field').setValue(val);
	}

	get changedFrom(): string {
		return this.fg.get('changedFrom').value;
	}
	set changedFrom(val) {
		this.fg.get('changedFrom').setValue(val);
	}

	get changedTo(): string {
		return this.fg.get('changedTo').value;
	}
	set changedTo(val) {
		this.fg.get('changedTo').setValue(val);
	}

	get changedBy(): string {
		return this.fg.get('changedBy').value;
	}
	set changedBy(val) {
		this.fg.get('changedBy').setValue(val);
	}

	get pfsLink(): string {
		return this.fg.get('pfsLink').value;
	}
	set pfsLink(val) {
		this.fg.get('pfsLink').setValue(val);
	}

	get module(): string {
		return this.fg.get('module').value;
	}
	set module(val) {
		this.fg.get('module').setValue(val);
	}

	get recordAffected(): string {
		return this.fg.get('recordAffected').value;
	}
	set recordAffected(val) {
		this.fg.get('recordAffected').setValue(val);
	}
}
