<h3>Available Organizations</h3>

<pcg-table
    #table
    dataSource="api/Administration/UserOrganization/GetNonAssociatedOrganizations"
    [pcgSort]="[['organizationName', 'asc']]"
    [ajaxData]="params"
    [columnDefs]="columns"
    [canExactMatchSearch]="false"
    [pageSize]="10" 
    [isRowClickable]="editable"
    (clickRow)="add($event.organizationId)"
    (tableReceive)="tblReceive($event)"
>

    <ng-container matColumnDef="organizationName">
        <th
            mat-header-cell
            *matHeaderCellDef
        >{{ columns.get('organizationName').displayName }}</th>
        <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="columns.get('organizationName').displayName"
        >{{ row.organizationName }}</td>        
    </ng-container>

    <ng-container matColumnDef="city">
        <th
            mat-header-cell
            *matHeaderCellDef
        >{{ columns.get('city').displayName }}</th>
        <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="columns.get('city').displayName"
        >{{ row.city }}</td>            
    </ng-container>

    <ng-container matColumnDef="county">
        <th
            mat-header-cell
            *matHeaderCellDef
        >{{ columns.get('county').displayName }}</th>
        <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="columns.get('county').displayName"
        >{{ row.county }}</td>            
    </ng-container>

    <ng-container matColumnDef="facilityCount">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="text-right"
        >{{ columns.get('facilityCount').displayName }}</th>
        <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="columns.get('facilityCount').displayName"
            class="text-right"
        >{{ row.facilityCount }}</td>            
    </ng-container>

    <ng-container 
        matColumnDef="add">
        <th
            class="text-center"
            mat-header-cell
            *matHeaderCellDef
        >
            <button
                *ngIf="hasData"
                type="button"
                class="btn btn-sm btn-save text-nowrap"
                ngbTooltip="Search will not affect Add All."
                container="body"
                (click)="addAll()"
            >
                <fa-icon [icon]="faIcon.faPlus"></fa-icon> All
            </button>
        </th>

        <td
            class="text-center"
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="columns.get('add').displayName"
        >
            <fa-icon 
                class="plus"
                [icon]="faIcon.faPlus"
            >
            </fa-icon>
        </td>            
    </ng-container>

</pcg-table>