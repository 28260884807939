import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { SystemMessageService, SystemMessage } from 'app/core/system-message/system-message-service';
import { VerifyFingerPrintVm } from 'app/shared/generated/Administration/Models/Users/VerifyFingerPrintVm';

@Injectable({
    providedIn: 'root'
})
export class FingerprintService {

    // Secugen CloudScanr settings to work with CloudAbis version 10
	captureSettingsV10 = JSON.stringify({
		DeviceName: 'Secugen',
		TemplateFormat: 'ISO',
		QuickScan: true,
		CaptureType: 'SingleCapture',
		CaptureMode: 'TemplateOnly',
		BioMetricImageFormat: 'JPEG'
	});

	// Secugen CloudScanr settings to work with CloudAbis version 12
	captureSettingsV12 = JSON.stringify({
		CaptureType: 'SingleCapture',
		SingleCaptureMode: 'LeftFingerCapture',
		QuickScan: "Enable",
		CaptureOperationName: 'IDENTIFY',
		DeviceName: 'Secugen',
		CaptureTimeOut: 120,
		LeftFingerPosition: "LeftThumb",
		RightFingerPosition: "LeftThumb"
	});

    constructor(
        private httpClient: HttpClient
        , private ms: SystemMessageService
    ) { }

    register(id: number, BiometricFingerprintVersion: number) {
		this.captureFingerprint((response: any) => {			
			this.httpClient.post('api/Administration/Users/RegisterFingerprint/', {
                id
				, biometricXml: response.TemplateData
            }).subscribe((msg: any) => {
                this.ms.setSystemMessage(msg.message, msg.messageClass);
            });
		}, BiometricFingerprintVersion);
	}

	async verifyGetFingerprint(userId: number) {
		let signatureResponse: any; 
		let posturl: string = 'http://localhost:15896/api/CloudABISV12Captures/Fingerprint/'; 
		let captureSettings = this.captureSettingsV12;

		try { signatureResponse = await lastValueFrom(this.httpClient.post(posturl, captureSettings)); } 
		catch (error) {
			this.ms.setSystemMessage(
				'Error communicating with fingerprint scanner. ' + 
                'Please try again, or contact an administrator.',
				'error'
			);
			return null;
		}

		if (signatureResponse !== undefined) {
			let biometricXmlval: string;
			try { 
				if (
					signatureResponse 
					&& typeof signatureResponse === 'object'
					&& 'Images' in signatureResponse
					&& typeof signatureResponse.Images === 'object'
					&& 'Fingerprint' in signatureResponse.Images
				) { biometricXmlval = signatureResponse.Images.Fingerprint[0].Base64Image; }
			} catch (error) {
				this.ms.setSystemMessage(
					'Error communicating with fingerprint scanner. ' + 
					'Please ensure you have the correct drivers installed and try again, or contact an administrator.',
					'error'
				);
				return;
			}

			const vm: VerifyFingerPrintVm = new VerifyFingerPrintVm();
			vm.userId = userId;
			vm.biometricXml = biometricXmlval;
			return vm;
		}
		
		return null;
	}

    async fulfillmentQc() {
		let signatureResponse: any; 
		let posturl: string = 'http://localhost:15896/api/CloudABISV12Captures/Fingerprint/'; 
		let captureSettings = this.captureSettingsV12;

		try { signatureResponse = await lastValueFrom(this.httpClient.post(posturl, captureSettings)); } 
		catch (error) {
			this.ms.setSystemMessage(
				'Error communicating with fingerprint scanner. ' + 
                'Please try again, or contact an administrator.',
				'error'
			);
			return null;
		}

		if (signatureResponse !== undefined) {
			let biometricXmlval: string;
			try { 
				if (
					signatureResponse 
					&& typeof signatureResponse === 'object'
					&& 'Images' in signatureResponse
					&& typeof signatureResponse.Images === 'object'
					&& 'Fingerprint' in signatureResponse.Images
				) { biometricXmlval = signatureResponse.Images.Fingerprint[0].Base64Image; }
			} catch (error) {
				this.ms.setSystemMessage(
					'Error communicating with fingerprint scanner. ' + 
					'Please ensure you have the correct drivers installed and try again, or contact an administrator.',
					'error'
				);
				return;
			}

			const vm: VerifyFingerPrintVm = new VerifyFingerPrintVm();
			vm.userId = null;
			vm.biometricXml = biometricXmlval;

			const msg = await lastValueFrom(this.httpClient.post<SystemMessage>(`api/Inventory/Shipping/IdentifyByFingerprint`, vm));
			if (
				!msg?.isSuccessful ||
				(msg?.message !== null && msg?.message !== '')
			) { this.ms.setSystemMessage(msg?.message, !msg?.messageClass ? 'success' : msg?.messageClass); }
			return msg;
		}
		return null;
	}

	async verifySaveFingerprint(userId: number, saveUrl: string) {
		let vm = await this.verifyGetFingerprint(userId);
		const msg = await lastValueFrom(this.httpClient.post<SystemMessage>(saveUrl, vm));
		if (
			!msg?.isSuccessful ||
			(msg?.message !== null && msg?.message !== '')
		) { this.ms.setSystemMessage(msg?.message, !msg?.messageClass ? 'success' : msg?.messageClass); }
		return msg;
	}

    captureFingerprint(apiCallback: any, biometricFingerprintVersion: number) {
		let posturl: string; 
		let captureSettings: any;
		
		switch(biometricFingerprintVersion){
			case 10: {
				posturl = 'http://localhost:15896/api/CloudScanr/FPCapture/';
				captureSettings = this.captureSettingsV10;
				break;
			}
			case 12: {
				posturl = 'http://localhost:15896/api/CloudABISV12Captures/Fingerprint/';
				captureSettings = this.captureSettingsV12;
				break;
			}
		};
		
		try { this.httpClient.post(posturl, captureSettings).subscribe({ next: apiCallback }); } 
		catch (error) {
			this.ms.setSystemMessage(
				error + ' ' +
				'Error communicating with fingerprint scanner. ' + 
                'Please try again, or contact an administrator.',
				'error'
			);
		}		
	}

	async loginFingerprintMfa(userId: number) {
		let signatureResponse: unknown; 
		let posturl: string = 'http://localhost:15896/api/CloudABISV12Captures/Fingerprint/'; 
		let captureSettings = this.captureSettingsV12;

		try { signatureResponse = await lastValueFrom(this.httpClient.post(posturl, captureSettings)); }
		catch (error) {
			this.ms.setSystemMessage(
				'Error communicating with fingerprint scanner. ' + 
                'Please try again, or contact an administrator.',
				'error'
			);
			return null;
		}
		
		if (signatureResponse !== undefined) {
			let biometricXmlval: string;
			try { 
				if (
					signatureResponse 
					&& typeof signatureResponse === 'object'
					&& 'Images' in signatureResponse
					&& typeof signatureResponse.Images === 'object'
					&& 'Fingerprint' in signatureResponse.Images
				) { biometricXmlval = signatureResponse.Images.Fingerprint[0].Base64Image; }
			} catch (error) {
				this.ms.setSystemMessage(
					'Error communicating with fingerprint scanner. ' + 
					'Please ensure you have the correct drivers installed and try again, or contact an administrator.',
					'error'
				);
				return;
			}

			const vm: VerifyFingerPrintVm = new VerifyFingerPrintVm();
			vm.userId = userId;
			vm.biometricXml = biometricXmlval;

			const msg = await lastValueFrom(this.httpClient.post<SystemMessage>(`api/Account/VerifyFingerPrint`, vm));
			if (
				!msg?.isSuccessful ||
				(msg?.message !== null && msg?.message !== '')
			) { this.ms.setSystemMessage(msg?.message, !msg?.messageClass ? 'success' : msg?.messageClass); }
			return msg;
		}
		return null;
	}
}
