import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SystemMessageComponent } from './system-message.component';

/**
 * This module implements the system message feature.
 * System messages are a global toast message box that
 * can be used for success, warning, and error messages.
 * Only one system message can be open at a time.
 */
@NgModule({
	declarations: [SystemMessageComponent],
	imports: [
		CommonModule,
		BrowserAnimationsModule
	],
	exports: [SystemMessageComponent]
})
export class SystemMessageModule { }
