// tslint:disable: quotemark
export enum PatientApplicationStatusEnum {
	New = 1,
	Complete = 2
}

export namespace PatientApplicationStatusEnum {
	export function toDescription(val: PatientApplicationStatusEnum) {
		switch (val) {
			case 1:
				return "Open";
			case 2:
				return "Complete";
		}
		return null;
	}
	export function toDisplay(val: PatientApplicationStatusEnum) {
		switch (val) {
			case 1:
				return "Open";
			case 2:
				return "Complete";
		}
		return null;
	}
}
