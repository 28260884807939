import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
  
@Directive({
	selector: '[pcgPhoneExt]'
})
export class PhoneExtDirective {

	constructor(private elRef: ElementRef, @Optional() private control: NgControl) { }

	charArray = [];
	selectedText: string;

	@HostListener('change', ['$event'])
	changeEvent() { if (this.control) { this.control.control.setValue(this.elRef.nativeElement.value); } }

	@HostListener('keydown', ['$event'])
	keyEvent(event: KeyboardEvent) { 
		if (
			!(
				(event.key === '0' 
					|| event.key === '1' 
					|| event.key === '2' 
					|| event.key === '3' 
					|| event.key === '4' 
					|| event.key === '5' 
					|| event.key === '6' 
					|| event.key === '7' 
					|| event.key === '8' 
					|| event.key === '9'
				) 
				||
				(event.keyCode > 36 && event.keyCode < 41) 
				|| event.keyCode === 8 
				|| event.keyCode === 9 
				|| event.keyCode === 46 
				|| event.keyCode === 110
			)
		) { return false; }

		if (this.control) { 
			if (this.selectedText == this.control.control.value || this.control.control.value == null) { this.control.control.setValue(""); }

			this.charArray = this.control.control.value.split(""); 
			this.elRef.nativeElement.value = this.charArray.join("");
			
			if (event.key === "Tab") {
				this.charArray.push('');
			}

			if (this.charArray.length === 0 
				&& event.key != "Backspace" 
				&& event.key != "Delete" 
				&& !(event.shiftKey == true && event.key == "Digit9") 
				&& event.shiftKey != true
			) { 
				this.charArray.unshift('(') 
			}
			if (this.charArray.length === 4 && event.key != "Backspace" && event.key != "Delete" && event.shiftKey != true) {
				this.charArray.push(')');
				this.charArray.push(' ');
			}
			if (this.charArray.length === 5 && event.key != "Backspace" && event.key != "Delete") {
				this.charArray.push(' ');
			}
			if (this.charArray.length === 9 && event.key != "Backspace" && event.key != "Delete") {
				this.charArray.push('-');
			}
			if (this.charArray.length === 14 && event.key != "Backspace" && event.key != "Delete") {
				this.charArray.push(' ');
				this.charArray.push('x');
			}
			if (this.charArray.length === 15 && event.key != "Backspace" && event.key != "Delete") {
				this.charArray.push('x');
			}
			//Limit extension length to 5 digits
			if (this.charArray.length > 20 && event.key != "Backspace" && event.key != "Delete") {
				return false; 
			}
			this.elRef.nativeElement.value = this.charArray.join("");			
		} 
		if (this.control) { this.control.control.setValue(this.elRef.nativeElement.value); }
	}

	@HostListener('mouseup', ['$event'])
	highlight(event: KeyboardEvent) { this.selectedText = window.getSelection().toString(); }
}
