<h1>{{title.getTitle()}}</h1>
<hr />

<pcg-table #table dataSource='api/Silkier/SilkierHistory/GetHistory' [multipleSearch]='true'
[columnDefs]='columns' [pcgSort]='[["actualFireTimeUtcStr", "desc"]]'>

    <ng-container matColumnDef="schedulerInstanceId">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>
            {{ columns.get('schedulerInstanceId').displayName}}</th>
        <td mat-cell *matCellDef='let row'>{{row.schedulerInstanceId}}</td>
    </ng-container>
    <ng-container matColumnDef="schedulerName">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>
            {{ columns.get('schedulerName').displayName}}</th>
        <td mat-cell *matCellDef='let row'>{{row.schedulerName}}</td>
    </ng-container>
    <ng-container matColumnDef="job">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>
            {{ columns.get('job').displayName}}</th>
        <td mat-cell *matCellDef='let row'>{{row.job}}</td>
    </ng-container>
    <ng-container matColumnDef="trigger">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>
            {{ columns.get('trigger').displayName}}</th>
        <td mat-cell *matCellDef='let row'>{{row.trigger}}</td>
    </ng-container>
    <ng-container matColumnDef="scheduledFireTimeUtcStr">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>
            {{ columns.get('scheduledFireTimeUtcStr').displayName}}</th>
        <td mat-cell *matCellDef='let row'>{{row.scheduledFireTimeUtcStr}}</td>
    </ng-container>
    <ng-container matColumnDef="actualFireTimeUtcStr">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>
            {{ columns.get('actualFireTimeUtcStr').displayName}}</th>
        <td mat-cell *matCellDef='let row'>{{row.actualFireTimeUtcStr}}</td>
    </ng-container>
    <ng-container matColumnDef="recovering">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>
            {{ columns.get('recovering').displayName}}</th>
        <td mat-cell *matCellDef='let row'>{{row.recovering}}</td>
    </ng-container>
    <ng-container matColumnDef="finishedTimeUtcStr">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>
            {{ columns.get('finishedTimeUtcStr').displayName}}</th>
        <td mat-cell *matCellDef='let row'>{{row.finishedTimeUtcStr}}</td>
    </ng-container>
    <ng-container matColumnDef="logMessage">
        <th mat-header-cell *matHeaderCellDef>
            {{ columns.get('logMessage').displayName }}</th>
        <td mat-cell *matCellDef='let row'>
            <div (click)='viewLogMessage(row.fireInstanceId);' class="block-link">View Log</div>
        </td>
    </ng-container>
    <ng-container matColumnDef="exceptionMessage">
        <th mat-header-cell *matHeaderCellDef>
            {{ columns.get('exceptionMessage').displayName }}</th>
        <td mat-cell *matCellDef='let row'>
            <div *ngIf="!row.wasSuccessful" (click)='viewExceptionMessage(row.fireInstanceId);' class="block-link">View Exception</div>
        </td>
    </ng-container>
</pcg-table>
