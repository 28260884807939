import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { Observable, Subscription, tap } from 'rxjs';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { RepositoryRegistrationVm } from 'app/shared/generated/Models/Public/RepositoryRegistrationVm';
import { environment } from 'environments/environment';

@Component({
	selector: 'repository-register',
	templateUrl: './repository-register.component.html',
	styleUrls: ['./repository-register.component.scss']
})
export class RepositoryRegisterComponent implements OnInit {

	@ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent;
	siteKey: string = environment.siteKey; // should pick up environment specific siteKey
	public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaResponse?: string;
	public captchaIsReady = false;
	public recaptcha: any = null;
	public execute: any;

	emptyCaptcha: boolean = true;
	honeypotControl;

	id: number;
	formGroup = RepositoryRegistrationVm.Form;
	model = RepositoryRegistrationVm.GetModel(this.formGroup);
	model$: Observable<RepositoryRegistrationVm>;
	subscriptions = new Subscription();
	currentDate: Date = new Date();
	canManualEntry = true;
	isSuccess = false;
	disableSave = false;

	phoneValidation = { pattern: "Invalid phone number" };

	constructor(private route: ActivatedRoute, private httpClient: HttpClient, title: Title,
		private ms: SystemMessageService, private cdr: ChangeDetectorRef) {
		title.setTitle('Register Repository');
	}

	ngOnInit() {
		this.setUpHoneyPot();
		this.formGroup.addControl('recaptcha', new FormControl('', Validators.required));
		this.formGroup.get('dateSigned').patchValue(this.currentDate.toLocaleString());
	}

	ngAfterViewInit(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

	setUpHoneyPot() {
		this.formGroup.controls['honeyPot'].setValidators(this.requireFalse());
		this.formGroup.updateValueAndValidity();
	}

	requireFalse(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value === true) { return { honeyPot: true }; }
			return null;
		}
	}

	handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.cdr.detectChanges();
	}

	handleReady(): void {
		this.captchaIsReady = true;
		this.cdr.detectChanges();
		this.execute = setInterval(() => {
			this.captchaElem.execute();
			this.captchaElem.getResponse() !== '' ? this.emptyCaptcha = false : this.emptyCaptcha = true;
		}, 5000);
	}

	handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

	handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		clearInterval(this.execute);
	}

	getRepository() {
		this.subscriptions.add(this.route.paramMap.subscribe(map => {
			this.id = parseInt(map.get('id'), 10);

			if (this.id !== 0) {
				this.model$ = this.httpClient
					.get(`api/RxConnects/Repository/GetRepositoryDetails/${this.id}`)
					.pipe(tap((model: RepositoryRegistrationVm) => {
						this.formGroup.patchValue(model);
					}));
			} else { this.formGroup.get('dateSigned').patchValue(this.currentDate.toLocaleString()); }
		}));
	}

	saveRepository() {
		if (this.formGroup.valid && this.formGroup.get('isAttested').value) {
			this.subscriptions.add(
				this.ms.getHttpObservable(this, 'api/PublicRepositoryRegister/saveRepository/', this.formGroup).subscribe(msg => {
					if (msg.isSuccessful) {
						this.isSuccess = true;
						this.disableForm();
					} else { this.isSuccess = false; }
				}));
		} else { validateForm(this.formGroup); }
	}

	disableForm() {
		this.formGroup.disable();
		this.disableSave = true;
	}

	updateCounty() {
		const address = this.formGroup.value.smartyAddress;

		if (address === null || address.countyName === null) { return; }
		this.httpClient.get(`api/PublicRepositoryRegister/GetCountyByName?countyName=${address.countyName}`).subscribe((countyId: number) => {
			if (countyId !== 0) {
				this.formGroup.get('countyId').patchValue(countyId)
				document.getElementById('pharmacistName').focus();
			}
			else {
				this.formGroup.get('countyId').patchValue(0)
				document.getElementById('countyId').focus();
			}
		});
	}
}
