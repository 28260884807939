export enum FaPositionUnits{
    percent,
    emphemeral,
    pixels
}
export class FaIconPosition {

    constructor(topVal: number = null, rightVal = null, bottomVal = null, leftVal = null, units: FaPositionUnits = null) {
        this.top = topVal;
        this.right = rightVal;
        this.bottom = bottomVal;
        this.left = leftVal;
        this.positionUnits = units;
    }
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
    positionUnits: FaPositionUnits;

    getMyPositionUnits(): string{
        return FaIconPosition.getPositionUnits(this.positionUnits);
    }
    static getPositionUnits(units: FaPositionUnits): string{
        switch(units){
            case FaPositionUnits.percent:
                return '%';
            case FaPositionUnits.emphemeral:
                return 'em';
            case FaPositionUnits.pixels:
                return 'px';
            default:
                return '%';
        }
    }
    static getPositionPropValue = (value: number, unitString: string): string => `${value}${unitString}`;
}
