import { Directive, HostBinding } from '@angular/core';

// This directive was originally copied from here:
// https://stackoverflow.com/questions/58716247/how-to-apply-cursor-pointer-property-to-click-event-handler

@Directive({
	selector: '[click]'
  })
  export class ClickCursorDirective {
	@HostBinding('style.cursor') cursor: string = 'pointer';
  }