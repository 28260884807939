<!-- REGULAR VERSION -->
<div *ngIf="isNotSmallScreen; else smallPassword">
	<div class="row">
		<pcg-control-group *ngIf="isFormControl" class="col-sm-12" [label]="label" [validations]="validations">
			<div class="showHidePassword" [formGroup]="parentForm">
				<input pcgControlRef [formControlName]="fcName" ngDefaultControl
					[type]="showPassword ? 'text' : 'password'" [ngClass]="inputCssClass" />
				<button type="button" class="showHidePassword" (click)="toggleShowHidePassword()">
					<fa-icon [icon]="showPassword ? faIconName.faEyeSlash : faIconName.faEye"></fa-icon>
				</button>
			</div>
		</pcg-control-group>
		<div *ngIf="!isFormControl"class="col-sm-12 pt-0 px-3">
			<label class='required-field for mb-0'>{{ label }}</label>
			<div class="showHidePassword">
				<input [(ngModel)]='password'
					[type]="showPassword ? 'text' : 'password'" [ngClass]="inputCssClass" />
				<button type="button" class="showHidePassword" (click)="toggleShowHidePassword()">
					<fa-icon [icon]="showPassword ? faIconName.faEyeSlash : faIconName.faEye"></fa-icon>
				</button>
			</div>
		</div>
	</div>
</div>

<!-- SMALL VERSION -->
<ng-template #smallPassword>
	<label class="sr-only" for="password">Password: </label>
	<div class="showHidePassword" [formGroup]="parentForm">
		<input *ngIf="isFormControl" pcgControlRef [formControlName]="fcName" ngDefaultControl [type]="showPassword ? 'text' : 'password'"
			[ngClass]="inputCssClass" />
		<input *ngIf="!isFormControl" [(ngModel)]='password'
				[type]="showPassword ? 'text' : 'password'" [ngClass]="inputCssClass" />
		<fa-icon [icon]=" faIconName.faEye"></fa-icon>
		<button type="button" class="showHidePassword " (click)="toggleShowHidePassword()">
			<fa-icon [icon]="showPassword ? faIconName.faEyeSlash : faIconName.faEye"></fa-icon>
		</button>
	</div>
</ng-template>