// tslint:disable: quotemark
export enum RxConnectsTransferEnum {
	In = 1,
	Out = 2
}

export namespace RxConnectsTransferEnum {
	export function toDescription(val: RxConnectsTransferEnum) {
		switch (val) {
			case 1:
				return "Transfer In";
			case 2:
				return "Transfer Out";
		}
		return null;
	}
	export function toDisplay(val: RxConnectsTransferEnum) {
		switch (val) {
			case 1:
				return "In";
			case 2:
				return "Out";
		}
		return null;
	}
}
