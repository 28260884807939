import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';
import { OrderOptionVm, OrderOptionVmFromFg } from '../../HEROS/Models/OrderOptionVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class EditApplicantVm {
	applicantId: number;
	roundId: number;
	orderOptions?: OrderOptionVm[];
	overdosesPreviousYear?: string;
	percentageTrainedNaloxone?: number;
	narrative?: string;
	agencyId?: number;
	strAgencyId?: string;
	historicAgencyId?: number;
	applicationStatus?: any;
	agencyName?: string;
	dateCreated?: string;
	dateSubmitted?: string;
	oathTimeStamp?: string;
	oathAcceptedNew?: boolean;
	oathDateTimeNew?: Date;
	oathUserNew?: string;
	verificationStatus?: string;
	hasInitialApproval?: string;
	canEditHistoric?: boolean;
	canDeleteHistoric?: boolean;
	isPublicSubmit: boolean;
	friendlyId?: string;
	confirmedMessages?: string[];

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'applicantId': new UntypedFormControl(0, [...(validators['applicantId'] ?? []), ...[Validators.required]]),
			'roundId': new UntypedFormControl(0, [...(validators['roundId'] ?? []), ...[Validators.required]]),
			'orderOptions': new UntypedFormArray([]),
			'overdosesPreviousYear': new UntypedFormControl(null, [...(validators['overdosesPreviousYear'] ?? []), ...[Validators.required, Validators.maxLength(100), Validators.minLength(0)]]),
			'percentageTrainedNaloxone': new UntypedFormControl(null, [...(validators['percentageTrainedNaloxone'] ?? []), ...[Validators.required, Validators.min(0), Validators.max(100)]]),
			'narrative': new UntypedFormControl(null, [...(validators['narrative'] ?? []), ...[Validators.required, Validators.maxLength(2500)]]),
			'agencyId': new UntypedFormControl(null, [...(validators['agencyId'] ?? []), ...[]]),
			'strAgencyId': new UntypedFormControl(null, [...(validators['strAgencyId'] ?? []), ...[]]),
			'historicAgencyId': new UntypedFormControl(null, [...(validators['historicAgencyId'] ?? []), ...[]]),
			'applicationStatus': new UntypedFormControl('', [...(validators['applicationStatus'] ?? []), ...[Validators.required]]),
			'agencyName': new UntypedFormControl(null, [...(validators['agencyName'] ?? []), ...[]]),
			'dateCreated': new UntypedFormControl(null, [...(validators['dateCreated'] ?? []), ...[]]),
			'dateSubmitted': new UntypedFormControl(null, [...(validators['dateSubmitted'] ?? []), ...[]]),
			'oathTimeStamp': new UntypedFormControl(null, [...(validators['oathTimeStamp'] ?? []), ...[]]),
			'oathAcceptedNew': new UntypedFormControl(null, [...(validators['oathAcceptedNew'] ?? []), ...[]]),
			'oathDateTimeNew': new UntypedFormControl(null, [...(validators['oathDateTimeNew'] ?? []), ...[]]),
			'oathUserNew': new UntypedFormControl(null, [...(validators['oathUserNew'] ?? []), ...[]]),
			'verificationStatus': new UntypedFormControl(null, [...(validators['verificationStatus'] ?? []), ...[]]),
			'hasInitialApproval': new UntypedFormControl(null, [...(validators['hasInitialApproval'] ?? []), ...[]]),
			'canEditHistoric': new UntypedFormControl(null, [...(validators['canEditHistoric'] ?? []), ...[]]),
			'canDeleteHistoric': new UntypedFormControl(null, [...(validators['canDeleteHistoric'] ?? []), ...[]]),
			'isPublicSubmit': new UntypedFormControl(false, [...(validators['isPublicSubmit'] ?? []), ...[]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'confirmedMessages': new UntypedFormControl([], [...(validators['confirmedMessages'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): EditApplicantVmFromFg {
		return new EditApplicantVmFromFg(fg);
	}
}

export class EditApplicantVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get applicantId(): number {
		return this.fg.get('applicantId').value;
	}
	set applicantId(val) {
		this.fg.get('applicantId').setValue(val);
	}

	get roundId(): number {
		return this.fg.get('roundId').value;
	}
	set roundId(val) {
		this.fg.get('roundId').setValue(val);
	}

	get overdosesPreviousYear(): string {
		return this.fg.get('overdosesPreviousYear').value;
	}
	set overdosesPreviousYear(val) {
		this.fg.get('overdosesPreviousYear').setValue(val);
	}

	get percentageTrainedNaloxone(): number {
		return this.fg.get('percentageTrainedNaloxone').value;
	}
	set percentageTrainedNaloxone(val) {
		this.fg.get('percentageTrainedNaloxone').setValue(val);
	}

	get narrative(): string {
		return this.fg.get('narrative').value;
	}
	set narrative(val) {
		this.fg.get('narrative').setValue(val);
	}

	get agencyId(): number {
		return this.fg.get('agencyId').value;
	}
	set agencyId(val) {
		this.fg.get('agencyId').setValue(val);
	}

	get strAgencyId(): string {
		return this.fg.get('strAgencyId').value;
	}
	set strAgencyId(val) {
		this.fg.get('strAgencyId').setValue(val);
	}

	get historicAgencyId(): number {
		return this.fg.get('historicAgencyId').value;
	}
	set historicAgencyId(val) {
		this.fg.get('historicAgencyId').setValue(val);
	}

	get applicationStatus(): any {
		return this.fg.get('applicationStatus').value;
	}
	set applicationStatus(val) {
		this.fg.get('applicationStatus').setValue(val);
	}

	get agencyName(): string {
		return this.fg.get('agencyName').value;
	}
	set agencyName(val) {
		this.fg.get('agencyName').setValue(val);
	}

	get dateCreated(): string {
		return this.fg.get('dateCreated').value;
	}
	set dateCreated(val) {
		this.fg.get('dateCreated').setValue(val);
	}

	get dateSubmitted(): string {
		return this.fg.get('dateSubmitted').value;
	}
	set dateSubmitted(val) {
		this.fg.get('dateSubmitted').setValue(val);
	}

	get oathTimeStamp(): string {
		return this.fg.get('oathTimeStamp').value;
	}
	set oathTimeStamp(val) {
		this.fg.get('oathTimeStamp').setValue(val);
	}

	get oathAcceptedNew(): boolean {
		return this.fg.get('oathAcceptedNew').value;
	}
	set oathAcceptedNew(val) {
		this.fg.get('oathAcceptedNew').setValue(val);
	}

	get oathDateTimeNew(): Date {
		return this.fg.get('oathDateTimeNew').value;
	}
	set oathDateTimeNew(val) {
		this.fg.get('oathDateTimeNew').setValue(val);
	}

	get oathUserNew(): string {
		return this.fg.get('oathUserNew').value;
	}
	set oathUserNew(val) {
		this.fg.get('oathUserNew').setValue(val);
	}

	get verificationStatus(): string {
		return this.fg.get('verificationStatus').value;
	}
	set verificationStatus(val) {
		this.fg.get('verificationStatus').setValue(val);
	}

	get hasInitialApproval(): string {
		return this.fg.get('hasInitialApproval').value;
	}
	set hasInitialApproval(val) {
		this.fg.get('hasInitialApproval').setValue(val);
	}

	get canEditHistoric(): boolean {
		return this.fg.get('canEditHistoric').value;
	}
	set canEditHistoric(val) {
		this.fg.get('canEditHistoric').setValue(val);
	}

	get canDeleteHistoric(): boolean {
		return this.fg.get('canDeleteHistoric').value;
	}
	set canDeleteHistoric(val) {
		this.fg.get('canDeleteHistoric').setValue(val);
	}

	get isPublicSubmit(): boolean {
		return this.fg.get('isPublicSubmit').value;
	}
	set isPublicSubmit(val) {
		this.fg.get('isPublicSubmit').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}
}
