<div class='nav-padding-placeholder'></div>
<nav 
	id="pcgMainNavigation" 
	class='navbar navbar-expand-lg top-nav-bg'
>
	<div 
		id='primaryNav' 
		class='align-items-center w-100'
	>
		<button 
			#mainHamburger 
			[class.d-none]="
				!navRoutes 
				|| navRoutes.length === 0
			" 
			(click)='toggleNav()' 
			id='mainHamburger' 
			class='float-right btn btn-sm mt-2'
		>
			<svg 
				viewBox='0 0 30 30' 
				xmlns='http://www.w3.org/2000/svg'
			>
				<path 
					stroke-width='2' 
					stroke-linecap='round' 
					stroke-miterlimit='10' 
					d='M4 7h22M4 15h22M4 23h22'
				></path>
			</svg>
		</button>
		
		<div class='navbar-header'>
			<a 
				[class.test]="mode !== 'Production'" 
				class='navbar-brand' 
				href='/'
			>
				<img 
					alt="HOMER" 
					src="assets/images/logos/logo_small.png" 
				/>
			</a>
		</div>

		<pcg-top-click-nav [navRoutes]='navRoutes'></pcg-top-click-nav>
		
		<div 
			id='rightNavContainer' 
			class='ml-auto align-items-center'
		>
			<div 
				*ngIf='
					fulfillmentNavAccess 
					&& user
				'
			>
				<a 
					id='btnStep1Count' 
					#btnStep1Count 
					(click)='openFulfillmentList(0)' 
					ngbTooltip="Centralized Fulfillment list will filter and display the number of Rx's in the Product Prep Status"
				>
					<pcg-fa-num-icon 
						[iconName]="faIconName.faPrescription" 
						[num]="step1Count" 
						[numColor]="faNumColor.success" 
						[isNumVisible]="step1count !== 0" 
						[numPosition]='step1Position'
					></pcg-fa-num-icon>
				</a>
			</div>

			<div 
				*ngIf='
					fulfillmentNavAccess 
					&& user
				'
			>
				<a 
					id='btnStep2Count' 
					#btnStep2Count 
					(click)='openFulfillmentList(1)' 
					ngbTooltip="Centralized Fulfillment list will filter and display the number of Rx's in the Shipping Prep Status"
				>
					<pcg-fa-num-icon 
						[iconName]="faIconName.faBoxOpen" 
						[num]="step2Count" 
						[numColor]="faNumColor.info" 
						[isNumVisible]="step2Count !== 0"
					></pcg-fa-num-icon>
				</a>
			</div>		

			<div 
				*ngIf='
					fulfillmentNavAccess 
					&& user
				'
			>
				<a 
					id='btnStep3Count' 
					#btnStep3Count 
					(click)='openFulfillmentList(2)' 
					ngbTooltip="Centralized Fulfillment list will filter and display the number of Rx's in the QC Check Status"
				>
					<pcg-fa-num-icon 
						[iconName]="faIconName.faUserMd" 
						[num]="step3Count" 
						[numColor]="faNumColor.warning" 
						[isNumVisible]="step3Count !== 0"
					></pcg-fa-num-icon>
				</a>
			</div>

			<!-- Org Account Update Request -->
			<div 
				*ngIf="
					accountRequestAccess 
					&& user
				" 
				class="pr-1"
			>
				<a 
					id="btnAccountUpdate" 
					#btnOrgAccountUpdte 
					(click)="openOrgAccountUpdateModal()"
					ngbTooltip="Click to open the Account Request Queue list."
				>
					<pcg-fa-num-icon 
						[iconName]="faIconName.faArrowRightArrowLeft" 
						[num]="updateRequestCount" 
						[numColor]="faNumColor.warning" 
						[isNumVisible]="updateRequestCount != 0"
					></pcg-fa-num-icon>
				</a>
			</div>

			<a 
				*ngIf='
					(
						reconNavAccess 
						|| invoiceNavAccess
						|| icqAccess
					) 
					&& user
				'
				id='btnReconciliations' 
				#btnReconciliations 
				(click)='openNotificationModal()' 				
			>
				<pcg-fa-num-icon 
					[iconName]="faIconName.faBell" 
					[num]="notificationCount" 
					[numColor]="faNumColor.danger" 
					[isNumVisible]="reconCount !== 0"
				></pcg-fa-num-icon>
			</a>
			<ul 
				*ngIf="user" 
				#userNav 
				id="userNav" 
				class="nav navbar-nav top-nav-bg" 
				(mouseleave)="userNavOpen = false"
			>
				<li 
					id="userNavDropDown_collapser" 
					class="dropdown"
				>
					<a 
						[routerLink]='"/edit-user/" + user.id' 
						(mouseenter)="
							checkAccess(); 
							userNavOpen = true; 
							false;
						" 
						(click)="isMobile === true ? userNavOpen = false : ''"
						class="d-block dropdown-toggle"
					>
						<fa-icon 
							[icon]="faUser" 
							class="mr-2" size='lg'
						></fa-icon>{{ user.firstName }} {{ user.lastName }}						
					</a>
					<ul 
						class="dropdown-menu top-nav-bg" 
						id='navUserDropdown' 
						[class.nav-open]="userNavOpen"
					>
						<li 
							[routerLinkActive]='["link-active"]' 
							[routerLinkActiveOptions]='{ exact: true }'
						>
							<a 
								(click)="clickUserLink()" 
								class="d-block" 
								[href]='"/edit-user/" + user.id'
							>My Profile</a>
						</li>
						<li>
							<a 
								class="d-block" 
								href="#" 
								(click)="
									logout(); 
									clickUserLink(); 
									false;
								"
							>Logout</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>

	<div 
		*ngIf="
			currSelectedNavMenu 
			&& currSelectedNavMenu?.id !== 'root'
		" 
		id='secondaryNav'
	>
		<div class='container'>
			<span class='current-section'>{{ currSelectedNavMenu.name }}</span>
			<pcg-top-secondary-nav 
				role="navigation" 
				aria-label="Secondary" 
				[forceSelect]='currForceSelect' 
				[rootNavId]='"root" + navDividerCharacter + currSelectedNavMenu?.id?.split(navDividerCharacter)[1]' 
				[routeNavId]="currSelectedNavMenu?.id" 
				[navRoutes]='currSelectedNavMenu?.children'
			></pcg-top-secondary-nav>
		</div>
	</div>
	
	<div 
		*ngIf="hasCiAccess" 
		id='bannerDiv'
	>
		<div class="container">
			<pcg-workstation-banner class="w-100"></pcg-workstation-banner>
		</div>
	</div>

	<mat-progress-bar 
		*ngIf="loading" 
		color="primary" 
		mode="determinate"
	></mat-progress-bar>
	
	<div 
		*ngIf="
			currSelectedNavMenu 
			&& currSelectedNavMenu.id !== 'root'
		" 
		id="nav-bottom-whitespace"
	>
		<span></span>
	</div>
</nav>
