import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { StepperComponent } from './stepper.component';

@NgModule({
	declarations: [ StepperComponent ],
	imports: [
		CommonModule, FormsModule, RouterModule,
		CdkStepperModule, FontAwesomeModule
	],
	exports: [ StepperComponent, CdkStepperModule ]
})
export class StepperModule { }
