<br />
<img 
	class='system-logo' 
	src='assets/images/logos/logo.png' 
/>
<br />
<h2 class="text-center">Change Password</h2>
<br />
<div class='change-password-container clearfix'>
	<form 
		*ngIf="!isExpired" 
		(ngSubmit)="onSubmit()" 
		[formGroup]="changePasswordForm"
	>
		<div class='row'>
			<pcg-control-group class="col-sm-12">
				<pcg-show-hide-password
					fcName="password" 
					[parentForm]="changePasswordForm" 
					label="New Password"
					inputCssClass="form-control"
					[validations]='{ "commonPassword": "Pick a less common password.", "sequentialChars" : "Password cannot contain more than 2 sequential characters."  }'
				></pcg-show-hide-password>
			</pcg-control-group>
		</div>
		<div class='row'>
			<pcg-control-group class="col-sm-12">
				<pcg-show-hide-password
					fcName="confirmPassword"
					[parentForm]="changePasswordForm" 
					label="Confirm New Password"
					inputCssClass="form-control"
					[validations]='{ "passwordsNotEqual": "Passwords do not match." }'
				></pcg-show-hide-password>
			</pcg-control-group>
		</div>
		<button 
			type="submit" 
			class="btn btn-secondary btn-lg"
		>Change Password</button>
	</form>
	<div 
		*ngIf="isExpired" 
		class="text-danger text-center expired-message"
	>{{errorMessage}}</div>
</div>
<br /><br />

<div class="text-center">
	<u>Password Requirements</u><br />
	Must be at least 10 characters in Length.<br />
	Cannot contain more than 2 sequential characters.<br />
	Cannot match any of the passwords in our list of commonly used passwords.<br />
</div>
