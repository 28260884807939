import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class CredentialListVm {
	credentialId?: any;
	displayName?: string;
	dateGenerated: Date;
	dateGeneratedFormatted?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'credentialId': new UntypedFormControl([], [...(validators['credentialId'] ?? []), ...[]]),
			'displayName': new UntypedFormControl(null, [...(validators['displayName'] ?? []), ...[]]),
			'dateGenerated': new UntypedFormControl('', [...(validators['dateGenerated'] ?? []), ...[Validators.required]]),
			'dateGeneratedFormatted': new UntypedFormControl(null, [...(validators['dateGeneratedFormatted'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['displayName', {"orderId":0,"sortColumn":"displayName","searchColumn":"displayName","includeInExcel":true,"exportColumns":null,"displayName":"Display Name","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['dateGenerated', {"orderId":1,"sortColumn":"dateGenerated","searchColumn":"dateGeneratedFormatted","includeInExcel":true,"exportColumns":null,"displayName":"Date Generated","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['delete', {"orderId":2,"sortColumn":"delete","searchColumn":"delete","includeInExcel":true,"exportColumns":null,"displayName":"Delete","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): CredentialListVmFromFg {
		return new CredentialListVmFromFg(fg);
	}
}

export class CredentialListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get credentialId(): any {
		return this.fg.get('credentialId').value;
	}
	set credentialId(val) {
		this.fg.get('credentialId').setValue(val);
	}

	get displayName(): string {
		return this.fg.get('displayName').value;
	}
	set displayName(val) {
		this.fg.get('displayName').setValue(val);
	}

	get dateGenerated(): Date {
		return this.fg.get('dateGenerated').value;
	}
	set dateGenerated(val) {
		this.fg.get('dateGenerated').setValue(val);
	}

	get dateGeneratedFormatted(): string {
		return this.fg.get('dateGeneratedFormatted').value;
	}
	set dateGeneratedFormatted(val) {
		this.fg.get('dateGeneratedFormatted').setValue(val);
	}
}
