import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';
import { ModuleAccessVm, ModuleAccessVmFromFg } from '../../../Administration/Models/Security/ModuleAccessVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class UserModulesVm {
	userId: number;
	moduleAccess?: ModuleAccessVm[];

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'userId': new UntypedFormControl(0, [...(validators['userId'] ?? []), ...[Validators.required]]),
			'moduleAccess': new UntypedFormControl([], [...(validators['moduleAccess'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): UserModulesVmFromFg {
		return new UserModulesVmFromFg(fg);
	}
}

export class UserModulesVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get userId(): number {
		return this.fg.get('userId').value;
	}
	set userId(val) {
		this.fg.get('userId').setValue(val);
	}
}
