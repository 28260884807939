import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InstallTimerComponent } from './install-timer/install-timer.component';
import { PushNotificationComponent } from './push-notification.component';

@NgModule({
	declarations: [
		PushNotificationComponent
		, InstallTimerComponent
	]
	, imports: [
		CommonModule
	]
	, exports: [
		PushNotificationComponent
		, InstallTimerComponent
	]
})
export class PushNotificationModule { }
