import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TableTopAlertService {

    @Output() alertEvent: EventEmitter<any> = new EventEmitter();

    onClick() {
        this.alertEvent.emit();
    }
}