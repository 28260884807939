import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class AgencyListByAgencyVm {
	agencyId: number;
	agencyName?: string;
	agencyAbbreviation?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'agencyId': new UntypedFormControl(0, [...(validators['agencyId'] ?? []), ...[Validators.required]]),
			'agencyName': new UntypedFormControl(null, [...(validators['agencyName'] ?? []), ...[]]),
			'agencyAbbreviation': new UntypedFormControl(null, [...(validators['agencyAbbreviation'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['agencyName', {"orderId":0,"sortColumn":"agencyName","searchColumn":"agencyName","includeInExcel":true,"exportColumns":null,"displayName":"Agency Name","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['agencyAbbreviation', {"orderId":1,"sortColumn":"agencyAbbreviation","searchColumn":"agencyAbbreviation","includeInExcel":true,"exportColumns":null,"displayName":"Agency Abbreviation","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): AgencyListByAgencyVmFromFg {
		return new AgencyListByAgencyVmFromFg(fg);
	}
}

export class AgencyListByAgencyVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get agencyId(): number {
		return this.fg.get('agencyId').value;
	}
	set agencyId(val) {
		this.fg.get('agencyId').setValue(val);
	}

	get agencyName(): string {
		return this.fg.get('agencyName').value;
	}
	set agencyName(val) {
		this.fg.get('agencyName').setValue(val);
	}

	get agencyAbbreviation(): string {
		return this.fg.get('agencyAbbreviation').value;
	}
	set agencyAbbreviation(val) {
		this.fg.get('agencyAbbreviation').setValue(val);
	}
}
