<div 
    *ngIf="
      minimized 
      && date
    " 
    class="header-update-warning" 
    [class.nav-whitespace]="navSpacerVisible" 
    (click)="toggleVisibility();"
>
    <span [class.text-danger]="warning">
        <span>
            System Update in:
        </span>
        &nbsp;
        <span>
            {{ minutes }}
        </span>
        :
        <span>
            {{ seconds | number:'2.0' }}
        </span>
    </span>
</div>

<div 
    *ngIf="
        !minimized 
        && date
    "
    #updateTimer 
    class="count-down-timer"
>
    <button 
        type="button" 
        class="close p-2" 
        aria-label="Close" 
        (click)="toggleVisibility();"
    >
		<span aria-hidden="true">&times;</span>
	</button>

    <h4 
        [class.text-danger]="warning" 
        class="p-2"
    >System Update</h4>

    <div class="wrapper d-flex flex-row justify-content-center">
        <div 
            class="description d-flex flex-column pl-5 pr-4" 
            [class.text-danger]="warning"
        >
            <span>Minutes</span>
            <h1>{{ minutes }}</h1>
        </div>

        <div 
            class="times d-flex flex-column pr-5 pl-4" 
            [class.text-danger]="warning"
        >
            <span>Seconds</span>
            <h1>{{ seconds }}</h1>
        </div>
    </div>
</div>
