import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { IcqReviewVm } from 'app/shared/generated/ICQ/Models/User/IcqReviewVm';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { GlobalService } from 'app/shared/services/global.service';

@Component({
    selector: 'pcg-icq-scheduling',
    templateUrl: './icq-scheduling.component.html',
    styleUrl: './icq-scheduling.component.scss'
})
export class IcqSchedulingComponent {

    icqReviewId: number;
    userId: number;
    approve: boolean;
    model: IcqReviewVm;
    isMobile = false;
    scheduleDate: string;
    isRescheduled = false;

    constructor(
        private activatedRoute: ActivatedRoute
        , private httpClient: HttpClient
        , private ms: SystemMessageService
    ) {}

    ngOnInit() {
        this.isMobile = GlobalService.setIsMobile(window.innerWidth);
        this.icqReviewId = Number(this.activatedRoute.snapshot.params.icqReviewId);
        this.userId = Number(this.activatedRoute.snapshot.params.userId);
        this.approve = Boolean(JSON.parse(
            !FormatHelperService.GetIsNullyOrWhitespace(this.activatedRoute?.snapshot.queryParams['approve'])
                ? this.activatedRoute?.snapshot.queryParams['approve']
                : "false"
        ));
        this.scheduleDate = this.activatedRoute?.snapshot.queryParams['scheduleDate'];
        this.getProposedSchedule();
    }

    getProposedSchedule() {
        this.httpClient.get(`api/ICQ/IcqScheduling/GetProposedSchedule?icqReviewId=${this.icqReviewId}`)
            .subscribe((vm: IcqReviewVm) => {
                this.model = vm; 
                if (this.model.scheduleDateTimeStr != this.scheduleDate) { this.isRescheduled = true; }
                if (!this.isRescheduled) { this.approveOrDeclineSchedule(); }
            });
    }

    approveOrDeclineSchedule() {
        this.httpClient.post(`api/ICQ/IcqScheduling/ApproveOrDeclineSchedule?icqReviewId=${this.icqReviewId}&userId=${this.userId}&approve=${this.approve}`, {})
            .subscribe((sm: SystemMessage) => { 
                if (!sm.isSuccessful) { this.ms.setSystemMessage(sm.message, sm.messageClass); }
             });
    }
}
