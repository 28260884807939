import { NavRoute } from "app/shared/navigation/nav.route.interface";
import { BusinessAreaEnum } from "../enums/generated/BusinessAreaEnum";
import { PermissionRole } from "../enums/generated/PermissionRole";

/** Returns `NavRoute` that is only accesible to 
 * Paul Consulting users. 
 * 
 * The client doesn't necessarily have purposes for these
 * features so we're hiding them behind the feature flag.
 * 
 * This is also a space for developers to hide features that
 * you are waiting to release.
 */
export const getPcgNav: () => NavRoute = () => {
    const pr = PermissionRole;

    const datadog : NavRoute = {
        name: 'Datadog Sandbox'
        , exact: true
        , path: 'administration/datadog/sandbox'
        , openNewTab: true
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.Admin ]
                , permissionRoles: [ pr.SystemAdmin ]
            }
        ]
    };
    
    const epcis : NavRoute = {
        name: 'EPCIS Sandbox'
        , exact: true
        , path: 'administration/epcis/sandbox'
        , openNewTab: true
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.Admin ]
                , permissionRoles: [ pr.SystemAdmin ]
            }
        ]
    };

    const sysInv : NavRoute = {
        name: 'SysInv Sandbox'
        , exact: true
        , path: 'administration/sysInv/sandbox'
        , openNewTab: true
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.Admin ]
                , permissionRoles: [ pr.SystemAdmin ]
            }
        ]
    };

    const releaseNotes : NavRoute = {
        name: 'Release Notes'
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.Admin ]
                , permissionRoles: [ PermissionRole.SystemAdmin ]
            }
        ]
        , exact: true
        , path: 'administration/release-notes/web-release-notes-list'
    };

    const quartz : NavRoute = {
        name: 'Quartz'
        , exact: true
        , path: 'quartz'
        , openNewTab: true
        , moduleAccess: [
            {
                modules: [ BusinessAreaEnum.Admin ]
                , permissionRoles: [ pr.SystemAdmin ]
            }
        ]
    };

    const children : NavRoute[] = [ 
        datadog
        , epcis
        , sysInv
        , releaseNotes
        , quartz
    ]

    // If you see this code, you're the target audience for this nav item!
    const navItems: NavRoute = {
        name: 'PCG'
        , featureFlag: true
        , children : children 
    }

    return navItems;
}