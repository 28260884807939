import { Component, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from 'app/shared/form-elements/form-validateForm.function';
import { ForgotPasswordVm } from 'app/shared/generated/Models/ForgotPasswordVm';

@Component({
	selector: 'pcg-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnDestroy, AfterViewInit {

	@ViewChild('footerEl', { static: true }) footerEl: ElementRef;

	forgotPasswordForm = ForgotPasswordVm.Form;
	forgotPasswordSub: Subscription = new Subscription();
	checkEmail = false;

	constructor(private ms: SystemMessageService) { }

    ngAfterViewInit() {
		const footContainer = document.querySelector('#footer .container');
		footContainer.insertBefore(this.footerEl.nativeElement, footContainer.firstChild);
    }

	onSubmit() {
		if (this.forgotPasswordForm.valid) {
			this.forgotPasswordSub.add(this.ms.getHttpObservable(this, 'api/Account/ForgotPassword', this.forgotPasswordForm)
				.subscribe(msg => { if (msg && msg.isSuccessful) { this.checkEmail = true; } }));
		} else { validateForm(this.forgotPasswordForm); }
	}

	ngOnDestroy() {
		this.forgotPasswordSub.unsubscribe();
		this.footerEl.nativeElement.parentNode.removeChild(this.footerEl.nativeElement);
	}
}
