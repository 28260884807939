import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class MakeCredentialVm {
	userId: number;
	displayName?: string;
	password?: string;
	isFingerprint: boolean;
	attestationResponse?: any;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'userId': new UntypedFormControl(0, [...(validators['userId'] ?? []), ...[Validators.required]]),
			'displayName': new UntypedFormControl(null, [...(validators['displayName'] ?? []), ...[]]),
			'password': new UntypedFormControl(null, [...(validators['password'] ?? []), ...[]]),
			'isFingerprint': new UntypedFormControl(false, [...(validators['isFingerprint'] ?? []), ...[]]),
			'attestationResponse': new UntypedFormControl(null, [...(validators['attestationResponse'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): MakeCredentialVmFromFg {
		return new MakeCredentialVmFromFg(fg);
	}
}

export class MakeCredentialVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get userId(): number {
		return this.fg.get('userId').value;
	}
	set userId(val) {
		this.fg.get('userId').setValue(val);
	}

	get displayName(): string {
		return this.fg.get('displayName').value;
	}
	set displayName(val) {
		this.fg.get('displayName').setValue(val);
	}

	get password(): string {
		return this.fg.get('password').value;
	}
	set password(val) {
		this.fg.get('password').setValue(val);
	}

	get isFingerprint(): boolean {
		return this.fg.get('isFingerprint').value;
	}
	set isFingerprint(val) {
		this.fg.get('isFingerprint').setValue(val);
	}

	get attestationResponse(): any {
		return this.fg.get('attestationResponse').value;
	}
	set attestationResponse(val) {
		this.fg.get('attestationResponse').setValue(val);
	}
}
