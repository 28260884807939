<div class="modal-header">
	<h5 
		class="modal-title" 
		id="modal-basic-title"
	>Register Device</h5>
	<button 
		type="button" 
		class="close" 
		aria-label="Close" 
		(click)="activeModal.dismiss()"
	><span aria-hidden="true">&times;</span></button>
</div>

<div 
	*ngIf='displayName !== ""' 
	class="modal-body"
>
	<img 
		id='imgSecurityKey' 
		src='assets/images/securitykey.svg'
	/>
	<br />
	<h2>Registering...</h2>
	<p>Tap your security key to continue registering your device...</p>
</div>

<div 
	*ngIf='displayName === ""' 
	class="modal-body"
>
	<p>Please enter a friendly display name for your security device.</p>
	<label class='required-field for'>Display Name</label>
	<input 
		(keyup.enter)='registerCredential()' 
		[(ngModel)]='displayNameVal' 
		id='txtDisplayName' 
		type='text' 
		class='form-control' 
		autocomplete='nothing' 
	/>
	<br />
	<pcg-show-hide-password
		#VerifyPassword
		(enterKeyPressed)='registerCredential()' 
		[(password)]='password'
		label="Verify Password"
		fcName="password" 
		inputCssClass="form-control"
		type='password'
	></pcg-show-hide-password>
	
	<div class="row mt-4">
		<div class="col-md-12">
			<div class="form-check form-check-inline ml-1">
				<ui-switch 
					class="form-check-inline" 
					[(ngModel)]='isFingerprint'
					id="chkIsFingerprint" 
					name="isFingerprint"
				></ui-switch>
				<label class="form-check-label" for="chkIsFingerprint">Is Fingerprint Device</label>
			</div>
		</div>
	</div>
</div>

<div class="modal-footer">
	<button 
		class="btn btn-cancel btn-lg" 
		(click)="activeModal.dismiss()"
	>Cancel</button>
	<button 
		*ngIf='displayName === ""'
		[disabled]='!validateForm()' 
		(click)='registerCredential()' 
		type="submit" 
		class="btn btn-save btn-lg"
	>Set Name</button>
</div>
