import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NavigateToOrderOrJobVm } from 'app/shared/generated/CIRepack/Models/Dashboard/NavigateToOrderOrJobVm';

@Injectable({
  	providedIn: 'root'
})
export class CiDashboardService {

	constructor(private httpClient: HttpClient) { }

	canNavigateToRepackOrderByString(repackOrderId : string) : Observable<NavigateToOrderOrJobVm> {
		return this.httpClient.get<NavigateToOrderOrJobVm>(`api/CIRepack/CIDashboard/NavigateToRepackOrderByString?repackOrderId=${repackOrderId}`);
	}	

	canNavigateToRepackOrderById(repackOrderId : number) : Observable<NavigateToOrderOrJobVm> {
		return this.httpClient.get<NavigateToOrderOrJobVm>(`api/CIRepack/CIDashboard/NavigateToRepackOrderById?repackOrderId=${repackOrderId}`);
	}

	canNavigateToJobByString(jobId : string, isHomer : boolean = false) : Observable<NavigateToOrderOrJobVm> {
		let httpParams = new HttpParams().set('jobId', jobId).set('isHomer', isHomer);
		return this.httpClient.get<NavigateToOrderOrJobVm>('api/CIRepack/CIDashboard/NavigateToJobByString', { params: httpParams });
	}

	canNavigateToJobById(jobId : number) : Observable<NavigateToOrderOrJobVm> {
		return this.httpClient.get<NavigateToOrderOrJobVm>(`api/CIRepack/CIDashboard/NavigateToJobById?jobId=${jobId}`);
	}

	canNavigateToJobByDohLotNum(lotnum: string) : Observable<NavigateToOrderOrJobVm> {
		return this.httpClient.get<NavigateToOrderOrJobVm>(`api/CIRepack/CIDashboard/NavigateToJobByDohLotNum?lotnum=${lotnum}`);
	}
}
