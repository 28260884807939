<div class="modal-header bg-white rounded">
	<h5 class="modal-title" id="modal-basic-title">Holidays for the year {{ currentYear }}</h5>
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss();">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body rounded bg-light p-0">
	<div class='p-3 notification'>
		<p>The following dates are US Public Holidays (Observed) and are not included in calculations for Daily Avg Usage, Cycle Count Reconciliation Scheduling, etc...</p>
		<table class="data-grid">
			<thead>
				<tr>
					<th>Holiday</th>
					<th>Date</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor='let h of holidays'>
					<td>{{ h.holidayName }}</td>
					<td>{{ h.holidayDate }}</td>
				</tr>
			</tbody>
		</table>
	</div>	
</div>
