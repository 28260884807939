import { HttpClient, HttpParams } from "@angular/common/http";
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	ViewChild,
} from "@angular/core";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SystemMessageService } from "app/core/system-message/system-message-service";
import { ConfirmationCommentModalComponent } from "app/shared/form-elements/components/confirmation-comment-modal/confirmation-comment-modal.component";
import { ConfirmationModalComponent } from "app/shared/form-elements/components/confirmation-modal/confirmation-modal.component";
import { DeleteButtonVm } from "app/shared/generated/Models/DeleteButtonVm";
import { param } from "app/shared/http-params";
import { Subscription } from "rxjs";
import { TableComponent } from "../../table.component";

@Component({
	selector: "pcg-delete-button",
	templateUrl: "./delete-button.component.html",
	styleUrls: ["./delete-button.component.scss"],
})
export class DeleteButtonComponent<T> implements OnDestroy {
	@Input() url: string; // URL to send delete request to
	@Input() id: string; // The id of the record to delete
	@Input() noConfirm = false; // By-pass the confirmation message
	@Input() useDefaultDelete = true; // Whether or not to use the default delete
	@Input() confirmMessage = "Are you sure you want to delete this?"; // Confirmation message
	@Input() table: TableComponent<T>; // Table for ajax reload
	@Input() useTrashIcon: boolean; // Boolean to determine whether to use 'X' svg or trash icon
	@Input() inlineSuccess: boolean;
	@Input() commentModal: boolean;
	@Input() disabled: boolean = false;

	@ViewChild("delBtn") delBtn: ElementRef;

	@Output() delete = new EventEmitter<any>(); // Can be used to trigger a custom function on delete

	subscriptions = new Subscription(); // Used to keep track of subscriptions
	faIconName = { faTrashCan };
	modal: NgbModalRef;
	formGroup = DeleteButtonVm.Form;
	emittedValue: any;

	constructor(
		private ms: SystemMessageService,
		private httpClient: HttpClient,
		public modalService: NgbModal
	) {}

	deleteRecord() {
		// Confirmation not required.
		if (this.noConfirm) {
			this.processDelete();
		}
		// Show confirmation modal.
		else {
			if (!this.commentModal) {
				this.modal = this.modalService.open(ConfirmationModalComponent);
			} else {
				this.modal = this.modalService.open(
					ConfirmationCommentModalComponent
				);
			}
			// Passes modal reference into component to give us the ability to close it.
			this.modal.componentInstance.modalRef = this.modal;
			// Instantiates properties in the component.
			this.modal.componentInstance.confirmationMessage =
				this.confirmMessage;
			// If user confirms, reset the job.
			this.modal.result
				.then((emittedValue) => {
					if (emittedValue) {
						if (typeof emittedValue === "string") {
							this.formGroup.controls["comment"].setValue(
								emittedValue
							);
						}
						if (typeof this.id === "number") {
							this.formGroup.controls["idNum"].setValue(this.id);
						} else
							this.formGroup.controls["idStr"].setValue(this.id);
						this.processDelete();
					}
				})
				.catch((err) => {
					// Prevent Uncaught (in promise) error
				});
		}
	}

	processDelete() {
		if (this.useDefaultDelete) {
			if (this.inlineSuccess && this.table) {
				this.processInlineSuccessDelete();
			} else {
				this.processDefaultDelete();
			}
		} else {
			this.delete.emit();
		}
	}

	//Cannot use with comments required
	//Still needs to be adapted to use the FormGroup
	processDefaultDelete() {
		const idQueryStr =
			typeof this.id === "string" || typeof this.id === "number"
				? "id=" + encodeURIComponent(this.id)
				: param(this.id);
		this.subscriptions.add(
			this.ms
				.getHttpObservable(this, this.url + "?" + idQueryStr)
				.subscribe((obj) => {
					this.delete.emit(obj);
					if (this.table) {
						this.table.ajaxReload();
					}
				})
		);
	}

	processInlineSuccessDelete() {
		let httpParams = new HttpParams().set(
			"id",
			encodeURIComponent(this.id)
		);

		this.httpClient
			.delete(this.url, {
				body: this.formGroup.value,
				params: httpParams,
			})
			.subscribe((ms: any) => {
				if (ms.isSuccessful) {
					let el = this.delBtn.nativeElement.closest(
						".mat-mdc-row"
					) as HTMLElement;

					el.innerHTML = `<td class="bg-success text-white text-center w-100" colspan="${
						this.table.getVisibleColDefs()?.length
					}"><h5>${ms.message}</h5></td>`;

					setTimeout(() => {
						this.delete.emit(ms);
						this.table.ajaxReload();
					}, 2000);
				} else {
					this.ms.setSystemMessage(ms.message, ms.messageClass);
				}
			});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
