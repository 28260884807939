import { combineLatest, concat } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';

import { TableComponent } from './table.component';

export class TableServerSide<T> {

	constructor(private table: TableComponent<T>) { }

	/** This applies server-side filtering, sorting, and paging to our table */
	addServersideEvents() {
		// Add debounce time and distinct check to search to avoid hitting the server so hard
		const perColumnSearchSource = concat(
			this.table.perColumnSearchSource.pipe(
				map(perColumnSearch => perColumnSearch.join('◬'))
				, distinctUntilChanged()
				, take(1)
			)
			, this.table.perColumnSearchSource.pipe(
				debounceTime(this.table.searchDebounceTime)
				, map(perColumnSearch => perColumnSearch.join('◬'))
				, distinctUntilChanged()
			)
		);
		const mainSearchSource = concat(
			this.table.filterSource.pipe(take(1))
			, this.table.filterSource.pipe(
				debounceTime(this.table.searchDebounceTime)
				, distinctUntilChanged()
			)
		);

		// Combine all of the data sources
		const combined = combineLatest(
			[
				this.table.exactMatchSource
				, mainSearchSource
				, perColumnSearchSource
				, this.table.pcgSort.sortChange
				, this.table.currentPageSource
				, this.table.pageSizeSource
			]
		);

		// Subscribe to changes to all observables and update the table from the server if anything changes
		combined.pipe(map(s => JSON.stringify(s)), distinctUntilChanged()).subscribe((s) => { this.table.ajaxReload(); });
	}
}
