import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HomerRegisterVm } from 'app/shared/generated/Models/Public/HomerRegisterVm';

@Injectable({
    providedIn: 'root'
})
export class HomerRegisterService {

    constructor(private httpClient: HttpClient) { }

    getUser(guid: string) : Observable<HomerRegisterVm> {
        return this.httpClient.get<HomerRegisterVm>(`api/PublicHomerRegister/GetPfsUserByGuid?guid=${guid}`)
    }
}
