// #region Imports
import {
	AfterContentInit
	, AfterViewChecked
	, AfterViewInit
	, ChangeDetectorRef
	, Component
	, ContentChildren
	, ElementRef
	, EventEmitter
	, Host
	, HostListener
	, Input
	, OnDestroy
	, OnInit
	, Optional
	, Output
	, QueryList
	, ViewChild
	, ViewEncapsulation
} from "@angular/core";
import { MatColumnDef, MatTable, MatHeaderRowDef, MatFooterRowDef } from "@angular/material/table";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { TreeNode } from "primeng/api";
import { TreeTable } from "primeng/treetable";

import { PcgSortDirective } from "./sort/sort";
import { PcgTableColumn } from "./interfaces/pcg-table-column.interface";
import { TableNoServerSide } from "./table-no-server-side.class";
import { param } from "../http-params";
import { TableServerSide } from "./table-server-side.class";
import { SecurityService } from "app/core/security/security.service";
import { ButtonFilterService } from "./table-top/button-filter/button-filter.service";
import { GlobalService } from "../services/global.service";
import { PcgTableInputColumn } from "./interfaces/pcg-table-input-column.interface";
import { PcgTableInput } from "./interfaces/pcg-table-input.interface";
import { PcgTableResult } from "./interfaces/pcg-table-result.interface";
import { TableTopComponent } from "./table-top/table-top.component";
import { TableTopAlert } from "./table-top-alert/table-top-alert";
// #endregion

declare global {
	interface HTMLCollectionOf<T extends Element> {
		[Symbol.iterator](): Iterator<T>;
	}
}
export { };

@Component({
	selector: "pcg-table",
	templateUrl: "./table.component.html",
	styleUrls: ["./table.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class TableComponent<T> implements OnInit, AfterContentInit, AfterViewChecked, OnDestroy, AfterViewInit {

	//@Input() dataSource: Observable<T[]>;
	@Input() dataSource;
	@Input() columnDefs: Map<string, PcgTableColumn> = new Map<string, PcgTableColumn>();
	@Input() multipleSearch = false;
	@Input() showTableTop = true;
	@Input() showPagination = true;
	@Input() showNumRows = true;
	@Input() canShowHideColumns = true;
	@Input() serverSide = true;
	@Input() pageLengths = [10, 25, 50, 100, 250, 500];
	@Input() pageSize = 100;
	@Input() searchDebounceTime = 400;
	@Input() alwaysBoxed = false; // Forces the table to be in box regardless of screen size
	@Input() responsive = true; // Whether or not to put the table in a responsive box
	@Input() fixedHeader = true; // Whether or not to have a sticky header, only available with responsive grids
	@Input() fixedFooter = true; // Whether or not to have a sticky footer, only available with responsive grids
	@Input() callbackFunc: (colResult: PcgTableResult) => void;
	@Input() ajaxData: any;
	@Input() canGlobalSearch = true;
	@Input() isRowClickable = false;
	@Input() canExportTableToExcel: boolean = null;
	@Input() canPrintTable: boolean = null;
	@Input() hasTooltips = false; // Whether or not the table has column tooltips (for table-top tooltip modal)		
	@Input() tooltipContent: string; // What gets passed into the table-top tooltip modal
	@Input() showTilesBtn = false;
	@Input() showTooltipsBtn = false;
	@Input() editLink = "";
	@Input() tableViewStorage = "";
	@Input() alert: TableTopAlert = null;

	@Input() canExactMatchSearch = true;	
	//** Excel and Print table options */
	@Input() excelUrl: string = null;
	@Input() printUrl: string = null;
	@Input() pdfUrl: string = null;
	@Input() excelTooltip: string = "Export to Excel";
	@Input() printTooltip: string = "Print the Report";
	@Input() pdfTooltip: string = "Export to PDF";	
	@Input() customExcelFunc: () => void;	
	@Input() canExportTableToPdf: boolean = null;	

	/**
	 * Pass through additional security as a boolean, used in conjunction with RequiresFullAccess set in the view model column defs
	 * to prevent columns requiring elevated security from rendering
	 */
	@Input() hasFullAccess = true;

	// Inputs for new mobile cards, including pcg-delete-button inputs
	/* Whether or not table should use the new mobile card design */
	@Input() isUsingMobileCard = false;
	/* Specifies which column is contains the ID of each data element */
	@Input() identificationColumn: string;
	/* Specifies unique (left-side) column for each table */
	@Input() uniqueColumn: string;
	/* Tile Header */
	@Input() tileHeaderColumn: string;
	/* Tile Sub-Header */
	@Input() tileSubHeaderColumn: string;
	/* Ability to add a second custom (left-side) column name */
	@Input() hasSecondUniqueColName: boolean;
	/* Specifies unique (left-side) column for each table */
	@Input() secondUniqueColumn: string;
	/* Delete button inputs */
	@Input() isAdmin: boolean;
	@Input() confirmMessage: string;
	@Input() deleteUrl: string;
	/* clickRow function passed from each table */
	@Input() redirectFunction: (args: any) => void;
	/* When true, clickRow output will be emitted instead of custom redirect func. */
	@Input() redirectFunctionEmit: boolean;
	/* Used to differentiate the unique columns that are do not require a display name to be shown */
	@Input() ignoreUniqueColumnName: boolean;
	/* map of string and and function pointer key value pairs where the key is the column def and the value is a string of HTML used 
	for special data values in mobile view*/
	@Input() mobileMap: Map<string, (args: any) => string>;
	@Input() tilesMap: [];
	/* Used to filter the data as needed in mobile view */
	@Input() mobileFilter: (args: any) => any;
	/* Whether or not to show the edit button in mobile view */
	@Input() showMobileEdit: boolean = false;
	/* Used to addition classed to mobile tiles */
	@Input() mobileClasses: string;
	/* Used created a nested table tree */
	@Input() isNestedTable: boolean = false;

	// Inputs for implementing the table dropdown filter features
	/** Pass through filter id to implement table dropdown filters. */
	@Input() filterId: string = null;
	/** Pass through a filtermap to show filters being utilized. Ex. src\app\shared\business-areas\order-list\order-list */
	@Input() filterMap: {} = null;
	/** Pass through empty filter array for special reset button visibility behavior. */
	@Input() emptyFilters: {} = null;
	/** Dictates whether emptyFilters are used for 'applied filters' visibility. */
	@Input() useEmptyFilters: boolean = false;
	/** Default filters for the table dropdown filters. */
	@Input() defaultFilters: {} = null;
	/** Pass through false if using the table dropdown filters and they do not have reset capabilities */
	@Input() canResetFilters: boolean = true;
	/* map of string and and function pointer key value pairs where the key is the key to the filter and the value is a function return a boolean of whether 
	or not to show reset filters*/
	@Input() customResetFiltersMap: Map<string, (args: any) => boolean>;

	@Output() clickRow = new EventEmitter();
	@Output() tableReceive = new EventEmitter<PcgTableResult>();
	@Output() isUsingTiles = new EventEmitter<boolean>();
	@Output() mobileEventEmitter = new EventEmitter();

	@ContentChildren(MatColumnDef) contentColumnDefs: QueryList<MatColumnDef>;

	@ViewChild(MatTable, { static: true }) table: MatTable<T>;
	@ViewChild(MatHeaderRowDef, { static: true }) searchHeader: MatHeaderRowDef;
	@ViewChild(MatFooterRowDef, { static: true }) footerRow: MatFooterRowDef;
	@ViewChild("tableContainer", { static: true }) tableContainer: ElementRef;
	@ViewChild("tableTop") tableTop: TableTopComponent;
	// PrimeNG tree table documentation: https://primeng.org/treetable
	@ViewChild("treeTable", { static: false }) treeTable: TreeTable;

	prevTableInput: PcgTableInput = null; // This object contains the table data sent to the server on the previous request

	// Keep track of total records
	totalDataCount = 0;
	filteredDataCount = 0;

	// Used to set the top offset for the search row
	searchRowTopOffset = 0;
	tableNum = 0;

	// The current filter text / exact match
	filterSource = new BehaviorSubject<string>("");
	filter$ = this.filterSource.asObservable();
	exactMatchSource = new BehaviorSubject<boolean>(false);
	exactMatch$ = this.exactMatchSource.asObservable();
	perColumnSearchSource = new BehaviorSubject<string[]>([]);
	perColumnSearch$ = this.perColumnSearchSource.asObservable();

	// Pagination variables
	currentPageSource = new BehaviorSubject<number>(1);
	currentPage$ = this.currentPageSource.asObservable();
	pageSizeSource: BehaviorSubject<number>;
	pageSize$: Observable<number>;

	// Data variables
	data: T[];
	dataNoServerSideUpdateSource = new BehaviorSubject<T[]>([]);
	dataSource$: Observable<T[]>;
	filteredDataSource = new BehaviorSubject<T[]>([]);
	filteredData$ = this.filteredDataSource.asObservable();
	dataOnPageSource = new BehaviorSubject<any[]>([{}]);
	dataOnPage$ = this.dataOnPageSource.asObservable();

	isResponsive = false; // Whether or not the table is currently responsive
	hasProtectedFields = false; // Whether or not the table has protected fields
	hasCompletedServerRequest = false; // Whether or not the table has completed a server request

	// Mobile card variables
	isMobile: boolean; // Whether or not screen size is in mobile-view
	showTiles: boolean = false;
	tableData: PcgTableResult; // This is the data that is looped through for the mobile cards

	pageTitle: string;

	loading: boolean = true;
	nestedData!: TreeNode[];
	isExpanded: boolean = false;
	defaultSort: string;
	scrollHeight: string;
	// Setting the scroll height used to keep the nested table scrollable with headers shown.
	observer = new IntersectionObserver(entries => {
		this.scrollHeight = (entries[0].intersectionRatio < 1) 
			? (entries[0].intersectionRect.height - entries[0].intersectionRect.top - 20) + 'px' 
			:  null;
	});

	initCanShowHideColumns = false;

	faPenToSquare = faPenToSquare;

	constructor(
		@Host() @Optional() public pcgSort: PcgSortDirective
		, private sec: SecurityService
		, private http: HttpClient
		, private cdRef: ChangeDetectorRef
		, public btnFilterService: ButtonFilterService
		, private router: Router // DO NOT remove, this is needed for the mobile cards click
	) { }

	// Fix responsive and search header top on resize
	@HostListener("window:resize")
	onResize() {
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
		this.fixResponsive();
		// I need this timeout because it takes 100 ms for the gross dynamic
		// nav sticky CSS to be added in header.component.ts :(
		setTimeout(() => {
			this.fixSearchHeaderTop();
			this.setScroll();
		}, 150);
	}

	// A few simple lambda functions that are used in the template
	hasData = () => this.filteredDataCount !== 0;
	hasNoData = () => this.filteredDataCount === 0;
	getColDefs = () => Array.from(this.columnDefs.keys());
	getVisibleColDefs = () => this.getColDefs().filter((key) => this.columnDefs.get(key).isVisible !== false);
	getSearchColDefs = () => this.getColDefs().map((key) => this.columnDefs.get(key).searchColumn || key);
	getColHeaderDefs = () => this.getVisibleColDefs().map((key) => `${key}_search`);
	getColSearchClasses = (key: string) => this.columnDefs.get(key).multiSearchCellClasses;
	canSearch = (key: string) => this.columnDefs.get(key).canSearch !== false;
	isNdc = (key: string) => this.columnDefs.get(key).isNdc !== false;
	isNdc10 = (key: string) => this.columnDefs.get(key).isNdc10 !== false;
	isSum = (key: string) => this.columnDefs.get(key).isSum !== false;

	ngOnInit() {
		this.isMobile = GlobalService.setIsMobile(window.innerWidth);
		this.initCanShowHideColumns = this.canShowHideColumns;
		if (!this.filterId) { this.canResetFilters = false; }
		if (this.isNestedTable) { this.defaultSort = this.pcgSort.sortData.flat()[0] ?? ""; }

		// Set canResetFilters is dropdown filters are not being implemented
		// Removing columns that require full access when full access is false.
		if (!this.hasFullAccess) {
			this.columnDefs.forEach((values: PcgTableColumn, key: string) => {
				if (values.requiresFullAccess) { this.columnDefs.delete(key); }
			});
		}

		window["tableCount"] = window["tableCount"] ?? 1;
		this.perColumnSearchSource.next(Array.from(new Array(this.getColDefs().length), () => ""));

		if (typeof this.dataSource !== "string") {
			this.dataSource$ = this.dataSource instanceof Observable
				? this.dataSource
				: of(this.dataSource);
		}

		this.tableNum = window["tableCount"]++;
		this.pageSizeSource = new BehaviorSubject<number>(this.pageSize);
		this.pageSize$ = this.pageSizeSource.asObservable();

		if (typeof this.dataSource === "string") {
			// Add our server-side scripting events
			if (this.serverSide === true) { new TableServerSide(this).addServersideEvents(); } 
			else {
				this.ajaxReload((o) => {
					this.data = o.data;
					new TableNoServerSide(this).addNoServersideEvents();
				});
			}
		} else { new TableNoServerSide(this).addNoServersideEvents(); } // Add our client-side scripting events

		if (this.tableViewStorage !== "") {
			if (localStorage.getItem(this.tableViewStorage) === null) {
				if (this.showTiles) { localStorage.setItem(this.tableViewStorage, "true"); }
				else { localStorage.setItem(this.tableViewStorage, "false"); }
			} else {
				if (localStorage.getItem(this.tableViewStorage) === "true") { this.showTiles = true; }
				else { this.showTiles = false; }
			}
		}
	}

	ngAfterContentInit() {
		// Add passed in column definitions
		let hasFooter = false;

		this.contentColumnDefs.forEach((o) => {
			this.table.addColumnDef(o);
			if (typeof o.footerCell !== "undefined") { hasFooter = true; }
		});

		this.columnDefs.forEach((o) => {
			if (o.isSum === true) {
				// Maybe do some processing here to sum the columns auto-magically
			}
		});

		if (hasFooter) { this.table.addFooterRowDef(this.footerRow); }
		// Add the multiple search, if desired
		if (this.multipleSearch) { this.table.addHeaderRowDef(this.searchHeader); }
		// Binding client-side pagination and search events into nested tree table.
		if (
			(
				typeof this.dataSource !== "string" 
				|| !this.serverSide
			) 
			&& this.isNestedTable
		) { this.bindTreeTableEvents(); }
	}

	ngAfterViewInit() {
		// Failed attempt to sum the columns
		// Commented out for now. To revisit later
		/* if (this.hasData) {
			let tbl = document.getElementById('pcgDataTable') as HTMLTableElement;
			let tblBody = tbl.tBodies[0] as HTMLTableSectionElement;
			let tblRows = tblBody.rows as HTMLCollectionOf<HTMLTableRowElement>;
			console.log(tblRows); // this shows the correct table row collection
			let array = Array.from(tblRows);
			console.log(array); // this only shows the table row with the Loading... text
		}	 */
	}

	/** This is hacky, and unfortunately, I don't know a better place to put this code.
	 * We need to constantly make sure the table becomes overflow: auto / fixed height if it becomes wider
	 * than the table container. It's also necessary to make sure the offset for the second header row
	 * containing searches is correct. Both of these things can change based on all kinds of table operations,
	 * and it needs to be fixed based on what is rendered.
	 */
	ngAfterViewChecked() {
		this.fixResponsive();
		this.fixSearchHeaderTop();
	}

	tableViewClick(isTiles: boolean) {
		if (this.tableViewStorage !== "") {
			if (isTiles) { localStorage.setItem(this.tableViewStorage, "true"); }
			else { localStorage.setItem(this.tableViewStorage, "false"); }
		}
		this.showTiles = isTiles;
		if (isTiles === true) { this.canShowHideColumns = false; }
		else { this.canShowHideColumns = this.initCanShowHideColumns; }
	}

	// Unsubscribe from our subscription(s)
	ngOnDestroy() {
		if (this.multipleSearch) {
			let dynamicStyle = document.getElementById(`searchHeader-${this.tableNum}`);
			if (dynamicStyle) { document.head.removeChild(dynamicStyle); }
		}
	}

	updatePerColumnSearch(
		index: number
		, newValue: string
	) {
		const newColumnSearch = this.perColumnSearchSource.value.slice(0); // Make shallow copy of current column search
		newColumnSearch[index] = newValue; // Set new value
		this.perColumnSearchSource.next(newColumnSearch); // Update the per column search values
	}

	// Table actions
	getExcel(exportName: string = "Results") { this.ajaxReload(() => {}, "Excel", exportName); }
	getPdf(exportName: string = null) { this.ajaxReload(() => {}, "PDF", exportName); }

	print() {
		this.pageSizeSource.next(Math.max(...this.pageLengths));
		setTimeout(() => { window.print(); }, 500);
	}

	getPcgTableInput(
		exportType: string = null
		, reportName: string = null
	) {
		const obj: PcgTableInput = {
			columns: null
			, start: this.pageSizeSource?.value * (this.currentPageSource?.value - 1)
			, length: this.pageSizeSource?.value
			, searchText: this.filterSource?.value
			, exactMatch: this.exactMatchSource?.value
			, exportType
			, reportName
			, isNestedTable: this.isNestedTable
		};
		return obj;
	}

	ajaxReload(
		ajaxReturnFunc: (colResult: PcgTableResult) => void = () => {}
		, exportType: string = null
		, reportName: string = null
	) {
		// Create a column list containing sort and per column search information
		const perColSearches = this.perColumnSearchSource.value;
		const columns: PcgTableInputColumn[] = [];
		const colNames = this.getColDefs();
		for (let i = 0; i < perColSearches.length; ++i) {
			const theCol: PcgTableInputColumn = { searchText: perColSearches[i] };
			const sortData = this.pcgSort.sortData;
			const colName = colNames[i];
			const mySort = sortData.find((o) => o[0] === colName);
			if (mySort) {
				theCol.sortColumnNum = this.pcgSort.sortData.indexOf(mySort);
				theCol.sortDirection = mySort[1];
			} else {
				theCol.sortColumnNum = null;
				theCol.sortDirection = null;
			}
			columns.push(theCol);
		}

		// Default the report name to what is in the h1, if nothing provided
		const theH1 = (document.querySelector(".report-title") as HTMLHeadingElement) ?? document.querySelector("h1");
		if (
			theH1 
			&& !reportName
		) { reportName = theH1.innerText; }

		// Create the object to send to the server
		const obj: PcgTableInput = this.getPcgTableInput(
			exportType
			, reportName
		);

		// Check if they are changing exact match + have no search string
		if (
			this.prevTableInput !== null 
			&& this.prevTableInput.exactMatch !== obj.exactMatch 
			&& obj.searchText === ""
		) {
			this.prevTableInput = obj;
			return; // Don't do a request if they are checking/unchecking exact match with no search string
		}
		// Remember the previous table input data
		this.prevTableInput = obj;

		// Get a query string based off of the user supplied ajax data
		const userAjaxDataString = !this.ajaxData
			? ""
			: typeof this.ajaxData === "string"
				? this.ajaxData
				: param(this.ajaxData);

		// User must provide an Excel password if we have protected fields
		if (
			exportType === "Excel" 
			&& this.hasProtectedFields
		) {
			this.sec.promptPassword((excelPassword) => {
				this.sendServerRequest(
					obj
					, userAjaxDataString
					, excelPassword
					, columns
					, ajaxReturnFunc
				);
			});
			return;
		}

		this.hasCompletedServerRequest = exportType === "Excel" || exportType === "PDF";
		this.sendServerRequest(
			obj
			, userAjaxDataString
			, ""
			, columns
			, ajaxReturnFunc
		);
		return true;
	}

	/** Show or hide a column in the table. This is equivalent to
	 * clicking the "Show / Hide" button and toggling the visibility
	 * of a column.
	 */
	hideShowColumn(
		columnName: string
		, isVisible: boolean
	) { this.columnDefs.get(columnName).isVisible = isVisible; }
	emitClick(row) { this.clickRow.emit(row); }

	// Removes specified columns from cards (right-side) on mobile
	getMobileColDefs() {
		const visibleColDefs = this.getVisibleColDefs();
		return visibleColDefs.filter((def) => def !== this.uniqueColumn && def !== "canDelete");
	}

	/**
	 * Send the table information to the server.
	 * Table data will be updated when it returns.
	 */
	private sendServerRequest(
		obj: PcgTableInput
		, userAjaxDataString: string
		, excelPassword: string
		, columns: PcgTableInputColumn[]
		, ajaxReturnFunc: (colResult: PcgTableResult) => void
	) {

		this.http.get(
			`${this.dataSource}?` 
				+ `${param(obj, true)}&${userAjaxDataString}&excelPassword=${encodeURIComponent(excelPassword)}` 
				+ `&columnJson=${encodeURIComponent(
					JSON.stringify(
						columns.map((o) => {
							const newObj: any = {};
							if (
								o.searchText !== null 
								&& o.searchText !== ""
							) { newObj.searchText = o.searchText; }
							if (o.sortColumnNum !== null) { newObj.sortColumnNum = o.sortColumnNum; }
							if (o.sortDirection !== null) { newObj.sortDirection = o.sortDirection; }
							return newObj;
						})
					)
				)}`
			).subscribe((tableResult: PcgTableResult) => {
				if (tableResult.exportLocation) { window.open(tableResult.exportLocation); } 
				else {
					this.totalDataCount = tableResult.recordsTotal;
					if (this.isNestedTable) { this.loadNodes(tableResult.data); }
					// Need to apply filters if we are handling data
					if (this.serverSide === false) { this.dataNoServerSideUpdateSource.next(tableResult.data); } 
					else {
						// Otherwise, set the next page and counts from server
						this.filteredDataCount = tableResult.recordsFiltered;
						this.data = this.filteredDataCount === 0
							? [{}]
							: tableResult.data;
						this.dataOnPageSource.next(this.data);
						this.hasCompletedServerRequest = true;
					}
					if (tableResult.hasProtectedFields) { this.hasProtectedFields = true; }
					this.tableReceive.emit(tableResult);
					ajaxReturnFunc(tableResult);
					if (this.callbackFunc) { this.callbackFunc(tableResult); }
					this.tableData = tableResult; // tableData is used for new mobile card view
					if (
						this.mobileFilter != null 
						&& this.isMobile
					) { this.tableData.data = this.tableData.data.filter(this.mobileFilter); }
				}
			}
		);
	}

	/** This fixes the sticky offset for the search columns, when
	 * multiple search is enabled
	 */
	private fixSearchHeaderTop() {
		let tableHeadRow = this.tableContainer.nativeElement.querySelector("table thead tr th");
		// material tabs cause the table header to be pushed down, this should correct that issue.
		if (tableHeadRow.closest(".mat-mdc-tab-body")) {
			this.setDynamicStyle(
				"navTableOffset"
				, `.pcg-table-fixed-header thead tr th, .pcg-table-fixed-header thead tr, pcg-side-nav { top: ${0}px; }`
			);
			this.cdRef.detectChanges();
		}

		if (this.multipleSearch) {
			const computedStyles = getComputedStyle(tableHeadRow);
			const newSearchRowTopOffset = parseInt(computedStyles.top, 10) + parseInt(computedStyles.height, 10) - 1;

			if (newSearchRowTopOffset !== this.searchRowTopOffset) {
				this.searchRowTopOffset = newSearchRowTopOffset;
				this.setDynamicStyle(
					`searchHeader-${this.tableNum}`,
					`.pcg-table-fixed-header .pcg-table-${this.tableNum} thead tr.search-row th,
					.pcg-table-fixed-header .pcg-table-${this.tableNum} thead tr.search-row {
						top: ${newSearchRowTopOffset}px;
					}`
				);
				this.cdRef.detectChanges();
			}
		}
	}

	private setDynamicStyle(
		name: string
		, styles: string
	) {
		let dynamicStyle = document.getElementById(name);
		if (dynamicStyle) { document.head.removeChild(dynamicStyle); }
		dynamicStyle = document.createElement("style");
		dynamicStyle.id = name;
		dynamicStyle.innerHTML = styles;
		document.head.appendChild(dynamicStyle);
	}

	/** Make the table container overflow auto and fixed height
	 *  based on whether or not the table is overflowing its container.
	 */
	private fixResponsive() {
		if (
			this.alwaysBoxed 
			&& !this.isResponsive
		) {
			this.isResponsive = true;
			this.cdRef.detectChanges();
		} else if (this.responsive) {
			const tableContainerWidth = this.tableContainer.nativeElement.getBoundingClientRect().width;
			const tableWidth = this.tableContainer.nativeElement.querySelector("table").getBoundingClientRect().width;
			if (this.isResponsive !== tableWidth > tableContainerWidth) {
				this.isResponsive = tableWidth > tableContainerWidth;
				this.cdRef.detectChanges();
			}
		}
	}

	loadNodes(data: TreeNode[]) {
		this.loading = true;
		this.isExpanded = false;
		setTimeout(() => {
			this.nestedData = [];
			if (data.length) { data?.map((node) => { this.nestedData.push(node); }); }
			this.cdRef.detectChanges();
			this.loading = false;
		}, data.length);
	}

	toggleNodes() {
		this.loading = true;
		this.isExpanded = !this.isExpanded;
		for (let node of this.nestedData) {
			this.expandRecursive(node, this.isExpanded);
		}
		this.setScroll();
		this.loading = false;
	}

	setScroll() {
		if (!this.nestedData) { this.scrollHeight = null; } 
		else {
			this.cdRef.detectChanges();
			if (this.tableContainer?.nativeElement) { this.observer.observe(this.tableContainer.nativeElement); }		
		}		
	}

	private expandRecursive(
		node: TreeNode
		, isExpand: boolean
	) {
		node.expanded = isExpand;
		if (node.children) { node.children.map((childNode) => { this.expandRecursive(childNode, isExpand); }); }
	}

	private bindTreeTableEvents() {
		this.cdRef.detectChanges();
		// Bind exact match toggle for global search.
		this.exactMatchSource.subscribe((o) => { this.filterSource.next(this.filterSource.getValue()); });
		// Bind global search filtering.
		this.filterSource.subscribe((o) => {
			let mode = this.exactMatchSource.getValue() == true
				? "equals"
				: "contains";
			this.treeTable.filterGlobal(o, mode);
			this.setScroll();
		});
		// Bind table page selection.
		this.currentPageSource.subscribe((event) => {
			this.treeTable.first = (event - 1) * this.pageSizeSource.getValue();
			this.treeTable.cd.detectChanges();
			this.treeTable.serializePageNodes();
			this.setScroll();
		});
		// Bind table page size.
		this.pageSizeSource.subscribe((event) => {
			// this.treeTable.reset();
			this.treeTable.rows = this.pageSize = Number(event);
			this.treeTable.first = (this.currentPageSource.getValue() - 1) * event;
			this.treeTable.cd.detectChanges();
			this.treeTable.updateSerializedValue();
			this.setScroll();
		});
	}
}
