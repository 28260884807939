import { Component, Input, OnInit } from '@angular/core';
import { FormControlName, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'pcg-show-hide-pin',
    templateUrl: './show-hide-pin.component.html',
    styleUrls: ['./show-hide-pin.component.scss']
})
export class ShowHidePinComponent implements OnInit {

    @Input() fcName: string;
    @Input() parentForm: UntypedFormGroup;
    @Input() validations: { [index: string]: string } = {};
    @Input() label: string;
    @Input() inputCssClass: string = "form-control minWidth";

    isNotSmallScreen: boolean;
    showPassword = false;
    faIconName = { faEye, faEyeSlash };

    constructor() {    }

    ngOnInit() { this.isNotSmallScreen = this.notSmallScreen(); }
    toggleShowHidePassword() { this.showPassword = !this.showPassword; }
    notSmallScreen(): boolean { return !this.inputCssClass.includes('mx-sm-1'); }
}
