import { Component, inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'pcg-confirmation-comment-modal',
  standalone : true,
  imports: [FormsModule],
  templateUrl: './confirmation-comment-modal.component.html',
  styleUrl: './confirmation-comment-modal.component.scss'
})
export class ConfirmationCommentModalComponent {
    @Input() confirmationMessage: string = 'You must leave a comment to continue?';
    @Input() showConfirmBtn: boolean = true;
    @Input() error: boolean = false;

    toastr = inject(ToastrService);
    comment : string;

    modalRef: NgbModalRef;

    confirm() { 
      if (FormatHelperService.GetIsNullyOrWhitespace(this.comment))
        return this.toastr.error('Comment is required.', "Error")
      this.modalRef.close(this.comment); 
    }
    cancel() { this.modalRef.close(false); }
}
