<div *ngIf="userIsBounced" class="alert alert-danger" role="alert">
	<div class="d-flex">
		<div class="p-2">
			Our records indicate that your email address is currently on our bounce list and is not receiving emails
			from Homer.<br />
			<strong>Reason: {{ bouncedEmailData.reason }}</strong><br />
			Please contact your company's network security team to whitelist HOMER&#64;fdepcs.com to receive email from the
			system in the future. <br />
			{{ this.errorMsg }}
		</div>
		<div class="ml-auto p2 align-self-end">
			<button type="button" class="btn btn-dark" (click)="removeFromBounceList()">Remove</button>
		</div>
	</div>
</div>
