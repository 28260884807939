<ul [ngClass]='ulClasses'>
	<ng-container *ngFor='let route of loopNavRoutes;'>
		<li 
			#linkLi 
			*ngIf='navService.shouldShowRouteLink(route, navRoutes)' 
			[class.small-screen-only]="route.isInUserNav" 
			[routerLinkActive]='["link-active"]'
			[class.link-active]='route.id === currForceSelect' 
			[routerLinkActiveOptions]='{ exact: route.exact }'>
			<p 
				class="d-block" 
				(click)='clickLink(route)' 
			>
				{{route.name}}
			</p>
		</li>
		<li 
			*ngIf='route && route.children && !route.hasSideNav' 
			[class.small-screen-only]="route.isInUserNav" 
			class="dropdown" [ngClass]="{
				'bottom-arrow': 
					isRoot && 
					!isSecondary &&
					(currSelectedNavMenuItem.startsWith(route.id) || currOpenSecondaryNavMenu.startsWith(route.id)),
				'dropdown-submenu': !isRoot
			}"
			(mouseleave)="closeDropdown(route.id)"
		>
			<p 
				[id]="getHtmlId(route.id) + '_collapser'" 
				role="button" 
				[attr.aria-expanded]="currNavMenuItem !== '' && currNavMenuItem.startsWith(route.id)"
				[attr.aria-controls]="getHtmlId(route.id)"
				class="d-block" 
				style='white-space: nowrap;' 
				[routerLink]="route.href" 
				(mouseenter)="openDropdown(route.id)"
				(click)="isMobile === true ? clickDropdown(route.id) : ''"
				[target]="route.openNewTab ? '_blank' : ''"	
				[ngClass]="{
					'underline-top-nav-style':
						isRoot &&
						!isSecondary &&
						(currSelectedNavMenuItem.startsWith(route.id) || currOpenSecondaryNavMenu.startsWith(route.id)),
					'dropdown-submenu': !isRoot 
				}"			
			>
				{{route.name}}
				<span 
					class="dropdown-toggle" 
					[class.isOpen]="!isRoot && currNavMenuItem !== '' && currNavMenuItem.startsWith(route.id)"
				></span>				
			</p>

			<pcg-top-click-nav 
				[id]="getHtmlId(route.id)" 
				[attr.aria-labelledby]="getHtmlId(route.id) + '_collapser'" 
				[isRoot]='false' 
				[menuItemId]="route.id" 
				[navRoutes]='route.children'
			></pcg-top-click-nav>
		</li>
	</ng-container>
	<li 
		class="dropdown" 
		*ngIf="isRoot && sec.isLoggedOn()"
	>
		<p 
			*ngIf='user' 
			routerLink="#" 
			(click)="clickDropdown('user')" 
			class="d-block small-screen-only dropdown-toggle"
		>
			{{ user.firstName }} {{ user.lastName }}
		</p>
		<ul 
			class="dropdown-menu top-nav-bg" 
			[class.nav-open]="userNavOpen"
		>
			<li 
				[routerLinkActive]='["link-active"]' 
				[routerLinkActiveOptions]='{ exact: true }'
			>
				<p 
					(click)="clickLink()" 
					class="d-block small-screen-only" 
					[routerLink]='"/edit-user/" + user?.id'
				>
					My Profile
				</p>
			</li>
			<li>
				<p 
					class="d-block small-screen-only" 
					routerLink="#" 
					(click)='logout(); clickLink()'
				>
					Logout
				</p>
			</li>
		</ul>
	</li> 
</ul>
