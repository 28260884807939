import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { FormElementsModule } from "app/shared/form-elements/form-elements.module";
import { MaterialsModule } from "app/shared/material.module";
import { TableModule } from "../../table/table.module";
import { FacilityInventoriesComponent } from "./facility-inventories.component";

@NgModule({
    declarations: [
        FacilityInventoriesComponent, 
    ],
    imports: [
        RouterModule, 
        CommonModule,
        TableModule,
        FormElementsModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialsModule
    ],
    exports: [
        RouterModule, 
        FacilityInventoriesComponent,
    ]
})
export class InventoriesModule { }