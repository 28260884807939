import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class HomerRegisterVm {
	id?: number;
	pfsUserId: number;
	firstName?: string;
	lastName?: string;
	userName?: string;
	email?: string;
	phoneNumber?: string;
	pfsFacilityIds?: number[];
	facilityIds?: number[];
	organizationIds?: number[];
	honeyPot?: string;
	alreadyExists: boolean;
	errorMessage?: string;
	fromAccounts: boolean;
	fromIcq: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'id': new UntypedFormControl(null, [...(validators['id'] ?? []), ...[]]),
			'pfsUserId': new UntypedFormControl(0, [...(validators['pfsUserId'] ?? []), ...[Validators.required]]),
			'firstName': new UntypedFormControl(null, [...(validators['firstName'] ?? []), ...[Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9\\s\\'\\-\\']*$")]]),
			'lastName': new UntypedFormControl(null, [...(validators['lastName'] ?? []), ...[Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9\\s\\'\\-\\']*$")]]),
			'userName': new UntypedFormControl(null, [...(validators['userName'] ?? []), ...[Validators.required]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[Validators.required, Validators.email, Validators.maxLength(100)]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[Validators.required]]),
			'pfsFacilityIds': new UntypedFormControl([], [...(validators['pfsFacilityIds'] ?? []), ...[]]),
			'facilityIds': new UntypedFormControl([], [...(validators['facilityIds'] ?? []), ...[]]),
			'organizationIds': new UntypedFormControl([], [...(validators['organizationIds'] ?? []), ...[]]),
			'honeyPot': new UntypedFormControl(null, [...(validators['honeyPot'] ?? []), ...[]]),
			'alreadyExists': new UntypedFormControl(false, [...(validators['alreadyExists'] ?? []), ...[]]),
			'errorMessage': new UntypedFormControl(null, [...(validators['errorMessage'] ?? []), ...[]]),
			'fromAccounts': new UntypedFormControl(false, [...(validators['fromAccounts'] ?? []), ...[]]),
			'fromIcq': new UntypedFormControl(false, [...(validators['fromIcq'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): HomerRegisterVmFromFg {
		return new HomerRegisterVmFromFg(fg);
	}
}

export class HomerRegisterVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get id(): number {
		return this.fg.get('id').value;
	}
	set id(val) {
		this.fg.get('id').setValue(val);
	}

	get pfsUserId(): number {
		return this.fg.get('pfsUserId').value;
	}
	set pfsUserId(val) {
		this.fg.get('pfsUserId').setValue(val);
	}

	get firstName(): string {
		return this.fg.get('firstName').value;
	}
	set firstName(val) {
		this.fg.get('firstName').setValue(val);
	}

	get lastName(): string {
		return this.fg.get('lastName').value;
	}
	set lastName(val) {
		this.fg.get('lastName').setValue(val);
	}

	get userName(): string {
		return this.fg.get('userName').value;
	}
	set userName(val) {
		this.fg.get('userName').setValue(val);
	}

	get email(): string {
		return this.fg.get('email').value;
	}
	set email(val) {
		this.fg.get('email').setValue(val);
	}

	get phoneNumber(): string {
		return this.fg.get('phoneNumber').value;
	}
	set phoneNumber(val) {
		this.fg.get('phoneNumber').setValue(val);
	}

	get honeyPot(): string {
		return this.fg.get('honeyPot').value;
	}
	set honeyPot(val) {
		this.fg.get('honeyPot').setValue(val);
	}

	get alreadyExists(): boolean {
		return this.fg.get('alreadyExists').value;
	}
	set alreadyExists(val) {
		this.fg.get('alreadyExists').setValue(val);
	}

	get errorMessage(): string {
		return this.fg.get('errorMessage').value;
	}
	set errorMessage(val) {
		this.fg.get('errorMessage').setValue(val);
	}

	get fromAccounts(): boolean {
		return this.fg.get('fromAccounts').value;
	}
	set fromAccounts(val) {
		this.fg.get('fromAccounts').setValue(val);
	}

	get fromIcq(): boolean {
		return this.fg.get('fromIcq').value;
	}
	set fromIcq(val) {
		this.fg.get('fromIcq').setValue(val);
	}
}
