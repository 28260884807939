import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { ActivationEnum } from 'app/core/enums/generated/ActivationEnum';
import { ActivationVm } from '../generated/Models/ActivationVm';

@Injectable({
    providedIn: 'root'
})
export class ActivationService {

    constructor(
        private ms: SystemMessageService
        , private httpClient: HttpClient
    ) { }

    activateDeactivate(
        id: any
        , activationEnum: ActivationEnum
        , isActive: boolean
        , isSpecialCase: boolean = false
        , isConfirmed: boolean = false
    ) : Observable<SystemMessage> {
        const model = new ActivationVm();
        model.id = id;
        model.activationEnum = activationEnum;
        model.isActive = isActive;
        model.isConfirmed = isConfirmed;
        const activationForm : UntypedFormGroup = ActivationVm.Form;
        activationForm.setValue(model);

        if (isSpecialCase === true) {
            return this.httpClient.post<SystemMessage>(`api/Activation/ActivateDeactivate`, JSON.stringify(model));
        } else {
            this.ms.getHttpObservable(this, 'api/Activation/ActivateDeactivate', activationForm).subscribe(msg => { return msg; });
            return null;
        }
    }

    /** Use this method if you want to subscribe to the response and perform any subsequent actions afterwards */
    activateDeactivateV2(
        id: any
        , activationEnum: ActivationEnum
        , isActive: boolean
        , isSpecialCase: boolean = false
        , isConfirmed: boolean = false
    ) : Observable<SystemMessage> {
        const model = new ActivationVm();
        model.id = id;
        model.activationEnum = activationEnum;
        model.isActive = isActive;
        model.isConfirmed = isConfirmed;
        const activationForm : UntypedFormGroup = ActivationVm.Form;
        activationForm.setValue(model);

        if (isSpecialCase === true) {
            return this.httpClient.post<SystemMessage>(`api/Activation/ActivateDeactivate`, JSON.stringify(model));
        } else {
            return this.ms.getHttpObservable(this, 'api/Activation/ActivateDeactivate', activationForm)
        }
    }
}
