<ul class='nav navbar-nav top-nav-bg nav-open'>
	<li *ngIf='routeNavId !== rootNavId'>
		<a class="d-block" href="#" (click)="clickDropdown(routeNavId, true)">
			<img class='back-svg' src="assets/images/navigation/Back.svg" />
		</a>
	</li>
	<ng-container *ngFor='let route of loopNavRoutes;'>
		<li class="ellipse" *ngIf=" route.ellipseBefore &&
			!navService.shouldDisplay(route, navRoutes) && !navService.shouldDisplay(navRoutes[route.index - 1], navRoutes)">
			...
		</li>
		<li #linkLi *ngIf="route.path && (route.ellipseBefore || route.ellipseAfter || navService.shouldShowRouteLink(route, navRoutes))"
			[class.link-active]="forceSelect === route.id" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: route.exact }">
			<p class="d-block" [routerLink]="[route.path]" [queryParams]="route.queryParams" [target]="route.openNewTab ? '_blank' : ''">
				{{ route.name }}
			</p>
		</li>
		<li *ngIf=" (route.ellipseBefore || route.ellipseAfter || navService.shouldDisplay(route, navRoutes)) &&
			route && route.children && !route.hasSideNav" class="dropdown">
			<p class="d-block dropdown-toggle" href="#" (click)="clickDropdown(route.id)">{{ route.name }}</p>
		</li>
		<li class="ellipse" *ngIf="route.ellipseAfter 
			&& !navService.shouldDisplay(route, navRoutes) && !navService.shouldDisplay(navRoutes[route.index + 1], navRoutes)">
			...
		</li>
	</ng-container>
</ul>
