import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { UserOrganizationListVm } from 'app/shared/generated/Administration/Models/Organizations/UserOrganizationListVm';
import { TableComponent } from 'app/shared/table/table.component';
import { UserOrganizationService } from '../user-organization.service';

@Component({
    selector: 'pcg-user-associated-organizations',
    templateUrl: './user-associated-organizations.component.html',
    styleUrls: ['./user-associated-organizations.component.scss']
})
export class UserAssociatedOrganizationsComponent implements OnInit {
    // Setting static: true to ensure that configureColumns works correctly.
    // Otherwise, `delete('remove')` call errors.
    @ViewChild(TableComponent, {static: true }) table: TableComponent<UserOrganizationListVm>;

    @Input() userId : number;
    @Input() editable : boolean;
    @Output() sync = new EventEmitter();

    columns = UserOrganizationListVm.ColumnDefs;
    params = { id: 0 };

    hasData = false;

    faIcon = { faXmark }

    constructor(
        public uos: UserOrganizationService
        , private ms : SystemMessageService
    ) { }

    ngOnInit(): void {
        this.configureParams();
        this.configureColumns();  
    }
    
    private configureParams() { this.params.id = this.userId; }

    public configureColumns() {
        if (!this.editable) { this.columns.delete('remove'); }
        this.columns.delete('add');
    }

    tblReceive(event) {
        if(event.recordsTotal > 0){ this.hasData = true; }
        else { this.hasData = false; }
    }
    
    remove(orgId: number) {
        if (!this.editable) { return; }
        this.uos.updateOrganization(this.params.id, orgId)
            .subscribe((sm : SystemMessage) => {
                if (sm.isSuccessful) { this.sync.emit(); }               
              this.ms.setSystemMessage(sm.message, sm.messageClass);
            }
        );
    }
    
    removeAll() {
        const pcgTableInput = this.table.getPcgTableInput();
        const tableInput = JSON.stringify(pcgTableInput);
        this.uos.unassociateAllOrganizations(this.params.id, tableInput)
            .subscribe((sm : SystemMessage) => {
                if (sm.isSuccessful) { this.sync.emit(); }
                this.ms.setSystemMessage(sm.message, sm.messageClass);
            }
        );
    }
}
