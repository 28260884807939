import { Directive, ElementRef, HostListener, Input, forwardRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { PercentageService } from './percentage.service';

const noop = () => { };

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => PercentageDirective),
	multi: true
};
/*
* Custom Directive for Percentage Mask
* The main requirements that drove the creation of this custom directive percentage mask are:
* 1. percentage Mask had to be easy to implement across application.
*     Directive control was used to accomplish that and everything is under one Module that can be easily imported.
* 2. Formatted value should be composed of: Value + 2 decimal point precision + percentage sign.
* 3. When user focus on the input, it should remove all formatting and only keep the decimal amount with the precision.
* 4. User shouldn't be able to type anything that isn't numbers or decimal separator "."
* 5. Optional parameter for allowing negative numbers added.
*/
@Directive({
	selector: '[pcgPercentage]',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class PercentageDirective implements ControlValueAccessor {

	private el: HTMLInputElement;
	// Keeps track of the value without formatting
	private innerValue: any;
	@Input('decimalPrecision')
	private decimalPrecision = 2;

	constructor(
		private elementRef: ElementRef
		, private percentageService: PercentageService
		, private renderer: Renderer2
	) { this.el = elementRef.nativeElement; }

	// Placeholders for the callbacks which are later providesd
	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (a: any) => void = noop;

	// set getter
	get value(): any { return this.innerValue; }

	// set accessor including call the onchange callback
	set value(v: any) {
		if (v !== this.innerValue) {
			this.innerValue = v;
			this.onChangeCallback(v);
		}
	}

	// From ControlValueAccessor interface
	writeValue(value: any) {
		if (value !== this.innerValue) {
			this.el.value = this.percentageService.transform(value, true, this.decimalPrecision);
			if (value) { this.renderer.setAttribute(this.elementRef.nativeElement, 'value', value); }
			this.innerValue = value;
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) { this.onChangeCallback = fn; }
	setDisabledState?(isDisabled: boolean): void { this.el.disabled = isDisabled; }
	// From ControlValueAccessor interface
	registerOnTouched(fn: any) { this.onTouchedCallback = fn; }

	// On Focus remove all non-digit or decimal separator values
	@HostListener('focus', ['$event.target.value'])
	onfocus(value) { this.el.value = this.percentageService.parse(value, true); }

	// On Blue remove all symbols except last . and set to percentage format
	@HostListener('blur', ['$event.target.value'])
	onBlur(value) {
		this.onTouchedCallback();
		this.el.value = this.percentageService.transform(value, true, this.decimalPrecision);
		this.innerValue = this.percentageService.parse(this.el.value, true);
		this.onChangeCallback(this.innerValue);
		if (this.innerValue) { this.renderer.setAttribute(this.elementRef.nativeElement, 'value', this.innerValue); }
	}

	// On Change remove all symbols except last . and set to percentage format
	@HostListener('change', ['$event.target.value'])
	onChange(value) {
		this.el.value = this.percentageService.transform(value, true, this.decimalPrecision);
		this.innerValue = this.percentageService.parse(this.el.value, true);
		this.onChangeCallback(this.innerValue);
		if (this.innerValue) { this.renderer.setAttribute(this.elementRef.nativeElement, 'value', this.innerValue); }
	}

	// Prevent user to enter anything but digits and decimal separator
	@HostListener('keypress', ['$event'])
	onKeyPress(event) {
		const key = event.which || event.keyCode || 0;
		if (key === 45 || (key !== 46 && key > 31 && (key < 48 || key > 57))) { event.preventDefault(); }
	}
}
