import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { FormatHelperService } from 'app/shared/helpers/format-helper.service';

@Injectable()
export class ChangePasswordForm {

	constructor(
		private http: HttpClient
		, @Inject("incCurrentPassword") incCurrentPassword: boolean = false
	) {
		this.formGroup = new UntypedFormGroup({});
		if (incCurrentPassword) {
			this.formGroup.addControl(
				'currentPassword'
				, new UntypedFormControl(
					''
					, {
						validators: [ Validators.required ]
						, asyncValidators: this.isCurrentPasswordValidator
						, updateOn: 'blur'
					}
				)
			);
		}
		this.formGroup.addControl(
			'password'
			, new UntypedFormControl(
				''
				, {
					validators: [ 
						Validators.required
						, Validators.minLength(10)
						, Validators.maxLength(250)
						, this.isSequentialCharsValidator 
					]
					, asyncValidators: this.commonPasswordValidator
					, updateOn: 'blur'
				}
			)
		);
		this.formGroup.addControl(
			'confirmPassword'
			, new UntypedFormControl(
				''
				, [ 
					Validators.required
					, this.passwordsEqualValidator
				]
			)
		);
	}

	// Our form group
	formGroup: UntypedFormGroup = null;

	// Validator to ensure new password === confirmation password
	passwordsEqualValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
		if (this.formGroup && this.formGroup.value) {
			const password = this.formGroup.value.password;
			const confirmPassword = control.value;
			return (password === confirmPassword) ? null : { 'passwordsNotEqual': true };
		}
		return null;
	}

	// Validator to prevent common passwords from being used, based on a dictionary
	commonPasswordValidator: AsyncValidatorFn = (control: UntypedFormGroup): Observable<ValidationErrors> => {
		return this.http.get('api/Account/IsCommonPassword?password=' + encodeURIComponent(control.value));
	}

	// Validator to check current password is correct
	isCurrentPasswordValidator: AsyncValidatorFn = (control: UntypedFormGroup): Observable<ValidationErrors> =>
		this.http.get('api/Account/IsCurrentPassword?password=' + encodeURIComponent(control.value))

	// Validator to check that the formgroup and password field have value and that the input does not contain 3 ascending sequential characters.
	isSequentialCharsValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => 
		(
			this.formGroup 
			&& this.formGroup.value 
			&& !FormatHelperService.GetIsNullyOrWhitespace(control.value) 
			&& this.containsSequential(control.value)
		) ? { 'sequentialChars': true } : null;

	containsSequential(password: string): boolean {
		const asciiMap = password.split('').map((char) => char.charCodeAt(0));
		let i = 0; let sequential = false;
		while (i <= asciiMap.length) {
			// Check for characters in ASCII alphanumeric range
			// [0-9] are contained within ASCII range 48-57
			// [a-zA-Z] are contained within ASCII range 65-90, 97-122
			if (
				(
					asciiMap[i] >= 48 
					&& asciiMap[i] <= 57
				) 
				|| 
				(
					asciiMap[i] >= 65 
					&& asciiMap[i] <= 90
				) 
				|| 
				(
					asciiMap[i] >= 97 
					&& asciiMap[i] <= 122
				)
			) {
				// Checks that next array element is subsequent character
				if (sequential && (asciiMap[i + 1] - 1) === asciiMap[i]) { return true; }
				// Checks whether iterated ASCII value is equal to next ASCII value in index
				sequential = asciiMap[i] === (asciiMap[i + 1] - 1);
			} else {
				// Sets sequential to false for any non-Alphanumeric chars
				sequential = false;
			}
			i++;
		}

		return false;
	}
}
