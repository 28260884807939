<br />
<div class="login-container clearfix">
    <div class="row">
        <div class="col-md-12 alignCenter">
            <img 
                alt="HOMER - Health Operations & Medical Emergency Response" 
                class='system-logo' 
                src='assets/images/logos/logo.png' 
            />
        </div>
    </div>
    <br />

    <div class="row">
        <div class="col-md-12 alignCenter">
            <h3>User Registration from PFS</h3>
        </div>
    </div>
    <br />
    <ng-container 
        *ngIf="
            (
                !fromAccounts 
                && !fromIcq
            ) 
            || (
                fromAccounts 
                && fromIcq
            )
        "
    >
        <div class="divider-content-s2">
            Registering for an account in HOMER coming from PFS will automatically give you access 
            to Support in order to create, view, and manage your Support Desk Tickets. 
            Your facility associations will automatically be associated to match PFS. 
            If your account already exists in HOMER you will be given a message to login with your existing account.
            <br /><br />
            Upon clicking Register you will be sent a welcome email with instructions to continue setting up your account.
        </div>
    </ng-container>
    <ng-template 
        *ngIf="
            fromAccounts 
            && !fromIcq
        " 
        #fromAccountsBanner
    >
        <div class="divider-content-s2">
            <b>Facility Accounts are now managed in Homer as Organization Accounts.</b>
            <br /><br />
            Registering for an account in HOMER coming from PFS will automatically give you access 
            to the Organizations admin area to manage these Accounts. 
            Your Organization associations will automatically be associated based on your PFS Facility associations. 
            If your account already exists in HOMER you will be given a message to login with your existing account.
            Please submit a Support Desk Ticket if you encounter any issues.
            <br /><br />
            Upon clicking Register you will be sent a welcome email with instructions to continue setting up your account.
        </div>
    </ng-template>
    <ng-container 
        *ngIf="
            fromIcq 
            && !fromAccounts
        "
    >
        <div class="divider-content-s2">
            <b>ICQ Review access is now located in HOMER.</b>
            <br /><br />
            Registering for an account in HOMER coming from PFS will automatically give you access 
            to the ICQ Review area. 
            Your Organization associations will automatically be associated based on your PFS Facility associations. 
            Please submit a Support Desk Ticket if you encounter any issues.
            <br /><br />
            Upon clicking Register you will be sent a welcome email with instructions to continue setting up your account.
        </div>
    </ng-container>
    <hr />

    <form 
        *ngIf="(model$ | async)"
        #myForm 
        (submit)="onSubmit()" 
        [formGroup]="formGroup" 
        class=""
    >
        <input type="hidden" formControlName="id" />
        <input type="hidden" formControlName="pfsUserId" />
        <input type="hidden" formControlName="pfsFacilityIds" />
        <input type="hidden" formControlName="facilityIds" />
        <input type="hidden" formControlName="firstName" />
        <input type="hidden" formControlName="lastName" />
        <input type="hidden" formControlName="userName" />
        <input type="hidden" formControlName="email" />
        <input type="hidden" formControlName="phoneNumber" />

        <div class="row mb-1">
            <div class="col-md-6">
                <div class="row mb-1">
                    <div class="col-md-12">
                        Name: {{ model.firstName }} {{ model.lastName }}                        
                    </div>
                </div>

                <div class="row mb-1">
                    <div class="col-md-12">
                        Email: {{ model.email }}                        
                    </div>
                </div>

                <div class="row mb-1">
                    <div class="col-md-12">
                        Phone: {{ model.phoneNumber }}                        
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="row mb-1">
                    <div class="col-md-12 text-center">
                        <button 
                            *ngIf="
                                !model.alreadyExists 
                                && !isSuccess
                            "
                            type='submit' 
                            class="btn btn-save btn-lg" 
                            [disabled]="emptyCaptcha"
                        >Register</button>
                    </div>
                </div>
            </div>
        </div>
        <br />

        <div *ngIf="isError">
            <div class="row">
                <div class="col-md-12 alert alert-danger text-center">
                    {{ errorString }}
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <a [href]="loginUrl">{{ loginUrl }}</a>
                </div>
            </div>
        </div>

        <div 
            *ngIf="
                isSuccess === true 
                && isError === false
            "
        >
            <div class="row">
                <div class="col-md-12 alert alert-success text-center">
                    You have successfully created an account in HOMER. 
                    Please watch for a welcome email from fdepcs.com to finish setting up your account.
                </div>
            </div>
        </div>

        <pcg-control-group 
            class='form-inline' 
            hidden
        >
            <input 
                id="control-pot" 
                type="checkbox" 
                pcgControlRef 
                formControlName='honeyPot' 
            />
        </pcg-control-group>

        <ngx-invisible-recaptcha 
            #captchaElem 
            [siteKey]="siteKey" 
            (reset)="handleReset()" 
            (ready)="handleReady()"
            (load)="handleLoad()" 
            (success)="handleSuccess($event)" 
            [useGlobalDomain]="false" 
            theme="light"
            type="normal" 
            badge="bottomleft" 
            formControlName="recaptcha"
        ></ngx-invisible-recaptcha> 
    </form>
</div>

<br />