import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { faCheck, faGrid } from '@fortawesome/pro-solid-svg-icons';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UiSwitchComponent } from "ngx-ui-switch";
import { Subscription } from "rxjs";

import { BusinessAreaEnum } from "app/core/enums/generated/BusinessAreaEnum";
import { PermissionRole } from "app/core/enums/generated/PermissionRole";
import { SecurityService } from "app/core/security/security.service";
import { SystemMessage, SystemMessageService } from "app/core/system-message/system-message-service";
import { CredentialListVm } from "app/shared/generated/Administration/Models/Security/CredentialListVm";
import { EditUserVm } from "app/shared/generated/Administration/Models/Users/EditUserVm";
import { param } from "app/shared/http-params";
import { TableComponent } from "app/shared/table/table.component";
import { RegisterAuthDeviceModalComponent } from "../register-auth-device-modal/register-auth-device-modal.component";
import { UserChangePasswordModalComponent } from "../user-change-password-modal/user-change-password-modal.component";
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';

@Component({
	selector: "pcg-user-multi-factor-authentication",
	templateUrl: "./user-multi-factor-authentication.component.html",
	styleUrls: ["./user-multi-factor-authentication.component.scss"]
})
export class UserMultiFactorAuthenticationComponent implements OnInit {

	@ViewChild(TableComponent, { static: true }) credentialTable: TableComponent<CredentialListVm>;
	@ViewChild("pinModal", { static: true }) pinModal: TemplateRef<any>;
	@ViewChild("inactivityTimeout", { static: false }) inactivityTimeout: UiSwitchComponent;
	@ViewChild("isInternalBphpUser", { static: false }) isInternalBphpUser: UiSwitchComponent;

	@Input() id = 0;
	@Input() pin: string;
	@Input() editUserVm: EditUserVm;
	@Input() formGroup = EditUserVm.Form;
	@Input() isCurrentUser: boolean = false;
	@Input() canManageUsers: boolean = false;
	@Input() canResetPassword: boolean = false;
	@Input() inactivityTimeoutFg: boolean = false;
	@Input() isInternalBphpUserFg: boolean = false;

	credColumns = CredentialListVm.ColumnDefs;
	subscriptions = new Subscription();

	hasMFADevice = false;
	origInactivityTimeout = false;
	isSysAdmin = false;

	faIconName = { faGrid, faCheck };

	canAccessOtherUsersFingerprints = false;
	user: UserJsVm;


	constructor(
		public modalService: NgbModal
		, private ms: SystemMessageService
		, private sec: SecurityService
		, private httpClient: HttpClient
		, public router: Router
	) { }

	ngOnInit() {
		this.isSysAdmin = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.SystemAdmin ]
		);
		setTimeout(() => {
			// used to put check next to label if they have a device registered
			if (this.credentialTable?.data?.length  >= 1) { this.hasMFADevice = true; }	
		}, 100);
		this.origInactivityTimeout = this.inactivityTimeoutFg;

		this.user = this.sec.getUser();
		this.canAccessOtherUsersFingerprints = this.user.email.includes("@paulconsulting.com");
	}

	saveUsername() {
		this.httpClient.post(`api/Administration/Users/SaveUsername/?id=${this.id}&username=${this.formGroup.controls.userName.value}`, {})
			.subscribe((message: SystemMessage) => {
				if (message.isSuccessful) {
					this.ms.setSystemMessage(message.message);
					return;
				}
				this.ms.setSystemMessage(message.message, 'error');
			});
	}

	setBPHPuser() {
		this.isInternalBphpUserFg = !this.isInternalBphpUserFg;
		this.httpClient.post(`api/Administration/Users/SaveBphpUser/?id=${this.id}&isBphpUser=${this.isInternalBphpUserFg}`, {})
			.subscribe((message: SystemMessage) => {
				if (message.isSuccessful) {
					this.ms.setSystemMessage(message.message);
					var user = this.sec.getUser();
					if (this.id === user.id) { 
						user.isInternalBphpUser = this.isInternalBphpUserFg;
						this.sec.setSecurity(undefined, user);
					}
					return;
				}
				this.ms.setSystemMessage(message.message, 'error');
			});
	}

	setInactivityTimeout() {
		this.inactivityTimeoutFg = !this.inactivityTimeoutFg;
		this.httpClient.post(`api/Administration/Users/SaveInactivityTimeout/?id=${this.id}&inactivityTimeout=${this.inactivityTimeoutFg}`, {})
			.subscribe((message: SystemMessage) => {
				if (message.isSuccessful) {
					this.ms.setSystemMessage(message.message);
					var user = this.sec.getUser();
					if (this.id === user.id) { 
						user.inactivityTimeout = this.inactivityTimeoutFg;
						this.sec.setSecurity(undefined, user);
					}
					return;
				}
				this.ms.setSystemMessage(message.message, 'error');
			});
	}

	openChangePasswordModal() { this.modalService.open(UserChangePasswordModalComponent, { animation: false }); }

	/** Called on click of the reset password and welcome email buttons */
	resetPassword(isWelcomeEmail: boolean) {
		this.subscriptions.add(this.httpClient.post("api/Account/ForgotPassword", {
			email: this.formGroup.value.email
			, isWelcomeEmail
		}).subscribe((msg: SystemMessage) => {
			if (msg.isSuccessful) {
				this.ms.setSystemMessage(
					`An email has been sent to the user with a ${isWelcomeEmail ? "welcome" : "reset password"} link.`
				);
			} else {
				this.ms.setSystemMessage(
					"An error occurred when attempting to send the email.",
					"error"
				);
			}
		}));
	}

	/** Open the modal to add a new multi-factor auth device */
	openMultiFactorDeviceModal() {
		const modal = this.modalService.open(
			RegisterAuthDeviceModalComponent
			, {
				animation: false
				, beforeDismiss: () => {
					this.credentialTable.ajaxReload();
					return true;
				}
			}
		);
		(<RegisterAuthDeviceModalComponent>modal.componentInstance).id = this.id;
	}

	/** Prompt user for password before continuing delete */
	deleteRecord(idParams: any) {
		this.sec.promptPassword(
			(password) => {
				this.subscriptions.add(
					this.ms
						.getHttpObservable(this,
							`api/Administration/Users/DeleteCredential?${param(
								idParams
							)}` + `&password=${encodeURIComponent(password)}`
						).subscribe(() => { this.credentialTable.ajaxReload(); })
				);
			}
			, "Are you sure you want to delete this credential? If so, verify your password below."
			, "Verify Password"
			, true
		);
	}

	/* PIN functions */
	openPINModal() { this.modalService.open(this.pinModal); }
	setPin(pin: string) { this.pin = pin; }
	dismissPinModal() { this.modalService.dismissAll(); }
}
