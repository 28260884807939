import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'app/shared/shared.module';
import { EditUserComponent } from './edit-user.component';
import { RegisterAuthDeviceModalComponent } from './register-auth-device-modal/register-auth-device-modal.component';
import { RegisterPinModalComponent } from './register-pin-modal/register-pin-modal.component';
import { UserAuditHistoryComponent } from './user-audit-history/user-audit-history.component';
import { UserChangePasswordModalComponent } from './user-change-password-modal/user-change-password-modal.component';
import { UserEmailSettingsComponent } from './user-email-settings/user-email-settings.component';
import { UserAssociatedFacilitiesComponent } from './user-facilities/user-associated-facilities/user-associated-facilities.component';
import { UserFacilitiesComponent } from './user-facilities/user-facilities.component';
import { UserNonAssociatedFacilitiesComponent } from './user-facilities/user-non-associated-facilities/user-non-associated-facilities.component';
import { UserLoginHistoryComponent } from './user-login-history/user-login-history.component';
import { UserModuleAccessComponent } from './user-module-access/user-module-access.component';
import { UserMultiFactorAuthenticationComponent } from './user-multi-factor-authentication/user-multi-factor-authentication.component';
import { UserOrganizationsComponent } from './user-organizations/user-organizations.component';
import { UserOrganizationsModule } from './user-organizations/user-organizations.module';
import { UserRoleInfoModalModule } from './user-role-info-modal/user-role-info-modal.module';
import { UserRxConnectsSettingsComponent } from './user-rxconnects-settings/user-rxconnects-settings.component';
import { UserFingerprintAuthenticationComponent } from './user-fingerprint-authentication/user-fingerprint-authentication.component';

@NgModule({
    declarations: [
        EditUserComponent
        , RegisterAuthDeviceModalComponent
        , UserChangePasswordModalComponent
        , UserLoginHistoryComponent
        , UserMultiFactorAuthenticationComponent
        , UserEmailSettingsComponent
        , UserAuditHistoryComponent
        , UserModuleAccessComponent
        , RegisterPinModalComponent
        , UserFacilitiesComponent
        , UserAssociatedFacilitiesComponent
        , UserNonAssociatedFacilitiesComponent
        , UserRxConnectsSettingsComponent
        , UserOrganizationsComponent
        , UserFingerprintAuthenticationComponent
    ]
    , imports: [ 
        RouterModule
        , CommonModule
        , SharedModule
        , UserRoleInfoModalModule
        , NgbModalModule
        , UserOrganizationsModule
    ]
    , exports: [
        EditUserComponent
        , UserLoginHistoryComponent
        , UserMultiFactorAuthenticationComponent
        , UserEmailSettingsComponent
        , UserAuditHistoryComponent
        , UserModuleAccessComponent
        , UserFacilitiesComponent
        , UserAssociatedFacilitiesComponent
        , UserNonAssociatedFacilitiesComponent
    ]
})
export class EditUserModule { }
