import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class LoginVm {
	userName?: string;
	password?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'userName': new UntypedFormControl(null, [...(validators['userName'] ?? []), ...[Validators.required]]),
			'password': new UntypedFormControl(null, [...(validators['password'] ?? []), ...[Validators.required]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): LoginVmFromFg {
		return new LoginVmFromFg(fg);
	}
}

export class LoginVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get userName(): string {
		return this.fg.get('userName').value;
	}
	set userName(val) {
		this.fg.get('userName').setValue(val);
	}

	get password(): string {
		return this.fg.get('password').value;
	}
	set password(val) {
		this.fg.get('password').setValue(val);
	}
}
