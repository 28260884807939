import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class ModuleAccessVm {
	moduleAccessId: number;
	userId: number;
	module?: any;
	permissionRole?: any;
	updatedByUser?: string;
	updateByDate: Date;
	friendlyId?: string;
	displayName?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'moduleAccessId': new UntypedFormControl(0, [...(validators['moduleAccessId'] ?? []), ...[Validators.required]]),
			'userId': new UntypedFormControl(0, [...(validators['userId'] ?? []), ...[Validators.required]]),
			'module': new UntypedFormControl('', [...(validators['module'] ?? []), ...[Validators.required]]),
			'permissionRole': new UntypedFormControl('', [...(validators['permissionRole'] ?? []), ...[Validators.required]]),
			'updatedByUser': new UntypedFormControl(null, [...(validators['updatedByUser'] ?? []), ...[Validators.maxLength(100)]]),
			'updateByDate': new UntypedFormControl('', [...(validators['updateByDate'] ?? []), ...[Validators.required]]),
			'friendlyId': new UntypedFormControl(null, [...(validators['friendlyId'] ?? []), ...[]]),
			'displayName': new UntypedFormControl(null, [...(validators['displayName'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): ModuleAccessVmFromFg {
		return new ModuleAccessVmFromFg(fg);
	}
}

export class ModuleAccessVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get moduleAccessId(): number {
		return this.fg.get('moduleAccessId').value;
	}
	set moduleAccessId(val) {
		this.fg.get('moduleAccessId').setValue(val);
	}

	get userId(): number {
		return this.fg.get('userId').value;
	}
	set userId(val) {
		this.fg.get('userId').setValue(val);
	}

	get module(): any {
		return this.fg.get('module').value;
	}
	set module(val) {
		this.fg.get('module').setValue(val);
	}

	get permissionRole(): any {
		return this.fg.get('permissionRole').value;
	}
	set permissionRole(val) {
		this.fg.get('permissionRole').setValue(val);
	}

	get updatedByUser(): string {
		return this.fg.get('updatedByUser').value;
	}
	set updatedByUser(val) {
		this.fg.get('updatedByUser').setValue(val);
	}

	get updateByDate(): Date {
		return this.fg.get('updateByDate').value;
	}
	set updateByDate(val) {
		this.fg.get('updateByDate').setValue(val);
	}

	get friendlyId(): string {
		return this.fg.get('friendlyId').value;
	}
	set friendlyId(val) {
		this.fg.get('friendlyId').setValue(val);
	}

	get displayName(): string {
		return this.fg.get('displayName').value;
	}
	set displayName(val) {
		this.fg.get('displayName').setValue(val);
	}
}
