import { formatDate } from '@angular/common';
import { Directive, ElementRef } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroupDirective, NgControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';

// This directive was originally copied from here:
// https://www.codeproject.com/Articles/1239543/Angular-Custom-Validation-Component
@Directive({
	selector: '[pcgControlRef]'
})
export class ControlRefDirective {
	
	constructor(private formControl: NgControl, private elRef: ElementRef, private fg: ControlContainer) {
		// Set the id based on the form control name, if none given
		if (!elRef?.nativeElement?.id && elRef?.nativeElement?.attributes?.formControlName) {
			elRef.nativeElement.id = elRef.nativeElement.attributes.formControlName.value;
		}
		// The following addresses the issue with ISO dates rendering without a value.
		if (elRef?.nativeElement?.attributes?.pcgDate) {
			const control = (fg?.control as UntypedFormGroup)?.controls?.[elRef?.nativeElement?.id] as UntypedFormControl;

			if (!FormatHelperService.GetIsNully(control) && !FormatHelperService.GetIsNully(control.value)) {
				control.patchValue(formatDate(control.value, "yyyy-MM-dd", "en-us", "ET"));
			}
		}
	}

	get hasError() {
		return this.formControl.dirty && this.formControl.touched && this.formControl.invalid;
	}

	get isRequired() {
		const validator = this.formControl?.control?.validator ? this.formControl.control.validator({} as AbstractControl) : '';
		return validator && validator.required;
	}

	get errors() {
		if (this.hasError && this.formControl.errors) { return this.formControl.errors; }
		return '';
	}

	get id() { return this.elRef.nativeElement.id; }

	get form() { return this.fg.formDirective ? (this.fg.formDirective as FormGroupDirective).form : null; }
}
