import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { SelectItem } from 'app/shared/models/select-item';

@Component({
	selector: 'pcg-radio-button-list',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => RadioButtonListComponent),
		multi: true
	}],
	templateUrl: './radio-button-list.component.html',
	styleUrls: ['./radio-button-list.component.scss']
})
export class RadioButtonListComponent implements OnInit, ControlValueAccessor {

	@Input() labelForId = '';
	@Input() items: SelectItem[] | string = [];
	@Input('value') _value = null;
	@Input() isDisabled = false;

	yesNoPreset = [
		{ text: 'Yes', value: true },
		{ text: 'No', value: false }
	];

	yesNoAllPreset = [
		{ text: 'Yes', value: true },
		{ text: 'No', value: false },
		{ text: 'All', value: null }
	];

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() { return this._value; }

	set value(val) {
		this._value = val;
		this.onChange(val);
		this.onTouched();
	}

	constructor() { }

	ngOnInit() {
		if (this.items === 'yesNo') { this.items = this.yesNoPreset; }
		else if (this.items === 'yesNoAll') { this.items = this.yesNoAllPreset; }
	}

	writeValue(obj: any) { this.value = obj; }
	registerOnChange(fn: any) { this.onChange = fn; }
	registerOnTouched(fn: any) { this.onTouched = fn; }
	setDisabledState?(isDisabled: boolean) { this.isDisabled = isDisabled; }
}
