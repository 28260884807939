import { NgModule } from "@angular/core";
import { TableModule } from "app/shared/table/table.module";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        TableModule
    ],
    exports: []
})

export class EditOrganizationsModule {}