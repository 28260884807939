import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';

import { ModuleAccessSecurityVm } from 'app/shared/generated/Administration/Models/Users/ModuleAccessSecurityVm';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SystemMessageService } from '../system-message/system-message-service';
import { SecurityService } from './security.service';

@Injectable()
export class AuthGuard {

	constructor(
		private sec: SecurityService
		, private router: Router
		, private ms: SystemMessageService
	) { }

	/**
	 * Controls whether or not a user can access a component
	 */
	canActivate(
		route: ActivatedRouteSnapshot
		, state: RouterStateSnapshot
	) {
		let modules = !route.data 
			? undefined 
			: (route.data['moduleAccess'] as ModuleAccessSecurityVm)?.modules;
		let roles = !route.data 
			? undefined 
			: (route.data['moduleAccess'] as ModuleAccessSecurityVm)?.permissionRoles;
		let user = this.sec.getUser();

		if (modules === undefined) { modules = user?.modules; }
		if (roles === undefined) { roles = user?.permissionRoles; }

		if (this.checkSecurity(state.url, modules, roles)) { return true; }		
				
		if (user) { 
			this.ms.setSystemMessage(
				"Access Denied! You don't have access to this module. Review your module access from your profile or open a new support ticket if you believe this is a mistake."
				, "error"
			); 
		}

		return this.router.createUrlTree(['/dashboard']);
	}

	/**
	 * Controls whether or not a module will be lazy loaded
	 */
	canLoad(route: Route, urlSegment: UrlSegment[]) {
		let modules= !route.data 
			? undefined 
			: (route.data['moduleAccess'] as ModuleAccessSecurityVm)?.modules;
		let roles = !route.data 
			? undefined 
			: (route.data['moduleAccess'] as ModuleAccessSecurityVm)?.permissionRoles;
		const redirectUrl = '/' + window.location.href.replace(document.getElementsByTagName('base')[0].href, '');
		let user = this.sec.getUser();

		if (modules === undefined) { modules = user?.modules; }
		if (roles === undefined) { roles = user?.permissionRoles; }

		if (this.checkSecurity(redirectUrl, modules, roles)) { return true; }
		
		if (user) { 
			this.ms.setSystemMessage(
				"Access Denied! You don't have access to this module. Review your module access from your profile or open a new support ticket if you believe this is a mistake."
				, "error"
			); 
		}

		return this.router.createUrlTree(['/dashboard']);
	}

	/**
	 * Right now it just calls checkSecurity in the security service
	 * to ensure they are logged in and saves the redirect URL.
	 *
	 * We may want to eventually use this to lock people out
	 * of entire modules of the website in one place, based on
	 * a single security setting.
	 */
	checkSecurity(
		url: string
		, modules: BusinessAreaEnum[] = []
		, permissionRoles: PermissionRole[] = []
	) {
		const isValid = this.sec.checkSecurity(modules, permissionRoles);

		// If they are not logged on, save the URL they tried to go to
		if (!isValid && !this.sec.isLoggedOn()) { this.router.navigateByUrl(`/login?redirectUrl=${encodeURIComponent(url)}`); }
		
		return isValid;
	}
}
