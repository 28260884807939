import { Component, Input, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FormatHelperService } from '../helpers/format-helper.service';
import { FaIconPosition } from './fa-icon-position/fa-icon-position';
import { FaNumColor } from './fa-num-color/fa-num-color.enum';

/**
 * This component centralizes the ability to have a font-awesome icon with a number label in the top right corner
 * To properly use this component, the parent component needs to have a property on it for the icon name so that
 * the template html can pass the correct value to this component. 
 * 
 * - For example:
 * class MyClassComponent{
 *  faIconName: FaIconName = new FaIconName();
 * }
 * 
 * - Then in the template html you could have:
 * <pcg-fa-num-icon num="3.14" [iconName]="faIconName.faSun"></pcg-fa-num-icon>
 * 
 * - To do the same with the color, the code would be:
 * faNumColor: typeof FaNumColor = FaNumColor;
 * <pcg-fa-num-icon [numColor]="faNumColor.success"></pcg-fa-num-icon>
 * 
 * - For a position, define an object:
 * faNumPosition: FaNumPosition = new FaIconPosition(topVal: 0, rightVal: 0, bottomVal: 0, leftVal: 0, FaPositionUnits.percent);
 * <pcg-fa-num-icon [numPosition]="faNumPosition"></pcg-fa-num-icon>
 */
@Component({
	selector: 'pcg-fa-num-icon',
	templateUrl: './fa-num-icon.component.html',
	styleUrls: ['./fa-num-icon.component.scss']
})
export class FaNumIconComponent implements OnInit {

	@Input() num?: number;
	@Input() numColor?: FaNumColor;
	@Input() isNumVisible = true;
	@Input() iconName: IconDefinition;
	@Input() iconColor?: string; // Accepts color keywords, rgb, and hex values
	@Input() numPosition?: FaIconPosition;

	iconColorName = "inherit";

	constructor(private formatHelper: FormatHelperService) { }

	ngOnInit() {
		if (!this.formatHelper.GetIsNully(this.iconColor)) {
			this.iconColorName = this.iconColor;
		}
	}
}
