import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";

@Injectable({
	providedIn: "root",
})
export class ValueValidationHelper {
	isNull = (value: any) => (value == null ? true : false);
	isUndefined = (value: any) => (value == undefined ? true : false);
	isEmpty = (value: any) => (value === "" ? true : false);

	isNullOrEmpty = (value: any) => this.isNull(value) || this.isEmpty(value);
	isUndefinedOrEmpty = (value: any) => this.isUndefined(value) || this.isEmpty(value);
	isNullUndefinedOrEmpty = (value: any) => this.isNull(value) || this.isUndefinedOrEmpty(value);

	static requiredNoWhitespace = (control: FormControl) => 
		control.value?.trim().length > 0
			? null
			: { required: true };
}
