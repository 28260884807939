<input 
    #fileUploader 
    class='form-control-file' 
    type='file' 
    [disabled]='isDisabled' 
    (touch)="onTouched" 
    (change)='setValuesOnChange($event)' 
    [name]='labelForId' 
    [id]='labelForId'
/>
