import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FidoService {

    constructor() { }

    static fixAssertionOptions(makeAssertionOptions: any) {
        // it is really annoying that these 2 for loops are needed. 
        // The enums are coming back as either null or the number representation instead of the string value.
        for (let i = 0; i < makeAssertionOptions?.allowCredentials?.length; i++ ) {
            if (makeAssertionOptions.allowCredentials[i].type === 0) {
                makeAssertionOptions.allowCredentials[i].type = 'public-key';
            }
            for (let x = 0; x < makeAssertionOptions?.allowCredentials[i]?.transports?.length; x++) {
                switch (makeAssertionOptions.allowCredentials[i].transports[x]) {
                    case 0: makeAssertionOptions.allowCredentials[i].transports[x] = "usb"; break;
                    case 1: makeAssertionOptions.allowCredentials[i].transports[x] = "nfc"; break;
                    case 2: makeAssertionOptions.allowCredentials[i].transports[x] = "ble"; break;
                    case 3: makeAssertionOptions.allowCredentials[i].transports[x] = "internal"; break;
                }
            }
        }

        switch (makeAssertionOptions.userVerification) {
            case 0: makeAssertionOptions.userVerification = "required"; break;
            case 1: makeAssertionOptions.userVerification = "preferred"; break;
            case 2: makeAssertionOptions.userVerification = "discouraged"; break;
        }

        return makeAssertionOptions;
    }

    static fixPublicKeyCredentialCreationOptions(publicKeyCredentialCreationOptions: any) {
        // it is really annoying that these 2 for loops are needed. 
        // The enums are coming back as either null or the number representation instead of the string value.
        for (let i = 0; i < publicKeyCredentialCreationOptions.pubKeyCredParams.length; i++ ) {
            if (publicKeyCredentialCreationOptions.pubKeyCredParams[i].type === 0) {
                publicKeyCredentialCreationOptions.pubKeyCredParams[i].type = 'public-key';
            }
        }
        if (publicKeyCredentialCreationOptions.excludeCredentials != null) {
            for (let i = 0; i < publicKeyCredentialCreationOptions.excludeCredentials.length; i++ ) {
                if (publicKeyCredentialCreationOptions.excludeCredentials[i].type === 0) {
                    publicKeyCredentialCreationOptions.excludeCredentials[i].type = 'public-key';
                }
                if (publicKeyCredentialCreationOptions.excludeCredentials[i].transports == null) {
                    publicKeyCredentialCreationOptions.excludeCredentials[i].transports = [ 'usb', 'nfc', 'ble', 'internal'];
                }
            }	
        }

        return publicKeyCredentialCreationOptions;
    }
}
