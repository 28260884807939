import { NgModule } from "@angular/core";
import { MatButtonToggleModule, MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from "@angular/material/button-toggle";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
    declarations: [],
    imports: [],
    exports: [
        MatNativeDateModule
        , MatFormFieldModule
        , MatDatepickerModule
        , MatInputModule
        , MatTableModule
        , MatButtonToggleModule
		, MatSlideToggleModule
		, MatSidenavModule
		, MatListModule
        , MatTabsModule
        , MatExpansionModule
        , MatProgressBarModule
    ],
    providers: [
         {
			provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS
			, useValue: {hideSingleSelectionIndicator: true}
		},
	],
})

export class MaterialsModule {}