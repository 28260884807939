import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { Observable, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { HomerRegisterVm } from 'app/shared/generated/Models/Public/HomerRegisterVm';
import { HomerRegisterService } from './homer-register.service';
import { pcgSettings } from 'app/shared/generated/pcg-settings';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';

@Component({
    selector: 'app-homer-register',
    templateUrl: './homer-register.component.html',
    styleUrls: ['./homer-register.component.scss']
})
export class HomerRegisterComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('captchaElem') captchaElem: InvisibleReCaptchaComponent;
    siteKey: string = environment.siteKey;

    public captchaIsLoaded = false;
	public captchaSuccess = false;
	public captchaResponse?: string;
	public captchaIsReady = false;	
	public recaptcha: any = null;
    public execute: any;
    emptyCaptcha: boolean = true;
    honeypotControl;

    formGroup = HomerRegisterVm.Form;
	model = HomerRegisterVm.GetModel(this.formGroup);
	model$: Observable<HomerRegisterVm>;

    isSuccess = false;
    alreadyExists = false;
    isError = false;
    fromAccounts = false;
    fromIcq = false;
    
    errorString = "";
    userGuid = "";
    loginUrl = "";

    constructor(
        private ms: SystemMessageService
        , private cdr: ChangeDetectorRef
        , public router: Router
        , private route: ActivatedRoute
        , private homerRegisterService: HomerRegisterService
        , private formatHelperService : FormatHelperService
        , title: Title
    ) { title.setTitle('HOMER Registration'); }

    ngOnInit() {
        this.setEnv(); 
        this.setUpHoneyPot();
        
        this.route.queryParams.subscribe((params) => {	
            if (!this.formatHelperService.GetIsNullyOrWhitespace(params?.guid)) { 
                this.isError = false; 
                this.userGuid = params.guid;
            } else { 
                this.isError = true; 
                this.errorString = "There was an error retrieving your PFS user information.";
            }
            if (!this.formatHelperService.GetIsNullyOrWhitespace(params?.fromAccounts)) {
                this.fromAccounts = true;
            }
            if (!this.formatHelperService.GetIsNullyOrWhitespace(params?.fromIcq)) {
                this.fromIcq = true;
            }
		});

        this.model$ = this.homerRegisterService.getUser(this.userGuid)
            .pipe(tap((vm: HomerRegisterVm) => { 
                this.formGroup.setValue(vm); 
                this.formGroup.addControl('recaptcha', new UntypedFormControl('', Validators.required));
                if (vm.alreadyExists === true) {
                    this.isError = true;
                    this.errorString = "This user and/or email already exists. " + 
                    "Please login to Homer below to proceed or contact a PFS/HOMER administrator for further assistance.";
                }
                if (vm.errorMessage !== "") { 
                    this.isError = true; 
                    this.errorString = vm.errorMessage;
                }
                this.formGroup.controls['fromAccounts'].setValue(this.fromAccounts);
                this.formGroup.controls['fromIcq'].setValue(this.fromIcq);
            }));
    }

    ngAfterViewInit(): void {
        this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

    setEnv() {
        if (pcgSettings.mode === 'Development') { this.loginUrl = "https://localhost:44327/"; } 
        else if (pcgSettings.mode === 'Demo') { this.loginUrl = "https://demo.fdepcs.com/login"; } 
        else { this.loginUrl = "https://fdepcs.com/login"; }
	}

    onSubmit() {
        this.isSuccess = false;
        this.isError = false;
        this.errorString = "";

        this.ms.getHttpObservable(this, 'api/PublicHomerRegister/RegisterHomerUser', this.formGroup)
            .subscribe(msg => {
                if (msg.isSuccessful) { this.isSuccess = true; } 
                else { 
                    this.isSuccess = false; 
                    this.isError = true;
                    this.errorString = msg.message;
                }
            }
        );
    }

    setUpHoneyPot() {
        this.formGroup.controls['honeyPot'].setValidators(this.requireFalse());
		this.formGroup.updateValueAndValidity();
    }

    requireFalse() : ValidatorFn {
		return (control : AbstractControl) : ValidationErrors | null => {
			if (control.value === true) { return { honeyPot: true}; }
			return null;
		}
	}

    handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.cdr.detectChanges();
	}

    handleReady(): void {
		this.captchaIsReady = true;
		this.cdr.detectChanges();
        this.execute = setInterval(() => {
			this.captchaElem?.execute();
            this.captchaElem?.getResponse() !== '' 
                ? this.emptyCaptcha = false 
                : this.emptyCaptcha = true;
		}, 2000);
    }

    handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
    }

    handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
    }

    ngOnDestroy() { clearInterval(this.execute); }
}
