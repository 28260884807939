import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[pcgFein]'
})
export class FeinDirective {

	constructor(private elRef: ElementRef, @Optional() private control: NgControl) {
	}

	@HostListener('keyup', ['$event'])
	keyEvent() {
		if (this.control) {
			this.control.control.setValue(this.elRef.nativeElement.value);
		}
		// On each keyup event, parses elRef and formats based on 'xx-xxxxxxx' pattern.
		this.elRef.nativeElement.value = this.formatFein(this.elRef.nativeElement.value);
	}

	@HostListener('change', ['$event'])
	changeEvent() {
		if (this.control) {
			this.control.control.setValue(this.elRef.nativeElement.value);
		}
		// Formats FEIN whenever they paste into input field
		this.elRef.nativeElement.value = this.formatFein(this.elRef.nativeElement.value);
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: any) {
		if (!((/^[0-9]$/.test(e.key)) || this.isAllowedCharacter(e))) {
			return false;
		}
	}

	isAllowedCharacter(e: any): boolean {
		return (e.keyCode > 36 && e.keyCode < 41)
			|| e.keyCode === 8
			|| e.keyCode === 9
			|| e.keyCode === 46
			|| e.keyCode === 110
			// Allows ability to select all with Ctrl/Cmd + A
			|| ((e.ctrlKey || e.metaKey) && e.key === 'a')
			// Allows ability to copy
			|| ((e.ctrlKey || e.metaKey) && e.key === 'c')
			// Allows ability to paste
			|| ((e.ctrlKey || e.metaKey) && e.key === 'v');
	}

	formatFein(input: string) {
		input = input.replace(/\D/g, '');

		let formattedString = input.slice(0, 2);

		if (input.length > 2) {
			formattedString += '-' + input.slice(2, 9);
		}

		return formattedString;
	}
}
