<h1 class="d-none">Audit History</h1>
<pcg-table 
    #table 
    [ajaxData]="filters" 
    [pcgSort]="[['dateChanged', 'desc']]"
    dataSource="api/AuditHistory/GetAuditHistory" 
    [columnDefs]="columns" 
    (tableReceive)="auditName = $event.value"
    [canExactMatchSearch]="false"
    [canExportTableToExcel]="true"
>
    
    <ng-container matColumnDef="friendlyRecordId">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('friendlyRecordId').displayName }}</th>
        <td mat-cell *matCellDef="let row">{{ row.friendlyRecordId }}</td>
    </ng-container>

    <ng-container matColumnDef="dateChanged">
        <th class="dateChanged" mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('dateChanged').displayName }}</th>
        <td class="dateChanged" mat-cell *matCellDef="let row">{{ row.dateChangedFormatted }}</td>
    </ng-container>

	<ng-container matColumnDef='module'>
		<th mat-header-cell *matHeaderCellDef>{{ columns.get('module').displayName }}</th>
		<td mat-cell *matCellDef='let row'>{{ row.module }}</td>
	</ng-container>

    <ng-container matColumnDef="recordAffected">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('recordAffected').displayName }}</th>
		<td mat-cell *matCellDef='let row'>{{ row.recordAffected }}</td>
	</ng-container>

    <ng-container matColumnDef="field">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('field').displayName }}</th>
        <td mat-cell *matCellDef="let row">{{ row.field }}</td>
    </ng-container>

    <ng-container matColumnDef="changedFrom">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('changedFrom').displayName }}</th>
        <td mat-cell *matCellDef="let row">{{ row.changedFrom }}</td>
    </ng-container>

    <ng-container matColumnDef="changedTo">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('changedTo').displayName }}</th>
        <td mat-cell *matCellDef="let row">{{ row.changedTo }}</td>
    </ng-container>

    <ng-container matColumnDef="changedBy">
        <th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('changedBy').displayName }}</th>
        <td mat-cell *matCellDef="let row">{{ row.changedBy }}</td>
    </ng-container>
</pcg-table>

<div class='bottom-buttons'>
    <a class='btn btn-cancel btn-lg' (click)="goBack()">Back</a>
</div>