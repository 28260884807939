<div style="text-align: center;">
	<h1>HOMER Tech Spec</h1>
	<h2>Technology</h2>
	<p>Angular 11</p>
	<p>Microsoft .NET 6.0 with C# syntax</p>
	<p>Microsoft SQL Server 2017</p>
	<h2>Supported Browsers</h2>
	<p>Edge</p>
	<p>Google Chrome</p>
	<p>Firefox</p>
	<h2>Domain Names</h2>
	<p>
		<a href="https://fdepcs.com">https://fdepcs.com</a><br />
		<a href="https://demo.fdepcs.com">https://demo.fdepcs.com</a>
	</p>
	<h2>Scheduled Jobs</h2>
	<p>
		Automated Routine - Every Hour
	</p>
</div>
