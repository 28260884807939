import {
	faBell as faBellRegular,
	faComments as faCommentsRegular
} from '@fortawesome/pro-regular-svg-icons';
import {
	faAddressBook,
	faAddressCard,
	faArrowRightArrowLeft,
	faBell,
	faBoxOpen,
	faClock,
	faComments,
	faExclamation,
	faExclamationCircle,
	faExclamationTriangle,
	faHourglassEnd,
	faHourglassHalf,
	faPlus,
	faPrescription,
	faPrescriptionBottle,
	faPrescriptionBottleAlt,
	faSearch,
	faSun,
	faUser,
	faUserMd
} from "@fortawesome/pro-solid-svg-icons";


export class FaIconName {
    faBell= faBell;
    faUser= faUser;
    faSearch= faSearch;
    faPrescription= faPrescription; 
    faPrescriptionBottle= faPrescriptionBottle;
    faBoxOpen= faBoxOpen;
    faUserMd= faUserMd;
    faPrescriptionBottleAlt= faPrescriptionBottleAlt;
    faSun= faSun;
    faHourglassEnd = faHourglassEnd;
    faClock = faClock;
    faHourglassHalf = faHourglassHalf;
	faComments = faComments;
	faExclamationCircle = faExclamationCircle;
	faExclamation = faExclamation;
	faExclamationTriangle = faExclamationTriangle;
	faAddressBook = faAddressBook;
	faAddressCard = faAddressCard;
	faCommentsRegular = faCommentsRegular;
	faBellRegular = faBellRegular;
	faPlus = faPlus;
	faArrowRightArrowLeft = faArrowRightArrowLeft
}
