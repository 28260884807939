import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'pcg-tech-spec',
  templateUrl: './tech-spec.component.html',
  styleUrls: ['./tech-spec.component.scss']
})
export class TechSpecComponent implements OnInit, OnDestroy {

  constructor(public title: Title) { }

  ngOnInit() {
	this.title.setTitle('Tech Spec');
  }

  ngOnDestroy() {

  }
}
