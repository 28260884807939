import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class PfsRegisterVm {
	firstName?: string;
	lastName?: string;
	email?: string;
	confirmEmail?: string;
	phoneNumber?: string;
	facilityIds?: number[];
	honeyPot?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'firstName': new UntypedFormControl(null, [...(validators['firstName'] ?? []), ...[Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9\\s\\'\\-\\']*$")]]),
			'lastName': new UntypedFormControl(null, [...(validators['lastName'] ?? []), ...[Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9\\s\\'\\-\\']*$")]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]]),
			'confirmEmail': new UntypedFormControl(null, [...(validators['confirmEmail'] ?? []), ...[Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[Validators.required]]),
			'facilityIds': new UntypedFormControl([], [...(validators['facilityIds'] ?? []), ...[Validators.required]]),
			'honeyPot': new UntypedFormControl(null, [...(validators['honeyPot'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): PfsRegisterVmFromFg {
		return new PfsRegisterVmFromFg(fg);
	}
}

export class PfsRegisterVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get firstName(): string {
		return this.fg.get('firstName').value;
	}
	set firstName(val) {
		this.fg.get('firstName').setValue(val);
	}

	get lastName(): string {
		return this.fg.get('lastName').value;
	}
	set lastName(val) {
		this.fg.get('lastName').setValue(val);
	}

	get email(): string {
		return this.fg.get('email').value;
	}
	set email(val) {
		this.fg.get('email').setValue(val);
	}

	get confirmEmail(): string {
		return this.fg.get('confirmEmail').value;
	}
	set confirmEmail(val) {
		this.fg.get('confirmEmail').setValue(val);
	}

	get phoneNumber(): string {
		return this.fg.get('phoneNumber').value;
	}
	set phoneNumber(val) {
		this.fg.get('phoneNumber').setValue(val);
	}

	get honeyPot(): string {
		return this.fg.get('honeyPot').value;
	}
	set honeyPot(val) {
		this.fg.get('honeyPot').setValue(val);
	}
}
