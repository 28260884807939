import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';
import { RxToFillVm, RxToFillVmFromFg } from '../../Inventory/Models/RxToFillVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class HomeDeliveryPublicVm {
	rxNumber1?: string;
	rxNumber2?: string;
	rxNumber3?: string;
	rxNumber4?: string;
	rxNumber5?: string;
	rxNumber6?: string;
	phoneNumber?: string;
	rxNumbers?: string[];
	rxRefills?: RxToFillVm[];
	honeyPot: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'rxNumber1': new UntypedFormControl(null, [...(validators['rxNumber1'] ?? []), ...[Validators.required, Validators.maxLength(8)]]),
			'rxNumber2': new UntypedFormControl(null, [...(validators['rxNumber2'] ?? []), ...[Validators.maxLength(8)]]),
			'rxNumber3': new UntypedFormControl(null, [...(validators['rxNumber3'] ?? []), ...[Validators.maxLength(8)]]),
			'rxNumber4': new UntypedFormControl(null, [...(validators['rxNumber4'] ?? []), ...[Validators.maxLength(8)]]),
			'rxNumber5': new UntypedFormControl(null, [...(validators['rxNumber5'] ?? []), ...[Validators.maxLength(8)]]),
			'rxNumber6': new UntypedFormControl(null, [...(validators['rxNumber6'] ?? []), ...[Validators.maxLength(8)]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[Validators.required, Validators.maxLength(21)]]),
			'rxNumbers': new UntypedFormControl([], [...(validators['rxNumbers'] ?? []), ...[]]),
			'rxRefills': new UntypedFormArray([]),
			'honeyPot': new UntypedFormControl(false, [...(validators['honeyPot'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): HomeDeliveryPublicVmFromFg {
		return new HomeDeliveryPublicVmFromFg(fg);
	}
}

export class HomeDeliveryPublicVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get rxNumber1(): string {
		return this.fg.get('rxNumber1').value;
	}
	set rxNumber1(val) {
		this.fg.get('rxNumber1').setValue(val);
	}

	get rxNumber2(): string {
		return this.fg.get('rxNumber2').value;
	}
	set rxNumber2(val) {
		this.fg.get('rxNumber2').setValue(val);
	}

	get rxNumber3(): string {
		return this.fg.get('rxNumber3').value;
	}
	set rxNumber3(val) {
		this.fg.get('rxNumber3').setValue(val);
	}

	get rxNumber4(): string {
		return this.fg.get('rxNumber4').value;
	}
	set rxNumber4(val) {
		this.fg.get('rxNumber4').setValue(val);
	}

	get rxNumber5(): string {
		return this.fg.get('rxNumber5').value;
	}
	set rxNumber5(val) {
		this.fg.get('rxNumber5').setValue(val);
	}

	get rxNumber6(): string {
		return this.fg.get('rxNumber6').value;
	}
	set rxNumber6(val) {
		this.fg.get('rxNumber6').setValue(val);
	}

	get phoneNumber(): string {
		return this.fg.get('phoneNumber').value;
	}
	set phoneNumber(val) {
		this.fg.get('phoneNumber').setValue(val);
	}

	get honeyPot(): boolean {
		return this.fg.get('honeyPot').value;
	}
	set honeyPot(val) {
		this.fg.get('honeyPot').setValue(val);
	}
}
