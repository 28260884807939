// tslint:disable: quotemark
export enum RxConnectsSecurityEnum {
	Repository = 1,
	Donor = 2
}

export namespace RxConnectsSecurityEnum {
	export function toDescription(val: RxConnectsSecurityEnum) {
		switch (val) {
			case 1:
				return "Repository";
			case 2:
				return "Donor";
		}
		return null;
	}
	export function toDisplay(val: RxConnectsSecurityEnum) {
		switch (val) {
			case 1:
				return "Repository";
			case 2:
				return "Donor";
		}
		return null;
	}
}
