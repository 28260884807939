import { Component, Input } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'pcg-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
    @Input() confirmationMessage: string = 'Are you sure you want to continue?';
    @Input() showConfirmBtn: boolean = true;
    @Input() error: boolean = false;

    modalRef: NgbModalRef;

    confirm() { this.modalRef.close(true); }
    cancel() { this.modalRef.close(false); }
}
