import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class DeleteButtonVm {
	idNum?: number;
	idStr?: string;
	comment?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'idNum': new UntypedFormControl(null, [...(validators['idNum'] ?? []), ...[]]),
			'idStr': new UntypedFormControl(null, [...(validators['idStr'] ?? []), ...[]]),
			'comment': new UntypedFormControl(null, [...(validators['comment'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): DeleteButtonVmFromFg {
		return new DeleteButtonVmFromFg(fg);
	}
}

export class DeleteButtonVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get idNum(): number {
		return this.fg.get('idNum').value;
	}
	set idNum(val) {
		this.fg.get('idNum').setValue(val);
	}

	get idStr(): string {
		return this.fg.get('idStr').value;
	}
	set idStr(val) {
		this.fg.get('idStr').setValue(val);
	}

	get comment(): string {
		return this.fg.get('comment').value;
	}
	set comment(val) {
		this.fg.get('comment').setValue(val);
	}
}
