import {
	AfterContentInit,
	Directive,
	OnDestroy,
	QueryList,
} from "@angular/core";
import { MatTab, MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { Subscription } from "rxjs/internal/Subscription";

import { FormatHelperService } from "app/shared/helpers/format-helper.service";
import { NavigationService } from "app/shared/navigation/navigation.service";

@Directive({ selector: "[pcgTabs]" })
export class TabsDirective implements AfterContentInit, OnDestroy {

	private groupSubscription: Subscription;

	constructor(
		private group: MatTabGroup
		, private route: ActivatedRoute
		, private router: Router
		, private nav: NavigationService
	) {
		this.groupSubscription = this.group.selectedTabChange.subscribe({
			next: (mtce: MatTabChangeEvent) => {
				this.onTabChange(mtce);
			},
		});
	}

	ngAfterContentInit(): void {
		/* Setting the tab for views utilizing material tabs, if one is provided in the route. */
		const tabLabelFromRoute = this.route.snapshot.queryParamMap.get("tab");
		const splitUrl = this.router.url.split("?");
		const queryParams = splitUrl[1]?.split("&");

		// if they navigated with a "tab" parameter in their route, but don't have 
		// a value for it, remove it from the route.
		// ex: page?tab= -> page; retains all other non-empty query params
		if (FormatHelperService.GetIsNullyOrWhitespace(tabLabelFromRoute)) {
			const route = [this.router.url.split("?")[0]];
			const qp = this.map(queryParams);
			this.router.navigate(route, { relativeTo: this.route, queryParams: qp } );
		}

		if (!FormatHelperService.GetIsNullyOrWhitespace(tabLabelFromRoute)) {
			// Observable for the QueryList<MatTab> object.
			const $tabChanges = this.group?._tabs.changes;
			$tabChanges.subscribe({
				next: (tabs: QueryList<MatTab>) => {
					// Tabs retrieved from Observable are not zero-indexed.
					// New map is created to zero-index objects.
					const _tabsArr = tabs.map((tab) => tab);
					const index = _tabsArr.findIndex(
						(tab) => tab.textLabel === tabLabelFromRoute
					);
					if (index) { this.group.selectedIndex = index; }
					return;
				},
			});

			const tab = this.group?._tabs?.find(
				(x) => x.textLabel?.toUpperCase() === tabLabelFromRoute.toUpperCase()
			);
			if (tab) { this.group.selectedIndex = tab.position; }
		}
	}

	onTabChange(event: MatTabChangeEvent) {
		if (!event) return;

		const emittedTabLabel = event.tab?.textLabel;
		// split query params 
		const parsedRouteContents = this.router.url.split("?");

		const unparsedQueryParams = parsedRouteContents[1];
		let queryParams: string[] = null;

		if (!FormatHelperService.GetIsNully(unparsedQueryParams)) {
			// query params are divided by '&'
			queryParams = unparsedQueryParams.split("&");
		}

		const params = !queryParams ? null : this.map(queryParams);

		const extras: NavigationExtras = {
			relativeTo: this.route,
			queryParams: params
		}

		if (FormatHelperService.GetIsNullyOrWhitespace(emittedTabLabel)) {
			extras.queryParams["tab"] = null;
		} else {
			if (!extras.queryParams) { extras.queryParams = {}; }
			extras.queryParams["tab"] = emittedTabLabel;
		}

		this.router.navigate([parsedRouteContents[0]], extras);
		// issue: navigation between the tabs fires event that resets the secondary nav

		// to resolve this, if a component calls `setOpenSecondaryMenu` the state
		// of what they passed in will get saved.
		// the same state will be emitted through another `setOpenSecondaryMenu` call  
		// to mimic the secondary nav setting behavior when done on the *.component.ts file
		this.nav.setOpenSecondaryMenu(this.nav.lastPageInitiatedMenu.getValue());
	}

	/** converts an array of query params to key-value pairs */
	private map = (array: string[]): { [key: string]: string } => {
		if (array?.length === 0) return;
		return array?.reduce((map, current) => {
			const [key, value] = current.split('=');
			if (!FormatHelperService.GetIsNullyOrWhitespace(value)) { map[key] = value; }
			return map;
		}, {})
	};

	ngOnDestroy(): void {
		if (this.groupSubscription) { this.groupSubscription.unsubscribe(); }
	}
}
