// tslint:disable: quotemark
export enum EligibilityStatusEnum {
	Indigent = 1,
	UnderinsuredBenefitsExhausted = 2,
	UninsuredNoCoverage = 3
}

export namespace EligibilityStatusEnum {
	export function toDescription(val: EligibilityStatusEnum) {
		switch (val) {
			case 1:
				return "At or below 200% of federal poverty level";
			case 2:
				return "Drug or health care benefits have been exhausted, or no drug coverage, including an inability to afford the out-of-pocket expenses for the drug prescribed";
			case 3:
				return "No health care coverage and not eligible for drug coverage under federal government program";
		}
		return null;
	}
	export function toDisplay(val: EligibilityStatusEnum) {
		switch (val) {
			case 1:
				return "Indigent (at or below 200% of federal poverty level)";
			case 2:
				return "Underinsured (drug or health care benefits have been exhausted, or no drug coverage, including an inability to afford the out-of-pocket expenses for the drug prescribed)";
			case 3:
				return "Uninsured (no health care coverage and not eligible for drug coverage under federal government program)";
		}
		return null;
	}
}
