import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';

import { ButtonFilterService } from './button-filter.service';

@Component({
	selector: 'pcg-button-filter',
	templateUrl: './button-filter.component.html',
	styleUrls: ['./button-filter.component.scss']
})
export class ButtonFilterComponent {

	@ViewChild('filterToggle', { static: false }) filterToggle: ElementRef;

	@Input() filterId: string = null; // Pass through filter id to implement table dropdown filters.

	faIconName = { faFilter };
	
	@HostListener('window:scroll', ['$event'])
	onScroll(){
		if (this.filterToggle) {
			const rect = this.filterToggle.nativeElement.getBoundingClientRect();
			if (rect.bottom <= 0) { this.btnFilterService.closeFilter(this.filterId); }
		}
	}
	
	constructor(public btnFilterService: ButtonFilterService) { }
}
