<div class="modal-header">
	<h5 class="modal-title" id="modal-basic-title">Authentication Key Required</h5>
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<img id='imgSecurityKey' src='assets/images/securitykey.svg' />
	<br />
	<h2>Authentication Key Required</h2>
	<p>Use your security key to continue logging in...</p>
</div>
<div class="modal-footer">
	<button class="btn btn-cancel btn-lg" (click)="modal.dismiss()">Cancel</button>
</div>
