<a 
	*ngIf="hasAccess" 
	(click)="onClick()" 
	[routerLink]='url' 
	[style]='linkStyle'
>
	<ng-container *ngTemplateOutlet="contentTpl"></ng-container> 
</a>
<span 
	*ngIf="!hasAccess" 
	class="disabled"
>
	<ng-container *ngTemplateOutlet="contentTpl"></ng-container> 
</span>
<ng-template #contentTpl>
	<ng-content></ng-content>
</ng-template>
