<div 
    #ciDashboardContent 
    class="pb-3 pt-3 dashboard-actions"   
    *ngIf="dashboardActionsClicked"
>
    <div class="card">
        <div class="card-header"><strong>Quick Scan Navigation</strong></div>
        <div class="card-body">

            <p class="card-text">Scan a Job Id, Order Id, or DOH Lot # into the appropriate scan box to quickly navigate to either record.</p>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-default">Order Id</span>
                </div>
                <input #repackId type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                    (focus) = "clearErrors('order'); repackId.value = '';" (keydown)="orderKeyDown($event)" />
                <div class="input-group-append">
                    <button type="button" class="btn btn-save dashboard-btn" (click)="navToRepackOrder(repackId.value)">Scan</button>
                </div>
            </div>
            <div *ngIf="repackIdhasErrors">
                <p class="text-danger">{{ repackErrorMsg }}</p>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-default">Job Id</span>
                </div>
                <input #jobId type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                    (focus) = "clearErrors('job'); jobId.value = '';" (keydown)="jobKeyDown($event)" />
                <div class="input-group-append">
                    <button type="button" class="btn btn-save dashboard-btn" (click)="navToJob(jobId.value)">Scan</button>
                </div>
            </div>
            <div *ngIf="jobIdHasErrors">
                <p class="text-danger">{{ jobIdErrorMsg }}</p>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-default">DOH Lot #</span>
                </div>
                <input #lotnum type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"
                    (focus) = "clearErrors('lotnum'); lotnum.value = '';" (keydown)="lotnumKeyDown($event)" />
                <div class="input-group-append">
                    <button type="button" class="btn btn-save dashboard-btn" (click)="navToLotnum(lotnum.value)">Find</button>
                </div>
            </div>
            <div *ngIf="lotnumHasErrors">
                <p class="text-danger">{{ lotnumErrorMsg }}</p>
            </div>
        </div>
    </div>

    <div class="text-center col-md-12 p-2">
        <button class="btn btn-cancel btn-sm" (click)="clickedOutside()">Close</button>
    </div>
</div>