import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { UiSwitchComponent } from 'ngx-ui-switch';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, skip, takeUntil, tap } from 'rxjs/operators';

import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { SecurityService } from 'app/core/security/security.service';
import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { NotificationService } from 'app/notification.service';
import { UserEmailSettingsVm } from 'app/shared/generated/Administration/Models/Users/UserEmailSettingsVm';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { ValueValidationHelper } from 'app/shared/helpers/value-validation.service';
import { NavigationService } from 'app/shared/navigation/navigation.service';

@Component({
	selector: 'pcg-user-email-settings',
	templateUrl: './user-email-settings.component.html',
	styleUrls: ['./user-email-settings.component.scss']
})
export class UserEmailSettingsComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('reconciliationEmails', { static: false }) reconciliationEmails: UiSwitchComponent;
	@ViewChild('reconciliationNotifications', { static: false }) reconciliationNotifications: UiSwitchComponent;
	@ViewChild('reorderLowInventory', { static: false }) reorderLowInventory: UiSwitchComponent;
	@ViewChild('overrideReorderSettings', { static: false }) overrideReorderSettings: UiSwitchComponent;
	@ViewChild('fulfillmentOverdue1', { static: false }) fulfillmentOverdue1: UiSwitchComponent;
	@ViewChild('fulfillmentOverdue2', { static: false }) fulfillmentOverdue2: UiSwitchComponent;
	@ViewChild('inventoryChange', { static: false }) inventoryChange: UiSwitchComponent;
	@ViewChild('metabolicFormulaSubmitted', { static: false }) metabolicFormulaSubmitted: UiSwitchComponent;
	@ViewChild('metabolicFormulaComments', { static: false }) metabolicFormulaComments: UiSwitchComponent;
	@ViewChild('newCiInvoicesAvailable', { static: false }) newCiInvoicesAvailable: UiSwitchComponent;
	@ViewChild('newCiInvoicesAvailableNotifications', { static: false }) newCiInvoicesAvailableNotifications: UiSwitchComponent;
	@ViewChild('newCiOrdersAvailable', { static: false }) newCiOrdersAvailable: UiSwitchComponent;
	@ViewChild('ciOrderCommentEmail', { static: false }) ciOrderCommentEmail: UiSwitchComponent;
	@ViewChild('ciOrderNotSubmitted', { static: false }) ciOrderNotSubmitted: UiSwitchComponent;
	@ViewChild('repackInvoiceEmail', { static: false }) repackInvoiceEmail: UiSwitchComponent;
	@ViewChild('facilityAccountRequests', { static: false }) facilityAccountRequests: UiSwitchComponent;
	
	formGroup = UserEmailSettingsVm.Form;
	subscriptions = new Subscription();
	private unsubscribe = new Subject<void>()
	model$: Observable<UserEmailSettingsVm>;
	user: UserJsVm;
	_subscription: PushSubscription;
		
	httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

	pushNotificationsEnabled: boolean;
	disablePushToggle = false;
	iAmMe: boolean;
	canEdit = false; 
	hasReconsAccess = false; 
	hasReplenishmentAccess = false; 
	hasFulfillmentAccess = false;
	hasMetabolicFormulaAccess = false;
	hasHerosAccess = false;
	hasCiModuleAccess = false;
	hasOrganizationAccountRequestAccess = false;
	hasOrganizationAccountUpdateAccess = false;
	hasSupportAccess = false;
	hasIcqAccess = false;
	isInternalBphpUser = false;
	isOverdue1: Number;
	isOverdue2: Number;
	isMetabolicFormulaCommentsAutoFocus = false;
	isCiNewInvoicesAutoFocus = false;
	isNewCiOrdersAutoFocus = false;
	isCiOrderCommentsAutoFocus = false;
	isCiOrderNotSubmittedAutoFocus = false;
	isRepackInvoiceEmailAutoFocus = false;
    hasAdminAccessInAnyModule = false;

	elementParam = "";
	inventoryIds = [];
	userId: number;	

	constructor(
		title: Title
		, private route: ActivatedRoute
		, private httpClient: HttpClient
		, public router: Router
		, private sec: SecurityService
		, private nav: NavigationService
		, private viewportScroller: ViewportScroller
		, public ms : SystemMessageService
		, private swPush: SwPush
		, private toast: NotificationService
		, public val: ValueValidationHelper
	) { 
		title.setTitle('User Email Settings'); 
		swPush.subscription.subscribe((subscription) => {
			this._subscription = subscription;
			this.pushNotificationsEnabled = (this._subscription === null) ? false : true;
		});
	}

	ngOnInit() {
        this.getAccessLevels();
        this.getModel();
		if (this.route.snapshot.queryParamMap.get('el')) { this.elementParam = this.route.snapshot.queryParamMap.get('el'); }
		this.autoSave(); // Handles auto save
	}

	ngAfterViewInit() {
		// set scroller offset if 'el' param present in url
		if (!this.val.isNullUndefinedOrEmpty(this.elementParam))this.viewportScroller.setOffset([0,120]);

		if (this.elementParam === "metabolicFormulaComments") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('metabolicFormula'); }, 500);
		} else if (this.elementParam === "metabolicFormulaSubmitted") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('metabolicFormula'); }, 500);
		} else if (this.elementParam === "ciNewInvoices") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "ciNewOrders") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "ciOrderCommentEmail") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "ciOrderNotSubmitted") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "repackInvoiceEmail") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "ciBatchControlRecord") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "organizationAccountRequestEmail") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('organization'); }, 500);
		} else if (this.elementParam === "organizationAccountExpirationEmail") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('organization'); }, 500);
		} else if (this.elementParam === "inventoryChangeEmail") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('inventory'); }, 500);
		} else if (this.elementParam === "ciEndOfWeekMetrics") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('ciRepack'); }, 500);
		} else if (this.elementParam === "reconciliationEmails") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('reconciliation'); }, 500);
		} else if (this.elementParam === "reorderLowInventory") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('replenishment');}, 500)
		} else if (this.elementParam === "overrideReorderSettings") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('replenishment');}, 500);
		} else if (this.elementParam === "herosPendingAgencyEmails") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('heros'); }, 500);
		} else if (this.elementParam === "releaseNotes") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('other'); }, 500);
		} else if (this.elementParam === "icq") {
			setTimeout(() => { this.viewportScroller.scrollToAnchor('icq'); }, 500);
		}
	}

    getAccessLevels() {
        this.user = this.sec.getUser();
		this.canEdit = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		) 
		|| this.sec?.hasModuleAccess(
			[ 
				BusinessAreaEnum.Admin
				, BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula 
			]
			, [ 
				PermissionRole.UserAdmin
				, ...SecurityService.setMinRole(PermissionRole.Manager)
			]
		);
		this.hasReconsAccess = this.hasReplenishmentAccess 
			= this.hasFulfillmentAccess  
			= this.sec?.hasModuleAccess(
				[ BusinessAreaEnum.Inventory ]
				, SecurityService.setMinRole(PermissionRole.Technician)
			);
		this.hasMetabolicFormulaAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.MetabolicFormula ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.hasHerosAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.HEROS ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.hasCiModuleAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.CI ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.userId = this.route.snapshot.params['id'];
		this.isInternalBphpUser = this.user?.isInternalBphpUser;
		this.hasOrganizationAccountRequestAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, SecurityService.setMinRole(PermissionRole.Manager)
		);
		this.hasOrganizationAccountUpdateAccess = this.sec?.hasModuleAccess(
			[ 
				BusinessAreaEnum.Admin
				, BusinessAreaEnum.CI
				, BusinessAreaEnum.MetabolicFormula
			]
			, SecurityService.setMinRole(PermissionRole.UserAdmin)
		);
		this.hasSupportAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.HelpDesk ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
		this.hasIcqAccess = this.sec?.hasModuleAccess(
			[ BusinessAreaEnum.ICQ ]
			, SecurityService.setMinRole(PermissionRole.User)
		);
        this.getHasAdminAccessInAnyModule();
    }

    getModel() {
        this.model$ = this.httpClient
            .get(`api/Administration/Users/GetUserEmailSettings/${this.userId}`)
            .pipe(tap((model: UserEmailSettingsVm) => {
                    this.formGroup.patchValue(model);
                    this.isOverdue1 = model.fulfillmentOverdue1;
                    this.isOverdue2 = model.fulfillmentOverdue2;
                    this.inventoryIds = model.inventoryIds;
                    if (this.canEdit) { this.nav.setOpenSecondaryMenu(['Admin', 'Users', 'User List']); }
                    this.iAmMe = this.user.id === this.formGroup.controls.userId.value
                })
            );
    }

	autoSave() {
		this.formGroup.valueChanges.pipe(
			// On init, this will skip it's backend hit after it binds the form
			skip(1)
			// Waits 200 ms to ignore additional event bindings that occur
			// when a form control value updates
			, debounceTime(200)
			// Passes in the event into the save function
			, map((formValue) => this.save(formValue))
			// Cleans up the subscription that was created
			, takeUntil(this.unsubscribe)
		).subscribe();
	}

	save(formValue: any) {
		this.httpClient.post(`api/Administration/Users/SaveUserEmailSettings`, formValue)
			.subscribe((msg: SystemMessage) => { this.ms.setSystemMessage(msg.message, msg.messageClass); });
		if (
			this.iAmMe 
			&& this._subscription === null 
			&& (
				Object.values(formValue).includes(2) 
				|| Object.values(formValue).includes(3)
			)
		) { this.subscribe(); }
	}

	selectForAll(param: any) {
		// Send values to db
		if (this.hasFulfillmentAccess) {
			this.formGroup.get('fulfillmentOverdue1').patchValue(param);
			this.formGroup.get('fulfillmentOverdue2').patchValue(param);
		}
		if (this.hasHerosAccess) { this.formGroup.get('herosPendingAgencyEmails').patchValue(param); }
		if (this.hasMetabolicFormulaAccess) {
			this.formGroup.get('metabolicFormulaSubmitted').patchValue(param);
			this.formGroup.get('metabolicFormulaComments').patchValue(param);			
		}
		if (this.hasReconsAccess) { this.formGroup.get('reconciliationEmails').patchValue(param); }
		if (this.hasReplenishmentAccess) {
			this.formGroup.get('reorderLowInventory').patchValue(param);
			this.formGroup.get('overrideReorderSettings').patchValue(param);
		}
		if (this.hasCiModuleAccess) {
			this.formGroup.get('newCiInvoicesAvailable').patchValue(param);
			this.formGroup.get('newCiOrdersAvailable').patchValue(param);
			this.formGroup.get('ciOrderCommentEmail').patchValue(param);
			this.formGroup.get('ciOrderNotSubmitted').patchValue(param);
			this.formGroup.get('ciBatchControlRecord').patchValue(param);
			this.formGroup.get('ciEndOfWeekMetrics').patchValue(param);
			if (this.isInternalBphpUser) { this.formGroup.get('repackInvoiceEmail').patchValue(param); }
		}	
		if (this.hasOrganizationAccountRequestAccess) { 
            this.formGroup.get('organizationAccountRequestEmail').patchValue(param); 
            this.formGroup.get('userEmailBounced').patchValue(param); 
        }
		if (this.hasOrganizationAccountUpdateAccess) { this.formGroup.get('organizationAccountExpirationEmail').patchValue(param); }		
		if (this.hasSupportAccess) {
			this.formGroup.get('ticketUpdate').patchValue(param);
			this.formGroup.get('ticketCreated').patchValue(param);
			this.formGroup.get('ticketStatusChange').patchValue(param);
			this.formGroup.get('ticketCommentAdded').patchValue(param);
			this.formGroup.get('ticketAssigned').patchValue(param);
		}
		if (this.hasIcqAccess) {
			this.formGroup.get('newSurveyAvailable').patchValue(param);
			this.formGroup.get('surveyCompletionReminder').patchValue(param);
			this.formGroup.get('overdueSurveyReminder').patchValue(param);
			this.formGroup.get('scheduleSiteVisitReminder').patchValue(param);
			this.formGroup.get('postSiteVisitReminder').patchValue(param);
		}
        if (this.hasAdminAccessInAnyModule) {
            this.formGroup.get('newErrorReportSubmitted').patchValue(param);
        }
		this.formGroup.get('inventoryChange').patchValue(param);
		this.formGroup.get('releaseNotes').patchValue(param);
		this.overdue1Change();
		this.overdue2Change();

		// Set front-end toggles
		if (this.hasFulfillmentAccess) {
			this.formGroup.value.fulfillmentOverdue1 = param;
			this.formGroup.value.fulfillmentOverdue2 = param;
		}
		if (this.hasHerosAccess) { this.formGroup.value.herosPendingAgencyEmails = param; }
		if (this.hasMetabolicFormulaAccess) {
			this.formGroup.value.metabolicFormulaSubmitted = param;
			this.formGroup.value.metabolicFormulaComments = param;
		}
		if (this.hasReconsAccess) { this.formGroup.value.reconciliationEmails = param; }
		if (this.hasReplenishmentAccess) {
			this.formGroup.value.reorderLowInventory = param;
			this.formGroup.value.overrideReorderSettings = param;
		}
		if (this.hasCiModuleAccess) {
			this.formGroup.value.newCiInvoicesAvailable = param;
			this.formGroup.value.newCiOrdersAvailable = param;
			this.formGroup.value.ciOrderCommentEmail = param;
			this.formGroup.value.ciOrderNotSubmitted = param;
			this.formGroup.value.ciBatchControlRecord = param;
			this.formGroup.value.ciEndOfWeekMetrics = param;
		}
		if (this.hasOrganizationAccountRequestAccess) { 
            this.formGroup.value.organizationAccountRequestEmail = param; 
            this.formGroup.value.userEmailBounced = param;
        }
		if (this.hasOrganizationAccountUpdateAccess) { this.formGroup.value.organizationAccountExpirationEmail = param; }
		if (this.hasSupportAccess) {
			this.formGroup.value.ticketUpdate = param;
			this.formGroup.value.ticketCreated = param;
			this.formGroup.value.ticketStatusChange = param;
			this.formGroup.value.ticketCommentAdded = param;
			this.formGroup.value.ticketAssigned = param;
		}
		if (this.hasIcqAccess) {
			this.formGroup.value.newSurveyAvailable = param;
			this.formGroup.value.surveyCompletionReminder = param;
			this.formGroup.value.overdueSurveyReminder = param;
			this.formGroup.value.scheduleSiteVisitReminder = param;
			this.formGroup.value.postSiteVisitReminder = param;
		}
        if (this.hasAdminAccessInAnyModule) {
            this.formGroup.value.newErrorReportSubmitted = param;
        }
		this.formGroup.value.inventoryChange = param;
		this.formGroup.value.releaseNotes = param;
	}

	selectPushForAll() {
		// Send values to db
		if (this.hasFulfillmentAccess) {
			this.formGroup.get('fulfillmentOverdue1').patchValue(0);
			this.formGroup.get('fulfillmentOverdue2').patchValue(0);
		}
		if (this.hasHerosAccess) { this.formGroup.get('herosPendingAgencyEmails').patchValue(0); }
		if (this.hasMetabolicFormulaAccess) {
			this.formGroup.get('metabolicFormulaSubmitted').patchValue(0);
			this.formGroup.get('metabolicFormulaComments').patchValue(0);			
		}
		if (this.hasReconsAccess) { this.formGroup.get('reconciliationEmails').patchValue(0); }
		if (this.hasReplenishmentAccess) {
			this.formGroup.get('reorderLowInventory').patchValue(0);
			this.formGroup.get('overrideReorderSettings').patchValue(0);
		}
		if (this.hasCiModuleAccess) {
			this.formGroup.get('newCiInvoicesAvailable').patchValue(0);
			this.formGroup.get('newCiOrdersAvailable').patchValue(0);
			this.formGroup.get('ciOrderCommentEmail').patchValue(0);
			this.formGroup.get('ciOrderNotSubmitted').patchValue(0);
			this.formGroup.get('ciBatchControlRecord').patchValue(0);
			this.formGroup.get('ciEndOfWeekMetrics').patchValue(0);
			if (this.isInternalBphpUser) { this.formGroup.get('repackInvoiceEmail').patchValue(0); }
		}	
		if (this.hasOrganizationAccountRequestAccess) { 
            this.formGroup.get('organizationAccountRequestEmail').patchValue(0); 
            this.formGroup.get('userEmailBounced').patchValue(0);
        }
		if (this.hasOrganizationAccountUpdateAccess) { this.formGroup.get('organizationAccountExpirationEmail').patchValue(0); }
		if (this.hasSupportAccess) {
			this.formGroup.get('ticketUpdate').patchValue(0);
			this.formGroup.get('ticketCreated').patchValue(2);
			this.formGroup.get('ticketStatusChange').patchValue(2);
			this.formGroup.get('ticketCommentAdded').patchValue(2);
			this.formGroup.get('ticketAssigned').patchValue(2);
		}
		if (this.hasIcqAccess) {
			this.formGroup.get('newSurveyAvailable').patchValue(2);
			this.formGroup.get('surveyCompletionReminder').patchValue(2);
			this.formGroup.get('overdueSurveyReminder').patchValue(2);
			this.formGroup.get('scheduleSiteVisitReminder').patchValue(2);
			this.formGroup.get('postSiteVisitReminder').patchValue(2);
		}
        if (this.hasAdminAccessInAnyModule) {
            this.formGroup.get('newErrorReportSubmitted').patchValue(2);
        }
		this.formGroup.get('inventoryChange').patchValue(0);
		this.formGroup.get('releaseNotes').patchValue(0);
		this.overdue1Change();
		this.overdue2Change();

		// Set front-end toggles
		if (this.hasFulfillmentAccess) {
			this.formGroup.value.fulfillmentOverdue1 = 0;
			this.formGroup.value.fulfillmentOverdue2 = 0;
		}
		if (this.hasHerosAccess) { this.formGroup.value.herosPendingAgencyEmails = 0; }
		if (this.hasMetabolicFormulaAccess) {
			this.formGroup.value.metabolicFormulaSubmitted = 0;
			this.formGroup.value.metabolicFormulaComments = 0;
		}
		if (this.hasReconsAccess) { this.formGroup.value.reconciliationEmails = 0; }
		if (this.hasReplenishmentAccess) {
			this.formGroup.value.reorderLowInventory = 0;
			this.formGroup.value.overrideReorderSettings = 0;
		}
		if (this.hasCiModuleAccess) {
			this.formGroup.value.newCiInvoicesAvailable = 0;
			this.formGroup.value.newCiOrdersAvailable = 0;
			this.formGroup.value.ciOrderCommentEmail = 0;
			this.formGroup.value.ciOrderNotSubmitted = 0;
			this.formGroup.value.ciBatchControlRecord = 0;
			this.formGroup.value.ciEndOfWeekMetrics = 0;
		}
		if (this.hasOrganizationAccountRequestAccess) { 
            this.formGroup.value.organizationAccountRequestEmail = 0; 
            this.formGroup.value.userEmailBounced = 0; 
        }
		if (this.hasOrganizationAccountUpdateAccess) { this.formGroup.value.organizationAccountExpirationEmail = 0; }
		if (this.hasSupportAccess) {
			this.formGroup.value.ticketUpdate = 0;
			this.formGroup.value.ticketCreated = 2;
			this.formGroup.value.ticketStatusChange = 2;
			this.formGroup.value.ticketCommentAdded = 2;
			this.formGroup.value.ticketAssigned = 2;
		}
		if (this.hasIcqAccess) {
			this.formGroup.value.newSurveyAvailable = 2;
			this.formGroup.value.surveyCompletionReminder = 2;
			this.formGroup.value.overdueSurveyReminder = 2;
			this.formGroup.value.scheduleSiteVisitReminder = 2;
			this.formGroup.value.postSiteVisitReminder = 2;
		}
        if (this.hasAdminAccessInAnyModule) {
            this.formGroup.value.newErrorReportSubmitted = 2;
        }
		this.formGroup.value.inventoryChange = 0;
		this.formGroup.value.releaseNotes = 0;
	}

	selectBothForAll() {
		// Send values to db
		if (this.hasFulfillmentAccess) {
			this.formGroup.get('fulfillmentOverdue1').patchValue(1);
			this.formGroup.get('fulfillmentOverdue2').patchValue(1);
		}
		if (this.hasHerosAccess) { this.formGroup.get('herosPendingAgencyEmails').patchValue(1); }
		if (this.hasMetabolicFormulaAccess) {
			this.formGroup.get('metabolicFormulaSubmitted').patchValue(1);
			this.formGroup.get('metabolicFormulaComments').patchValue(1);			
		}
		if (this.hasReconsAccess) { this.formGroup.get('reconciliationEmails').patchValue(1); }
		if (this.hasReplenishmentAccess) {
			this.formGroup.get('reorderLowInventory').patchValue(1);
			this.formGroup.get('overrideReorderSettings').patchValue(1);
		}
		if (this.hasCiModuleAccess) {
			this.formGroup.get('newCiInvoicesAvailable').patchValue(1);
			this.formGroup.get('newCiOrdersAvailable').patchValue(1);
			this.formGroup.get('ciOrderCommentEmail').patchValue(1);
			this.formGroup.get('ciOrderNotSubmitted').patchValue(1);
			this.formGroup.get('ciBatchControlRecord').patchValue(1);
			this.formGroup.get('ciEndOfWeekMetrics').patchValue(1);
			if (this.isInternalBphpUser) { this.formGroup.get('repackInvoiceEmail').patchValue(1); }
		}	
		if (this.hasOrganizationAccountRequestAccess) { 
            this.formGroup.get('organizationAccountRequestEmail').patchValue(1); 
            this.formGroup.get('userEmailBounced').patchValue(1);
        }
		if (this.hasOrganizationAccountUpdateAccess) { this.formGroup.get('organizationAccountExpirationEmail').patchValue(1); }
		if (this.hasSupportAccess) {
			this.formGroup.get('ticketUpdate').patchValue(1);
			this.formGroup.get('ticketCreated').patchValue(3);
			this.formGroup.get('ticketStatusChange').patchValue(3);
			this.formGroup.get('ticketCommentAdded').patchValue(3);
			this.formGroup.get('ticketAssigned').patchValue(3);
		}
		if (this.hasIcqAccess) {
			this.formGroup.get('newSurveyAvailable').patchValue(3);
			this.formGroup.get('surveyCompletionReminder').patchValue(3);
			this.formGroup.get('overdueSurveyReminder').patchValue(3);
			this.formGroup.get('scheduleSiteVisitReminder').patchValue(3);
			this.formGroup.get('postSiteVisitReminder').patchValue(3);
		}
        if (this.hasAdminAccessInAnyModule) {
            this.formGroup.get('newErrorReportSubmitted').patchValue(3);
        }
		this.formGroup.get('inventoryChange').patchValue(1);
		this.formGroup.get('releaseNotes').patchValue(1);
		this.overdue1Change();
		this.overdue2Change();

		// Set front-end toggles
		if (this.hasFulfillmentAccess) {
			this.formGroup.value.fulfillmentOverdue1 = 1;
			this.formGroup.value.fulfillmentOverdue2 = 1;
		}
		if (this.hasHerosAccess) { this.formGroup.value.herosPendingAgencyEmails = 1; }
		if (this.hasMetabolicFormulaAccess) {
			this.formGroup.value.metabolicFormulaSubmitted = 1;
			this.formGroup.value.metabolicFormulaComments = 1;
		}
		if (this.hasReconsAccess) { this.formGroup.value.reconciliationEmails = 1; }
		if (this.hasReplenishmentAccess) {
			this.formGroup.value.reorderLowInventory = 1;
			this.formGroup.value.overrideReorderSettings = 1;
		}
		if (this.hasCiModuleAccess) {
			this.formGroup.value.newCiInvoicesAvailable = 1;
			this.formGroup.value.newCiOrdersAvailable = 1;
			this.formGroup.value.ciOrderCommentEmail = 1;
			this.formGroup.value.ciOrderNotSubmitted = 1;
			this.formGroup.value.ciBatchControlRecord = 1;
			this.formGroup.value.ciEndOfWeekMetrics = 1;
		}
		if (this.hasOrganizationAccountRequestAccess) { 
            this.formGroup.value.organizationAccountRequestEmail = 1; 
            this.formGroup.value.userEmailBounced = 1;
        }
		if (this.hasOrganizationAccountUpdateAccess) { this.formGroup.value.organizationAccountExpirationEmail = 1; }
		if (this.hasSupportAccess) {
			this.formGroup.value.ticketUpdate = 1;
			this.formGroup.value.ticketCreated = 3;
			this.formGroup.value.ticketStatusChange = 3;
			this.formGroup.value.ticketCommentAdded = 3;
			this.formGroup.value.ticketAssigned = 3;
		}
		if (this.hasIcqAccess) {
			this.formGroup.value.newSurveyAvailable = 3;
			this.formGroup.value.surveyCompletionReminder = 3;
			this.formGroup.value.overdueSurveyReminder = 3;
			this.formGroup.value.scheduleSiteVisitReminder = 3;
			this.formGroup.value.postSiteVisitReminder = 3;
		}
        if (this.hasAdminAccessInAnyModule) {
            this.formGroup.value.newErrorReportSubmitted = 3;
        }
		this.formGroup.value.inventoryChange = 1;
		this.formGroup.value.releaseNotes = 1;
	}

	overdue1Change() {
		if (this.formGroup.value.fulfillmentOverdue1 !== 0) {
			this.isOverdue1 = 1;
			this.formGroup.get('fulfillmentOverdueFirstInventoryIds').patchValue(this.inventoryIds);
		} else {
			this.isOverdue1 = 0;
			this.formGroup.get('fulfillmentOverdueFirstInventoryIds').patchValue(null);
		}
	}

	overdue2Change() {
		if (this.formGroup.value.fulfillmentOverdue2 !== 0) {
			this.isOverdue2 = 1;
			this.formGroup.get('fulfillmentOverdueSecondInventoryIds').patchValue(this.inventoryIds);
		} else {
			this.isOverdue2 = 0;
			this.formGroup.get('fulfillmentOverdueSecondInventoryIds').patchValue(null);
		}
	}

	operatePushNotification() {
        this._subscription == null
			? this.subscribe() 
			: this.unsubscribePush(this._subscription.endpoint);
    }

    subscribe() {
        // Retrieve public VAPID key from the server
        this.httpClient.get('api/PushSubscriptions/GetPublicKey', { responseType: 'text' }).subscribe(publicKey => {
            // Request subscription with the service worker
            this.swPush.requestSubscription({
                serverPublicKey: publicKey
            }).then(subscription => this.httpClient.post('api/PushSubscriptions/AddSubscription', subscription, this.httpOptions).subscribe(
				() => { },
				error => console.error(error)
			)).catch((error) => {
				console.error(error);
				this.toast.showWarning("Check your browser settings. You may have push notifications disabled for this site (if on iOS, select the 'add to home screen' option");
				
				setTimeout(() => {
					document.getElementById("pushToggle").classList.remove("mat-mdc-slide-toggle-checked");
					document.getElementById("pushToggle").classList.remove("cdk-focused");
					document.getElementById("pushToggle").classList.remove("cdk-mouse-focused");
					document.getElementById("pushToggle-button").classList.remove("mdc-switch--selected");
					this.disablePushToggle = true;
				}, 500)
			});
        },
		error => console.error(error));
    }

    unsubscribePush(endpoint) {
        this.swPush.unsubscribe()
            .then(() => this.httpClient.get(
				`api/PushSubscriptions/DeleteSubscription
				?userId=${this.sec?.getUser().id}
				&endpoint=${encodeURIComponent(endpoint)}`)
			.subscribe(() => { },
                error => console.error(error)
            )).catch(error => console.error(error));
    }

	ngOnDestroy() { 
		this.unsubscribe.next();
		this.subscriptions.unsubscribe(); 
	}

    getHasAdminAccessInAnyModule() {
        var adminAccessModules = this.user?.moduleAccess?.filter(o => o.permissionRole > PermissionRole.Pharmacist);
        this.hasAdminAccessInAnyModule = adminAccessModules?.length > 0;
    }
}
