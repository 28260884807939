import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class UserOrganizationListVm {
	organizationId: number;
	organizationName?: string;
	city?: string;
	county?: string;
	facilityCount: number;
	add: boolean;
	remove: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'organizationId': new UntypedFormControl(0, [...(validators['organizationId'] ?? []), ...[Validators.required]]),
			'organizationName': new UntypedFormControl(null, [...(validators['organizationName'] ?? []), ...[]]),
			'city': new UntypedFormControl(null, [...(validators['city'] ?? []), ...[]]),
			'county': new UntypedFormControl(null, [...(validators['county'] ?? []), ...[]]),
			'facilityCount': new UntypedFormControl(0, [...(validators['facilityCount'] ?? []), ...[Validators.required]]),
			'add': new UntypedFormControl(false, [...(validators['add'] ?? []), ...[]]),
			'remove': new UntypedFormControl(false, [...(validators['remove'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['organizationName', {"orderId":0,"sortColumn":"organizationName","searchColumn":"organizationName","includeInExcel":true,"exportColumns":null,"displayName":"Organization","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['city', {"orderId":1,"sortColumn":"city","searchColumn":"city","includeInExcel":true,"exportColumns":null,"displayName":"City","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['county', {"orderId":2,"sortColumn":"county","searchColumn":"county","includeInExcel":true,"exportColumns":null,"displayName":"County","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['facilityCount', {"orderId":3,"sortColumn":"facilityCount","searchColumn":"facilityCount","includeInExcel":true,"exportColumns":null,"displayName":"Facilities","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['add', {"orderId":4,"sortColumn":"add","searchColumn":"add","includeInExcel":true,"exportColumns":null,"displayName":"Add","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['remove', {"orderId":5,"sortColumn":"remove","searchColumn":"remove","includeInExcel":true,"exportColumns":null,"displayName":"Remove","isVisible":true,"canSearch":false,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): UserOrganizationListVmFromFg {
		return new UserOrganizationListVmFromFg(fg);
	}
}

export class UserOrganizationListVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get organizationId(): number {
		return this.fg.get('organizationId').value;
	}
	set organizationId(val) {
		this.fg.get('organizationId').setValue(val);
	}

	get organizationName(): string {
		return this.fg.get('organizationName').value;
	}
	set organizationName(val) {
		this.fg.get('organizationName').setValue(val);
	}

	get city(): string {
		return this.fg.get('city').value;
	}
	set city(val) {
		this.fg.get('city').setValue(val);
	}

	get county(): string {
		return this.fg.get('county').value;
	}
	set county(val) {
		this.fg.get('county').setValue(val);
	}

	get facilityCount(): number {
		return this.fg.get('facilityCount').value;
	}
	set facilityCount(val) {
		this.fg.get('facilityCount').setValue(val);
	}

	get add(): boolean {
		return this.fg.get('add').value;
	}
	set add(val) {
		this.fg.get('add').setValue(val);
	}

	get remove(): boolean {
		return this.fg.get('remove').value;
	}
	set remove(val) {
		this.fg.get('remove').setValue(val);
	}
}
