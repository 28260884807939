import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { Subscription, filter, fromEvent } from 'rxjs';

@Directive({
    selector: '[pcgClickOutside]'
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy  {

    @Input() elementId = "";
    @Output() clickOutside = new EventEmitter<void>();

    documentClickSubscription: Subscription | undefined;

    constructor(private element: ElementRef, @Inject(DOCUMENT) private document: Document) { }

    ngAfterViewInit(): void {
        this.documentClickSubscription = fromEvent(this.document, 'click')
            .pipe(filter((event) => { 
                const el = (event.target as HTMLElement);

                if (this.elementId !== "") {
                    let children = document.querySelectorAll('#' + this.elementId);
                    let childrenNodeArr = Array.from(children);
                    let elemFound = childrenNodeArr.find(e => (e as HTMLElement) === el);
                    if (!elemFound) { return !this.isInside(el); }
                } else {
                    return !this.isInside(el); 
                }
            })).subscribe(() => { this.clickOutside.emit(); });
    }

    isInside(elementToCheck: HTMLElement): boolean {
        return (elementToCheck === this.element.nativeElement || this.element.nativeElement.contains(elementToCheck));
    }

    ngOnDestroy(): void { this.documentClickSubscription?.unsubscribe(); }
}
