<div 
	*ngIf='showNotification' 
	class="header-update-warning" 
	[class.nav-whitespace]="navSpacerVisible"
>
	<span>HOMER has been updated with new features.</span>&nbsp;
	<a 
		href='#' 
		(click)='hardReload()' 
		class="header-update-button"
	>Click here to reload the page.</a>
</div>

<div *ngIf='scheduledInstall'>
	<pcg-install-timer #installTimer></pcg-install-timer>
</div>