import { NavRoute } from "app/shared/navigation/nav.route.interface";
import { BusinessAreaEnum } from "../enums/generated/BusinessAreaEnum";
import { PermissionRole } from "../enums/generated/PermissionRole";
import { SecurityService } from "../security/security.service";

export const getInvoicesNav: () => NavRoute = () => {
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.
	return {
		name: 'Cardinal Invoices'
		, path: 'central-invoices/central-invoices-list'
		, moduleAccess: [
			{
				modules: [ BusinessAreaEnum.Inventory ]
				, permissionRoles: [ ...sec.setMinRole(p.Technician) ]
			}
		]
	};
};
