import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class RepositoryRegistrationVm {
	honeyPot?: string;
	repositoryId: number;
	name?: string;
	phoneNumber?: string;
	licenseNumber?: string;
	websiteAddress?: string;
	licensingAgencyID?: number;
	licenseOfIssuingAgency?: string;
	address?: string;
	addressId?: number;
	smartyAddress?: any;
	countyId?: number;
	county?: string;
	zip?: string;
	emailAddress?: string;
	pharmacistName?: string;
	pharmacistContactPhone?: string;
	facilityType?: string;
	facilityTypeId: number;
	isAttested: boolean;
	pharmacistSignature?: string;
	dateSigned: Date;
	comments?: string;
	statusId: number;
	deniedReasonId?: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'honeyPot': new UntypedFormControl(null, [...(validators['honeyPot'] ?? []), ...[]]),
			'repositoryId': new UntypedFormControl(0, [...(validators['repositoryId'] ?? []), ...[Validators.required]]),
			'name': new UntypedFormControl(null, [...(validators['name'] ?? []), ...[Validators.required, Validators.maxLength(100)]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[Validators.required, Validators.maxLength(20), Validators.pattern("(\\+\\d{1,3}\\s?)?((\\(\\d{3}\\)\\s?)|(\\d{3})(\\s|-?))(\\d{3}(\\s|-?))(\\d{4})(\\s?(([E|e]xt[:|.|]?)|x|X)(\\s?\\d+))?")]]),
			'licenseNumber': new UntypedFormControl(null, [...(validators['licenseNumber'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'websiteAddress': new UntypedFormControl(null, [...(validators['websiteAddress'] ?? []), ...[Validators.required, Validators.maxLength(100)]]),
			'licensingAgencyID': new UntypedFormControl(null, [...(validators['licensingAgencyID'] ?? []), ...[Validators.required]]),
			'licenseOfIssuingAgency': new UntypedFormControl(null, [...(validators['licenseOfIssuingAgency'] ?? []), ...[Validators.maxLength(100)]]),
			'address': new UntypedFormControl(null, [...(validators['address'] ?? []), ...[Validators.maxLength(200)]]),
			'addressId': new UntypedFormControl(null, [...(validators['addressId'] ?? []), ...[]]),
			'smartyAddress': new UntypedFormControl(null, [...(validators['smartyAddress'] ?? []), ...[Validators.required]]),
			'countyId': new UntypedFormControl(null, [...(validators['countyId'] ?? []), ...[Validators.required]]),
			'county': new UntypedFormControl(null, [...(validators['county'] ?? []), ...[]]),
			'zip': new UntypedFormControl(null, [...(validators['zip'] ?? []), ...[]]),
			'emailAddress': new UntypedFormControl(null, [...(validators['emailAddress'] ?? []), ...[Validators.required, Validators.email, Validators.maxLength(100)]]),
			'pharmacistName': new UntypedFormControl(null, [...(validators['pharmacistName'] ?? []), ...[Validators.required, Validators.maxLength(100)]]),
			'pharmacistContactPhone': new UntypedFormControl(null, [...(validators['pharmacistContactPhone'] ?? []), ...[Validators.required, Validators.maxLength(20), Validators.pattern("(\\+\\d{1,3}\\s?)?((\\(\\d{3}\\)\\s?)|(\\d{3})(\\s|-?))(\\d{3}(\\s|-?))(\\d{4})(\\s?(([E|e]xt[:|.|]?)|x|X)(\\s?\\d+))?")]]),
			'facilityType': new UntypedFormControl(null, [...(validators['facilityType'] ?? []), ...[]]),
			'facilityTypeId': new UntypedFormControl(0, [...(validators['facilityTypeId'] ?? []), ...[Validators.required]]),
			'isAttested': new UntypedFormControl(false, [...(validators['isAttested'] ?? []), ...[]]),
			'pharmacistSignature': new UntypedFormControl(null, [...(validators['pharmacistSignature'] ?? []), ...[Validators.required, Validators.maxLength(100)]]),
			'dateSigned': new UntypedFormControl('', [...(validators['dateSigned'] ?? []), ...[Validators.required]]),
			'comments': new UntypedFormControl(null, [...(validators['comments'] ?? []), ...[Validators.maxLength(250)]]),
			'statusId': new UntypedFormControl(0, [...(validators['statusId'] ?? []), ...[Validators.required]]),
			'deniedReasonId': new UntypedFormControl(null, [...(validators['deniedReasonId'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): RepositoryRegistrationVmFromFg {
		return new RepositoryRegistrationVmFromFg(fg);
	}
}

export class RepositoryRegistrationVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get honeyPot(): string {
		return this.fg.get('honeyPot').value;
	}
	set honeyPot(val) {
		this.fg.get('honeyPot').setValue(val);
	}

	get repositoryId(): number {
		return this.fg.get('repositoryId').value;
	}
	set repositoryId(val) {
		this.fg.get('repositoryId').setValue(val);
	}

	get name(): string {
		return this.fg.get('name').value;
	}
	set name(val) {
		this.fg.get('name').setValue(val);
	}

	get phoneNumber(): string {
		return this.fg.get('phoneNumber').value;
	}
	set phoneNumber(val) {
		this.fg.get('phoneNumber').setValue(val);
	}

	get licenseNumber(): string {
		return this.fg.get('licenseNumber').value;
	}
	set licenseNumber(val) {
		this.fg.get('licenseNumber').setValue(val);
	}

	get websiteAddress(): string {
		return this.fg.get('websiteAddress').value;
	}
	set websiteAddress(val) {
		this.fg.get('websiteAddress').setValue(val);
	}

	get licensingAgencyID(): number {
		return this.fg.get('licensingAgencyID').value;
	}
	set licensingAgencyID(val) {
		this.fg.get('licensingAgencyID').setValue(val);
	}

	get licenseOfIssuingAgency(): string {
		return this.fg.get('licenseOfIssuingAgency').value;
	}
	set licenseOfIssuingAgency(val) {
		this.fg.get('licenseOfIssuingAgency').setValue(val);
	}

	get address(): string {
		return this.fg.get('address').value;
	}
	set address(val) {
		this.fg.get('address').setValue(val);
	}

	get addressId(): number {
		return this.fg.get('addressId').value;
	}
	set addressId(val) {
		this.fg.get('addressId').setValue(val);
	}

	get smartyAddress(): any {
		return this.fg.get('smartyAddress').value;
	}
	set smartyAddress(val) {
		this.fg.get('smartyAddress').setValue(val);
	}

	get countyId(): number {
		return this.fg.get('countyId').value;
	}
	set countyId(val) {
		this.fg.get('countyId').setValue(val);
	}

	get county(): string {
		return this.fg.get('county').value;
	}
	set county(val) {
		this.fg.get('county').setValue(val);
	}

	get zip(): string {
		return this.fg.get('zip').value;
	}
	set zip(val) {
		this.fg.get('zip').setValue(val);
	}

	get emailAddress(): string {
		return this.fg.get('emailAddress').value;
	}
	set emailAddress(val) {
		this.fg.get('emailAddress').setValue(val);
	}

	get pharmacistName(): string {
		return this.fg.get('pharmacistName').value;
	}
	set pharmacistName(val) {
		this.fg.get('pharmacistName').setValue(val);
	}

	get pharmacistContactPhone(): string {
		return this.fg.get('pharmacistContactPhone').value;
	}
	set pharmacistContactPhone(val) {
		this.fg.get('pharmacistContactPhone').setValue(val);
	}

	get facilityType(): string {
		return this.fg.get('facilityType').value;
	}
	set facilityType(val) {
		this.fg.get('facilityType').setValue(val);
	}

	get facilityTypeId(): number {
		return this.fg.get('facilityTypeId').value;
	}
	set facilityTypeId(val) {
		this.fg.get('facilityTypeId').setValue(val);
	}

	get isAttested(): boolean {
		return this.fg.get('isAttested').value;
	}
	set isAttested(val) {
		this.fg.get('isAttested').setValue(val);
	}

	get pharmacistSignature(): string {
		return this.fg.get('pharmacistSignature').value;
	}
	set pharmacistSignature(val) {
		this.fg.get('pharmacistSignature').setValue(val);
	}

	get dateSigned(): Date {
		return this.fg.get('dateSigned').value;
	}
	set dateSigned(val) {
		this.fg.get('dateSigned').setValue(val);
	}

	get comments(): string {
		return this.fg.get('comments').value;
	}
	set comments(val) {
		this.fg.get('comments').setValue(val);
	}

	get statusId(): number {
		return this.fg.get('statusId').value;
	}
	set statusId(val) {
		this.fg.get('statusId').setValue(val);
	}

	get deniedReasonId(): number {
		return this.fg.get('deniedReasonId').value;
	}
	set deniedReasonId(val) {
		this.fg.get('deniedReasonId').setValue(val);
	}
}
