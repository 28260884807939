import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class PfsHelpTicketVm {
	fullName?: string;
	email?: string;
	phoneNumber?: string;
	facilityId: number;
	facilityName?: string;
	categoryId: number;
	categoryName?: string;
	priorityId: number;
	priorityName?: string;
	subject?: string;
	description?: string;
	honeyPot?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'fullName': new UntypedFormControl(null, [...(validators['fullName'] ?? []), ...[Validators.required, Validators.maxLength(250), Validators.pattern("^[a-zA-Z\\s\\'\\-\\']*$")]]),
			'email': new UntypedFormControl(null, [...(validators['email'] ?? []), ...[Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]]),
			'phoneNumber': new UntypedFormControl(null, [...(validators['phoneNumber'] ?? []), ...[Validators.required]]),
			'facilityId': new UntypedFormControl(0, [...(validators['facilityId'] ?? []), ...[Validators.required]]),
			'facilityName': new UntypedFormControl(null, [...(validators['facilityName'] ?? []), ...[]]),
			'categoryId': new UntypedFormControl(0, [...(validators['categoryId'] ?? []), ...[Validators.required]]),
			'categoryName': new UntypedFormControl(null, [...(validators['categoryName'] ?? []), ...[]]),
			'priorityId': new UntypedFormControl(0, [...(validators['priorityId'] ?? []), ...[Validators.required]]),
			'priorityName': new UntypedFormControl(null, [...(validators['priorityName'] ?? []), ...[]]),
			'subject': new UntypedFormControl(null, [...(validators['subject'] ?? []), ...[Validators.required, Validators.maxLength(200), Validators.pattern("^[a-zA-Z0-9\\s\\'\\-\\?\\.\\']*$")]]),
			'description': new UntypedFormControl(null, [...(validators['description'] ?? []), ...[Validators.required, Validators.maxLength(500), Validators.pattern("^[a-zA-Z0-9\\s\\'\\-\\?\\.\\']*$")]]),
			'honeyPot': new UntypedFormControl(null, [...(validators['honeyPot'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): PfsHelpTicketVmFromFg {
		return new PfsHelpTicketVmFromFg(fg);
	}
}

export class PfsHelpTicketVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get fullName(): string {
		return this.fg.get('fullName').value;
	}
	set fullName(val) {
		this.fg.get('fullName').setValue(val);
	}

	get email(): string {
		return this.fg.get('email').value;
	}
	set email(val) {
		this.fg.get('email').setValue(val);
	}

	get phoneNumber(): string {
		return this.fg.get('phoneNumber').value;
	}
	set phoneNumber(val) {
		this.fg.get('phoneNumber').setValue(val);
	}

	get facilityId(): number {
		return this.fg.get('facilityId').value;
	}
	set facilityId(val) {
		this.fg.get('facilityId').setValue(val);
	}

	get facilityName(): string {
		return this.fg.get('facilityName').value;
	}
	set facilityName(val) {
		this.fg.get('facilityName').setValue(val);
	}

	get categoryId(): number {
		return this.fg.get('categoryId').value;
	}
	set categoryId(val) {
		this.fg.get('categoryId').setValue(val);
	}

	get categoryName(): string {
		return this.fg.get('categoryName').value;
	}
	set categoryName(val) {
		this.fg.get('categoryName').setValue(val);
	}

	get priorityId(): number {
		return this.fg.get('priorityId').value;
	}
	set priorityId(val) {
		this.fg.get('priorityId').setValue(val);
	}

	get priorityName(): string {
		return this.fg.get('priorityName').value;
	}
	set priorityName(val) {
		this.fg.get('priorityName').setValue(val);
	}

	get subject(): string {
		return this.fg.get('subject').value;
	}
	set subject(val) {
		this.fg.get('subject').setValue(val);
	}

	get description(): string {
		return this.fg.get('description').value;
	}
	set description(val) {
		this.fg.get('description').setValue(val);
	}

	get honeyPot(): string {
		return this.fg.get('honeyPot').value;
	}
	set honeyPot(val) {
		this.fg.get('honeyPot').setValue(val);
	}
}
