import { Directive, ElementRef, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[pcgNumeric]',
})
export class NumericDirective {
	@Input() allowDecimals: boolean = false;
	@Input() decimalPrecision: number = 2;

	constructor(private elRef: ElementRef, @Optional() private control: NgControl) { }

	@HostListener('keyup', ['$event'])
	keyEvent() {
		if (this.control) { this.control?.control.setValue(this.elRef.nativeElement.value); }
	}

	@HostListener('change', ['$event'])
	changeEvent() {
		if (this.control) { this.control?.control.setValue(this.elRef.nativeElement.value); }
		this.formatNumber();
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: any) {
		if (!this.isAllowedCharacter(e) || this.decimalCheck(e)) {
			return e.preventDefault();
		}
	}

	isAllowedCharacter(e: any): boolean {
		return (e.keyCode > 36 && e.keyCode < 41)	// Arrow keys
			|| !(/\D/g.test(e.key))	// Is a number
			|| e.keyCode === 8		// Backspace
			|| e.keyCode === 9		// Tab
			|| e.keyCode === 13		// Enter
			|| e.keyCode === 16		// Shift
			|| e.keyCode === 17		// Ctrl	
			|| e.keyCode === 46		// Delete
			|| this.isDecimal(e)	// .
			|| ((e.ctrlKey || e.metaKey) && e.key === 'a')
			// Allows ability to copy
			|| ((e.ctrlKey || e.metaKey) && e.key === 'c')
			// Allows ability to paste
			|| ((e.ctrlKey || e.metaKey) && e.key === 'v')
			// Allows ability to press enter
			|| (e.key === 'Enter');
	}

	decimalCheck(e: any): boolean {
		let regex = '[^0-9.]'
		// Check that decimals are enabled on the input and that no more than 2 '.' are entered.
		return this.allowDecimals && /(.\.)+.*/.test(this.elRef.nativeElement.value) && this.isDecimal(e);
	}

	// The key code equals .>(Main Keys) or .(NUM pad)
	isDecimal = (e: any): boolean => this.allowDecimals && (e.keyCode === 110 || e.keyCode === 190);

	formatNumber() {
		if (this.allowDecimals) {
			let precisionLength = parseInt('1'.padEnd(this.decimalPrecision, '0') + '0');
			// Remove all non-numeric values and dupe '.', and convert to a float.
			let num = parseFloat(this.elRef.nativeElement.value.replace(/[^0-9.]/g, '').replace(/\.{2,}/g, '.'));
			this.elRef.nativeElement.value = Number.isNaN(num) ? null : Math.floor(num * precisionLength) / precisionLength;
		} else {
			this.elRef.nativeElement.value = this.elRef.nativeElement.value.replace(/\D/g, '')
		}
		this.control?.control.setValue(this.elRef.nativeElement.value);
	}
}
