import { Component, Input, OnInit } from '@angular/core';
import { faEye } from '@fortawesome/pro-solid-svg-icons';

import { PcgTableColumn } from '../../interfaces/pcg-table-column.interface';

@Component({
	selector: 'pcg-button-hide-show',
	templateUrl: './button-hide-show.component.html',
	styleUrls: ['./button-hide-show.component.scss']
})
export class ButtonHideShowComponent implements OnInit {

	@Input() columnDefs: Map<string, PcgTableColumn>;

	colVisActive = false;

	faIconName = { faEye };

	getColDefs = () => Array.from(this.columnDefs.keys());

	constructor() { }

	ngOnInit() { this.columnDefs.forEach((value, key) => { if (value.isVisible !== false) { value.isVisible = true; } }); }
}
