/**
 * DateRange Type represents start date and end date.
 */
 export class DateRange {
	dateBegin?: Date;
	dateEnd?: Date;
	/**
	 * Examples:
   	```typescript
  	const d1 = new DateRange();
  	const d2 = new DateRange(new Date(), new Date(2018, 9, 10));
   	```
	 * @param dateBegin [Optional] Start Date. Default: null.
	 * @param dateEnd [Optional] End Date. Default: null
	 */
	constructor(dateBegin: Date = null, dateEnd: Date = null) {
		this.dateBegin = dateBegin;
		this.dateEnd = dateEnd;
	}

	/**
	 * Examples:
   	```typescript
  	const d1 = DateRange.nextDays(7);
  	// a date range of next week since today
   	```
	 * @param n Number of days after today.
	 */
	static nextDays(n: number): DateRange {
		const start = new Date();
		const end = new Date();
		end.setDate(end.getDate() + n);
		return new DateRange(start, end);
	}

	/**
	 * Examples:
   	```typescript
  	const d1 = DateRange.nextTwoWeeks();
  	// a date range of next two weeks since today
   	```
	 */
	static nextTwoWeeks(): DateRange {
		return DateRange.nextDays(14);
	}

	/**
	 * Examples:
   	```typescript
  	const d1 = DateRange.nextMonth();
  	// a date range of next month since today
   	```
	 */
	static nextMonth(): DateRange {
		const start = new Date();
		const end = new Date();
		end.setMonth(end.getMonth() + 1);
		return new DateRange(start, end);
	}

	/**
   * Examples:
	 ```typescript
	const d1 = DateRange.lastMonth();
	// a date range of last month till today
	 ```
   */
	static lastMonth(): DateRange {
		const start = new Date();
		const end = new Date();
		start.setMonth(start.getMonth() - 1);
		return new DateRange(start, end);
	}

	/**
	 * Examples:
	```typescript
	const d1 = DateRange.create({});
	```
	 * @param start start date of range you're creating
	 * @param end end date of range you're creating
	 */
	static create(start: any, end: any): DateRange {
		let startDate = null;
		let endDate = null;
		if (DateRange.isValidDate(start)) {
			startDate = new Date(start);
		}
		if (DateRange.isValidDate(end)) {
			endDate = new Date(end);
		}
		return new DateRange(startDate, endDate);
	}

	/**
	 * Examples:
	```typescript
	const isValid = DateRange.isValidDate(new Date());
	```
	 * @param value date you want to verify as date
	 */
	static isValidDate(value: any): boolean {
		if (!value) {
			return false;
		}

		switch (typeof value) {
			case 'number':
				return true;
			case 'string':
				return !isNaN(Date.parse(value));
			case 'object':
				if (value instanceof Date) {
					return !isNaN(value.getTime());
				}
				break;
			default:
				return false;
		}
	}

	static getFiscalYearDateRange(): DateRange {
		let today = new Date();
		let startYear = today.getFullYear();
		let endYear = today.getFullYear();
		// Months are zero-indexed: January = 0, Feb...
		if (today.getMonth() >= 0 && today.getMonth() <= 5) { startYear--; }
		else { endYear++; }

		// Months start at 0 index, hence 6 => July and 5 => June
		return new DateRange(new Date(startYear, 6, 1), new Date(endYear, 5, 30));
	}
}
