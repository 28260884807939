import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'utcToLocal'
})
export class UtcToLocalPipe implements PipeTransform {
    transform(utcTimeString: string): Date {
        if (!utcTimeString) { return null }
        const utcDate = new Date(utcTimeString);
        const millisecondsPerMinute = 60000;
        const minutes = utcDate.getTimezoneOffset()
        // Local DT is determined by the UTC date minus the offset by timezone.
        const localDateTime = new Date(utcDate.getTime() - (minutes * millisecondsPerMinute))     
        return localDateTime;
    }
}
