<label class="for">
    <span>Fingerprint(s)</span>
    <fa-icon 
        *ngIf="hasFingerprint" 
        [icon]="faIconName.faCheck" 
        class="fa-sm"
    ></fa-icon>
    <small *ngIf="hasFingerprint" class="ml-2">Registered: {{ fingerprintsRegistered }}</small>
</label>

<div class="fingerprint-ctnr">
    <button 
        class="fingerprint-btn" 
        (click)="captureFingerprint()"
    >
        <fa-icon 
            [icon]="faIconName.faFingerprint" 
            class="fa-lg"
        ></fa-icon>
        {{ hasFingerprint ? 'Add' : 'Capture' }} Fingerprint
    </button>

    <div 
        *ngIf="hasFingerprint" 
        class="btn btn-cancel btn-sm ml-3" 
        (click)="removeFingerprint()"
    >Clear</div>
</div>

<div 
    *ngIf="isError" 
    class="alert alert-danger text-center"
>{{ errorString }}</div>