import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { TableComponent } from 'app/shared/table/table.component';
import { ReleaseNotesVm } from 'app/shared/generated/Models/ReleaseNotesVm';
import { NavigationService } from 'app/shared/navigation/navigation.service';

@Component({
	selector: 'pcg-web-release-notes',
	templateUrl: './web-release-notes.component.html',
	styleUrls: ['./web-release-notes.component.scss']
})
export class WebReleaseNotesComponent implements OnInit {

	@ViewChild(TableComponent, { static: true }) table: TableComponent<ReleaseNotesVm>;
	columns = ReleaseNotesVm.ColumnDefs;

	constructor(public title: Title, public nav: NavigationService) { }

	ngOnInit() { 
		this.title.setTitle('Web Release Notes'); 
		this.nav.setOpenSecondaryMenu(["Support", "Release Notes"]);
	}
}
