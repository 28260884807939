import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { faArrowRightArrowLeft, faCircle, faExternalLinkAlt, faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationAccountRequestVm } from 'app/shared/generated/Administration/Models/Organizations/OrganizationAccountRequestVm';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'pcg-organization-account-update-nav',
  templateUrl: './organization-account-update-nav.component.html',
  styleUrls: ['./organization-account-update-nav.component.scss']
})
export class OrganizationAccountUpdateNavComponent implements OnInit {
  /** FontAwesome Icons used to style component */ 
  faCircle = faCircle;
	faArrowRightArrowLeft = faArrowRightArrowLeft;
  faLink = faExternalLinkAlt;
  faArrow = faSortDown;

  requests$: Observable<OrganizationAccountRequestVm[]>;
  organizationAccountRequestId: number; 

  subscription = new Subscription();

  selectedRequestUpdate: boolean = false;

  constructor(public modal: NgbActiveModal, private http: HttpClient) {}

  ngOnInit(): void {
      this.getFacilityUpdateRequests();
  }

  getFacilityUpdateRequests() {
    this.requests$ = this.http.get<OrganizationAccountRequestVm[]>('api/Administration/OrganizationAccountRequest/GetNotificationList');
  }

  openRequest(id: number) {
    // Sets id to then be passed into facility-account-update component
    this.organizationAccountRequestId = id;
    this.toggleModal()
    this.modal.update({size: 'xl'});
  }

  toggleModal = () =>  this.selectedRequestUpdate = !this.selectedRequestUpdate 
}
