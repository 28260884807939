<div 
    #filter 
    class="pcg-table-filter" 
    [class.d-none]="!isVisible"
>
    <div 
        #filterBody 
        class="pcg-table-filter-body ml-0 mt-0" 
        (click)="positionFilter()"
    >
        <!--  Prevent chrome autofill -->
        <input 
            class="d-none" 
            type="password" 
            name="password" 
            value="" 
            autocomplete="new-password" 
        />
        <ng-content></ng-content>
    </div>
    <div class="pcg-table-filter-overlay"></div>
</div>