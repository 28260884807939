import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class PushNotificationService {

	constructor() { }

	public static checkForNavSpace() {
		let spacer = document.getElementById("nav-bottom-whitespace");
		if (spacer != null) { spacer.style.display = 'none'; }
		return spacer != null;
	}

	public static checkForSystemUpdate(): Date {
		let updateTimer = localStorage.getItem('update-timer');

		if (
			!updateTimer 
			|| new Date(JSON.parse(updateTimer)) < new Date()
		) {
			localStorage.removeItem('update-timer');
			return null;
		}

		return new Date(JSON.parse(updateTimer));
	}
}
