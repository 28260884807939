import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class OrderOptionVm {
	applicantOrderId: number;
	orderId: number;
	optionName?: string;
	quantity?: string;
	dosage?: string;
	dosesRequested?: number;
	estimatedCostPerUnit?: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'applicantOrderId': new UntypedFormControl(0, [...(validators['applicantOrderId'] ?? []), ...[Validators.required]]),
			'orderId': new UntypedFormControl(0, [...(validators['orderId'] ?? []), ...[Validators.required]]),
			'optionName': new UntypedFormControl(null, [...(validators['optionName'] ?? []), ...[]]),
			'quantity': new UntypedFormControl(null, [...(validators['quantity'] ?? []), ...[]]),
			'dosage': new UntypedFormControl(null, [...(validators['dosage'] ?? []), ...[]]),
			'dosesRequested': new UntypedFormControl(null, [...(validators['dosesRequested'] ?? []), ...[Validators.required, Validators.min(0), Validators.max(999999)]]),
			'estimatedCostPerUnit': new UntypedFormControl(null, [...(validators['estimatedCostPerUnit'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): OrderOptionVmFromFg {
		return new OrderOptionVmFromFg(fg);
	}
}

export class OrderOptionVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get applicantOrderId(): number {
		return this.fg.get('applicantOrderId').value;
	}
	set applicantOrderId(val) {
		this.fg.get('applicantOrderId').setValue(val);
	}

	get orderId(): number {
		return this.fg.get('orderId').value;
	}
	set orderId(val) {
		this.fg.get('orderId').setValue(val);
	}

	get optionName(): string {
		return this.fg.get('optionName').value;
	}
	set optionName(val) {
		this.fg.get('optionName').setValue(val);
	}

	get quantity(): string {
		return this.fg.get('quantity').value;
	}
	set quantity(val) {
		this.fg.get('quantity').setValue(val);
	}

	get dosage(): string {
		return this.fg.get('dosage').value;
	}
	set dosage(val) {
		this.fg.get('dosage').setValue(val);
	}

	get dosesRequested(): number {
		return this.fg.get('dosesRequested').value;
	}
	set dosesRequested(val) {
		this.fg.get('dosesRequested').setValue(val);
	}

	get estimatedCostPerUnit(): number {
		return this.fg.get('estimatedCostPerUnit').value;
	}
	set estimatedCostPerUnit(val) {
		this.fg.get('estimatedCostPerUnit').setValue(val);
	}
}
