import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class RxConnectsUserSecurityVm {
	repositoryId: number;
	donorId: number;
	userId: number;
	securityEnum?: any;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'repositoryId': new UntypedFormControl(0, [...(validators['repositoryId'] ?? []), ...[Validators.required]]),
			'donorId': new UntypedFormControl(0, [...(validators['donorId'] ?? []), ...[Validators.required]]),
			'userId': new UntypedFormControl(0, [...(validators['userId'] ?? []), ...[Validators.required]]),
			'securityEnum': new UntypedFormControl('', [...(validators['securityEnum'] ?? []), ...[Validators.required]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): RxConnectsUserSecurityVmFromFg {
		return new RxConnectsUserSecurityVmFromFg(fg);
	}
}

export class RxConnectsUserSecurityVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get repositoryId(): number {
		return this.fg.get('repositoryId').value;
	}
	set repositoryId(val) {
		this.fg.get('repositoryId').setValue(val);
	}

	get donorId(): number {
		return this.fg.get('donorId').value;
	}
	set donorId(val) {
		this.fg.get('donorId').setValue(val);
	}

	get userId(): number {
		return this.fg.get('userId').value;
	}
	set userId(val) {
		this.fg.get('userId').setValue(val);
	}

	get securityEnum(): any {
		return this.fg.get('securityEnum').value;
	}
	set securityEnum(val) {
		this.fg.get('securityEnum').setValue(val);
	}
}
