<div class="portrait">
    <div class="banner d-flex justify-content-between align-items-center mb-2">
        <div class="logo">
            <img class="homer-logo" alt="HOMER Logo" src="assets/images/logos/logo_small.png"/>
        </div>
        <div class="text-center">
            <h4>Shortage/Overage Report</h4>
            <h5>{{ orderNumber }}</h5>
        </div>
        <div class="logo d-flex justify-content-end">
            <img class="fl-health-logo" alt="FL Health Logo" src="assets/images/logos/DOH_logo.png"/>
        </div>
    </div>
    <div *ngIf="emptyShortOverList" class="text-center">
        <p><b>The are no shortages or overages on this order.</b></p>
    </div>

    <div class="d-flex justify-content-center"  *ngIf="!emptyShortOverList">
        <div class="regTable">
            <table>
                <thead>
                    <tr>
                        <th class="text-center">Invoice #</th>
                        <th class="text-center">Product NDC</th>
                        <th class="text-center">Bottles Ordered</th>
                        <th class="text-center">Bottles Invoiced</th>
                        <th class="text-center">Size</th>
                        <th class="text-center">Product Invoiced</th>
                        <th class="text-center">Count Per Card</th>
                        <th class="text-center">Cards Ordered</th>
                        <th class="text-center">Product Remaining</th>
                        <th class="text-center">Bottles Received</th>
                        <th class="text-center">Product Repacked</th>
                        <th class="text-center">Repacked Cards</th>
                        <th class="text-center">Product Returned</th>
                        <th class="text-center">Product Difference</th>
                        <!-- commenting out of now, uncomment if needed for DSCSA compliance-->
                        <!-- <th class="text-center">Extra Bottles</th> -->
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let row of shortOverList; let i = index;">
                        <tr>
                            <td class="text-center p-2">{{ row.invoiceNumber }}</td>
                            <td class="text-center p-2 product-ndc">{{ row.productName}}<br />{{ row.ndc}}</td>
                            <td class="text-center">{{ row.bottlesOrdered }}</td>
                            <td class="text-center">{{ row.bottlesInvoiced }}</td>
                            <td class="text-center">{{ row.size }}</td>
                            <td class="text-center">{{ row.productInvoiced | number }}</td>
                            <td class="text-center">{{ row.countPerCard | number }}</td>
                            <td class="text-center">{{ row.cardsOrdered | number }}</td>
                            <td class="text-center">{{ row.productRemaining | number }}</td>
                            <td class="text-center">{{ row.bottlesReceived | number }}</td>
                            <td class="text-center">{{ row.productRepacked | number }}</td>
                            <td class="text-center">{{ row.repackedCards | number }}</td>
                            <td class="text-center">{{ row.productReturned | number }}</td>
                            <td class="text-center">{{ row.productDifference | number }}</td>
                            <!-- <td class="text-center">{{ row.extraBottles | number }}</td> -->
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>

</div>