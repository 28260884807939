<h3>Associated HEROS Agencies</h3>

<pcg-table
    #table
    class="card-table"
    [pcgSort]="[['facilityName', 'asc']]"
    dataSource="api/Administration/Users/GetAssociatedFacilitiesAndAgencies"
    [ajaxData]="filters"
    [canResetFilters]="false"
    [canShowHideColumns]="false"
    [columnDefs]="columns"
    [pageSize]="10"
    [isRowClickable]="canEditFacilities"
    [canExactMatchSearch]="false" 
    (tableReceive)="tblReceive($event)"
    (clickRow)="removeFac($event)"
>

    <ng-container matColumnDef="facilityName">
        <th 
            mat-header-cell 
            pcg-sort-header 
            *matHeaderCellDef
        >{{ columns.get('facilityName').displayName }}</th>
        <td 
            mat-cell 
            *matCellDef="let row" 
            [attr.data-label]="columns.get('facilityName').displayName"
        >{{ row.facilityName }}</td>
    </ng-container>

    <ng-container matColumnDef="facilityIdentifier">
        <th 
            mat-header-cell 
            pcg-sort-header 
            *matHeaderCellDef
        >{{ columns.get('facilityIdentifier').displayName }}</th>
        <td 
            mat-cell 
            *matCellDef="let row" 
            [attr.data-label]="columns.get('facilityIdentifier').displayName"
        >{{ row.facilityIdentifier }}</td>
    </ng-container>

    <ng-container matColumnDef="countyName">
        <th 
            mat-header-cell 
            pcg-sort-header 
            *matHeaderCellDef
        >{{ columns.get('countyName').displayName }}</th>
        <td 
            mat-cell 
            *matCellDef="let row" 
            [attr.data-label]="columns.get('countyName').displayName"
        >{{ row.countyName }}</td>
    </ng-container>

    <ng-container matColumnDef="facilityType">
        <th 
            mat-header-cell 
            pcg-sort-header 
            *matHeaderCellDef
        >{{ columns.get('facilityType').displayName }}</th>
        <td 
            mat-cell 
            *matCellDef="let row" 
            [attr.data-label]="columns.get('facilityType').displayName"
        >{{ row.facilityType }}</td>
    </ng-container>

    <ng-container matColumnDef="canDelete">
        <th 
            mat-header-cell 
            *matHeaderCellDef 
            class="text-center"
        >
            <button 
                *ngIf="hasData" 
                type="button"
                class="btn btn-sm btn-danger text-nowrap"
                (click)="
                    $event.stopPropagation(); 
                    removeAll();
                "
            >
                <fa-icon [icon]="faIcon.faXmark"></fa-icon> All</button>
        </th>
        <td
            class="text-center"
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="columns.get('canDelete').displayName"
        >
            <fa-icon 
                class="xmark"
                [icon]="faIcon.faXmark"
            >
            </fa-icon>
        </td>
    </ng-container>
</pcg-table>