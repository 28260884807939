import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';
import { VerificationVm, VerificationVmFromFg } from '../../Administration/Models/VerificationVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class VerificationSettingVm {
	businessArea?: any;
	amountOfChoices?: any;
	verificationChoices?: any;
	verificationSettings?: VerificationVm[];

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'businessArea': new UntypedFormControl('', [...(validators['businessArea'] ?? []), ...[Validators.required]]),
			'amountOfChoices': new UntypedFormControl(null, [...(validators['amountOfChoices'] ?? []), ...[]]),
			'verificationChoices': new UntypedFormControl(null, [...(validators['verificationChoices'] ?? []), ...[]]),
			'verificationSettings': new UntypedFormArray([])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): VerificationSettingVmFromFg {
		return new VerificationSettingVmFromFg(fg);
	}
}

export class VerificationSettingVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get businessArea(): any {
		return this.fg.get('businessArea').value;
	}
	set businessArea(val) {
		this.fg.get('businessArea').setValue(val);
	}

	get amountOfChoices(): any {
		return this.fg.get('amountOfChoices').value;
	}
	set amountOfChoices(val) {
		this.fg.get('amountOfChoices').setValue(val);
	}

	get verificationChoices(): any {
		return this.fg.get('verificationChoices').value;
	}
	set verificationChoices(val) {
		this.fg.get('verificationChoices').setValue(val);
	}
}
