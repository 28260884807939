import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";

import { SystemMessage } from "app/core/system-message/system-message-service";

@Injectable({
    providedIn: 'root'
})
export class UserOrganizationService {

    constructor(private http: HttpClient) {}

    private save = (body : UserOrganizationRequestBody) => 
        this.http.post<SystemMessage>('api/Administration/UserOrganization/SaveUserOrganizations', body)

    updateOrganization(
        _userId : number
        , _orgId : number
    ) : Observable<SystemMessage> {
        const body = new UserOrganizationRequestBody(_userId, _orgId, false, false); 
        return this.save(body)
    }

    associateAllOrganizations(
        _userId : number
        , _tableInput : string
    ) : Observable<SystemMessage> {        
        const body = new UserOrganizationRequestBody(_userId, null, true, false, _tableInput)
        return this.save(body);
    }

    unassociateAllOrganizations(
        _userId : number
        , _tableInput : string
    ) : Observable<SystemMessage> {
        const body = new UserOrganizationRequestBody(_userId, null, false, true, _tableInput);
        return this.save(body);
    }
}

class UserOrganizationRequestBody {
    id : number;
    orgId? : number;
    associateAll: boolean;
    removeAll: boolean;
    tableInput : string;

    constructor(
        _id : number
        , _orgId : number
        , _associateAll : boolean
        , _removeAll : boolean
        , _tableInput? : string
    ) {
        this.id = _id;
        this.orgId = _orgId;
        this.associateAll = _associateAll;
        this.removeAll = _removeAll;
        this.tableInput = _tableInput;
    }
}