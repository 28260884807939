<div class="container border border-dark">
	<div class="row ">
		<div class="col-md-2 text-left pl-0">
			<img src="\assets\images\logos\dohLogo.jpg" class="img-logo">
		</div>
		<div class="col-md-8">
			<h1 class="d-flex flex-column justify-content-center align-items-center header">
				PRESCRIPTION DRUG DONATION PROGRAM
			</h1>
			<h4 class="d-flex flex-column justify-content-center align-items-center header">
				DONOR PARTICIPATION FORM
			</h4>
		</div>
	</div>
</div>
<div class="container border border-dark">
	<div class="row">
		<div class="col edit-body">
			APPLICATION – DONOR FACILITY
		</div>
		<div class='userMessage'>
			Thank you for your willingness to donate to the Prescription Drug Donation Repository (Rx Connects) Program. Please be aware the Rx Connects Program is governed by Section 465.1902, Florida Statutes which will only allow us to accept donations from healthcare organizations and <b>not from individual citizens</b>. If you are not filling out this form on behalf of your healthcare organization please contact 850-841-8530 and one of our staff members will be able to assist you further.
			Questions about completion of this form may be directed to the Bureau of Public Health Pharmacy at (850) 841-8530.

		</div>
	</div>
	<form [formGroup]='formGroup' (submit)='saveDonor()'>
		<div class="container clearfix">

		</div>
		<div class="container clearfix">
			<div class='row'>
				<pcg-control-group class="col-sm-8" label="Donor Name">
					<input type="text" pcgControlRef class="form-control" formControlName="name" />
				</pcg-control-group>

				<pcg-control-group class='col-md-4' label='Type of Facility'>
					<pcg-select pcgControlRef [multiple]='false'
								dataSource='api/PublicDonorRegistration/RxConnectsDonorFacilityType'
								formControlName='facilityTypeId' placeholder='Select a Facility'>
					</pcg-select>
				</pcg-control-group>
			</div>

			<div class='row'>
				<pcg-control-group class="col-sm-8" label="Email">
					<input type="text" pcgControlRef class="form-control" formControlName="emailAddress" />
				</pcg-control-group>

				<pcg-control-group class="col-sm-4" label="Donor Telephone Number" [validations]="phoneValidation">
					<input pcgPhoneExt type="text" pcgControlRef class="form-control" formControlName="phoneNumber" />
				</pcg-control-group>

			</div>

			<div class='row'>
				<pcg-control-group class="col-sm-8" label="Address">
					<pcg-smarty pcgControlRef formControlName="smartyAddress" [showAllStates]="true"
								[canManualEntry]="canManualEntry" [getFullZip]="true" (change)='updateCounty()'>
					</pcg-smarty>
				</pcg-control-group>

				<pcg-control-group class="col-sm-4" label="County">
					<pcg-select pcgControlRef [multiple]='false' dataSource='api/PublicSelect/Counties'
								formControlName="countyId" placeholder='Select a County' [sendFormData]='false'>
					</pcg-select>
				</pcg-control-group>
			</div>

			<div class='row'>
				<pcg-control-group class="col-sm-8" label="Contact Name">
					<input type="text" pcgControlRef class="form-control" formControlName="contactName" />
				</pcg-control-group>

				<pcg-control-group class="col-sm-4" label="Contact Telephone Number" [validations]="phoneValidation">
					<input pcgPhoneExt type="text" pcgControlRef class="form-control" formControlName="contactPhone" />
				</pcg-control-group>
			</div>

			<div class="row">
				<pcg-control-group class="col-md-12 " label='Attestation'>
					<input class="form-check-input" pcgControlRef id="isAttested" formControlName='isAttested'
						   type="checkbox" />
					<label class='form-check-label' style="font-weight:bold;" for='isAttested'>
						I attest that the above-named facility is licensed in the State of Florida and complies with all
						applicable state and
						federal laws and administrative rules, including the requirements of section 465.1902, Florida
						Statutes.
					</label>
				</pcg-control-group>
			</div>

			<div class='row'>
				<pcg-control-group class="col-md-6" label=" Electronic Signature">
					<input type="text" pcgControlRef class="form-control" formControlName="signature" />
				</pcg-control-group>

				<pcg-control-group class="col-md-6" label="Date Signed">
					<input type="text" pcgControlRef class="form-control" readonly="readonly"
						   formControlName="dateSigned" />
				</pcg-control-group>
			</div>

			<div class="row alert alert-success" role="alert" *ngIf='this.disableSave'>
				<b>Thank you for submitting your application to become a donor in the Rx Connects Program. We are processing your application and will notify your facility via email provided upon completion.</b>
			</div>
		</div>

		<pcg-fixed-buttons *ngIf='!this.disableSave'>
			<a class='btn btn-cancel btn-lg' routerLink=''>Cancel</a>
			<button type='submit' class="btn btn-save btn-lg" [disabled]="emptyCaptcha">Submit</button>
		</pcg-fixed-buttons>

		<pcg-control-group class='form-inline' hidden>
			<input id="control-pot" type="checkbox" pcgControlRef formControlName='honeyPot' />
		</pcg-control-group>

		<ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (ready)="handleReady()"
								 (load)="handleLoad()" (success)="handleSuccess($event)" [useGlobalDomain]="false" theme="light"
								 type="normal" badge="bottomleft" formControlName="recaptcha">
		</ngx-invisible-recaptcha>
	</form>
</div>
<meta-pixel />
