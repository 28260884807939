<mat-expansion-panel [expanded]="visible" class='mb-3' hideToggle>
	<mat-expansion-panel-header (click)='visible = !visible'>
		<mat-panel-title *ngIf='headerIsString'>{{ header }}</mat-panel-title>
		<mat-panel-title *ngIf='!headerIsString'>
			<ng-container *ngTemplateOutlet="header"></ng-container>
		</mat-panel-title>
		<mat-panel-description class='mr-0'>
			<div class='d-flex flex-row w-100'>
				<!-- Content passed through with the description decoration will be placed here. This is intended to be used for section descriptions. -->
				<ng-content select="[description]"></ng-content>
				<div class='ml-auto'>
					<fa-icon *ngIf='visible' [icon]="faMinus"></fa-icon>
					<fa-icon *ngIf='!visible' [icon]="faPlus"></fa-icon>
				</div>
			</div>
		</mat-panel-description>
	</mat-expansion-panel-header>
	<ng-template matExpansionPanelContent *ngIf="visible">
		<br />
		<ng-content matExpansionPanelContent></ng-content>
	</ng-template>
</mat-expansion-panel>