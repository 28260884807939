import { combineLatest } from 'rxjs';

import { TableComponent } from './table.component';

export class TableNoServerSide<T> {

	constructor(private table: TableComponent<T>) { }

	/** This applies client-side filtering, sorting, and paging to our table */
	addNoServersideEvents() {
		if (typeof this.table.dataSource === 'string') {
			this.table.dataNoServerSideUpdateSource.next(this.table.data);
			this.table.dataSource$ = this.table.dataNoServerSideUpdateSource.asObservable();
		}

		// Apply filters when the data source or filter is updated
		combineLatest(
			[
				this.table.dataSource$
				, this.table.filterSource
				, this.table.exactMatchSource
				, this.table.perColumnSearchSource
				, this.table.pcgSort.sortChange
			]
		).subscribe(([newData]) => {
			this.table.data = newData;
			this.table.totalDataCount = newData.length;
			this.applyFilters(); // both applies searching and sorting
		});

		// Update page data on filtered data, current page, or page size change
		combineLatest(
			[
				this.table.filteredData$
				, this.table.currentPageSource
				, this.table.pageSizeSource
			]
		).subscribe(([filteredData, currentPage, pageSize]) => {
			const startingIndex = (currentPage - 1) * pageSize;
			const onPage = filteredData.slice(startingIndex, startingIndex + parseInt(pageSize.toString(), null));
			if (onPage.length === 0) { this.table.dataOnPageSource.next([{}]); }
			else { this.table.dataOnPageSource.next(onPage); }
		});
	}

	/** Sorts the data, including on multiple columns, if needed */
	sortTable() {
		let newSortedData = this.table.data;

		// Sort the table, if they added the pcgSort directive
		if (this.table.pcgSort) {
			const sortData = this.table.pcgSort.sortData.slice(0);
			newSortedData = sortData.reverse().reduce((prevVal, currVal) => {
				const col = this.table.columnDefs.get(currVal[0]);
				const sortKey = (col && col.sortColumn) 
					? col.sortColumn 
					: currVal[0];
				const sortDirection = currVal[1];
				return typeof prevVal !== 'object' 
					? prevVal 
					: prevVal.sort((a, b) => {
						if (
							a[sortKey] > b[sortKey] 
							|| b[sortKey] === '' 
							|| b[sortKey] === null
						) { return sortDirection === 'asc' ? 1 : -1; }
						if (
							a[sortKey] < b[sortKey] 
							|| a[sortKey] === '' 
							|| a[sortKey] === null
						) { return sortDirection === 'asc' ? -1 : 1; }
						return 0;
					}
				);
			}, newSortedData);
		}

		return newSortedData;
	}

	/** Applies searching and sorting to data */
	applyFilters() {
		// Start with the sorted data
		let newFilteredData = this.sortTable();

		// Filter on main search
		if (
			this.table.filterSource.value 
			&& this.table.filterSource.value !== ''
		) { 
			newFilteredData = this.table.data.filter(o => this.filterPredicate(o, this.table.filterSource.value));
		}

		// Filter per column
		for (let i = 0; i < this.table.perColumnSearchSource.value.length; ++i) {
			if (
				this.table.perColumnSearchSource.value[i] 
				&& this.table.perColumnSearchSource.value[i] !== ''
			) {
				newFilteredData = newFilteredData.filter(o =>
					o[this.table.getSearchColDefs()[i]] 
					&& o[this.table.getSearchColDefs()[i]].toString().toLowerCase().includes(this.table.perColumnSearchSource.value[i].toLowerCase())
				);
			}
		}

		// Update count and filter data
		this.table.filteredDataCount = newFilteredData.length;
		this.table.filteredDataSource.next(newFilteredData);
	}

	/**
	* Checks if a data object matches the data source's filter string. By default, each data object
	* is converted to a string of its properties and returns true if the filter has
	* at least one occurrence in that string. By default, the filter string has its whitespace
	* trimmed and the match is case-insensitive. May be overridden for a custom implementation of
	* filter matching.
	* @param data Data object used to check against the filter.
	* @param filter Filter string that has been set on the data source.
	* @returns Whether the filter matches against the data
	*/
	filterPredicate: ((data: T, filter: string) => boolean) = (data: T, filter: string): boolean => {
		// Transform the data into a lowercase string of all property values.
		const dataStr = this.table.getSearchColDefs().reduce((currentTerm: string, key: string) => {
			// Use an obscure Unicode character to delimit the words in the concatenated string.
			// This avoids matches where the values of two columns combined will match the user's query
			// (e.g. `Flute` and `Stop` will match `Test`). The character is intended to be something
			// that has a very low chance of being typed in by somebody in a text field. This one in
			// particular is "White up-pointing triangle with dot" from
			// https://en.wikipedia.org/wiki/List_of_Unicode_characters
			return currentTerm + (data as { [key: string]: any })[key] + '◬';
		}, '◬').toLowerCase();

		// Transform the filter by converting it to lowercase and removing whitespace.
		const transformedFilter = filter.trim().toLowerCase();

		return this.table.exactMatchSource.value ?
		dataStr.indexOf('◬' + transformedFilter + '◬') !== -1 :
		dataStr.indexOf(transformedFilter) !== -1;
	}
}
