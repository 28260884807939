<div>
    <fa-icon 
        [icon]="faIcon.faInfoCircle" 
        class="description-icon"
    ></fa-icon>
    Select active organizations from the table below to associate them with this user.
</div>
<br>

<div class="row">
    <div class="col-md-6">
       <pcg-user-non-associated-organizations 
            #available
            [userId]="userId"
            [editable]="canManageOrgs"
            (sync)="sync()"
        ></pcg-user-non-associated-organizations>
    </div>
    <div class="col-md-6">
        <pcg-user-associated-organizations 
            #associated
            [userId]="userId"
            [editable]="canManageOrgs"
            (sync)="sync()"
        ></pcg-user-associated-organizations>
    </div>
</div>