<pcg-table #table [ajaxData]='filters' [multipleSearch]='false' [pcgSort]='[[ "dateChanged", "desc" ]]'
	dataSource='api/Administration/Users/GetUserAuditHistory' [columnDefs]='columns'
	(tableReceive)="fullName = $event.value" [canExportTableToExcel]='true'>

	<ng-container matColumnDef="dateChanged">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('dateChanged').displayName }}</th>
		<td mat-cell *matCellDef='let row'>{{ row.dateChangedFormatted }}</td>
	</ng-container>

	<ng-container matColumnDef="module">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('module').displayName }}</th>
		<td mat-cell *matCellDef='let row'>{{ row.module }}</td>
	</ng-container>

	<ng-container matColumnDef="recordAffected">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('recordAffected').displayName }}</th>
		<td mat-cell *matCellDef='let row'>{{ row.recordAffected }}</td>
	</ng-container>

	<ng-container matColumnDef="field">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('field').displayName }}</th>
		<td mat-cell *matCellDef='let row'>{{ row.field }}</td>
	</ng-container>

	<ng-container matColumnDef="changedFrom">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('changedFrom').displayName }}</th>
		<td mat-cell *matCellDef='let row'>{{ row.changedFrom }}</td>
	</ng-container>

	<ng-container matColumnDef="changedTo">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('changedTo').displayName }}</th>
		<td mat-cell *matCellDef='let row'>{{ row.changedTo }}</td>
	</ng-container>

	<ng-container matColumnDef="changedBy">
		<th mat-header-cell pcg-sort-header *matHeaderCellDef>{{ columns.get('changedBy').displayName }}</th>
		<td mat-cell *matCellDef='let row'>{{ row.changedBy }}</td>
	</ng-container>

</pcg-table>