import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { SendgridUserBounceResponse } from "app/shared/models/SendgridUserBounceResponse";

@Injectable({ providedIn: "root" })
export class SendgridApiService {

	constructor(private httpClient: HttpClient) {}

	getIsBouncedResponse(
		username: string
	): Observable<SendgridUserBounceResponse> {
		const httpParams = new HttpParams().set("username", username);
		return this.httpClient.get<SendgridUserBounceResponse>(
			"api/BouncedEmail/IsUserBounced"
			, { params: httpParams }
		);
	}

	removeBouncedUser(username: string) {
		const httpParams = new HttpParams().set("username", username);
		return this.httpClient.get(
			"api/BouncedEmail/RemoveFromBounceList"
			, { params: httpParams }
		);
	}
}
