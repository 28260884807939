<pcg-page-info>
    Select active facilities from the table below to associate them with this user.
</pcg-page-info>

<div class="row">
    <div class="col-md-6">
        <pcg-user-non-associated-facilities 
            #nonAssociatedFac 
            [id]="filters.id" 
            [canEditFacilities]="canEditFacilities"
            (reload)="reloadTables()"
        ></pcg-user-non-associated-facilities>
    </div>

    <div class="col-md-6">
        <pcg-user-associated-facilities 
            #associatedFac 
            [id]="filters.id" 
            [canEditFacilities]="canEditFacilities"
            (reload)="reloadTables()"
        ></pcg-user-associated-facilities>
    </div>
</div>