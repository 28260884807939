import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { param } from '../../../shared/http-params';
import { SystemMessageService } from '../../system-message/system-message-service';
import { MakeCredentialVm } from 'app/shared/generated/Models/Fido/MakeCredentialVm';
import { FidoService } from 'app/shared/services/fido.service';
import { FormatHelperService } from 'app/shared/helpers/format-helper.service';

@Injectable({
	providedIn: 'root'
})
export class WebAuthnService {

	constructor(
		private http: HttpClient
		, private ms: SystemMessageService
		, private formatHelper: FormatHelperService
	) { }

	/**
	 * Make sure your Browser is compatible and you are using HTTPS
	 */
	isFidoCompatible() {
		if (window.location.protocol !== 'https:') {
			this.ms.setSystemMessage('U2F authentication is not supported without HTTPs.', 'error');
			return false;
		}

		if (window['PublicKeyCredential'] === undefined || typeof window['PublicKeyCredential'] !== 'function') {
			this.ms.setSystemMessage('U2F authentication is not supported on this browser.', 'error');
			return false;
		}
		return true;
	}

	/**
	 * Makes your user credential options by asking the FIDO controller for the options
	 * @param userId
	 * @param password
	 */
	makeCredentials(userId: number, password: string) {
		return this.http.get('api/Fido/MakeCredentialOptions?' 
			+ param(
				{
					userId: userId
					, attType: 'none' // or direct, indirect
					, authType: '' // or cross-platform, platform
					, userVerification: 'preferred' // or required, discouraged
					, requireResidentKey: false
					, password: password
				}
			)
		).pipe(
			tap(
				(makeCredentialOptions: any) => {
					if (makeCredentialOptions.status !== 'error') {
						// Turn the challenge back into the accepted format
						makeCredentialOptions.challenge = this.coerceToArrayBuffer(makeCredentialOptions.challenge);
						// Turn ID into a UInt8Array Buffer for some reason
						makeCredentialOptions.user.id = this.coerceToArrayBuffer(makeCredentialOptions.user.id);

						makeCredentialOptions.excludeCredentials = makeCredentialOptions.excludeCredentials.map((c) => {
							c.id = this.coerceToArrayBuffer(c.id);
							return c;
						});

						if (makeCredentialOptions.authenticatorSelection.authenticatorAttachment === null) {
							makeCredentialOptions.authenticatorSelection.authenticatorAttachment = undefined;
						}
					}
				}
			)
		);
	}

	/**
	 * Hits the FIDO controller and saves your credentials
	 * @param newCredential
	 * @param displayName
	 * @param userId
	 * @param password
	 */
	saveCredentials(
		newCredential
		, displayName: string
		, userId: number
		, password: string
		, isFingerprint: boolean
	) {
		// Move data into Arrays incase it is super long
		window['newCred'] = newCredential;
		const attestationObject = new Uint8Array(newCredential.response.attestationObject);
		const clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
		const rawId = new Uint8Array(newCredential.rawId);
		
		const attestation = {
			id: this.coerceToBase64Url(rawId)
			, rawId: this.coerceToBase64Url(rawId)
			, type: newCredential.type
			, extensions: newCredential.getClientExtensionResults()
			, response: {
				attestationObject: this.coerceToBase64Url(attestationObject)
				, clientDataJson: this.coerceToBase64Url(clientDataJSON)
			}
		};

		var vm = new MakeCredentialVm();
		vm.userId = userId;
		vm.displayName = displayName;
		vm.password = password;
		vm.isFingerprint = isFingerprint;
		vm.attestationResponse = attestation;
		
		return this.http.post('api/Fido/MakeCredential', vm);
	}

	/**
	 * Get options for web authn assertion request
	 * @param userName
	 * @param password
	 */
	getAssertionOptions(userName: string, password: string) {
		const data = { userName, password };
		return this.http.get('api/Fido/GetAssertionOptions?' + param(data))
			.pipe(tap((makeAssertionOptions: any) => {
				makeAssertionOptions = FidoService.fixAssertionOptions(makeAssertionOptions);
				const challenge = makeAssertionOptions?.challenge?.replace(/-/g, '+')?.replace(/_/g, '/');
				makeAssertionOptions.challenge = this.coerceToArrayBuffer(challenge);
				makeAssertionOptions.allowCredentials.forEach((listItem) => {
					var fixedId = listItem?.id?.replace(/\_/g, "/")?.replace(/\-/g, "+");
					listItem.id = this.coerceToArrayBuffer(fixedId);
				});
			})
		);
	}

	getAssertionOptionsNoUser() {
		return this.http.get('api/Fido/GetAssertionOptionsNoUser')
			.pipe(tap((makeAssertionOptions: any) => {
				makeAssertionOptions = FidoService.fixAssertionOptions(makeAssertionOptions);
				var challenge;
				if (!this.formatHelper.GetIsNully(makeAssertionOptions) && !this.formatHelper.GetIsNully(makeAssertionOptions.challenge)) {
					challenge = makeAssertionOptions.challenge.replace(/-/g, '+').replace(/_/g, '/');
				}
				makeAssertionOptions.challenge = this.coerceToArrayBuffer(challenge);
				makeAssertionOptions.allowCredentials.forEach((listItem) => {
					var fixedId = listItem?.id?.replace(/\_/g, "/")?.replace(/\-/g, "+");
					listItem.id = this.coerceToArrayBuffer(fixedId);
				});
			})
		);
	}

	verifyAssertion(
		assertedCredential
		, userName: string
		, password: string
	) {
		// Move data into Arrays incase it is super long
		let clientResponse = {};
		if (assertedCredential) {			
			const authData = new Uint8Array(assertedCredential.response.authenticatorData);
			const clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
			const rawId = new Uint8Array(assertedCredential?.rawId);
			const sig = new Uint8Array(assertedCredential.response.signature);
			clientResponse = {
				id: this.coerceToBase64Url(rawId)
				, rawId: this.coerceToBase64Url(rawId)
				, type: assertedCredential.type
				, extensions: assertedCredential.getClientExtensionResults()
				, response: {
					authenticatorData: this.coerceToBase64Url(authData)
					, clientDataJson: this.coerceToBase64Url(clientDataJSON)
					, signature: this.coerceToBase64Url(sig)
				}
			};
		}
		return this.ms.getHttpObservable(
			this
			, 'api/Fido/MakeAssertion'
			, null
			, {
				userName
				, password
				, clientResponse
			}
		);
	}

	verifyAssertionNoUser(
		assertedCredential
		, shipmentId
		, rxNumber
	) {
		let clientResponse = {};
		if (assertedCredential) {
			const authData = new Uint8Array(assertedCredential.response.authenticatorData);
			const clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
			const rawId = new Uint8Array(assertedCredential.rawId);
			const sig = new Uint8Array(assertedCredential.response.signature);
			clientResponse = {
				id: this.coerceToBase64Url(rawId)
				, rawId: this.coerceToBase64Url(rawId)
				, type: assertedCredential.type
				, extensions: assertedCredential.getClientExtensionResults()
				, response: {
					authenticatorData: this.coerceToBase64Url(authData)
					, clientDataJson: this.coerceToBase64Url(clientDataJSON)
					, signature: this.coerceToBase64Url(sig)
				}
			};
		}
		if (
			!FormatHelperService.GetIsNullyOrWhitespace(shipmentId) 
			&& !FormatHelperService.GetIsNullyOrWhitespace(rxNumber)
		) {
			return this.ms.getHttpObservable(
				this
				, `api/Fido/MakeAssertionNoUser/?shipmentId=${shipmentId}&rxNumber=${rxNumber}`
				, null
				, { clientResponse }
			);
		} else {
			return this.ms.getHttpObservable(
				this
				, `api/Fido/MakeAssertionNoUserNoRx/`
				, null
				, { clientResponse }
			);
		}
	}

	coerceToBase64Url = function (thing) {
		// Array or ArrayBuffer to Uint8Array
		if (Array.isArray(thing)) { thing = Uint8Array.from(thing); }
		if (thing instanceof ArrayBuffer) { thing = new Uint8Array(thing); }

		// Uint8Array to base64
		if (thing instanceof Uint8Array) {
			let str = '';
			const len = thing.byteLength;

			for (let i = 0; i < len; i++) { str += String.fromCharCode(thing[i]); }
			thing = window.btoa(str);
		}

		if (typeof thing !== 'string') { alert(); throw new Error('could not coerce to string'); }
		return thing;
	};

	coerceToArrayBuffer(thing) {
		if (typeof thing === 'string') {
			// base64url to base64
			if (!this.formatHelper.GetIsNully(thing)) { thing = thing.replace(/-/g, '+').replace(/_/g, '/'); }

			// base64 to Uint8Array
			const str = window.atob(thing);
			const bytes = new Uint8Array(str.length);
			for (let i = 0; i < str.length; i++) { bytes[i] = str.charCodeAt(i); }
			thing = bytes;
		}

		// Array to Uint8Array
		if (Array.isArray(thing)) { thing = new Uint8Array(thing); }

		// Uint8Array to ArrayBuffer
		if (thing instanceof Uint8Array) { thing = thing.buffer; }

		// error if none of the above worked
		if (!(thing instanceof ArrayBuffer)) { throw new TypeError('could not coerce to ArrayBuffer'); }

		return thing;
	}

	b64enc(buf) { return this.coerceToBase64Url(buf); }
	b64RawEnc(buf) { return this.b64enc(buf); }
}
