import { Component, Input, OnInit } from '@angular/core';

import { FormatHelperService } from 'app/shared/helpers/format-helper.service';
import { FaIconPosition } from '../fa-icon-position/fa-icon-position';
import { FaNumColor } from '../fa-num-color/fa-num-color.enum';

@Component({
    selector: 'pcg-fa-count',
    templateUrl: './fa-count.component.html',
    styleUrls: ['./fa-count.component.scss']
})
export class FaCountComponent implements OnInit {

    @Input() num?: number;
    @Input() numColor?: FaNumColor;
    @Input() isNumVisible = true;
    @Input() numPosition?: FaIconPosition;

    iconNumClassNames = "badge badge-pill countNum";
  
    numPositionStyles: object;
    
    constructor(private formatHelper: FormatHelperService) { }

    ngOnInit() {
        this.assignNumColor(this.numColor);
        if(this.formatHelper.GetIsNully(this.num)) { this.isNumVisible = false; }
        if(!this.formatHelper.GetIsNully(this.numPosition)) { this.assignNumPosition(this.numPosition); }
    }

    //When adding a new case on this switch, make sure to add the same to the enum in ../fa-num-color/fa-num-color.enum.ts
    assignNumColor(numColor?: FaNumColor) {
        this.numColor = numColor;
        switch(numColor){
            case FaNumColor.success: this.iconNumClassNames += " badge-success"; break;
            case FaNumColor.warning: this.iconNumClassNames += " badge-warning"; break;
            case FaNumColor.danger: this.iconNumClassNames += " badge-danger"; break;
            case FaNumColor.info: this.iconNumClassNames += " badge-info"; break;
            case FaNumColor.secondary: this.iconNumClassNames += " badge-secondary"; break;
            case FaNumColor.other: this.iconNumClassNames += " badge-secondary"; break;
            default: this.iconNumClassNames += " badge-secondary";
        }
    } 

    assignNumPosition(position: FaIconPosition):void{
        let isPositionAssigned = false;
        let numStyles = { position: 'relative' };

        if (!this.formatHelper.GetIsNully(position.top)){
            isPositionAssigned = true;
            numStyles['top'] = FaIconPosition.getPositionPropValue(position.top, position.getMyPositionUnits());
        }
        if (!this.formatHelper.GetIsNully(position.right)){
            isPositionAssigned = true;
            numStyles['right'] = FaIconPosition.getPositionPropValue(position.right, position.getMyPositionUnits());
        }
        if (!this.formatHelper.GetIsNully(position.bottom)){
            isPositionAssigned = true;
            numStyles['bottom'] = FaIconPosition.getPositionPropValue(position.bottom, position.getMyPositionUnits());
        }
        if (!this.formatHelper.GetIsNully(position.left)){
            isPositionAssigned = true;
            numStyles['left'] = FaIconPosition.getPositionPropValue(position.left, position.getMyPositionUnits());
        }

        if (isPositionAssigned) { this.numPositionStyles = numStyles; }
    }
}
