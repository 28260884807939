<div *ngIf="true"> <!-- doesn't work without *ngIf="true". No clue why-->
    <input 
        id="x" 
        [value]="editorValue" 
        type="hidden"
    />
    <trix-editor 
        input="x" 
        class="trix-content"
    ></trix-editor>
</div>
