import { Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccountRequestModalStateService {
  private modalRefSubject = new BehaviorSubject<NgbModalRef | null>(null);

  setModalRef(modalRef: NgbModalRef): void {
    this.modalRefSubject.next(modalRef);
  }

  getModalRefObservable() {
    return this.modalRefSubject.asObservable();
  }
}