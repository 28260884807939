import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuditHistoryVm } from 'app/shared/generated/Models/AuditHistoryVm';
import { NavigationService } from '../navigation/navigation.service';
import { TableComponent } from '../table/table.component';

@Component({
    selector: 'pcg-audit-history',
    templateUrl: './audit-history.component.html',
    styleUrls: ['./audit-history.component.scss']
})
export class AuditHistoryComponent implements OnInit {

	@ViewChild(TableComponent, { static: true }) table: TableComponent<AuditHistoryVm>;

    @Input() id: number;
    @Input() viewModel: string;
    @Input() auditHistoryName: string;

    columns = AuditHistoryVm.ColumnDefs;
    auditName: string;
    filters = {
        id: null,
        viewModel: null,
        auditName: null
    };

    hideBackButton = false;

    constructor(
        private activatedRoute: ActivatedRoute
        , private nav : NavigationService
    ) { }

    ngOnInit() {
        if (this.id && this.viewModel && this.auditHistoryName) {
            this.filters.id = this.id;
            this.filters.viewModel = this.viewModel;
            this.filters.auditName = this.auditHistoryName;
        } else if (this.activatedRoute.snapshot.params['vmType'] && this.activatedRoute.snapshot.params['auditName']) {
            this.filters.id = this.activatedRoute.snapshot.params['id'];
            this.filters.viewModel = this.activatedRoute.snapshot.params['vmType'];
            this.filters.auditName = this.activatedRoute.snapshot.params['auditName'];
        } else {
            this.filters.id = this.activatedRoute.snapshot.params['id'];
            this.filters.viewModel = localStorage?.getItem('vmType');
            this.filters.auditName = localStorage?.getItem('auditName');
        }
        if (this.activatedRoute.snapshot.queryParamMap.get('hideBackButton')) { 
			const hideBackButtonStr = this.activatedRoute.snapshot.queryParamMap.get('hideBackButton');
			if (hideBackButtonStr === "true") { this.hideBackButton = true; }
		}
        // Display the module comment by default for CI Repack. This will eventually by default shown for all BA's once audit enhancements are complete. 
        if ((this.filters.viewModel as string)?.includes('CIRepack')) { this.columns.get('module').isVisible = true; }
        this.setNav();
    }

    setNav() {
        if (this.isCi()) {
            if (this.isCiJob()) {
                this.nav.setOpenSecondaryMenu(['CI Repack', 'Global Jobs']);
            }
        }
    }

    // CI Navigation Conditional Methods
    isCi = () => this.viewModel?.includes("Job") || this.filters?.viewModel?.includes("Job")
    isCiJob = () => this.viewModel?.includes("Job") || this.filters?.viewModel?.includes("Job")

    goBack() { window.history.back(); }
}
