import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';
import { UserRoleInfoComponent } from './business-area-role-info/user-role-info/user-role-info.component';
import { UserRoleInfoModalComponent } from './user-role-info-modal.component';
import { RoleInfoComponent } from './business-area-role-info/role-info/role-info.component'; 

@NgModule({
    declarations: [
        UserRoleInfoComponent
        , UserRoleInfoModalComponent
        , RoleInfoComponent        
    ]
    , imports: [
        RouterModule
        , CommonModule
        , SharedModule
    ]
    , exports: [
        UserRoleInfoComponent
        , UserRoleInfoModalComponent
        , RoleInfoComponent 
    ]
})
export class UserRoleInfoModalModule { }
