import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class AddressInformationVm {
	address?: string;
	secondaryAddress?: string;
	city?: string;
	state?: number;
	zipCode?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'address': new UntypedFormControl(null, [...(validators['address'] ?? []), ...[Validators.maxLength(500), Validators.minLength(0)]]),
			'secondaryAddress': new UntypedFormControl(null, [...(validators['secondaryAddress'] ?? []), ...[Validators.maxLength(500), Validators.minLength(0)]]),
			'city': new UntypedFormControl(null, [...(validators['city'] ?? []), ...[Validators.maxLength(100), Validators.minLength(0)]]),
			'state': new UntypedFormControl(null, [...(validators['state'] ?? []), ...[]]),
			'zipCode': new UntypedFormControl(null, [...(validators['zipCode'] ?? []), ...[Validators.maxLength(50), Validators.minLength(0)]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): AddressInformationVmFromFg {
		return new AddressInformationVmFromFg(fg);
	}
}

export class AddressInformationVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get address(): string {
		return this.fg.get('address').value;
	}
	set address(val) {
		this.fg.get('address').setValue(val);
	}

	get secondaryAddress(): string {
		return this.fg.get('secondaryAddress').value;
	}
	set secondaryAddress(val) {
		this.fg.get('secondaryAddress').setValue(val);
	}

	get city(): string {
		return this.fg.get('city').value;
	}
	set city(val) {
		this.fg.get('city').setValue(val);
	}

	get state(): number {
		return this.fg.get('state').value;
	}
	set state(val) {
		this.fg.get('state').setValue(val);
	}

	get zipCode(): string {
		return this.fg.get('zipCode').value;
	}
	set zipCode(val) {
		this.fg.get('zipCode').setValue(val);
	}
}
