import { Component, OnInit } from '@angular/core';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';

@Component({
    selector: 'pcg-pharmacy-verification-settings',
    templateUrl: './pharmacy-verification-settings.component.html',
    styleUrls: ['./pharmacy-verification-settings.component.scss']
})
export class PharmacyVerificationSettingsComponent implements OnInit {

    businessArea: BusinessAreaEnum = BusinessAreaEnum.Inventory;

    constructor() { }

    ngOnInit() { }
}
