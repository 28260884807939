<div class="container">
	<h2>
		RxConnects Security
	</h2>
	<hr />
</div>
<div class="container">
	<form [formGroup]='formGroup' (submit)='saveSecurityForm()'>
		<div class="container clearfix">
			<div class='row'>
			</div>
			<div class='row header'>
				<pcg-radio-button-list pcgControlRef formControlName="securityEnum" [items]="[
						 { text: 'Repository	', value: RxConnectsSecurityEnum.Repository },
						 { text: 'Donor', value: RxConnectsSecurityEnum.Donor }
					   ]">
				</pcg-radio-button-list>
			</div>
			<br />
			<div class='row' *ngIf="formGroup.get('securityEnum').value === RxConnectsSecurityEnum.Repository">
				<pcg-control-group class="col-sm-8 " label="Repository">
					<pcg-select pcgControlRef [multiple]='false' dataSource='api/PublicSelect/RxConnectsRepositories'
						formControlName="repositoryId" placeholder='Select a Repository'>
					</pcg-select>
				</pcg-control-group>
				<pcg-control-group class='col-sm-4' label='&nbsp;'>
					<a class='btn btn-lg btn-danger' (click)="removeSecurity()">Remove</a>
				</pcg-control-group>
			</div>
			<div class='row' *ngIf="formGroup.get('securityEnum').value === RxConnectsSecurityEnum.Donor">
				<pcg-control-group class="col-sm-8" label="Donor">
					<pcg-select pcgControlRef [multiple]='false' dataSource='api/Select/GetDonors'
						formControlName="donorId" placeholder='Select a donor'>
					</pcg-select>

				</pcg-control-group>
				<pcg-control-group class="col-sm-4" label='&nbsp;'>
					<a class='btn btn-lg btn-danger' (click)="removeSecurity()">Remove</a>
				</pcg-control-group>
			</div>
		</div>
		<pcg-fixed-buttons>
			<button type='submit' class="btn btn-save btn-lg">Save</button>
		</pcg-fixed-buttons>
	</form>
</div>