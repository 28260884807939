import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'pcg-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

	@ViewChild('img', { static: true }) img: ElementRef;

	origImgHeight: string;

	constructor(public title: Title) { title.setTitle('Dashboard'); }

	ngOnInit() {
		document.body.classList.add('nav-background-color');
		document.getElementById('footer').classList.add('nav-text-color');
	}

	setImgHeight() {
		const height = this.img.nativeElement.offsetHeight;
		const navHeight = document.getElementById('primaryNav').offsetHeight;
		const footerHeight = document.getElementById('footer').offsetHeight;
		const maxHeight = document.body.offsetHeight - navHeight - footerHeight;
		this.origImgHeight = this.img.nativeElement.style.height;

		if (height > maxHeight) { this.img.nativeElement.style.height = `${maxHeight}px`; }
	}

	@HostListener('window:resize', ['$event'])
	onBlur() {
		this.img.nativeElement.style.height = this.origImgHeight;
		this.setImgHeight();
	}

	ngOnDestroy() {
		document.body.classList.remove('nav-background-color');
		document.getElementById('footer').classList.remove('nav-text-color');
	}
}
