import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { UserFacilityAgencyListVm } from 'app/shared/generated/Administration/Models/Users/UserFacilityAgencyListVm';
import { ButtonFilterService } from 'app/shared/table/table-top/button-filter/button-filter.service';
import { TableComponent } from 'app/shared/table/table.component';

@Component({
    selector: 'pcg-user-non-associated-facilities',
    templateUrl: './user-non-associated-facilities.component.html',
    styleUrls: ['./user-non-associated-facilities.component.scss']
})
export class UserNonAssociatedFacilitiesComponent implements OnInit {

    @ViewChild(TableComponent) nonTable: TableComponent<UserFacilityAgencyListVm>;
    @Input() id: number;
    @Input() canEditFacilities: boolean = false;
    @Output() reload = new EventEmitter();

    nonColumns = UserFacilityAgencyListVm.ColumnDefs;
    formGroup = UserFacilityAgencyListVm.Form;

    hasData = false;

    faIcon = { faPlus };

    nonFilters = { 
        userId: 0
        , countyId: null
        , businessArea: null 
    };
    initNonFilters = { 
		userId: 0
        , countyId: null
        , businessArea: null 
	};
    nonFilterMap = { 
        "countyId": "County"
        , "businessArea": "Business Area"
    };

    constructor(
        public btnNonFilterService: ButtonFilterService
        , private httpClient: HttpClient
        , private ms: SystemMessageService
    ) { }

    ngOnInit() {
        this.nonFilters.userId = this.id;
        this.initNonFilters = Object.assign({}, this.nonFilters);
        this.configureColumns();
        this.btnNonFilterService.resetFilterData.subscribe(() => { this.resetFilters(); });
    }

    public configureColumns() {
        if (!this.canEditFacilities) { this.nonColumns.delete('canAdd'); }
        this.nonColumns.delete('canDelete');
      }    

    tblReceive(event) {
		if (event.value === true) { this.hasData = true; }
        else { this.hasData = false; }
	}

    resetFilters() {
		this.nonFilters = Object.assign(this.nonFilters, this.initNonFilters);
		this.nonTable?.ajaxReload();
	}

    addFac(row: UserFacilityAgencyListVm) {
        if (!this.canEditFacilities) { return; }
        var source = "";
        if (row.facilityType === "HEROS") {
            source = "api/Administration/Users/AddAgencyToUser";
        } else {
            this.ms.setSystemMessage("This facility does not have a type therefore it cannot be associated", "error");
            return;
        }
        
        this.formGroup.patchValue(row);
        this.httpClient.post(source, this.formGroup.getRawValue())
            .subscribe(async (sm: SystemMessage) => {
                if (!sm.isSuccessful) { this.ms.setSystemMessage(sm.message, 'error'); return; } 
                else { this.reload.emit(); }
            }
        );
    }

    addAll() {
        const pcgTableInput = this.nonTable.getPcgTableInput();
        const tableInput = JSON.stringify(pcgTableInput);

        this.httpClient.post('api/Administration/Users/AddAllFacilitiesAndAgenciesToUser'
            , tableInput
            , { 
                params: new HttpParams()
                    .set('userId', this.id)
                    .set('countyId', this.nonFilters.countyId) 
                    .set('businessArea', this.nonFilters.businessArea) 
            }
        ).subscribe(async (sm: SystemMessage) => {
            if (!sm.isSuccessful) { this.ms.setSystemMessage(sm.message, 'error'); return; } 
            else { this.reload.emit(); }
        });
    }
}
