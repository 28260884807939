import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class PatientApplicationVm {
	iD: number;
	firstName?: string;
	lastName?: string;
	addressId?: number;
	smartyAddress?: any;
	smartyAddressString?: string;
	address?: string;
	city?: string;
	state?: string;
	zip?: string;
	countyId: number;
	county?: string;
	homePhone?: string;
	cellPhone?: string;
	emailAddress?: string;
	repositoryId?: number;
	repository?: string;
	eligibilityStatusId: number;
	signature?: string;
	dateSigned: Date;
	isAttested?: boolean;
	honeyPot?: string;
	applicationStatus: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'iD': new UntypedFormControl(0, [...(validators['iD'] ?? []), ...[Validators.required]]),
			'firstName': new UntypedFormControl(null, [...(validators['firstName'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'lastName': new UntypedFormControl(null, [...(validators['lastName'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'addressId': new UntypedFormControl(null, [...(validators['addressId'] ?? []), ...[]]),
			'smartyAddress': new UntypedFormControl(null, [...(validators['smartyAddress'] ?? []), ...[Validators.required]]),
			'smartyAddressString': new UntypedFormControl(null, [...(validators['smartyAddressString'] ?? []), ...[]]),
			'address': new UntypedFormControl(null, [...(validators['address'] ?? []), ...[]]),
			'city': new UntypedFormControl(null, [...(validators['city'] ?? []), ...[]]),
			'state': new UntypedFormControl(null, [...(validators['state'] ?? []), ...[]]),
			'zip': new UntypedFormControl(null, [...(validators['zip'] ?? []), ...[]]),
			'countyId': new UntypedFormControl(0, [...(validators['countyId'] ?? []), ...[Validators.required]]),
			'county': new UntypedFormControl(null, [...(validators['county'] ?? []), ...[]]),
			'homePhone': new UntypedFormControl(null, [...(validators['homePhone'] ?? []), ...[Validators.maxLength(14), Validators.pattern("(\\+\\d{1,3}\\s?)?((\\(\\d{3}\\)\\s?)|(\\d{3})(\\s|-?))(\\d{3}(\\s|-?))(\\d{4})(\\s?(([E|e]xt[:|.|]?)|x|X)(\\s?\\d+))?")]]),
			'cellPhone': new UntypedFormControl(null, [...(validators['cellPhone'] ?? []), ...[Validators.required, Validators.maxLength(14), Validators.pattern("(\\+\\d{1,3}\\s?)?((\\(\\d{3}\\)\\s?)|(\\d{3})(\\s|-?))(\\d{3}(\\s|-?))(\\d{4})(\\s?(([E|e]xt[:|.|]?)|x|X)(\\s?\\d+))?")]]),
			'emailAddress': new UntypedFormControl(null, [...(validators['emailAddress'] ?? []), ...[Validators.required, Validators.maxLength(50), Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]]),
			'repositoryId': new UntypedFormControl(null, [...(validators['repositoryId'] ?? []), ...[]]),
			'repository': new UntypedFormControl(null, [...(validators['repository'] ?? []), ...[]]),
			'eligibilityStatusId': new UntypedFormControl(0, [...(validators['eligibilityStatusId'] ?? []), ...[Validators.required]]),
			'signature': new UntypedFormControl(null, [...(validators['signature'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'dateSigned': new UntypedFormControl('', [...(validators['dateSigned'] ?? []), ...[Validators.required]]),
			'isAttested': new UntypedFormControl(null, [...(validators['isAttested'] ?? []), ...[Validators.required]]),
			'honeyPot': new UntypedFormControl(null, [...(validators['honeyPot'] ?? []), ...[]]),
			'applicationStatus': new UntypedFormControl(0, [...(validators['applicationStatus'] ?? []), ...[Validators.required]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): PatientApplicationVmFromFg {
		return new PatientApplicationVmFromFg(fg);
	}
}

export class PatientApplicationVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get iD(): number {
		return this.fg.get('iD').value;
	}
	set iD(val) {
		this.fg.get('iD').setValue(val);
	}

	get firstName(): string {
		return this.fg.get('firstName').value;
	}
	set firstName(val) {
		this.fg.get('firstName').setValue(val);
	}

	get lastName(): string {
		return this.fg.get('lastName').value;
	}
	set lastName(val) {
		this.fg.get('lastName').setValue(val);
	}

	get addressId(): number {
		return this.fg.get('addressId').value;
	}
	set addressId(val) {
		this.fg.get('addressId').setValue(val);
	}

	get smartyAddress(): any {
		return this.fg.get('smartyAddress').value;
	}
	set smartyAddress(val) {
		this.fg.get('smartyAddress').setValue(val);
	}

	get smartyAddressString(): string {
		return this.fg.get('smartyAddressString').value;
	}
	set smartyAddressString(val) {
		this.fg.get('smartyAddressString').setValue(val);
	}

	get address(): string {
		return this.fg.get('address').value;
	}
	set address(val) {
		this.fg.get('address').setValue(val);
	}

	get city(): string {
		return this.fg.get('city').value;
	}
	set city(val) {
		this.fg.get('city').setValue(val);
	}

	get state(): string {
		return this.fg.get('state').value;
	}
	set state(val) {
		this.fg.get('state').setValue(val);
	}

	get zip(): string {
		return this.fg.get('zip').value;
	}
	set zip(val) {
		this.fg.get('zip').setValue(val);
	}

	get countyId(): number {
		return this.fg.get('countyId').value;
	}
	set countyId(val) {
		this.fg.get('countyId').setValue(val);
	}

	get county(): string {
		return this.fg.get('county').value;
	}
	set county(val) {
		this.fg.get('county').setValue(val);
	}

	get homePhone(): string {
		return this.fg.get('homePhone').value;
	}
	set homePhone(val) {
		this.fg.get('homePhone').setValue(val);
	}

	get cellPhone(): string {
		return this.fg.get('cellPhone').value;
	}
	set cellPhone(val) {
		this.fg.get('cellPhone').setValue(val);
	}

	get emailAddress(): string {
		return this.fg.get('emailAddress').value;
	}
	set emailAddress(val) {
		this.fg.get('emailAddress').setValue(val);
	}

	get repositoryId(): number {
		return this.fg.get('repositoryId').value;
	}
	set repositoryId(val) {
		this.fg.get('repositoryId').setValue(val);
	}

	get repository(): string {
		return this.fg.get('repository').value;
	}
	set repository(val) {
		this.fg.get('repository').setValue(val);
	}

	get eligibilityStatusId(): number {
		return this.fg.get('eligibilityStatusId').value;
	}
	set eligibilityStatusId(val) {
		this.fg.get('eligibilityStatusId').setValue(val);
	}

	get signature(): string {
		return this.fg.get('signature').value;
	}
	set signature(val) {
		this.fg.get('signature').setValue(val);
	}

	get dateSigned(): Date {
		return this.fg.get('dateSigned').value;
	}
	set dateSigned(val) {
		this.fg.get('dateSigned').setValue(val);
	}

	get isAttested(): boolean {
		return this.fg.get('isAttested').value;
	}
	set isAttested(val) {
		this.fg.get('isAttested').setValue(val);
	}

	get honeyPot(): string {
		return this.fg.get('honeyPot').value;
	}
	set honeyPot(val) {
		this.fg.get('honeyPot').setValue(val);
	}

	get applicationStatus(): number {
		return this.fg.get('applicationStatus').value;
	}
	set applicationStatus(val) {
		this.fg.get('applicationStatus').setValue(val);
	}
}
