import { Component, OnInit, forwardRef, Input, ViewChild, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export class PcgFile {
	fileName: string;
	contentType: string;
	contentLength: number;
	fileContent: string;
}

@Component({
	selector: 'pcg-file-upload',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => FileUploadComponent),
		multi: true
	}],
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {

	@Input() labelForId = '';
	@Input('value') _value: PcgFile = null;
	@Input() isDisabled = false;
	
	@ViewChild('fileUploader', { static: true }) fileUploader: ElementRef;

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() { return this._value; }
	set value(val) {
		this._value = val;
		this.onChange(val);
		this.onTouched();
	}

	constructor() { }

	ngOnInit() { }
	writeValue(obj: any) { this.value = obj; }
	registerOnChange(fn: any) { this.onChange = fn; }
	registerOnTouched(fn: any) { this.onTouched = fn; }
	setDisabledState?(isDisabled: boolean) { this.isDisabled = isDisabled; }

	setValuesOnChange(val: any) {
		// Get the file
		const file = this.fileUploader?.nativeElement?.files[0];

		if (file && file instanceof File){
			// Get the file content as a base64 result
			const fileReader = new FileReader();
			fileReader.onload = () => {
				// Set the PCG file value
				this.value = {
					fileName: file.name,
					contentType: file.type || 'application/octet-stream',
					contentLength: file.size,
					fileContent: fileReader.result as string
				};
			};
			fileReader.readAsDataURL(file);
		}
	}
}
