import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class ActivationVm {
	id?: any;
	activationEnum?: any;
	isActive: boolean;
	isConfirmed?: boolean;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'id': new UntypedFormControl(null, [...(validators['id'] ?? []), ...[]]),
			'activationEnum': new UntypedFormControl('', [...(validators['activationEnum'] ?? []), ...[Validators.required]]),
			'isActive': new UntypedFormControl(false, [...(validators['isActive'] ?? []), ...[]]),
			'isConfirmed': new UntypedFormControl(null, [...(validators['isConfirmed'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): ActivationVmFromFg {
		return new ActivationVmFromFg(fg);
	}
}

export class ActivationVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get id(): any {
		return this.fg.get('id').value;
	}
	set id(val) {
		this.fg.get('id').setValue(val);
	}

	get activationEnum(): any {
		return this.fg.get('activationEnum').value;
	}
	set activationEnum(val) {
		this.fg.get('activationEnum').setValue(val);
	}

	get isActive(): boolean {
		return this.fg.get('isActive').value;
	}
	set isActive(val) {
		this.fg.get('isActive').setValue(val);
	}

	get isConfirmed(): boolean {
		return this.fg.get('isConfirmed').value;
	}
	set isConfirmed(val) {
		this.fg.get('isConfirmed').setValue(val);
	}
}
