<div>
	<div class="modal-header">
		<h5 class="modal-title" id="modal-basic-title">Submitted Rx's</h5>
		<button type="button" class="close" aria-label="Close" (click)="closeModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<p class="instructional-text">The following prescriptions have been successfully submitted to Central Pharmacy.</p>
		<table class="data-grid">
			<thead>
				<th>{{columns.get('rxNumber').displayName}}</th>
				<th>{{columns.get('refillsRemaining').displayName}}</th>
				<th>Submitted</th>
			</thead>
			<tbody *ngFor="let row of this.tableData">
				<tr>
					<td>{{row.rxNumber}}</td>
					<td>{{row.refillsRemaining}}</td>
					<td><img src="../assets/images/icons/accepted_48.png" border="0" width="20" /></td>
				</tr>
			</tbody>
		</table>
	</div>

	<div class="modal-footer">
		<input type="button" class="btn btn-cancel btn-lg" (click)="closeModal()" value="Close" />
	</div>
</div>
