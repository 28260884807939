<h3>Available HEROS Agencies</h3>

<pcg-table-filter id="nonFilters">
    <div (change)="nonTable?.ajaxReload();">
        <div class="additional-info mt-1">
            <div class='mt-2 filter-width'>
                <label class="for">County</label>
                <pcg-select 
                    #countyId
                    placeholder="Select..."
                    [multiple]='false' 
                    dataSource="api/Select/Counties"
                    [(ngModel)]="nonFilters.countyId"
                ></pcg-select>
            </div>

            <div class='mt-2 filter-width'>
                <label class="for">Business Area</label>
                <pcg-select 
                    #businessArea
                    placeholder="Select..."
                    [multiple]='false' 
                    dataSource="api/Select/BusinessAreasForUserFacilities"
                    [(ngModel)]="nonFilters.businessArea"
                ></pcg-select>
            </div>
        </div>
    </div>
</pcg-table-filter>

<pcg-table
    #nonTable
    class="card-table"
    [pcgSort]="[['facilityName', 'asc']]"
    dataSource="api/Administration/Users/GetNonAssociatedFacilitiesAndAgencies"
    [ajaxData]="nonFilters"
    filterId="nonFilters"
    [filterMap]="nonFilterMap"
    [emptyFilters]="initNonFilters"
    [canShowHideColumns]="false"
    [canExactMatchSearch]="false" 
    [canResetFilters]="true"
    [columnDefs]="nonColumns"
    [pageSize]="10"
    [isRowClickable]="canEditFacilities"
    (clickRow)="addFac($event)"
    (tableReceive)="tblReceive($event)"
>

    <ng-container matColumnDef="facilityName">
        <th 
            mat-header-cell 
            pcg-sort-header 
            *matHeaderCellDef
        >{{ nonColumns.get('facilityName').displayName }}</th>
        <td 
            mat-cell 
            *matCellDef="let row" 
            [attr.data-label]="nonColumns.get('facilityName').displayName"
        >{{ row.facilityName }}</td>
    </ng-container>

    <ng-container matColumnDef="facilityIdentifier">
        <th 
            mat-header-cell 
            pcg-sort-header 
            *matHeaderCellDef
        >{{ nonColumns.get('facilityIdentifier').displayName }}</th>
        <td 
            mat-cell 
            *matCellDef="let row" 
            [attr.data-label]="nonColumns.get('facilityIdentifier').displayName"
            >{{ row.facilityIdentifier }}</td>
        </ng-container>

    <ng-container matColumnDef="countyName">
        <th 
            mat-header-cell 
            pcg-sort-header 
            *matHeaderCellDef
        >{{ nonColumns.get('countyName').displayName }}</th>
        <td 
            mat-cell 
            *matCellDef="let row" 
            [attr.data-label]="nonColumns.get('countyName').displayName"
        >{{ row.countyName }}</td>
    </ng-container>

    <ng-container matColumnDef="facilityType">
        <th 
            mat-header-cell 
            pcg-sort-header 
            *matHeaderCellDef
        >{{ nonColumns.get('facilityType').displayName }}</th>
        <td 
            mat-cell 
            *matCellDef="let row" 
            [attr.data-label]="nonColumns.get('facilityType').displayName"
        >{{ row.facilityType }}</td>
    </ng-container>

    <ng-container matColumnDef="canAdd">
        <th 
            mat-header-cell 
            *matHeaderCellDef 
            class="text-center"
        >
            <button 
                *ngIf="hasData" 
                type="button"
                class="d-flex btn btn-sm btn-save"
                ngbTooltip="Search will not affect Add All. This will add all Facilities based on filters."
                container="body"
                (click)="
                    $event.stopPropagation(); 
                    addAll();
                "
            >
                <fa-icon [icon]="faIcon.faPlus"></fa-icon> &nbsp;All
            </button>
        </th>
        <td 
            mat-cell 
            *matCellDef="let row" 
            [attr.data-label]="nonColumns.get('canAdd').displayName" 
            class="icon-align text-center"
        >
            <fa-icon
                [icon]="faIcon.faPlus" 
                class="add plus"
            ></fa-icon>
        </td>
    </ng-container>

</pcg-table>
