// tslint:disable: quotemark
export enum PermissionRole {
	NoAccess = 0,
	User = 1,
	UserAdmin = 2,
	Technician = 3,
	Manager = 4,
	Pharmacist = 5,
	RxConnectsPharmacist = 6,
	RxConnectsDonor = 7,
	RxConnectsAdmin = 8,
	SystemAdmin = 99
}

export namespace PermissionRole {
	export function toDescription(val: PermissionRole) {
		switch (val) {
			case 0:
				return "No Access";
			case 1:
				return "User";
			case 2:
				return "User Admin";
			case 3:
				return "Technician";
			case 4:
				return "Manager";
			case 5:
				return "Pharmacist";
			case 6:
				return "RxConnects Pharmacist";
			case 7:
				return "RxConnects Donor";
			case 8:
				return "RxConnects Administrator";
			case 99:
				return "System Admin";
		}
		return null;
	}
	export function toDisplay(val: PermissionRole) {
		switch (val) {
			case 0:
				return "No Access";
			case 1:
				return "User";
			case 2:
				return "User Admin";
			case 3:
				return "Technician";
			case 4:
				return "Manager";
			case 5:
				return "Pharmacist";
			case 6:
				return "RxConnects Pharmacist";
			case 7:
				return "RxConnects Donor";
			case 8:
				return "RxConnects Administrator";
			case 99:
				return "System Admin";
		}
		return null;
	}
}
