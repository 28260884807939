import {
	AfterContentInit,
	Component,
	ContentChild,
	HostBinding,
	Input,
} from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";

import { ControlRefDirective } from "../../directives/control-ref.directive";

// This component was originally copied from here:
// https://www.codeproject.com/Articles/1239543/Angular-Custom-Validation-Component
@Component({
	selector: "pcg-control-group",
	templateUrl: "./control-group.component.html",
	styleUrls: ["./control-group.component.scss"],
})
export class ControlGroupComponent implements AfterContentInit {
	@Input() label: string;
	@Input() labelTooltip: any;
	@Input() validations: { [index: string]: string } = {};
	@Input() info: string;
	@Input() hideRequired: boolean;
	@Input() customControl: UntypedFormControl = null;
	@Input() useCustomControl = false;
	@Input() customLabelClasses = ""; //This input was introduced as a bit of a hack to programmatically decide whether or not the asterisk on the label is rendered
	@Input() placement: string = "auto";

	@ContentChild(ControlRefDirective) control: ControlRefDirective;
	@HostBinding("class.form-group") isFormGroup = true;

	controlId = "";

	get isRequired() {
		if (this.useCustomControl) {
			return this.customControl.hasValidator(Validators.required);
		} else {
			return this.control?.isRequired ?? false;
		}
	}

	get isError() {
		if (this.useCustomControl) {
			return this.customControl.hasError;
		}
		return this.control?.hasError;
	}

	get errorMessages() {
		const errors = this.useCustomControl
			? this.customControl.errors ?? {}
			: this.control.errors;
		const messages = [];
		if (errors[this.useCustomControl ? 'require' : 'required']) {
			messages.push(this.validations['required'] ? this.validations['required'] : 'Required.');
		} else if (errors['minlength']) {
			messages.push(this.validations['minlength'] ? this.validations['minlength'] : `The minimum length is ${errors['minlength']['requiredLength']}.`);
		} else if (errors['maxlength']) {
			messages.push(this.validations['maxlength'] ? this.validations['maxlength'] : `The maximum length is ${errors['maxlength']['requiredLength']}.`);
		} else if (errors['max']) {
			messages.push(this.validations['max'] ? this.validations['max'] : `The maximum value is ${errors['max']['max']}.`);
		} else if (errors['min']) {
			messages.push(this.validations['min'] ? this.validations['min'] : `The minimum value is ${errors['min']['min']}.`);
		} else if (errors['email']) {
			messages.push(this.validations['email'] ? this.validations['email'] : 'Not a valid email address.');
		} else if (errors['pattern']) {
			messages.push(this.validations['pattern'] ? this.validations['pattern'] : this.getRegexError());
		} else if (errors['emailsMatch']) {
			messages.push(this.validations['emailsMatch'] ? this.validations['emailsMatch'] : 'Primary and Secondary emails must be different.');
		} else if (this.validations) {
			const keys = Object.keys(this.validations);
			keys.forEach((key) => {
				if (errors[key]) {
					messages.push(this.validations[key]);
				}
			});
		}

		return messages;
	}

	get labelClasses() {
		let classObj = {
			"required-field": this.isRequired && !this.hideRequired,
		};
		let classesToAdd = this.customLabelClasses.split(" ");
		classesToAdd.forEach((className) => {
			classObj[className] = true;
		});
		return classObj;
	}

	constructor() {}

	ngAfterContentInit() {
		if (this.useCustomControl) {
			this.errorMessages;
			this.isError;
		} else {
			this.controlId = this.control?.id;
		}
	}

	/**
	 * Analyzes pattern validation errors and provides detailed feedback
	 * @returns A user-friendly string explaining validation failures
	 * 
	 * Example outputs:
	 * "Missing: uppercase letter, number. Invalid characters found: #, $"
	 * "Missing: format XXX-XXX-XXXX"
	 * "Missing: minimum length of 8 characters"
	 */
	getRegexError() {
		// Extract the regex pattern and actual value from the validation error
		const regex = this.control.errors['pattern'].requiredPattern;
		const value = this.control.errors['pattern'].actualValue;
		// Clean the regex string by removing delimiters
		let cleanRegex = regex.replace(/^\/|\/[a-z]*$/g, '');
		
		let unmetRequirements = [];
		let violations = [];

		// Check common pattern requirements - only add to unmetRequirements if the value fails the check
		const patternChecks = [
			// Only check for required patterns, not optional ones
			{ pattern: /[A-Z]+/, description: 'uppercase letter', required: /[A-Z]+/.test(cleanRegex) },
			{ pattern: /[a-z]+/, description: 'lowercase letter', required: /[a-z]+/.test(cleanRegex) },
			{ pattern: /\d+/, description: 'number', required: /\d+/.test(cleanRegex) && !cleanRegex.includes('0-9') },
			{ pattern: /[@$!%*?&#]/, description: 'special character', required: /[@$!%*?&#]/.test(cleanRegex) },
			{ pattern: /^\d{3}-\d{3}-\d{4}$/, description: 'format XXX-XXX-XXXX' },
			{ pattern: /^\d{2}\/\d{2}\/\d{4}$/, description: 'date format MM/DD/YYYY' }
		];
	
		// Check to if the pattern is required
		patternChecks.forEach(check => {
			if (check.required && !check.pattern.test(value)) {
				unmetRequirements.push(check.description);
			}
		});
		
		// Check length requirements from regex pattern like {8} or {8,16}
		let lengthMatch = cleanRegex.match(/\{(\d+),?(\d+)?\}/);
		if (lengthMatch) {
			let min = parseInt(lengthMatch[1]);
			let max = lengthMatch[2] ? parseInt(lengthMatch[2]) : null;
			
			if (max && (value.length < min || value.length > max)) {
				unmetRequirements.push(`length between ${min} and ${max} characters`);
			} else if (value.length < min) {
				unmetRequirements.push(`minimum length of ${min} characters`);
			}
		}

		// Identify specific characters that don't match the pattern
		try {
			let regexObj = new RegExp(cleanRegex);
			let disallowedChars = value.split('')
				.filter(char => !regexObj.test(char))
				.filter((char, index, self) => self.indexOf(char) === index);
				
			if (disallowedChars.length > 0) {
				violations.push(`Invalid character${disallowedChars.length > 1 ? "s" : ""} found: ${disallowedChars.join(', ')}`);
			}
		} catch (e) {
			console.warn('Regex pattern evaluation failed:', e);
		}

		// Construct the final error message
		let message = [];
		if (unmetRequirements.length > 0) {
			message.push(`Missing: ${unmetRequirements.join(', ')}`);
		}
		if (violations.length > 0) {
			message.push(violations.join('. '));
		}

		return message.join('. ') || 'Not valid.';
	}
}
