import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';

import { SystemMessageService } from 'app/core/system-message/system-message-service';
import { CiInvoicePdfVm } from 'app/shared/generated/CIRepack/Models/CiInvoices/CiInvoicePdfVm';

@Component({
    selector: 'pcg-invoice-pdf',
    templateUrl: './invoice-pdf.component.html',
    styleUrls: ['./invoice-pdf.component.scss']
})
export class CiInvoicePdfComponent implements OnInit, OnDestroy {

    code: string = null;
    id: string = null;
    vision: string; //

    model: CiInvoicePdfVm;
    model$: Observable<CiInvoicePdfVm>;

    dataIsLoaded: boolean = false;

    constructor(
        title: Title
        , public httpClient: HttpClient
        , public ms: SystemMessageService
        , private route: ActivatedRoute
        , public router: Router
    ) {
        title.setTitle('CI Invoicing PDF');
        // Hides header and footer
        document.querySelector('pcg-header')?.classList.add('d-none');
        document.getElementById('footer')?.classList.add('d-none');
        this.getRouteParams();
    }

    ngOnInit(): void {
        this.vision = "To be the Healthiest State in the Nation.";
        this.getModel();
    }

    ngAfterViewInit() {
        setTimeout(() => { this.printPage(); }, 300);
    }

    getRouteParams() {
        this.route.paramMap.subscribe(params => {
            this.code = params.get('code');
            this.id = params.get('id');
        });
    }

    getModel() {
        if (this.code != null) { this.getPdfPublic(); } 
        else if (this.id != null) { this.getPdfPrivate(); }
    }

    // Note: changes to public method must be applied to private and vice versa
    getPdfPublic() {
        this.model$ = this.httpClient.get(`api/PublicCiInvoicePdf/GetCiInvoicePdf?code=${this.code}`).pipe(
            tap((vm: CiInvoicePdfVm) => {
                if (vm == null) {
                    this.router.navigate(['/']);
                } else {
                    this.model = vm;
                    this.dataIsLoaded = true;
                }
            })
        );
    }

    // Note: changes to public method must be applied to private and vice versa
    getPdfPrivate() {
        this.model$ = this.httpClient.get(`api/CIRepack/CiInvoice/GetCiInvoicePdf?id=${this.id}`).pipe(
            tap((vm: CiInvoicePdfVm) => {
                this.model = vm;
                this.dataIsLoaded = true;
            })
        );
    }

    printPage() {
        // If all page elements are loaded and data has been received from the server, print the page
        if (this.dataIsLoaded) {
            window.print();
            window.close();
        }
    }

    ngOnDestroy() : void {
        document.querySelector('pcg-header')?.classList.remove('d-none');
        document.getElementById('footer')?.classList.remove('d-none');
    }
}
