import { trigger, state, animate, transition, style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

import { SystemMessageService, SystemMessageSettings } from './system-message-service';

/**
 * This component handles the display of system messages
 * by subscribing to changes in the SystemMessageService.
 */
@Component({
	selector: 'pcg-system-message',
	templateUrl: './system-message.component.html',
	styleUrls: ['./system-message.component.scss'],

	// Handles the fade in and fade out animation of system messages
	animations: [
		trigger('visibilityChanged', [
			state('true', style({ opacity: 1 })),
			state('false', style({ opacity: 0 })),
			transition('* => *', animate('.3s'))
		])
	],
})
export class SystemMessageComponent implements OnInit {
	model: SystemMessageSettings;
	public displayMessage: 'block' | 'none' = 'none';

	// Inject the SystemMessageService. This is a singleton
	// used throughout the application to send system messages.
	constructor(private messageService: SystemMessageService) { }

	ngOnInit() {
		// Set the initial model
		this.model = this.messageService.settings;

		// Update the model when we receive a new system message
		// or need to close it.
		this.messageService.settings$.subscribe(model => {
			this.model = model;
		});
	}

	/** This will use the SystemMessageService to send a
	 * new system message model with messageOpen set to false. */
	closeSystemMessage() {
		this.messageService.closeSystemMessage();
	}
}
