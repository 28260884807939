import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';
import { EditAgencyVm, EditAgencyVmFromFg } from '../../HEROS/Models/EditAgencyVm';
import { EditApplicantVm, EditApplicantVmFromFg } from '../../HEROS/Models/EditApplicantVm';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class EditPublicApplicationVm {
	agency?: EditAgencyVm;
	application?: EditApplicantVm;
	confirmedMessages?: string[];

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'agency': EditAgencyVm.Form,
			'application': EditApplicantVm.Form,
			'confirmedMessages': new UntypedFormControl([], [...(validators['confirmedMessages'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): EditPublicApplicationVmFromFg {
		return new EditPublicApplicationVmFromFg(fg);
	}
}

export class EditPublicApplicationVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get agency(): EditAgencyVmFromFg {
		return new EditAgencyVmFromFg(this.fg.get('agency') as UntypedFormGroup);
	}
	set agency(val) {
		(this.fg.get('agency') as UntypedFormGroup).setValue(val);
	}

	get application(): EditApplicantVmFromFg {
		return new EditApplicantVmFromFg(this.fg.get('application') as UntypedFormGroup);
	}
	set application(val) {
		(this.fg.get('application') as UntypedFormGroup).setValue(val);
	}
}
