<form (ngSubmit)="checkCredential()" [formGroup]="pinForm">
	<div class="modal-header">
		<h5 class="modal-title" id="modal-basic-title">Register PIN</h5>
		<button type="button" class="close" aria-label="Close" (click)="dismiss.emit()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p>Please enter your preferred PIN:</p>
		<div class="row">
			<pcg-control-group class="col-sm-12 pin">
				<pcg-show-hide-pin id="pin" fcName="pin" [parentForm]="pinForm" label="PIN (4 digits)"
					[validations]="{ pattern: 'PIN must include only numbers.' }" type="password"></pcg-show-hide-pin>
			</pcg-control-group>
		</div>
		<div class="row">
			<pcg-control-group class="col-sm-12">
				<pcg-show-hide-pin id="pin" fcName="confirmPin" [parentForm]="pinForm" label="Confirm PIN"
					[validations]="{ pinsNotEqual: 'Pins do not match.' }" type="password"></pcg-show-hide-pin>
			</pcg-control-group>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-cancel btn-lg" (click)="dismiss.emit()">Cancel</button>
		<button type="submit" class="btn btn-save btn-lg">Register</button>
	</div>
</form>