<form [formGroup]='fg'>
	<pcg-control-group [formGroupName]='fgName' [ngSwitch]='field.customFieldType' class="disabled-as-text">
		<!------------------------------------->
		<!-- Label                           -->
		<!------------------------------------->
		<label *ngIf="field.name" [ngbTooltip]="field.tooltip" [ngClass]="{ 'required-field': field.isRequired }"
			[for]='field.key' class="control-label for">{{field.name}}</label>

		<!------------------------------------->
		<!-- Date picker                     -->
		<!------------------------------------->

		<input type="date" *ngSwitchCase="CustomFieldTypeEnum.Date" pcgControlRef [labelForId]="field.key"
			[formControlName]='field.key' placeholder="MM/dd/yyyy" class="form-control" maxlength="10"
			[ngModel]="field.key | date:'yyyy-MM-dd'" />

		<!------------------------------------->
		<!-- MultiSelect / dropdown          -->
		<!------------------------------------->

		<pcg-select *ngSwitchCase="CustomFieldTypeEnum.MultiSelect" pcgControlRef [id]="field.key"
			[formControlName]="field.key" [items]='items' [multiple]='true'></pcg-select>

		<!------------------------------------->
		<!-- Select / dropdown               -->
		<!------------------------------------->

		<pcg-select *ngSwitchCase="CustomFieldTypeEnum.DropDown" pcgControlRef [id]="field.key"
			[formControlName]="field.key" [items]='items' placeholder='Select a value'></pcg-select>

		<!------------------------------------->
		<!-- Textbox for numeric input       -->
		<!------------------------------------->

		<input *ngSwitchCase="CustomFieldTypeEnum.Number" pcgControlRef [id]="field.key" class='form-control'
			[formControlName]="field.key" type="number" />

		<!------------------------------------->
		<!-- Text area for multi-line input  -->
		<!------------------------------------->

		<textarea *ngSwitchCase="CustomFieldTypeEnum.TextArea" class='form-control' pcgControlRef [id]="field.key"
			[formControlName]="field.key" type="text"></textarea>

		<!------------------------------------->
		<!-- Textbox and all masking options -->
		<!------------------------------------->

		<ng-container *ngSwitchCase="CustomFieldTypeEnum.TextBox">
			<!-- Default -->
			<input class='form-control' pcgControlRef [id]="field.key" *ngIf='field.customFieldMask === null'
				[formControlName]="field.key" type="text" />

			<!-- Phone -->
			<input pcgPhone class='form-control' pcgControlRef [id]="field.key"
				*ngIf='field.customFieldMask === CustomFieldMaskEnum.Phone' [formControlName]="field.key" type="text" />

			<!-- Phone /w extension -->
			<input pcgPhoneExt class='form-control' pcgControlRef [id]="field.key"
				*ngIf='field.customFieldMask === CustomFieldMaskEnum.PhoneExt' [formControlName]="field.key"
				type="text" />

			<!-- Zip Code -->
			<input class='form-control' pcgControlRef [id]="field.key"
				*ngIf='field.customFieldMask === CustomFieldMaskEnum.ZipCode' [formControlName]="field.key"
				type="text" />

			<!-- Currency -->
			<input pcgCurrency class='form-control' pcgControlRef [id]="field.key"
				*ngIf='field.customFieldMask === CustomFieldMaskEnum.Currency' [formControlName]="field.key"
				type="text" />

			<!-- Percentage -->
			<input pcgPercentage class='form-control' pcgControlRef [id]="field.key"
				*ngIf='field.customFieldMask === CustomFieldMaskEnum.Percentage' [formControlName]="field.key"
				type="text" />

			<!-- NDC -->
			<input pcgNdc class='form-control' pcgControlRef [id]="field.key"
				*ngIf='field.customFieldMask === CustomFieldMaskEnum.Ndc' [formControlName]="field.key" type="text" />
			<!------------------------------------->
		</ng-container>
	</pcg-control-group>
</form>