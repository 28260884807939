<div id="systemMessagesWrapper"
	 [@visibilityChanged]="model.messageOpen" (@visibilityChanged.start)="displayMessage = 'block'" (@visibilityChanged.done)="displayMessage = ( model.messageOpen ? 'block' : 'none')"
	 [style.display]="displayMessage" [ngStyle]="model.centeredStyles" (click)="closeSystemMessage()">
	<div [ngClass]="{ 'd-none': model.firstLoad }">
		<span id="systemMessageCloser">
			X
		</span>

		<div id="systemMessages" [ngClass]="[ model.messageClass ]">
			<span id="sysMessageHeader">{{ model.messageClass }}!</span>
			{{ model.message }}
			<span id="sysMessLink" [innerHtml]="model.link"></span> 
			<span id="sysMessTime">{{ model.openDate | date: 'hh:mm:ss a' }}</span>
		</div>
	</div>
</div>
