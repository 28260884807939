<div *ngIf="model$ | async" id="pdfTable" #pdfTable class="portrait">
    <header class="d-flex justify-content-around">
        <div class="box mission-vision p-4">
            <p><b>Mission</b></p>
            <p class="text">{{ model.ciInvoiceVm.missionStatement }}</p>

            <!-- FIXME: Should this be hardcoded or do we have a space to log this? -->
            <p><b>Vision:</b> {{ vision }}</p>
        </div>
        <div class="box logos p-2"> 
            <div class="text-center">
                <img src="assets\images\logos\DOH_logo.png" 
                    alt="FL Department of Health" 
                    width="127" height="147">
            </div>
            <div class="text-center mt-3 ml-1">
                <img src="assets/images/logos/logo_small.png" 
                    alt="HOMER (Health Operations & Medical Emergency Response)" 
                    width="133" height="33">
            </div>
        </div>
        <div class="box people p-4"> 
            <div class="text-right">
                <p class="name"><b> {{ model.ciInvoiceVm.governor }} </b></p>
                <p class="title">Governor</p>
            </div>
            <div class="text-right">
                <p class="name"><b> {{ model.ciInvoiceVm.surgeonGeneral }} </b></p>
                <p class="title">State Surgeon General & Secretary</p>
            </div>
        </div>
    </header>
    
    <!-- Invoice Order Info -->
    <section class="d-flex justify-content-around px-4 pt-2 order">
        <!-- Facility Info -->
        <div class="box">
            <p class="label info">Ordering Facility</p>
            <p class="info"><b> {{ model.ciInvoiceVm.facilityName }} </b></p>
            <p class="info"> {{ model.ciInvoiceVm.facilityAddress }} </p>
            <p class="info"> {{ model.ciInvoiceVm.facilityCity }}, {{ model.ciInvoiceVm.facilityState}} {{ model.ciInvoiceVm.facilityZip}} </p>

            <p class="label info">Order By / Date</p>
            <p class="info">{{ model.orderBy }}</p>
            <p class="info">{{ model.orderDate | utcToLocal | date: 'short'  }}</p>

            <p class="label">Facility Identifier / DBPR Permit 50</p>
            <p class="info">{{ model.ciInvoiceVm.facilityIdentifier }} </p>
            <p class="info">{{ model.permit50 }} </p>
        </div>
        <!-- BPHP Address -->
        <div class="box text-center">
            <p class="info"><b>Bureau of Public Health Pharmacy</b></p>
            <p class="info"><b>(BPHP) - Repack</b></p>
            <p class="info"><b>Tallahassee, FL 32304</b></p>
            <p class="info"><b>Permit # 2829</b></p>
            <p class="info"><b>Permit # 50238</b></p>
        </div>
        <!-- Invoice Info -->
        <div class="box text-right">
            <p class="label info">Invoice Number</p>
            <p> {{ model.ciInvoiceVm.invoiceNumber }} </p>

            <p class="label info">Invoice Date</p>
            <p> {{ model.ciInvoiceVm.invoiceDateStr }} </p>

            <p class="label">Invoice Amount Due</p>
            <p>{{ model.ciInvoiceVm.invoiceAmount | currency }}</p>
        </div>
    </section>

    
    <!-- Line Items -->
    <section class="line-items">
        <section class="d-none d-print-block">
            <!-- Adding lots of spacing to make sure footer is at the bottom -->
            <br>
            <h2 class="text-center my-5">See Invoice Line Items on the following page(s).</h2>
            <br>
        </section>

        <!-- Interagency Agreement Statement -->
        <div class="text-center">
            <p>
                In accordance with Inter-Agency Agreement <b><u>{{ model.ciInvoiceVm.interAgencyAgreementNumber }}</u></b> please Journal Transfer
                the funds for the expenditures below to the following benefiting information for
                repackaging services for <b>Repack Order ID: {{ model.ciInvoiceVm.orderNumber }}, <u>{{ model.ciInvoiceVm.facilityIdentifier }}</u>:</b>
            </p>
        </div>

        <!-- Account Info -->
        <div class="d-flex justify-content-center">
            <div>
                <p><b>Account Code:</b></p>
                <p><b>Org/EO/OCA:</b></p>
                <p><b>BF Obj Code:</b></p>
                <p><b>BF Category:</b></p>
            </div>
            <div class="ml-2">
                <p>{{ model.ciInvoiceVm.accountCode }}</p>
                <p>{{ model.ciInvoiceVm.org }}/{{ model.ciInvoiceVm.eo }}/{{ model.ciInvoiceVm.oca }}</p>
                <p>{{ model.ciInvoiceVm.bfObjCode }}</p>
                <p>{{ model.ciInvoiceVm.bfCategory }}</p>
            </div>
        </div>

        <!-- Don't hate the player. Hate the game. -->
        <div class="d-none d-print-block my-4"><br></div>
        <div class="d-print-none"><br></div>

        <div class="d-flex justify-content-end">
            <div class="mr-3">
                <p>Please send a copy of the voucher schedule ATTN: </p>
            </div>
            <div class="mr-3">
                <p class="attn">{{ model.ciInvoiceVm.attentionToUser }}</p>
                <p class="attn">{{ model.ciInvoiceVm.attentionToUserEmail }}</p>
                <p class="attn">{{ model.ciInvoiceVm.attentionToUserPhoneNumber }}</p>
            </div>
            <div>
                <p>(name)</p>
                <p>(email)</p>
                <p>(phone/x)</p>
            </div>
        </div>

        <!-- Roll Up Table -->
        <br class="d-print-none">
        <table class="table table-striped table-bordered d-print-none">
            <thead class="thead-dark">
                <tr>
                    <th>Type</th>
                    <th class="text-right">Cards / Strip Packs</th>
                    <th class="text-right">Invoiced Units*</th>
                    <th class="text-right">Cost / Unit</th>
                    <th class="text-right">Total Cost</th>
                </tr>
            </thead>
            <tbody>
                <tr >
                    <td>Card (A Type)</td>
                    <td class="text-right">{{ model.totals.totalInvoicedCards }}</td>
                    <td class="text-right">{{ model.totals.totalActualInvoicedCards }}</td>
                    <td class="text-right">{{ model.totals.cardCost | currency }}</td>
                    <td class="text-right">{{ model.totals.totalInvoiceCardsAmount | currency }}</td>
                </tr>
                <tr >
                    <td>Strip</td>
                    <td class="text-right">{{ model.totals.totalInvoicedStrips }}</td>
                    <td class="text-right">{{ model.totals.totalActualInvoicedStrips }}</td>
                    <td class="text-right">{{ model.totals.stripCost | currency }}</td>
                    <td class="text-right">{{ model.totals.totalInvoiceStripsAmount | currency }}</td>
                </tr>
                <tr class="bg-dark">
                    <td colspan="1" class="text-white no-right-border"><b>Totals:</b></td>
                    <td colspan="1" class="text-white text-right no-right-border no-left-border">{{model.totals.totalInvoiceUnits}}</td>
                    <td colspan="1" class="text-white text-right no-right-border no-left-border">{{model.totals.totalActualInvoiceUnits}}</td>
                    <td colspan="2" class="text-white text-right no-left-border">{{ model.ciInvoiceVm.invoiceAmount | currency }}</td>
                </tr>
            </tbody>
        </table>

        <!-- Line Item Table -->
        <br class="d-print-none">
        <table class="table table-striped table-bordered d-print-none">
            <thead class="thead-dark">
                <tr>
                    <th>Drug | Strength | Form</th>
                    <th>NDC</th>
                    <th class="text-right">Type</th>
                    <th class="text-right">Invoiced Units*</th>
                    <th class="text-right">Count / Unit</th>
                    <th class="text-right">Cost / Unit</th>
                    <th class="text-right">Total Cost</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let row of model.ciInvoiceLineItems; let i = index;">
                    <tr >
                        <td>{{ row.drugName }} | {{ row.strength }} | {{ row.form}}</td>
                        <td>{{row.ndc}}</td>
                        <td class="text-right">{{ row.isStripPack ? "Strip" : "Card (A Type)" }}</td>
                        <td class="text-right">{{ row.actualInvoicedUnits }}</td>
                        <td class="text-right">{{ row.countPerUnit }}</td>
                        <td class="text-right">{{ row.costPerUnit | currency }}</td>
                        <td class="text-right">{{ row.totalCost | currency }}</td>
                    </tr>
                </ng-container>
                <tr class="bg-dark">
                    <td colspan="2" class="text-white no-right-border"><b>Totals:</b></td>
                    <td colspan="2" class="text-right text-white no-right-border no-left-border">
                        {{model.totals.totalActualInvoicedCards}}-Cards, {{model.totals.totalActualInvoicedStrips}}-Strips, {{model.totals.totalActualInvoiceUnits}}-Total
                    </td>
                    <td colspan="3" class="text-right text-white no-left-border">{{ model.ciInvoiceVm.invoiceAmount | currency }}</td>
                </tr>
            </tbody>
        </table>

        <p class="d-print-none">*For Strip Packs, Invoiced Units = # of Strip Packs / 30, rounded up to the next whole number.</p>

        <!-- Don't hate the player. Hate the game. -->
        <div class="d-none d-print-block my-5"><br></div>
        <div class="d-print-none"><br></div>
    </section>

    <footer class="d-flex p-4">
        <!-- FDOH Address and Contact Info -->
        <div class="box">
            <p class="info"><b>{{ model.ciInvoiceVm.bureau }}</b></p>
            <p class="info"> {{ model.ciInvoiceVm.division }} </p>
            <!-- FIXME: Make sure this is the correct facility name and address -->
            <p class="info"> {{ model.ciInvoiceVm.sendingFacilityName }} </p>
            <p class="info"> {{ model.ciInvoiceVm.sendingFacilityAddress }} </p>
            <p class="info"> PHONE: {{ model.ciInvoiceVm.contactPhone }} | FAX: {{ model.ciInvoiceVm.contactFax}}</p>
        </div>
        <!-- Socials -->
        <div class="box text-right">
            <p class="info"><b>www.FloridasHealth.com</b></p>
            <p class="info social-media">{{ model.ciInvoiceVm.socialMedia }}</p>
        </div>
    </footer>

    <!-- This will only display on the print view. -->
            
    <!-- Print Roll Up Table -->
    <table class="table table-striped table-bordered print d-none d-print-table">
        <thead class="thead-dark">
            <tr>
                <th>Type</th>
                <th class="text-right">Cards / Strip Packs</th>
                <th class="text-right">Invoiced Units*</th>
                <th class="text-right">Cost / Unit</th>
                <th class="text-right">Total Cost</th>
            </tr>
        </thead>
        <tbody>
            <tr >
                <td>Card (A Type)</td>
                <td class="text-right">{{ model.totals.totalInvoicedCards }}</td>
                <td class="text-right">{{ model.totals.totalActualInvoicedCards }}</td>
                <td class="text-right">{{ model.totals.cardCost | currency }}</td>
                <td class="text-right">{{ model.totals.totalInvoiceCardsAmount | currency }}</td>
            </tr>
            <tr >
                <td>Strip</td>
                <td class="text-right">{{ model.totals.totalInvoicedStrips }}</td>
                <td class="text-right">{{ model.totals.totalActualInvoicedStrips }}</td>
                <td class="text-right">{{ model.totals.stripCost | currency }}</td>
                <td class="text-right">{{ model.totals.totalInvoiceStripsAmount | currency }}</td>
            </tr>
            <tr class="bg-dark">
                <td colspan="1" class="no-right-border"><b>Totals:</b></td>
                <td colspan="1" class="text-right no-right-border no-left-border"><b>{{model.totals.totalInvoiceUnits}}</b></td>
                <td colspan="1" class="text-right no-right-border no-left-border"><b>{{model.totals.totalActualInvoiceUnits}}</b></td>
                <td colspan="2" class="text-right no-left-border"><b>{{ model.ciInvoiceVm.invoiceAmount | currency }}</b></td>
            </tr>
        </tbody>
    </table>
    <br class="d-none d-print-table">

    <!-- Print Line Item Table -->
    <table class="table table-striped table-bordered d-none d-print-table">
        <thead class="thead-dark">
            <tr class="d-print-table-row">
                <th>Drug | Strength | Form</th>
                <th>NDC</th>
                <th class="text-right">Type</th>
                <th class="text-right">Invoiced Units*</th>
                <th class="text-right">Count / Unit</th>
                <th class="text-right">Cost / Unit</th>
                <th class="text-right">Total Cost</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let row of model.ciInvoiceLineItems; let i = index;">
                <tr>
                    <td>{{ row.drugName }} | {{ row.strength }} | {{ row.form}}</td>
                    <td>{{row.ndc}}</td>
                    <td class="text-right">{{ row.isStripPack ? "Strip" : "Card (A Type)" }}</td>
                    <td class="text-right">{{ row.actualInvoicedUnits }}</td>
                    <td class="text-right">{{ row.countPerUnit }}</td>
                    <td class="text-right">{{ row.costPerUnit | currency }}</td>
                    <td class="text-right">{{ row.totalCost | currency }}</td>
                </tr>
            </ng-container>
            <tr>
                <td colspan="2" class="no-right-border"><b>Totals:</b></td>
                <td colspan="2" class="text-right no-right-border no-left-border">
                    {{model.totals.totalActualInvoicedCards}}-Cards, {{model.totals.totalActualInvoicedStrips}}-Strips, {{model.totals.totalActualInvoiceUnits}}-Total
                </td>
                <td colspan="3" class="text-right no-left-border"><b>{{ model.ciInvoiceVm.invoiceAmount | currency }}</b></td>
            </tr>
        </tbody>
    </table>

    <p class="d-none d-print-table">*For Strip Packs, Invoiced Units = # of Strip Packs / 30, rounded up to the next whole number.</p>
</div>