import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { SpinnerService } from './spinner.service';

@Component({
	selector: 'pcg-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
	
	isLoading: boolean;

	constructor(
		public spinner: SpinnerService
		, private ref: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.isLoading = false;
		this.spinner.loadingSource$.subscribe(isLoading => {
			this.isLoading = isLoading;
			this.ref.detectChanges();
		});
	}
}
