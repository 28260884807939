import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TableModule } from "app/shared/table/table.module";
import { UserAssociatedOrganizationsComponent } from './user-associated-organizations/user-associated-organizations.component';
import { UserNonAssociatedOrganizationsComponent } from './user-non-associated-organizations/user-non-associated-organizations.component';
import { UserOrganizationService } from "./user-organization.service";

@NgModule({
    declarations: [
        UserNonAssociatedOrganizationsComponent,
        UserAssociatedOrganizationsComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        TableModule,
        NgbTooltipModule
    ],
    exports: [
        UserNonAssociatedOrganizationsComponent,
        UserAssociatedOrganizationsComponent
    ],
    providers: [
        UserOrganizationService
    ]
})

export class UserOrganizationsModule {}