<div class="file-upload-ctnr d-flex flex-wrap justify-content-between">
    <!-- Drop zone -->
    @if (!disabled) {
        <div *ngIf="
            maxFiles > 0 
            && showButtons
            && fileCount < maxFiles" class="drop-zone-ctnr p-2">
            <label class="for">{{ labelStr }} <small>{{ allowedFilesMessage }}</small></label>
            <div *ngIf="showDragDrop && canEdit" id="divDropZone" class="drop-zone" pcgDragDrop
                (files)="filesDropped($event)">
                <div *ngIf="!isMobile">
                    <b>Drag and Drop</b>
                    <br />
                    or
                    <br />
                </div>
                <!-- 'Choose File' buttons -->
                <div *ngIf="showButtons" class="uploaded-ctnr">
                    <div *ngIf="maxFiles > 0" class="uploaded-item">
                        <!-- Here label acts as the input button, and the input is hidden -->
                        <label [for]="'fileUploader'" class="custom-file-btn">
                            <fa-icon [icon]="faIconName.faFilePlus" class="mr-1"></fa-icon>
                            Choose File
                        </label>
                        <input [id]="'fileUploader'" class="form-control-file" type="file"
                            (change)="uploadFile($event, 'fileUploader')" />
                    </div>
                </div>
            </div>
        </div>
    }

    <!-- Staged files -->
    <div *ngIf="maxFiles > 0 && pcgFiles?.length > 0" class="staged-files-ctnr p-2 mr-auto">
        <div *ngIf="pcgFiles?.length > 0" class="staged-files">
            <label class="for">
                Staged files
                <fa-icon [icon]="faIconName.faCircleCheck" class="check"
                    ngbTooltip="Submit form to complete file upload"></fa-icon>
            </label>
            <div *ngFor="let f of pcgFiles" class="uploaded-ctnr">
                <div class="uploaded-item">
                    {{ f.fileName }}
                    <button *ngIf="!disabled" type="button" (click)="$event.stopPropagation(); removeAttachment(f)"
                        class="delete"><fa-icon [icon]="faIconName.faTrashCan"></fa-icon></button>
                </div>
            </div>
        </div>
    </div>

    <!-- Uploaded files -->
    <div *ngIf="fileCount > 0" class="uploaded-files p-2 ml-auto" [ngClass]="{'ml-0' : maxFiles <= 0 }">
        <label class="for">
            Uploaded files
            <fa-icon [icon]="faIconName.faCircleCheck" class="check"></fa-icon>
        </label>

        <!-- static -->
        @if(fileList != null) {
        <div class="uploaded-ctnr">
            <div *ngFor="let f of fileList" class="uploaded-item">
                <a [href]="f.fileUrl" target="_blank">{{ f.fileName }}</a>
                <button *ngIf="!disabled" type="button" (click)="delete(f.fileName)" class="delete">
                    <fa-icon [icon]="faIconName.faTrashCan"></fa-icon>
                </button>
            </div>
        </div>
        } @else {
        <div class="uploaded-ctnr">
            @for(f of fileList$ | async; track f) {
            <div class="uploaded-item">
                <a [href]="f.fileUrl" target="_blank">{{ ( f.fileName.length > 30 && truncateFileName ) ? (f.fileName | slice:0:30) + '...' : f.fileName }}</a>
                <button *ngIf="!disabled" type="button" (click)="delete(f.fileName)" class="delete">
                    <fa-icon [icon]="faIconName.faTrashCan"></fa-icon>
                </button>
            </div>
            }
        </div>
        }
    </div>
</div>

<div *ngIf="isError" class="w-100 alert alert-danger text-center mt-3">{{ errorString }}</div>