<br />
<div class="container">
    @if (!isMobile) {
        <div class="row">
            <div class="col-md-12 text-center">
                <img 
                    alt="HOMER - Health Operations & Medical Emergency Response" 
                    class='system-logo' 
                    src='assets/images/logos/logo.png' 
                />
            </div>
        </div>
        <br />
    }

    @if (isRescheduled) {
        <div class="alert alert-warning text-center">
            The ICQ Review Visit has been rescheduled. Please check your email to view the new date and time.
        </div>
    } @else {
        <div class="d-flex flex-column align-items-center">
            @if (approve) {
                <h1>Approved!</h1>
                <br>
                <span class="text-center">
                    You have approved the time <b>{{ model?.scheduleDateTimeStr }}</b> for the ICQ review for {{ model?.facilityName }} at
                    {{ model?.facilityStreetAddress }}, {{ model?.facilityCity }}, {{ model?.facilityStateAbr }}, {{ model?.facilityZip }}
                </span>
            } @else {
                <h1>Declined!</h1>
                <br>
                <span class="text-center">
                    You have declined the time <b>{{ model?.scheduleDateTimeStr }}</b> for the ICQ review for {{ model?.facilityName }} at
                    {{ model?.facilityStreetAddress }}, {{ model?.facilityCity }}, {{ model?.facilityStateAbr }}, {{ model?.facilityZip }}
                </span>
            }
        </div>
    }
</div>