import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { AuditHistoryVm } from 'app/shared/generated/Models/AuditHistoryVm';
import { SecurityService } from 'app/core/security/security.service';
import { NavigationService } from 'app/shared/navigation/navigation.service';
import { TableComponent } from 'app/shared/table/table.component';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';
import { BusinessAreaEnum } from 'app/core/enums/generated/BusinessAreaEnum';

@Component({
	selector: 'pcg-user-audit-history',
	templateUrl: './user-audit-history.component.html',
	styleUrls: ['./user-audit-history.component.scss']
})
export class UserAuditHistoryComponent implements OnInit {

	@ViewChild(TableComponent, { static: true }) table: TableComponent<AuditHistoryVm>;

	columns = AuditHistoryVm.ColumnDefs;
	canEditUser = false;
	fullName: string = null;

	filters = { userId: null };

	constructor(
		title: Title
		, private activatedRoute: ActivatedRoute
		, private sec: SecurityService
		, private nav: NavigationService
	) { title.setTitle('User Audit History'); }

	ngOnInit() {
		this.filters.userId = this.activatedRoute.snapshot.params['id'];
		this.canEditUser = this.sec.hasModuleAccess(
			[ BusinessAreaEnum.Admin ]
			, [ PermissionRole.Pharmacist ]
		) || this.sec.hasModuleAccess(
			SecurityService.anyArea()
			, [ PermissionRole.UserAdmin, ...SecurityService.setMinRole(PermissionRole.Manager) ]
		);
		this.setSecondaryNav();
	}
	
	/** Set the navigation that sits under the main navigation */
	setSecondaryNav() { if (this.canEditUser) { this.nav.setOpenSecondaryMenu(['Admin', 'Users', 'User List']); } }
}
