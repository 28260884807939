import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class LoginHistoryVm {
	attemptSuccessful?: string;
	userName?: string;
	userID: number;
	ipAddress?: string;
	dateAttempted: Date;
	dateAttemptedFormatted?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'attemptSuccessful': new UntypedFormControl(null, [...(validators['attemptSuccessful'] ?? []), ...[]]),
			'userName': new UntypedFormControl(null, [...(validators['userName'] ?? []), ...[]]),
			'userID': new UntypedFormControl(0, [...(validators['userID'] ?? []), ...[Validators.required]]),
			'ipAddress': new UntypedFormControl(null, [...(validators['ipAddress'] ?? []), ...[]]),
			'dateAttempted': new UntypedFormControl('', [...(validators['dateAttempted'] ?? []), ...[Validators.required]]),
			'dateAttemptedFormatted': new UntypedFormControl(null, [...(validators['dateAttemptedFormatted'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static get ColumnDefs(): Map<string, PcgTableColumn> {
		return new Map([
			['dateAttempted', {"orderId":0,"sortColumn":"dateAttempted","searchColumn":"dateAttemptedFormatted","includeInExcel":true,"exportColumns":null,"displayName":"Date Attempted","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['attemptSuccessful', {"orderId":1,"sortColumn":"attemptSuccessful","searchColumn":"attemptSuccessful","includeInExcel":true,"exportColumns":null,"displayName":"Attempt Successful?","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['userName', {"orderId":2,"sortColumn":"userName","searchColumn":"userName","includeInExcel":true,"exportColumns":null,"displayName":"Username","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}],
			['ipAddress', {"orderId":3,"sortColumn":"ipAddress","searchColumn":"ipAddress","includeInExcel":true,"exportColumns":null,"displayName":"IP Address","isVisible":true,"canSearch":true,"isNdc":false,"isNdc10":false,"isSum":false,"multiSearchCellClasses":null,"requiresFullAccess":false,"cellClass":null,"cellTransform":null,"cellNavigationColumn":null}]
		]);
	}

	static GetModel(fg: UntypedFormGroup): LoginHistoryVmFromFg {
		return new LoginHistoryVmFromFg(fg);
	}
}

export class LoginHistoryVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get attemptSuccessful(): string {
		return this.fg.get('attemptSuccessful').value;
	}
	set attemptSuccessful(val) {
		this.fg.get('attemptSuccessful').setValue(val);
	}

	get userName(): string {
		return this.fg.get('userName').value;
	}
	set userName(val) {
		this.fg.get('userName').setValue(val);
	}

	get userID(): number {
		return this.fg.get('userID').value;
	}
	set userID(val) {
		this.fg.get('userID').setValue(val);
	}

	get ipAddress(): string {
		return this.fg.get('ipAddress').value;
	}
	set ipAddress(val) {
		this.fg.get('ipAddress').setValue(val);
	}

	get dateAttempted(): Date {
		return this.fg.get('dateAttempted').value;
	}
	set dateAttempted(val) {
		this.fg.get('dateAttempted').setValue(val);
	}

	get dateAttemptedFormatted(): string {
		return this.fg.get('dateAttemptedFormatted').value;
	}
	set dateAttemptedFormatted(val) {
		this.fg.get('dateAttemptedFormatted').setValue(val);
	}
}
