import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImageSliderComponent } from './image-slider/image-slider.component';

@NgModule({
    imports: [ CommonModule ],
    exports: [ ImageSliderComponent ],
    declarations: [ ImageSliderComponent ]
})
export class ImageSliderModule { }
