// tslint:disable: quotemark
export enum ActivationEnum {
	CiRepackLabelPrinter = 0,
	User = 1,
	Facility = 2,
	CiFacility = 3,
	CiWorkstation = 4,
	CiZebraPrinter = 5,
	CiOfficePrinter = 6,
	Suppliers = 7,
	Inventory = 8,
	InventoryAction = 9,
	OrderingOptions = 10,
	InventoryCustomField = 11,
	AdminPrinterList = 12,
	ShippingAccount = 13,
	ShippingBox = 14,
	UserCustomField = 15,
	EditProduct = 16,
	EditGlobalNDC = 17,
	EditRx = 18,
	EditNonRx = 19,
	CiBox = 20,
	CiReworkReason = 21,
	HEROSAgency = 22,
	FacilityAccountName = 23,
	HelpDeskCategory = 24,
	HelpDeskPriority = 25,
	HelpDeskStatus = 26,
	HelpDeskTemplateResponse = 27,
	Organization = 28,
	Program = 29,
	FormularyProduct = 30,
	IcqIssue = 31,
	ErrorType = 32
}

export namespace ActivationEnum {
	export function toDescription(val: ActivationEnum) {
		switch (val) {
			case 0:
				return "CI Repack Label Printer";
			case 1:
				return "User";
			case 2:
				return "Facility";
			case 3:
				return "CI Facility";
			case 4:
				return "CI WorkStation";
			case 5:
				return "CI Zebra Printer";
			case 6:
				return "CI Office Printer";
			case 7:
				return "Suppliers";
			case 8:
				return "Inventory";
			case 9:
				return "Inventory Action";
			case 10:
				return "Ordering Options";
			case 11:
				return "Inventory Custom Field";
			case 12:
				return "Admin Printer List";
			case 13:
				return "Shipping Account";
			case 14:
				return "Shipping Box";
			case 15:
				return "User Custom Field";
			case 16:
				return "Edit Product";
			case 17:
				return "Edit Global NDC";
			case 18:
				return "Edit Rx";
			case 19:
				return "Edit Non Rx";
			case 20:
				return "CI Box";
			case 21:
				return "CI Rework Reason";
			case 22:
				return "HEROS Agency";
			case 23:
				return "Facility Account Name";
			case 24:
				return "Help Desk Category";
			case 25:
				return "Help Desk Priority";
			case 26:
				return "Help Desk Status";
			case 27:
				return "Help Desk Template Response";
			case 28:
				return "Organization";
			case 29:
				return "Program";
			case 30:
				return "Formulary Product";
			case 31:
				return "ICQ Issue";
			case 32:
				return "Error Reporting Error Type";
		}
		return null;
	}
	export function toDisplay(val: ActivationEnum) {
		switch (val) {
			case 0:
				return "CI Repack Label Printer";
			case 1:
				return "User";
			case 2:
				return "Facility";
			case 3:
				return "CI Facility";
			case 4:
				return "CI WorkStation";
			case 5:
				return "CI Zebra Printer";
			case 6:
				return "CI Office Printer";
			case 7:
				return "Suppliers";
			case 8:
				return "Inventory";
			case 9:
				return "Inventory Action";
			case 10:
				return "Ordering Options";
			case 11:
				return "Custom Field";
			case 12:
				return "Admin Printer List";
			case 13:
				return "Shipping Account";
			case 14:
				return "Shipping Box";
			case 15:
				return "User Custom Field";
			case 16:
				return "Edit Product";
			case 17:
				return "Edit Global NDC";
			case 18:
				return "Edit Rx";
			case 19:
				return "Edit Non Rx";
			case 20:
				return "CI Box";
			case 21:
				return "CI Rework Reason";
			case 22:
				return "HEROS Agency";
			case 23:
				return "Facility Account Name";
			case 24:
				return "Help Desk Category";
			case 25:
				return "Help Desk Priority";
			case 26:
				return "Help Desk Status";
			case 27:
				return "Help Desk Template Response";
			case 28:
				return "Organization";
			case 29:
				return "Program";
			case 30:
				return "Formulary Product";
			case 31:
				return "ICQ Issue";
			case 32:
				return "Error Reporting Error Type";
		}
		return null;
	}
}
