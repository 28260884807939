import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { NavRoute } from 'app/shared/navigation/nav.route.interface';
import { BusinessAreaEnum } from '../enums/generated/BusinessAreaEnum';
import { RxConnectsTransferEnum } from '../enums/generated/RxConnectsTransferEnum';
import { PermissionRole } from '../enums/generated/PermissionRole';
import { SecurityService } from '../security/security.service';

export const getRxConnectsNav: () => NavRoute = () => {
	const user = getUser();
	const sec = SecurityService;
	const p = PermissionRole; // Setting this to reduce text.
	const navRepositoryItems: NavRoute[] = getRepositoryAreas();
	const navDonorItems: NavRoute[] = getDonorAreas();

	if (user !== null) {
		return {
			name: 'RxConnects'
			, moduleAccess: [
				{
					modules: [ BusinessAreaEnum.RxConnects ]
					, permissionRoles: sec.setMinRole(p.User)
				}
			]
			, href: 'rxconnects/dashboard'
			, children: [
				{
					name: 'Dashboard'
					, exact: true
					, path: 'rxconnects/dashboard'
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.RxConnects ]
							, permissionRoles: sec.setMinRole(p.User)
						}
					]
				}
				, {
					name: 'Repository'
					, exact: true
					, children: navRepositoryItems
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.RxConnects ]
							, permissionRoles: [ PermissionRole.RxConnectsPharmacist ]
						}
					]
				}
				, {
					name: 'Donor'
					, exact: true
					, children: navDonorItems
					, moduleAccess: [
						{
							modules: [ BusinessAreaEnum.RxConnects ]
							, permissionRoles: [ PermissionRole.RxConnectsDonor ]
						}
					]
				}
			]
		};
	}
	return { name: "" };
};

function getRepositoryAreas(): NavRoute[] {

	const navRepositoryReportItems: NavRoute[] = getRepositoryReportAreas()

	return [
		{
			name: 'Patient',
			exact: true,
			children: [{
				name: 'Application List',
				path: 'rxconnects/patient-list',
				exact: true
			},
			{
				name: 'New Application',
				path: 'patient-application',
				exact: true
			},
			],
			moduleAccess: [{
				modules: [BusinessAreaEnum.RxConnects],
				permissionRoles: [PermissionRole.RxConnectsPharmacist]
			}]
		},		
		{
			name: 'Inventory',
			path: 'rxconnects/repository/inventory-list',
			exact: true,
		},
		{
			name: 'Transfer',
			exact: true,
			children: [{
				name: 'Transfer - Incoming',
				path: `rxconnects/inventory-transfer-list/${RxConnectsTransferEnum.In}`,
				exact: true
			},
			{
				name: 'Transfer - Outgoing',
				path: `rxconnects/inventory-transfer-list/${RxConnectsTransferEnum.Out}`,
				exact: true
			},
			],
			moduleAccess: [{
				modules: [BusinessAreaEnum.RxConnects],
				permissionRoles: [PermissionRole.RxConnectsPharmacist]
			}]
		},
		{
			name: 'Available-Donations',
			path: 'rxconnects/repository/available-donations',
			exact: true,
		},
		{
			name: 'Destruction of Drugs',
			path: 'rxconnects/destruction-drug-list',
			exact: true,
		},
		{
			name: 'Reports',
			exact: true,
			children: [{
				name: 'Drug Destruction',
				path: `rxconnects/destruction-report`,
				exact: true
			}],
			moduleAccess: [{
				modules: [BusinessAreaEnum.RxConnects],
				permissionRoles: [PermissionRole.RxConnectsPharmacist]
			}]
		},
		{
			name: 'Withdrawal',
			path: 'rxconnects/withdrawal',
			exact: true,
		},
	];
}

function getRepositoryReportAreas(): NavRoute[] {
	return [
		{
			name: 'Total patients served'
			, path: ''
			, exact: true
		}
		, {
			name: 'Total prescriptions dispensed'
			, path: ''
			, exact: true
		}
		, {
			name: 'Inventory'
			, path: ''
			, exact: true
		}
		, {
			name: 'Donors who have donated to them'
			, path: ''
			, exact: true
		}
		, {
			name: 'Destruction of Drugs report'
			, path: ''
			, exact: true
		}
		, {
			name: 'Pending Patient Applications'
			, path: ''
			, exact: true
		}
	];
}

function getDonorAreas(): NavRoute[] {
	return [
		{ 
			name: 'New Donation'
			, path: 'rxconnects/donation-details/0'
			, exact: true 
		}
		, { 
			name: 'Inventory'
			, path: 'rxconnects/donor/inventory-list'
			, exact: true 
		}
	];
}

function getUser(): UserJsVm {
	const userJson: string = localStorage.getItem('user');
	if (userJson !== null) { return JSON.parse(userJson); }
	return null;
}
