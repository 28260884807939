<pcg-table 
	[ajaxData]='{ userId: id }' 
	[multipleSearch]='false' 
	[pcgSort]='[[ "dateAttempted", "desc" ]]'
	dataSource='api/Administration/Users/GetLoginHistoryList' 
	[columnDefs]='columns'
>

	<ng-container matColumnDef="dateAttempted">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('dateAttempted').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row'
		>{{ row.dateAttemptedFormatted }}</td>
	</ng-container>

	<ng-container matColumnDef="attemptSuccessful">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('attemptSuccessful').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row'
		>{{ row.attemptSuccessful }}</td>
	</ng-container>

	<ng-container matColumnDef="userName">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('userName').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row'
		>{{ row.userName }}</td>
	</ng-container>

	<ng-container matColumnDef="ipAddress">
		<th 
			mat-header-cell 
			pcg-sort-header 
			*matHeaderCellDef
		>{{ columns.get('ipAddress').displayName }}</th>
		<td 
			mat-cell 
			*matCellDef='let row'
		>{{ row.ipAddress }}</td>
	</ng-container>
</pcg-table>
