import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PcgSortDirective } from './sort';
import { PcgSortHeaderComponent } from './sort-header';

@NgModule({
	declarations: [ 
		PcgSortDirective
		, PcgSortHeaderComponent 
	]
	, imports: [
		CommonModule
		, FormsModule
	]
	, exports: [ 
		PcgSortDirective
		, PcgSortHeaderComponent 
	]
})
export class SortModule { }
