import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import { PcgTableColumn } from '../../../table/interfaces/pcg-table-column.interface';

// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class KnowledgeBaseDocumentVm {
	documentId: number;
	module?: any;
	permissionRole?: any;
	title?: string;
	description?: string;
	content?: string;
	uploadFiles?: any;
	videoId?: string;
	sortOrder?: number;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'documentId': new UntypedFormControl(0, [...(validators['documentId'] ?? []), ...[Validators.required]]),
			'module': new UntypedFormControl(null, [...(validators['module'] ?? []), ...[]]),
			'permissionRole': new UntypedFormControl(null, [...(validators['permissionRole'] ?? []), ...[]]),
			'title': new UntypedFormControl(null, [...(validators['title'] ?? []), ...[Validators.required, Validators.maxLength(50)]]),
			'description': new UntypedFormControl(null, [...(validators['description'] ?? []), ...[Validators.maxLength(500)]]),
			'content': new UntypedFormControl(null, [...(validators['content'] ?? []), ...[]]),
			'uploadFiles': new UntypedFormControl([], [...(validators['uploadFiles'] ?? []), ...[]]),
			'videoId': new UntypedFormControl(null, [...(validators['videoId'] ?? []), ...[]]),
			'sortOrder': new UntypedFormControl(null, [...(validators['sortOrder'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): KnowledgeBaseDocumentVmFromFg {
		return new KnowledgeBaseDocumentVmFromFg(fg);
	}
}

export class KnowledgeBaseDocumentVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get documentId(): number {
		return this.fg.get('documentId').value;
	}
	set documentId(val) {
		this.fg.get('documentId').setValue(val);
	}

	get module(): any {
		return this.fg.get('module').value;
	}
	set module(val) {
		this.fg.get('module').setValue(val);
	}

	get permissionRole(): any {
		return this.fg.get('permissionRole').value;
	}
	set permissionRole(val) {
		this.fg.get('permissionRole').setValue(val);
	}

	get title(): string {
		return this.fg.get('title').value;
	}
	set title(val) {
		this.fg.get('title').setValue(val);
	}

	get description(): string {
		return this.fg.get('description').value;
	}
	set description(val) {
		this.fg.get('description').setValue(val);
	}

	get content(): string {
		return this.fg.get('content').value;
	}
	set content(val) {
		this.fg.get('content').setValue(val);
	}

	get videoId(): string {
		return this.fg.get('videoId').value;
	}
	set videoId(val) {
		this.fg.get('videoId').setValue(val);
	}

	get sortOrder(): number {
		return this.fg.get('sortOrder').value;
	}
	set sortOrder(val) {
		this.fg.get('sortOrder').setValue(val);
	}
}
