import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';


// tslint:disable:quotemark
// tslint:disable:max-line-length
// tslint:disable:whitespace
export class HelpDeskFeedbackVm {
	id: number;
	helpDeskTicketId: number;
	helpDeskTicketSubject?: string;
	helpDeskTicketFacility?: string;
	rating: number;
	comment?: string;
	userConcurrencyStamp?: string;

	static get FormData() {
		return this.getFormData();
	}

	static getFormData(validators = {}) {
		return {
			'id': new UntypedFormControl(0, [...(validators['id'] ?? []), ...[Validators.required]]),
			'helpDeskTicketId': new UntypedFormControl(0, [...(validators['helpDeskTicketId'] ?? []), ...[Validators.required]]),
			'helpDeskTicketSubject': new UntypedFormControl(null, [...(validators['helpDeskTicketSubject'] ?? []), ...[]]),
			'helpDeskTicketFacility': new UntypedFormControl(null, [...(validators['helpDeskTicketFacility'] ?? []), ...[]]),
			'rating': new UntypedFormControl(0, [...(validators['rating'] ?? []), ...[Validators.required, Validators.min(1), Validators.max(5)]]),
			'comment': new UntypedFormControl(null, [...(validators['comment'] ?? []), ...[Validators.maxLength(1000)]]),
			'userConcurrencyStamp': new UntypedFormControl(null, [...(validators['userConcurrencyStamp'] ?? []), ...[]])
		};
	}

	static get Form() {
		return new UntypedFormGroup(this.FormData);
	}

	static GetModel(fg: UntypedFormGroup): HelpDeskFeedbackVmFromFg {
		return new HelpDeskFeedbackVmFromFg(fg);
	}
}

export class HelpDeskFeedbackVmFromFg {
	constructor(public fg: UntypedFormGroup) { }


	get id(): number {
		return this.fg.get('id').value;
	}
	set id(val) {
		this.fg.get('id').setValue(val);
	}

	get helpDeskTicketId(): number {
		return this.fg.get('helpDeskTicketId').value;
	}
	set helpDeskTicketId(val) {
		this.fg.get('helpDeskTicketId').setValue(val);
	}

	get helpDeskTicketSubject(): string {
		return this.fg.get('helpDeskTicketSubject').value;
	}
	set helpDeskTicketSubject(val) {
		this.fg.get('helpDeskTicketSubject').setValue(val);
	}

	get helpDeskTicketFacility(): string {
		return this.fg.get('helpDeskTicketFacility').value;
	}
	set helpDeskTicketFacility(val) {
		this.fg.get('helpDeskTicketFacility').setValue(val);
	}

	get rating(): number {
		return this.fg.get('rating').value;
	}
	set rating(val) {
		this.fg.get('rating').setValue(val);
	}

	get comment(): string {
		return this.fg.get('comment').value;
	}
	set comment(val) {
		this.fg.get('comment').setValue(val);
	}

	get userConcurrencyStamp(): string {
		return this.fg.get('userConcurrencyStamp').value;
	}
	set userConcurrencyStamp(val) {
		this.fg.get('userConcurrencyStamp').setValue(val);
	}
}
