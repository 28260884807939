import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SystemMessage, SystemMessageService } from 'app/core/system-message/system-message-service';
import { validateForm } from '../form-validateForm.function';

@Directive({
	selector: '[pcgSubmitUrl]'
})
export class SubmitUrlDirective implements OnDestroy {

	@Input('pcgSubmitUrl') url: string;
	@Input() additionalFields: any;
	@Output() messageReceive: EventEmitter<SystemMessage> = new EventEmitter<SystemMessage>();
	@Output() messageSuccess: EventEmitter<SystemMessage> = new EventEmitter<SystemMessage>();
	@Output() messageError: EventEmitter<SystemMessage> = new EventEmitter<SystemMessage>();

	subscriptions = new Subscription();

	constructor(public formGroupDirective: FormGroupDirective, public ms: SystemMessageService) { }

	@HostListener('submit', ['$event']) onSubmit($event: any) {
		if (this.formGroupDirective.form.valid) { // Send system message save event if form valid
			this.subscriptions.add(
				this.ms.getHttpObservable(this, this.url, this.formGroupDirective.form, this.additionalFields)
				.subscribe((msg: SystemMessage) => {
					this.messageReceive.emit(msg);
					if (msg && msg.isSuccessful) { this.messageSuccess.emit(msg); }
					if (msg && !msg.isSuccessful) { this.messageError.emit(msg); }
				})
			);
		} else { // If form is not valid, show validation errors
			validateForm(this.formGroupDirective.form);
		}
	}

	// Unsubscribe to cancel any requests
	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
