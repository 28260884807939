import { EventEmitter, Injectable, Output } from '@angular/core';

import { TableFilterComponent } from '../table-filter/table-filter.component';

@Injectable({ providedIn: 'root' })
export class ButtonFilterService {

    @Output() toggleResetBtnVis: EventEmitter<any> = new EventEmitter();
    @Output() resetFilterData: EventEmitter<any> = new EventEmitter();

    private filters: TableFilterComponent[] = [];
    
    filterMap = { "isActive": "Is Active" };// Commonly used filter

    sendResetBtnVis(data: any): any { this.toggleResetBtnVis.emit(data); }
    sendResetFilterData(data: any): any { this.resetFilterData.emit(data); }

    // add modal to array of active modals
    addFilter(filter: any) { this.filters.push(filter); }

    // remove modal from array of active modals
    removeFilter(id: string) { this.filters = this.filters.filter(x => x.id !== id); }

    openFilter(id: string) {
        // open modal specified by id
        const filter = this.filters.find(x => x.id === id);
        filter.open();
    }

    closeFilter(id: string) {
        // close modal specified by id
        const filter = this.filters.find(x => x.id === id);
        filter.close();
    }

    /**
     * Get the applied filters to display above a table.
     * @param filters Filters used to for the table (ajaxData)
     * @param filterMap Key value array used to map filter properties to Display values. Ex. src\app\inventory\rx-fulfillment\shipment-list\shipment-list.component.ts
     * @param emptyFilters Filter array used for custom or misbehaving filters. Ex. src\app\inventory\rx-fulfillment\shipment-list\shipment-list.component.ts
     * @param useEmptyFilters Dictates whether to use the emptyFilters array, added to preserve behavior in older list screens.
     * @returns List of applied filters as a comma seperated string.
     */
    getActiveFilters(filters: {}, filterMap: {}, emptyFilters: {} = null, useEmptyFilters: boolean = false): string {
        // Empty array that will be populated with currently applied filters.
        let filtList = [];
        // Iterate through each of the filter objects, if any of them have a value add to the array.
        let c = Object.fromEntries(Object.entries(filters).filter(([key, val]) =>
            (!emptyFilters || !useEmptyFilters)
                ? (
                    (
                        val != null 
                        && val != "null"        // Where value is not null
                        && val?.toString()?.trim() != ""    // Where value is not an empty string 
					    && val != "[]"                      // Where value is not an empty array					
                        && (
                            typeof (val) != 'object' 
                            || (
                                val['dateBegin'] != null 
                                && val['dateEnd'] != null
                            )
                        )
                    ) // Checking empty date ranges and UI switches
                    != (
                        key == "deactivateAgenciesHideFilters" 
                        && val == false
                    ) //only apply Deactivated Agencies filter if filter is true
                ) : (
                    key in emptyFilters 
                    && emptyFilters[key] !== val
                )
            )
        );
        Object.entries(c).forEach(([key]) => { 
            if (filterMap[key.toString()] !== undefined) { filtList.push(filterMap[key.toString()]); } 
        });
        return filtList.join(", ");
    }
}
