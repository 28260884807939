import { Injectable } from '@angular/core';
import { PermissionRole } from 'app/core/enums/generated/PermissionRole';

@Injectable({
    providedIn: 'root'
})
export class UserRoleInfoServiceService {

    constructor() {}

    addSec(a) {
        a.name = a.name;
        a.user = a.user;
        a.userAdmin = a.userAdmin ?? a.user;
        a.technician = a.technician ?? a.userAdmin;
        a.manager = a.manager ?? a.technician;
        a.pharmacist = a.pharmacist ?? a.manager;
        a.systemAdmin = a.systemAdmin ?? true;
        return a;
    }

    addSecData(jsonData: any) {
        var modalData = [];
        for (var permission = 0; permission < jsonData.length; permission++) {
            var secAccessObj = {
                name: jsonData[permission].PermissionName,
                descr: jsonData[permission].PermissionDescription,
                user: false,
                manager: false,
                pharmacist: false,
                technician: false,
                systemAdmin : false,
                userAdmin: false
            }
            for(var role = 0; role < jsonData[permission].PermissionInfoRoles.length; role++) {
                if (secAccessObj.user == null || secAccessObj.user == false) {
                    secAccessObj.user = jsonData[permission].PermissionInfoRoles[role].PermissionRole == PermissionRole.User;
                }
                if (secAccessObj.manager == null || secAccessObj.manager == false) {
                    secAccessObj.manager = jsonData[permission].PermissionInfoRoles[role].PermissionRole == PermissionRole.Manager;
                }
                if (secAccessObj.pharmacist == null || secAccessObj.pharmacist == false) {
                    secAccessObj.pharmacist = jsonData[permission].PermissionInfoRoles[role].PermissionRole == PermissionRole.Pharmacist;
                }
                if (secAccessObj.technician == null || secAccessObj.technician == false) {
                    secAccessObj.technician = jsonData[permission].PermissionInfoRoles[role].PermissionRole == PermissionRole.Technician;
                }  
                if (secAccessObj.userAdmin == null || secAccessObj.userAdmin == false) {
                    secAccessObj.userAdmin = jsonData[permission].PermissionInfoRoles[role].PermissionRole == PermissionRole.UserAdmin;
                }   
                if (secAccessObj.systemAdmin == null || secAccessObj.systemAdmin == false) {
                    secAccessObj.systemAdmin = jsonData[permission].PermissionInfoRoles[role].PermissionRole == PermissionRole.SystemAdmin;
                }     
            }
            modalData.push(secAccessObj);
            
        }
        return modalData;
    }
}
