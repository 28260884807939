import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { WorkstationService } from 'app/ci-repack/admin/workstations/workstation.service';
import { SecurityService } from 'app/core/security/security.service';
import { UserJsVm } from 'app/shared/generated/Administration/Models/Users/UserJsVm';
import { CiDashboardActionsComponent } from '../ci-dashboard-actions/ci-dashboard-actions.component';

@Component({
    selector: 'pcg-workstation-banner',
    templateUrl: './workstation-banner.component.html',
    styleUrls: ['./workstation-banner.component.scss']
})
export class WorkstationBannerComponent implements OnInit {
    @ViewChild('ciDashboardComponent') ciDashboardComponent : TemplateRef<CiDashboardActionsComponent>

    user: UserJsVm
    workStationName: string
    getModelRetries: number = 0;
    noWorkstation = false;
    dashboardActionsClicked = false;    
    isOpen = false;

    constructor(private workStationService: WorkstationService, private sec: SecurityService) {
        this.user = this.sec.getUser();
    }

    ngOnInit(): void { this.getModel(); }

    getModel() {
        this.workStationService.getModel(this.user?.ciWorkstationId).subscribe(() => {
            setTimeout(() => {
                this.workStationName = this.workStationService.workStationName;
                this.noWorkstation = this.workStationService.noWorkstation;
                // Call this method again if getting name fails, maximum of 5 retries.
                if (!this.workStationName && this.getModelRetries <= 5) { 
                    this.getModelRetries++;
                    this.getModel();
                } else { this.getModelRetries = 0; }
            }, 100)
        });
    }

    openCiWorkstationModal() {
        this.workStationService.openCiWorkstationModal();
        // When a user closes the modal to select their workstation,
        // an event will be emitted to update the data on the UI.
        this.workStationService.update.subscribe(() => { this.getModel(); });
    }
}
