import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnDestroy, OnInit, Optional, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';

import { param } from '../../../http-params';
import { SelectItem } from '../../../models/select-item';

export const CUSTOM_SELECT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	multi: true,
};

@Component({
	selector: 'pcg-select',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PcgSelectComponent),
			multi: true,
		},
	],
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss']
})
export class PcgSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {

	@Input() dataSource: string | undefined = undefined;
	@Input() labelForId = '';
	@Input('value') innerValue: any = null;
	@Input() queryParams: any = {};
	@Input() cascadeTo: PcgSelectComponent[] = [];
	@Input() items: SelectItem[] | null = [];
	@Input() sourceItems: SelectItem[] | null = [];
	@Input() placeholder: string | undefined;
	@Input() multiple = false;
	@Input() disabled = false;
	@Input() clearable = true;
	@Input() getDataOnInit = true;
	@Input() virtualScroll = false;
	@Input() sendFormData = true;
	@Input() showDisabled = false;
	@Input() selectFirstValue: boolean = false;
	/**
	 * To speed up queries, this property was added for situations where
	 * you want to open a dropdown after deferring certain logic.
	 	*   To optimize a slow query, the decision to defer fully populating our 
		*	`items` property came with certain side effects. Most notably, closing
		*	the dropdown between API calls resulted in the user having to click
		* 	twice to view their dropdown data. 
	 * The `openDropdownOnDefer` property should only be used with a dynamic boolean.
	 * This dynamic boolean should be initialized to false and updated through 
	 * its model to true after your defer logic is hit. Failure to do so will result in
	 * dropdowns being set to open before the user interacts with them.
	 */
	@Input() openDropdownOnDefer: boolean = false;
	@Input() itemsShowLimit: number = 4;
	@Input() selectedItemIds: number[] = [];
	@Output() itemLoad = new EventEmitter<SelectItem[] | null>();
	@Output() changeEvent = new EventEmitter<any>();
	@Output() change = this.changeEvent;
	@ViewChild('theSelect', { static: true })
	
	select: NgSelectComponent;
	text: string | null | undefined = null;
	groupBy: string | null | undefined = null;
	additionalData: any;
	formControl: NgControl | null = null;

	// Show a native select for better mobile support if we have
	// (for now) a single dropdown and mobile device
	isMobile: boolean = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);

	get showNativeSelect() { return !this.multiple && this.isMobile; }

	subscriptions = new Subscription();

	onChange: any = () => {};
	onTouched: any = () => {};

	get value() { return this.innerValue; }

	set value(val) {
		if (this.innerValue !== val) {
			this.innerValue = val;
			this.addSelectedMissingDisabledItems();
			this.setProps();
			this.onTouched();
			this.onChange(val);
			this.cascadeLoadSelectData();
		}
	}

	private get form() { return this.fg?.formDirective ? (this.fg.formDirective as FormGroupDirective).form : null; }

	@HostListener('window:resize')
	onResize() { }

	constructor(
		private http: HttpClient
		, private element: ElementRef
		, private _injector: Injector
		, @Optional() private fg: ControlContainer
	) { }

	ngOnInit() {
		// Get the select component's form control, if any
		this.formControl = this._injector.get(NgControl);

		// Single select will not be clearable if no placeholder is provided
		if (!this.multiple && (this.placeholder === '' || !this.placeholder)) { this.clearable = false; }

		// Get the data if the getDataOnInit input is true (it is by default)
		if (this.getDataOnInit) { this.getData(); }

		const updateVal = () => {
			this.triggerChanged();
			this.addSelectedMissingDisabledItems();
			this.setProps();
			this.onChange(this.value);
		}

		// Automatically select the first value in the select if there is only one value in the select
		if (this.selectFirstValue) {
			this.subscriptions.add(
				this.itemLoad.subscribe(() => {
					if (this.formControl?.control?.value === null && this.items?.length === 1) {
						this.value = this.items[0].value;
					}
				})
			);
		}

		this.subscriptions.add(this.select?.clearEvent?.subscribe(updateVal));
		this.subscriptions.add(this.select?.changeEvent?.subscribe(updateVal));
	}

	ngOnChanges(changes: SimpleChanges): void {
		// When the developer decided to defer some logic and 
		// the items property has been updated, open the select
		// dropdown to fix bug that closes dropdown between 
		// updates.
		if(changes['items'] && this.openDropdownOnDefer) { this.select.open(); }
	}

	/**
	 * Performs an API call to get data to show in the select component
	 *
	 * @param dataSource The URL to fetch from. Null will use the URL passed to the select component.
	 * @param markAsUntouched Whether or not ta mark as untouched after retrieving data to fix validation.
	 */
	getData(dataSource: string | null = null, markAsUntouched = false) {
		// Use either the dataSource passed in, or..
		if (dataSource !== null) { this.dataSource = dataSource; } 
		else { dataSource = this.dataSource!; } // if none, use the component dataSource

		// Begin getting the request ready if we have one
		if (this.dataSource) {
			// Add queryParams and form data to dataSource if none were passed in URL (based on no '?')
			if (this.sendFormData && !this.dataSource.includes('?')) {
				dataSource += '?' + param({ ...(this.form?.getRawValue() ?? {}), ...this.queryParams });
			}

			// Do the web request to get the data
			this.subscriptions.add(
				this.http.get(dataSource).subscribe((items: SelectItem[]) => {
          			const itemList = items as SelectItem[];

					// Set the items from the server dataSource as sourceItems
					this.sourceItems = itemList;
					// Items may not include disabeld items, depending on the showDisabled setting
					this.items = this.showDisabled ? itemList : itemList?.filter(o => !o.disabled);
					// Disabled items will still show if one happens to be selected, however
					this.addSelectedMissingDisabledItems();

					// If we don't have a value and the dropdown isn't clearable,
					// select the first value in the dropdown
					if (this.items && this.items.length > 0 && !this.clearable && !this.value) {
						this.value = this.items[0].value;
					}

					// Set the text, groupBy, and additionalData properties based on the currently selected value
					this.setProps();

					// Show selected items first
					var selectedItems: Array<SelectItem> = [];
					var i = 0;
					while (i < items.length) {
						if (this.selectedItemIds?.some(id => id == items[i].value)) {
							selectedItems.push(items[i]);
							const index = items.indexOf(items[i]);
							items.splice(index, 1);
						} else { i++; }
					}
					selectedItems.forEach((item) => { items.unshift(item); });
					this.items = items;

					// Emit an event indicating that items have been loaded from the server
					this.itemLoad.emit(this.items);
					// Mark field as untouched, if desired
					if (markAsUntouched) { this.formControl?.control?.markAsUntouched(); }
				})
			);
		}
	}

	/** Handles removing disabled fields and adding them back if selected */
	addSelectedMissingDisabledItems() {
		if (this.sourceItems?.length === 0) { this.sourceItems = this.items; }
		// Add item if in original data, but was taken out because disabled
		if (!this.showDisabled) {
			this.items = this.sourceItems?.filter(o => o.value === this.value || !o.disabled) ?? this.items;
		}
	}

	/** Sets the text, groupBy, and additionalData properties based on the currently selected value */
	setProps() {
		if (this.items) {
			const val = this.items.find(i => i.value === this.innerValue);
			if (val) {
				this.text = val.text;
				this.groupBy = val.groupBy;
				this.additionalData = val.additionalData;
			}
			// If we have a multi select,
			// * text will be a comma separated list
			// * groupBy and additionalData will not be set because there are multiple values
			else if (this.multiple) {
				this.text = this.items
					.filter(o => this.innerValue?.includes(o.value))
					.map(o => o.text.trim())
					.join(', ');
				this.groupBy = undefined;
				this.additionalData = undefined;
			}
			// Otherwise, make everything undefined if value was not found in items
			else {
				this.text = undefined;
				this.groupBy = undefined;
				this.additionalData = undefined;
			}
		}
		// Set everything to null if items are not loaded
		else {
			this.text = null;
			this.groupBy = null;
			this.additionalData = null;
		}
	}

	/** Clear value and item list of select component */
	clear() {
		this.value = null;
		this.items = null;
	}

	triggerChanged() {
		const event = new CustomEvent('change', { bubbles: true });
		this.element.nativeElement.dispatchEvent(event);
		if (this.cascadeTo.length > 0) {
			for (let i = 0; i < this.cascadeTo.length; ++i) {
				this.cascadeTo[i].clear();
				this.cascadeTo[i].getData(null, true);
			}
		}
	}

	/** Handles cascading to other dropdowns */
	cascadeLoadSelectData() {
		if (this.cascadeTo.length > 0) {
			for (let i = 0; i < this.cascadeTo.length; ++i) {
				this.cascadeTo[i].clear();
				this.cascadeTo[i].getData(null, true);
			}
		}
	}

	/** From ControlValueAccessor interface */
	writeValue(obj: any): void { if (obj !== this.value) { this.value = obj; } }

	/** From ControlValueAccessor interface */
	registerOnChange(fn: any): void { this.onChange = fn; }

	/** From ControlValueAccessor interface */
	registerOnTouched(fn: any): void { this.onTouched = fn; }

	/** From ControlValueAccessor interface */
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.select?.setDisabledState(isDisabled);
	}

	/** Remove subscriptions on component destruction */
	ngOnDestroy() { this.subscriptions.unsubscribe(); }
}
