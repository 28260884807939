<div class="container pt-4">
	<div class="row ">
		<div class="col-md-2 text-center pl-0">
			<img src="\assets\images\logos\dohLogo.jpg" class="img-logo">
		</div>
		<div class="col-md-8">
			<h2 class="d-flex flex-column justify-content-center align-items-center header">
				PRESCRIPTION DRUG DONATION PROGRAM
			</h2>
			<h4 class="d-flex flex-column justify-content-center align-items-center header">
				REPOSITORY PARTICIPATION FORM
			</h4>

		</div>
	</div>
</div>
<div class="container">
	<ul>
		<li>Questions about completion of this form may be directed to the Bureau of Public Health Pharmacy at (850)
			841-8530.</li>
	</ul>
</div>

<div class="container border border-dark">

	<div class="row">
		<div class="col edit-body">
			APPLICATION – PHARMACY OR MEDICAL FACILITY
		</div>
	</div>

	<form [formGroup]='formGroup' (submit)='saveRepository()'>
		<div class="container clearfix">
			<div class='row'>
				<pcg-control-group class="col-sm-8" label="Name of Pharmacy or Medical Facility">
					<input type="text" pcgControlRef class="form-control" formControlName="name" />
				</pcg-control-group>

				<pcg-control-group class='col-md-4' label='Type of Facility'>
					<pcg-select pcgControlRef [multiple]='false'
						dataSource='api/PublicRepositoryRegister/RxConnectsFacilityType'
						formControlName='facilityTypeId' placeholder='Select a Facility'>
					</pcg-select>
				</pcg-control-group>
			</div>

			<div class='row'>
				<pcg-control-group class="col-sm-2" label="Facility Telephone Number" [validations]="phoneValidation">
					<input pcgPhoneExt type="text" pcgControlRef class="form-control" formControlName="phoneNumber" />
				</pcg-control-group>

				<pcg-control-group class="col-sm-6" label="License/Registration Issuing Agency">
					<pcg-select pcgControlRef [multiple]='false'
						dataSource='api/PublicSelect/RxConnectsLicensingAgencies' formControlName='licensingAgencyID'
						[sendFormData]='false' placeholder='Select a License/Registration Issuing Agency'>
					</pcg-select>
				</pcg-control-group>

				<pcg-control-group class="col-sm-4" label="License/Registration Number">
					<input type="text" pcgControlRef class="form-control" formControlName="licenseNumber" />
				</pcg-control-group>
			</div>

			<div class='row'>
				<pcg-control-group class="col-sm-8" label="Website (if you do not have a website, indicate “N/A”)">
					<input type="text" pcgControlRef class="form-control" formControlName="websiteAddress" />
				</pcg-control-group>

				<pcg-control-group class="col-sm-4" label="Email Address">
					<input type="text" pcgControlRef class="form-control email" formControlName="emailAddress" />
				</pcg-control-group>
			</div>

			<div class='row'>
				<pcg-control-group class="col-sm-8" label="Address">
					<pcg-smarty pcgControlRef formControlName="smartyAddress" [showAllStates]="false"
						[canManualEntry]="canManualEntry" [getFullZip]="true" (change)='updateCounty()'>
					</pcg-smarty>
				</pcg-control-group>

				<pcg-control-group class="col-sm-4" label="County">
					<pcg-select pcgControlRef [multiple]='false' dataSource='api/PublicSelect/Counties'
						formControlName='countyId' placeholder='Select a County' [sendFormData]='false'>
					</pcg-select>
				</pcg-control-group>
			</div>

			<div class='row'>
				<pcg-control-group class="col-sm-8" label="Name-Pharmacist">
					<input type="text" pcgControlRef class="form-control" formControlName="pharmacistName" />
				</pcg-control-group>

				<pcg-control-group class="col-sm-4" label="Telephone Number" [validations]="phoneValidation">
					<input pcgPhoneExt type="text" pcgControlRef class="form-control"
						formControlName="pharmacistContactPhone" />
				</pcg-control-group>
			</div>

			<div class='row'>
			</div>

			<div class="row">
				<pcg-control-group class="col-md-12" label='Attestation'>
					<div class="form-check">
						<input class="form-check-input" pcgControlRef id="isAttested" formControlName='isAttested'
							type="checkbox" required />
						<label class='form-check-label' style="font-weight:bold;" for='isAttested'>
							I attest that the above-named facility is licensed in the State of Florida and complies with
							all applicable state and
							federal laws and administrative rules, including the requirements of section 465.1902,
							Florida Statutes.
						</label>
					</div>
				</pcg-control-group>
			</div>

			<div class='row'>
				<pcg-control-group class="col-md-8" label="SIGNATURE-Responsible Pharmacist">
					<input type="text" pcgControlRef class="form-control" formControlName="pharmacistSignature" />
				</pcg-control-group>

				<pcg-control-group class="col-md-4" label="Date Signed">
					<input type="text" pcgControlRef class="form-control" readonly="readonly"
						formControlName="dateSigned" />
				</pcg-control-group>
			</div>

			<div class="row alert alert-success" role="alert" *ngIf='this.disableSave'>
				<b>Thank you for submitting your application to become a repository in the Rx Connects Program. We are
					processing your application
					and will notify your facility via email provided upon completion.</b>
			</div>
		</div>

		<pcg-fixed-buttons *ngIf='!this.disableSave'>
			<a class='btn btn-cancel btn-lg' routerLink='/rxconnects/eligibility'>Cancel</a>
			<button type='submit' class="btn btn-save btn-lg" [disabled]='this.disableSave'>Submit</button>
		</pcg-fixed-buttons>

		<pcg-control-group class='form-inline' hidden>
			<input id="control-pot" type="checkbox" pcgControlRef formControlName='honeyPot' />
		</pcg-control-group>

		<ngx-invisible-recaptcha #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (ready)="handleReady()"
			(load)="handleLoad()" (success)="handleSuccess($event)" [useGlobalDomain]="false" theme="light"
			type="normal" badge="bottomleft" formControlName="recaptcha">
		</ngx-invisible-recaptcha>
	</form>
</div>
<meta-pixel />
